import { Route } from "react-router-dom";

import { HookPage } from "./hookDetails/components/HookPage";
import { HookList } from "./hooks/components/HooksList";
import { NewHookForm } from "./newHook/components/NewHookForm";

const Routes = [
    <Route
        key="hooks"
        path="/:accountId/settings/hooks"
        exact
        component={HookList}
    />,
    <Route
        key="new_hook"
        path="/:accountId/settings/hooks/new"
        exact
        component={NewHookForm}
    />,
    <Route
        key="hook_details"
        path="/:accountId/settings/hooks/:hookAccountId/:hookId"
        exact
        component={HookPage}
    />,
];

export default Routes;
