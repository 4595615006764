import { createSelector } from "reselect";
import { State } from "../../../reducer";
import { namespace } from "./index";

const getPayoutRulesSelectorState = (state: State) => state[namespace];

export const getPayoutRulesSelector = createSelector(
    [getPayoutRulesSelectorState],
    (s) => s,
);
