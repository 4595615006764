import { Dispatch } from "redux";
import apiRequest from "../apiRequest";
import { createAccountIdChangedCaseReducer } from "../auth/useAccountId";
import { CHECKOUT_API_HOSTNAME } from "../env";
import { errorExternalStore } from "../errors";
import { CheckoutConfig } from "../types/checkout";
import { CheckoutConfigWrite } from "../types/checkout/generated";
import { createSwitchlessReducer } from "../util/switchlessReducer";

const namespace = "checkout.config" as const;

export type CheckoutConfigurationState = {
    isLoading: Record<string, boolean>;
    checkoutConfiguration: Record<string, CheckoutConfig>;
    updatedSuccessfully: boolean;
};

const initialState: CheckoutConfigurationState = {
    isLoading: {},
    checkoutConfiguration: {},
    updatedSuccessfully: false,
};

const { setState, reducer } = createSwitchlessReducer({
    initialState,
    namespace,
    globalReducer: createAccountIdChangedCaseReducer(() => initialState),
});

const changeLoading =
    (accountId: string, newLoadingState: boolean) =>
    (
        state: CheckoutConfigurationState,
    ): Partial<CheckoutConfigurationState> => ({
        isLoading: {
            ...state.isLoading,
            [accountId]: newLoadingState,
        },
    });

const changeUpdated =
    (accountId: string, newUpdatedState: boolean, newLoadingState: boolean) =>
    (
        state: CheckoutConfigurationState,
    ): Partial<CheckoutConfigurationState> => ({
        isLoading: {
            ...state.isLoading,
            [accountId]: newLoadingState,
        },
        updatedSuccessfully: newUpdatedState,
    });

const createCheckoutConfigurationActions = (
    dispatch: Dispatch,
    accountId: string,
) => {
    const url = `${CHECKOUT_API_HOSTNAME}/v1/admin/checkout`;

    const getCheckoutConfig = async (aid = accountId) => {
        dispatch(
            setState("getCheckoutConfiguration", changeLoading(aid, true)),
        );

        try {
            const checkoutConfig = await apiRequest("GET", aid, url);
            dispatch(
                setState("getCheckoutConfigurationSuccess", (state) => ({
                    ...state,
                    ...changeLoading(aid, false)(state),
                    checkoutConfiguration: {
                        ...state.checkoutConfiguration,
                        [aid]: checkoutConfig,
                    },
                })),
            );
        } catch (err) {
            dispatch(
                setState(
                    "getCheckoutConfigurationFailure",
                    changeLoading(aid, false),
                ),
            );
            if (err.status !== 404) {
                errorExternalStore.dispatch("setError", err);
            }
        }
    };

    const updateCheckoutConfig = async (
        aid = accountId,
        updatedCheckoutConfiguration: CheckoutConfigWrite,
    ) => {
        dispatch(
            setState(
                "putCheckoutConfiguration",
                changeUpdated(aid, false, true),
            ),
        );

        try {
            const checkoutConfig = await apiRequest(
                "PUT",
                aid,
                url,
                {},
                updatedCheckoutConfiguration,
            );
            dispatch(
                setState("putCheckoutConfigurationSuccess", (state) => ({
                    ...state,
                    ...changeUpdated(aid, true, false)(state),
                    checkoutConfiguration: {
                        ...state.checkoutConfiguration,
                        [aid]: checkoutConfig,
                    },
                })),
            );
        } catch (err) {
            dispatch(
                setState(
                    "putCheckoutConfigurationFailure",
                    changeUpdated(aid, false, false),
                ),
            );
            errorExternalStore.dispatch("setError", err);
        }
    };
    return {
        getCheckoutConfig,
        updateCheckoutConfig,
    };
};

export { initialState, namespace, reducer, createCheckoutConfigurationActions };
