import { TFunction } from "i18next";
import { DropdownOption } from "../../../components/Forms/Dropdown";

export const getTypeOptions = (t: TFunction) =>
    [
        {
            label: t("locations.location_form.fields.physical"),
            value: "Physical",
        },
        {
            label: t("locations.location_form.fields.online"),
            value: "Mobile",
        },
    ] as DropdownOption[];

export const getCountryOptions = (t: TFunction) => {
    return [
        {
            label: t("locations.location_form.fields.norway"),
            value: "NO",
        },
        {
            label: t("locations.location_form.fields.sweden"),
            value: "SE",
        },
        {
            label: t("locations.location_form.fields.denmark"),
            value: "DK",
        },
    ] as DropdownOption[];
};

export const getFastTrackOptions = (t: TFunction) =>
    [
        {
            label: t(
                "payout:payout_destination.form.payout_destination_description.auto_approve",
            ),
            value: "AUTO_APPROVE",
        },
        {
            label: t(
                "payout:payout_destination.form.payout_destination_description.auto_decline",
            ),
            value: "AUTO_DECLINE",
        },
        {
            label: t(
                "payout:payout_destination.form.payout_destination_description.auto_wait_for_signature",
            ),
            value: "AUTO_WAIT_FOR_SIGNATURE",
        },
    ] as DropdownOption[];
