import { applyMiddleware, compose, createStore } from "redux";
import effects from "./effects";
import { NODE_ENV } from "./env";
import { initialState, reducer } from "./reducer";
import { createSideEffects } from "./sideEffects";

const getComposeEnhancer = () => {
    // https://github.com/zalmoxisus/redux-devtools-extension
    if (NODE_ENV === "development") {
        return (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    }
    return compose;
};

const sideEffects = createSideEffects();
const composeEnhancers = getComposeEnhancer();
const store = createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(sideEffects)),
);
sideEffects.add(effects);

export default store;
