import styled from "styled-components/macro";
import { PUBLIC_URL } from "../../../env";

const basename = PUBLIC_URL ? PUBLIC_URL : "";

const PaymentLogoMark = ({ logo, label }: { logo: string; label: string }) => {
    return (
        <PaymentLogoWrapper>
            <img
                src={`${basename}/assets/images/payment_types_logomarks/${logo}.svg`}
                alt={label}
            />
        </PaymentLogoWrapper>
    );
};

const PaymentLogoWrapper = styled.span`
    width: 47px;
    margin-right: 12px;
    display: inline-block;
    & > img {
        max-height: 100%;
        max-width: 100%;
    }
`;

export default PaymentLogoMark;
