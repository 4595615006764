import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { Formik } from "formik";
import { TFunction } from "i18next";
import { useAccountId } from "../../../auth/useAccountId";
import Modal from "../../../components/Modal";
import { H1, P } from "../../../components/Typography";
import {
    validate,
    validateEmail,
    validateRequired,
} from "../../../helpers/validation";
import { SettlementReportConfigDraft } from "../../../reports/settlementList/types";
import { colors, distances } from "../../../styles/constants";
import { Account } from "../../../types/management-auth";
import { PayoutDestinationOption } from "../../../util/usePayoutDestinations";
import {
    ConfigurationForm,
    SettlementReportConfigurationFormikValues,
} from "./ConfigurationForm";

export interface CreateConfigurationModalProps {
    accountIds: string[];
    dismissModal: () => void;
    createConfiguration: (
        accountId: string,
        configuration: SettlementReportConfigDraft,
    ) => Promise<boolean>;
    destinations: PayoutDestinationOption[];
    createError: undefined | "bad_request" | "conflict";
    account: Account | undefined;
}

const toConfiguration = (
    values: SettlementReportConfigurationFormikValues,
): SettlementReportConfigDraft => {
    const { accountId, emails, accountEmail, ...config } = values;
    const destinations: SettlementReportConfigDraft["destinations"] = emails
        .filter((email) => !!email)
        .map((email) => {
            return {
                destination_type: "email",
                destination_value: email,
            };
        });
    if (accountEmail) {
        destinations.push({
            destination_type: "account_email",
        });
    }
    const updatedFilters = config.filters?.filter((item) => item.value !== "*");
    return {
        destinations,
        filetypes: config.filetypes,
        providers: config.providers,
        filters: updatedFilters,
    };
};

const validateForm = (
    values: SettlementReportConfigurationFormikValues,
    t: TFunction,
) => {
    const validators = [
        {
            path: "filetypes",
            validator: validateRequired(
                t("settings.settlement_report_config.form.invalid.filetypes"),
            ),
        },
    ];
    if (values.emails.length === 0) {
        validators.push({
            path: "accountEmail",
            validator: validateRequired(
                t(
                    "settings.settlement_report_config.form.invalid.account_email.required",
                ),
            ),
        });
    }
    (values.emails || []).forEach((value, i) => {
        validators.push({
            path: `emails.${i}`,
            validator: validateEmail(
                t(
                    "settings.settlement_report_config.form.invalid.email.invalid",
                ),
            ),
        });
    });

    return validators.reduce((acc, elem) => {
        return validate(elem.path, elem.validator, values, acc);
    }, {});
};

const CreateConfigurationModal = ({
    accountIds,
    dismissModal,
    createConfiguration,
    destinations,
    createError,
    account,
}: CreateConfigurationModalProps) => {
    const { t } = useTranslation();
    const accountId = useAccountId();

    const accountBillingEmailAddress = account?.billing.email;
    const initialEmailList = accountBillingEmailAddress ? [] : [""];

    const initialValues: SettlementReportConfigurationFormikValues = {
        accountId: accountId,
        emails: initialEmailList,
        filters: [],
        filetypes: ["csv", "pdf", "xml"],
        providers: [],
        accountEmail: !!accountBillingEmailAddress,
    };
    return (
        <Modal fixedWidth width="600px">
            <H1>{t("settings.settlement_report_config.create.title")}</H1>
            <TextWrapper>
                <P>
                    {t("settings.settlement_report_config.create.description")}
                </P>
            </TextWrapper>
            {createError && (
                <ErrorMessage>
                    {t("settings.settlement_report_config.create.error", {
                        context: createError,
                    })}
                </ErrorMessage>
            )}
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validate={(values) => {
                    return validateForm(values, t);
                }}
                onSubmit={async (values, formikBag) => {
                    const configuration = toConfiguration(values);
                    const result = await createConfiguration(
                        values.accountId,
                        configuration,
                    );
                    formikBag.setSubmitting(result);
                }}
            >
                {(formik) => {
                    return (
                        <ConfigurationForm
                            operation="create"
                            formik={formik}
                            destinations={destinations}
                            accountIds={accountIds}
                            dismissModal={dismissModal}
                            accountBillingEmailAddress={
                                accountBillingEmailAddress
                            }
                        />
                    );
                }}
            </Formik>
        </Modal>
    );
};

export default CreateConfigurationModal;

const ErrorMessage = styled.div`
    padding: ${distances.small};
    background-color: ${colors.interactiveBackgroundInvalid};
    color: ${colors.invalid};
    border: 1px solid ${colors.invalid};
    border-radius: 4px;
`;

const TextWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.normal};
`;
