import { connect } from "react-redux";

import { State } from "../../../../reducer";
import {
    clearFetchedData,
    deletePayoutRule,
    fetchPayoutRule,
} from "../actions";
import PayoutRuleDetails from "../components/PayoutRuleDetails";
import { payoutRuleDetailsSelector } from "../selectors";

const mapStateToProps = (state: State) => ({
    payoutRuleDetailsState: payoutRuleDetailsSelector(state),
});

const mapDispatchToProps = {
    fetchPayoutRule,
    deletePayoutRule,
    clearFetchedData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayoutRuleDetails);
