import { BillingPayoutRule } from "../../../types/billing";
import { SalesLocation } from "../../../types/customers";

export enum ActionTypes {
    FetchPayoutRule = "[payout_rules/fetch_payout_rule] FETCH_PAYOUT_RULE",
    FetchPayoutRuleResponse = "[payout_rules/fetch_payout_rule] FETCH_PAYOUT_RULE_RESPONSE",
    EditPayoutRule = "[payout_rules/payout_rule_edit] EDIT_PAYOUT_RULE",
    EditPayoutRuleResponse = "[payout_rules/_payout_rule_edit] EDIT_PAYOUT_RULE_RESPONSE",
    ResetForm = "[payout_rules/reset_form] RESET_PAYOUT_RULE_FORM",
    FetchLocations = "[payout_rules/payout_rule_edit] FETCH_LOCATIONS",
    FetchLocationsResponse = "[payout_rules/payout_rule_edit] FETCH_LOCATIONS_RESPONSE",
}

export interface FetchPayoutRule {
    type: ActionTypes.FetchPayoutRule;
    payload: {
        accountId: string;
        payoutRuleId: string;
    };
}

export interface FetchPayoutRuleResponse {
    type: ActionTypes.FetchPayoutRuleResponse;
    payload: {
        payoutRules: BillingPayoutRule[];
        payoutRuleId: string;
    };
    meta: {
        cause: FetchPayoutRule;
    };
}

export function fetchPayoutRule(
    accountId: string,
    payoutRuleId: string,
): FetchPayoutRule {
    return {
        type: ActionTypes.FetchPayoutRule,
        payload: {
            accountId,
            payoutRuleId,
        },
    };
}

export interface EditPayoutRule {
    type: ActionTypes.EditPayoutRule;
    payload: {
        accountId: string;
        payoutRule: BillingPayoutRule;
        payoutRuleId: string;
    };
}

export interface EditPayoutRuleResponse {
    type: ActionTypes.EditPayoutRuleResponse;
    payload: {
        payoutRule: BillingPayoutRule;
    };
    meta: {
        cause: EditPayoutRule;
    };
}

export function editPayoutRule(
    accountId: string,
    payoutRule: BillingPayoutRule,
    payoutRuleId: string,
): EditPayoutRule {
    return {
        type: ActionTypes.EditPayoutRule,
        payload: {
            accountId,
            payoutRule,
            payoutRuleId,
        },
    };
}

export interface ResetForm {
    type: ActionTypes.ResetForm;
    payload: {};
}

export function resetForm() {
    return {
        type: ActionTypes.ResetForm,
        payload: {},
    };
}

export interface FetchLocations {
    type: ActionTypes.FetchLocations;
    payload: {
        accountId: string;
    };
}

export interface FetchLocationsResponse {
    type: ActionTypes.FetchLocationsResponse;
    payload: SalesLocation[];
    meta: {
        cause: FetchLocations;
    };
}

export const fetchLocations = (accountId: string): FetchLocations => {
    return {
        type: ActionTypes.FetchLocations,
        payload: {
            accountId,
        },
    };
};

export type Actions =
    | FetchPayoutRule
    | FetchPayoutRuleResponse
    | EditPayoutRule
    | EditPayoutRuleResponse
    | ResetForm
    | FetchLocations
    | FetchLocationsResponse;
