import createExternalStoreSelector from "../../common/utils/createExternalStoreSelector";
import { decodeTokenScopes } from "./helpers";
import authStore from "./observables/authStore";

export const getUser = createExternalStoreSelector(authStore, (state) => state);
export const getAccountUser = createExternalStoreSelector(
    authStore,
    (state) => state.account_user,
);
export const getCognitoToken = createExternalStoreSelector(
    authStore,
    (state) => state.cognito_access_token,
);
export const getUserId = createExternalStoreSelector(authStore, (state) => {
    const prodAccountId = Object.keys(state.tokens).find((k) =>
        k.startsWith("P"),
    );
    if (prodAccountId) {
        const prodAccount = state.account_user.accounts.find(
            (a) => a.account_id === prodAccountId,
        );
        if (prodAccount) {
            return prodAccount.user.id;
        }
    }
    return "";
});
export const getTokens = createExternalStoreSelector(
    authStore,
    (state) => state.tokens,
);
export const getToken = (_: unknown, accountId: string) =>
    authStore.select((state) => state.tokens)[accountId];
export const getIsLoadingTokens = createExternalStoreSelector(
    authStore,
    (state) => state.isLoadingTokens,
);
export const getIsLoadingSubAccountTokens = createExternalStoreSelector(
    authStore,
    (state) => state.isLoadingSubAccountTokens,
);
export const getExchangeTokenPartnerId = createExternalStoreSelector(
    authStore,
    (state) => state.exchangeTokenPartnerId,
);
export const getChangePasswordState = createExternalStoreSelector(
    authStore,
    (state) => state.changePasswordState,
);
export const getMfaSmsState = createExternalStoreSelector(
    authStore,
    (state) => state.changeMfaSmsState,
);
export const getMfaTotpState = createExternalStoreSelector(
    authStore,
    (state) => state.changeMfaTotpState,
);
export const selectTokenScopes = (_: unknown, accountId: string) => {
    const tokens = authStore.select((state) => state.tokens);
    return decodeTokenScopes(tokens[accountId]);
};
