import React from "react";
import styled from "styled-components/macro";

import { border, colors, distances } from "../../styles/constants";
import Icon from "../Icons";

interface ButtonProps {
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Button = ({ onClick }: ButtonProps) => (
    <StyledButton className="with-icon" onClick={onClick}>
        <IconWrapper>
            <Icon icon="search" />
        </IconWrapper>
    </StyledButton>
);

const StyledButton = styled.button`
    border-radius: 2px;
    color: ${colors.background};
    background: transparent;
    border: ${border.normal} solid ${colors.borderLight};
    text-decoration: none;
    user-select: none;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    line-height: ${distances.small};
    position: relative;
    margin-left: 2px;
    min-width: 30px;
    padding: 0;
    height: 34px;
    padding-left: ${distances.nano};
    padding-right: ${distances.nano};

    &:hover {
        border: ${border.normal} solid ${colors.primary};
    }

    &:focus {
        outline: none;
    }

    &:disabled {
        background: ${colors.primaryDisabled};
        border-color: ${colors.primaryDisabled};
        cursor: not-allowed;
    }

    &.with-icon {
        padding-top: 3px;
        padding-bottom: 1px;
        padding-left: 1px;
    }
`;

const IconWrapper = styled.div`
    position: absolute;
    top: -2px;
    left: -4px;
`;
export default Button;
