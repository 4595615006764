import deepEqual from "fast-deep-equal";
import {
    Account,
    AccountBilling,
    AccountCompany,
} from "../../types/management-auth";

export const checkAccountingAndBillingEqual = (
    company: AccountCompany,
    billing: AccountBilling,
) => {
    const companyPartial = {
        address: company.address,
        organization_number: company.organization_number,
        business_name: company.business_name,
    };
    const billingPartial = {
        address: billing.address,
        organization_number: billing.organization_number,
        business_name: billing.business_name,
    };
    return deepEqual(companyPartial, billingPartial);
};

const getValueAt = (path: string, values: any): any => {
    const trail = path.split(".");
    return trail.reduce((acc: any, crumb) => {
        if (!acc) {
            return undefined;
        }
        return acc[crumb];
    }, values);
};

export const hasAtLeastOneProductAndServiceDetailsField = (
    account: Account,
): boolean => {
    return (
        account.average_transaction_value !== undefined ||
        account.projected_sales !== undefined ||
        account.most_expensive_product_price !== undefined ||
        account.delivery_timeline !== undefined ||
        account.gift_card_revenue_percentage !== undefined ||
        account.credit_rating !== undefined
    );
};

export const checkAccountReadyForProduction = (account: Account) => {
    const requiredFields = [
        "company.business_name",
        "company.organization_number",
        "company.address.address_line",
        "company.address.postal_place",
        "company.address.country",
        "billing.business_name",
        "billing.organization_number",
        "billing.address.address_line",
        "billing.address.postal_place",
        "billing.address.country",
    ];
    return requiredFields.reduce((result: boolean, path: string) => {
        const value = getValueAt(path, account);
        if (typeof value === "string") {
            return result && value.trim() !== "";
        }
        return false;
    }, true);
};

export const accountCurrencies = (account: Account): string[] => {
    const currencySet = new Set<string>();
    if (account?.connections?.bambora?.configuration?.payment_options) {
        account?.connections?.bambora?.configuration?.payment_options.forEach(
            (p) => p.currencies.forEach((c) => currencySet.add(c)),
        );
    }
    if (account?.connections?.payex?.configuration?.payment_options) {
        account?.connections?.payex?.configuration?.payment_options.forEach(
            (p) => p.currencies.forEach((c) => currencySet.add(c)),
        );
    }
    if (account?.connections?.collector?.configuration?.payment_options) {
        account?.connections?.collector?.configuration?.payment_options.forEach(
            (p) => p.currencies.forEach((c) => currencySet.add(c)),
        );
    }
    if (account?.connections?.instabank?.configuration?.payment_options) {
        currencySet.add("NOK");
    }
    if (account?.connections?.vipps?.configuration?.payment_options) {
        currencySet.add("NOK");
    }
    if (account?.connections?.swish?.configuration?.payment_options) {
        currencySet.add("SEK");
    }
    return [...currencySet];
};
