import styled from "styled-components/macro";
import { distances, globalMobileBreakpoint } from "../../styles/constants";
import View from "../View";

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 8px;
    flex-direction: column;
    background: white;
    z-index: 3;
`;

export const FilterComponentWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: var(--Spacing-1);
`;

export const FilterRowWrapper = styled.div<{ uniformPadding?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    padding-top: ${distances.tiny};

    ${({ uniformPadding }) =>
        !uniformPadding &&
        `
        padding-bottom: ${distances.tiny};
    `};



    @media (max-width: 768px) {
        padding: ${distances.tiny};
    }
`;

export const FiltersWrapper = styled.div`
    flex-grow: 999;

    display: flex;
    justify-content: flex-start;
    gap: 16px;

    @media (max-width: 499px) {
        padding-bottom: ${distances.tiny};
    }
`;

export const RightWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 16px;
`;

export const FiltersContainer = styled(View).attrs({
    pb: distances.small,
    gap: 16,
})`
    &:empty {
        display: none;
    }
    @media (max-width: ${globalMobileBreakpoint}px) {
        flex-wrap: wrap;
    }
`;
