import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { useAccountIds } from "../../../../auth/accessToken/components/withAccountIds";
import { LoadingOverlay } from "../../../../components/Loading";
import Modal from "../../../../components/Modal";
import { H2, P } from "../../../../components/Typography";
import { distances, grid } from "../../../../styles/constants";

import { useSelector } from "react-redux";
import { namespace } from "..";
import { useActions } from "../../../../Actions";
import { Button, ButtonGroup } from "../../../../components/Buttons";
import { usePaymentApprovals } from "../../approvalsPayments/hooks";
import { VippsModalState } from "../actions";
import VippsForm from "./VippsForm";

const VippsModal = () => {
    const { t } = useTranslation();
    const { closeVippsModal } = useActions(namespace);
    const { prodAccountId } = useAccountIds();
    const { approvals, isLoading } = usePaymentApprovals();
    const activeApprovals = (approvals || []).filter(
        (x) => x?.case_status === "ACTIVE",
    );
    const { modalState, account } = useSelector((state) => ({
        modalState: state[namespace].modalState,
        account: state[namespace].account,
    }));

    if (!prodAccountId) {
        return null;
    }

    if (modalState === VippsModalState.Closed) {
        return null;
    }

    if (!account || isLoading) {
        return <LoadingOverlay />;
    }

    return (
        <Modal fixedWidth>
            {[
                VippsModalState.ApplicationFormOpened,
                VippsModalState.InitiateApplicationLoading,
            ].includes(modalState) &&
                account &&
                account?.company.website && (
                    <>
                        <H2>
                            {t(
                                "settings.payment_connections.vipps_modal.title_intro",
                            )}
                        </H2>
                        <VippsForm activeApprovals={activeApprovals} />
                        {modalState ===
                            VippsModalState.InitiateApplicationLoading && (
                            <LoadingOverlay />
                        )}
                    </>
                )}
            {modalState === VippsModalState.ApplicationReceived && (
                <>
                    <H2>
                        {t(
                            "settings.payment_connections.vipps_modal.title_last_step",
                        )}
                    </H2>

                    <SpacedWrapper>
                        <P>
                            {t(
                                "settings.payment_connections.vipps_modal.last_step",
                            )}
                        </P>
                    </SpacedWrapper>
                    <ButtonGroup>
                        <Button onClick={closeVippsModal}>
                            {t(
                                "settings.payment_connections.vipps_modal.close",
                            )}
                        </Button>
                    </ButtonGroup>
                </>
            )}
            {modalState === VippsModalState.VippsApiError && (
                <>
                    <H2>
                        {t(
                            "settings.payment_connections.vipps_modal.title_api_error",
                        )}
                    </H2>

                    <SpacedWrapper>
                        <P>
                            <Trans i18nKey="settings.payment_connections.vipps_modal.api_error" />
                        </P>
                    </SpacedWrapper>

                    <ButtonGroup>
                        <Button onClick={closeVippsModal}>
                            {t(
                                "settings.payment_connections.vipps_modal.close",
                            )}
                        </Button>
                    </ButtonGroup>
                </>
            )}
        </Modal>
    );
};

export default VippsModal;

const SpacedWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.normal};
`;
