export default function FilterIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            {...props}
        >
            <path
                d="M3 4C3 3.44771 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V5.6969C17 5.96211 16.8946 6.21647 16.7071 6.404L11.8484 11.2627C11.6609 11.4502 11.5556 11.7046 11.5556 11.9698V13.8889L8.44444 17V11.9698C8.44444 11.7046 8.33909 11.4502 8.15155 11.2627L3.29289 6.404C3.10536 6.21647 3 5.96211 3 5.6969V4Z"
                stroke="currentColor"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
