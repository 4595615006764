export const lngToCurrency = (lng: string) => {
    switch (lng) {
        case "no":
            return "NOK";
        case "sv":
            return "SEK";
        default:
            return "NOK";
    }
};
