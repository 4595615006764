import { connect } from "react-redux";

import { State } from "../../../../reducer";
import {
    editPayoutRule,
    fetchLocations,
    fetchPayoutRule,
    resetForm,
} from "../actions";
import PayoutRuleEdit from "../components/PayoutRuleEdit";
import { payoutRuleEditSelector } from "../selectors";

const mapStateToProps = (state: State) => ({
    payoutRuleEditState: payoutRuleEditSelector(state),
});

const mapDispatchToProps = {
    fetchPayoutRule,
    editPayoutRule,
    resetForm,
    fetchLocations,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayoutRuleEdit);
