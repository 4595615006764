import { ActionTypes, Actions } from "./actions";

export interface State {
    isLoading: boolean;
    error: boolean;
    recentlyOverridenGateways: {
        [locationId: string]: string[] | undefined;
    };
}

export const initialState: State = {
    isLoading: false,
    error: false,
    recentlyOverridenGateways: {},
};

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {
        case ActionTypes.PutOverride:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.PutOverrideResponse:
            const locationId = action.meta.cause.payload.storeId;
            const newOverridenGateways = {
                ...state.recentlyOverridenGateways,
                [locationId]: [
                    ...(state.recentlyOverridenGateways[locationId] || []),
                    action.payload.gateway,
                ],
            };
            return {
                ...state,
                isLoading: false,
                recentlyOverridenGateways: newOverridenGateways,
            };

        default:
            return state;
    }
}
