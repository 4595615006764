import { BillingPayoutRule } from "../../../types/billing";
import { CheckoutConfig } from "../../../types/checkout";

export enum ActionTypes {
    GetPayoutRules = "[payoutRules] GET_PAYOUT_RULES",
    FetchPayoutRulesResponse = "[payoutRules] FETCH_PAYOUT_RULES_RESPONSE",
    GetCheckoutConfiguration = "[payoutRules] GET_CHECKOUT_CONFIGURATION",
    FetchCheckoutConfigurationResponse = "[payoutRules] CHECKOUT_CONFIGURATION_RESPONSE",
    ClearData = "[payoutRules] CLEAR_DATA",
}

export enum GetPayoutRulesReason {
    mount = "mount",
    update = "update",
    next = "next",
    refresh = "refresh",
    testAccount = "testAccount",
}

export interface GetPayoutRules {
    type: ActionTypes.GetPayoutRules;
    payload: {
        accountId: string;
        params: URLSearchParams;
        reason: GetPayoutRulesReason;
        requestId: number;
    };
}

export interface FetchPayoutRulesResponse {
    type: ActionTypes.FetchPayoutRulesResponse;
    payload: {
        payoutRules: BillingPayoutRule[];
    };
    meta: {
        cause: GetPayoutRules;
    };
}

export function getPayoutRules(
    accountId: string,
    params: URLSearchParams,
    origin: GetPayoutRulesReason,
): GetPayoutRules {
    return {
        type: ActionTypes.GetPayoutRules,
        payload: {
            accountId,
            params,
            reason: origin,
            requestId: performance.now(),
        },
    };
}

export interface GetCheckoutConfiguration {
    type: ActionTypes.GetCheckoutConfiguration;
    payload: {
        accountId: string;
    };
}

export function getCheckoutConfiguration(
    accountId: string,
): GetCheckoutConfiguration {
    return {
        type: ActionTypes.GetCheckoutConfiguration,
        payload: {
            accountId: accountId,
        },
    };
}

export interface FetchCheckoutConfigurationResponse {
    type: ActionTypes.FetchCheckoutConfigurationResponse;
    payload: {
        configuration: CheckoutConfig;
    };
}

export interface ClearData {
    type: ActionTypes.ClearData;
}

export function clearData(): ClearData {
    return {
        type: ActionTypes.ClearData,
    };
}

export type Actions =
    | GetPayoutRules
    | FetchPayoutRulesResponse
    | GetCheckoutConfiguration
    | FetchCheckoutConfigurationResponse
    | ClearData;
