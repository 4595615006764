import { getValueAt } from "../getValueAt";

export const validateBothNotSet = (comparePath: string, message: string) => {
    return (value: string | undefined, values: any) => {
        if (!value) {
            return;
        }
        const compareArray = getValueAt(comparePath, values);
        const isValid =
            (!value && compareArray && compareArray.length > 0) ||
            (value && (!compareArray || compareArray.length === 0));
        if (!isValid) {
            return message;
        }
    };
};
