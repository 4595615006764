import { CheckoutConfig, ProfileConfiguration } from "../../../types/checkout";
import { Account } from "../../../types/management-auth";
import { ActionTypes, Actions } from "./actions";

export interface State {
    isLoading: boolean;
    checkoutConfiguration: CheckoutConfig | undefined;
    account: Account | undefined;
    profile: ProfileConfiguration | undefined;
    result?: {
        paymentProfile: ProfileConfiguration;
        accountId: string;
    };
}

export const initialState: State = {
    isLoading: false,
    checkoutConfiguration: undefined,
    account: undefined,
    profile: undefined,
    result: undefined,
};

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {
        case ActionTypes.ResetForm:
            return {
                ...initialState,
            };

        case ActionTypes.GetCheckoutConfiguration:
            return {
                ...initialState,
                isLoading: true,
            };

        case ActionTypes.FetchCheckoutConfigurationResponse:
            return {
                ...state,
                isLoading: false,
                checkoutConfiguration: action.payload.configuration,
                account: action.payload.account,
                profile: action.payload.profile,
            };

        case ActionTypes.EditPaymentProfile:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FetchEditPaymentProfileResponse:
            return {
                ...state,
                isLoading: false,
                result: {
                    paymentProfile: action.payload.paymentProfile,
                    accountId: action.payload.accountId,
                },
            };

        default:
            return state;
    }
}
