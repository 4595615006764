import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { Button } from "../../../components/Buttons";
import { H2, P } from "../../../components/Typography";
import { colors, distances, grid } from "../../../styles/constants";

const Pad = styled.div`
    padding: ${distances.large};
`;

const Container = styled.div`
    max-width: ${grid.spans.span7};
    margin: 0 auto;
    padding: ${distances.normal};
    position: relative;
    min-height: calc(${distances.ginormous} + ${distances.large});
    border: 2px solid ${colors.borderLight};
`;

type NoReportSettingsProps = {
    openNewModal: () => void;
};

const NoSettlementData = ({ openNewModal }: NoReportSettingsProps) => {
    const { t } = useTranslation();
    return (
        <Pad>
            <Container>
                <H2>{t("settings.settlement_report_config.no_data.title")}</H2>
                <br />
                <Trans i18nKey="settings.settlement_report_config.no_data.body">
                    First part <P>Second part</P>
                </Trans>
                <br />
                <br />
                <Button className="alt" onClick={openNewModal}>
                    {t(
                        "settings.settlement_report_config.create.call_to_action",
                    )}
                </Button>
            </Container>
        </Pad>
    );
};

export default NoSettlementData;
