import styled from "styled-components/macro";
import StyledLink from "../../components/StyledLink";
import { colors } from "../../styles/constants";
import { AccountAdminUser, Client } from "../../types/management-auth";
import useApiClients from "../hooks/useApiClients";
import useUsers from "../hooks/useUsers";

const checkId = (obj: Client | AccountAdminUser, id: string) =>
    obj.created_by === id || obj.deleted_by === id;

interface ApiUserProps {
    id?: string;
}

const ApiUser = ({ id }: ApiUserProps) => {
    const { isLoading: isLoadingUsers, users } = useUsers();
    const { isLoading: isLoadingClients, clients } = useApiClients();

    if (id && id.startsWith("mailto:")) {
        const mail = id.replace("mailto:", "");
        return <StyledLink href={id}>{mail}</StyledLink>;
    }
    if (id && !isLoadingUsers && users.some((x) => checkId(x, id))) {
        const user = users.find((x) => checkId(x, id));
        return (
            <StyledLink href={`mailto:${user?.email}`}>
                {user?.email}
            </StyledLink>
        );
    }
    if (id && !isLoadingClients && clients.some((x) => checkId(x, id))) {
        const client = clients.find((x) => checkId(x, id));
        return <Code>{client?.name || client?.id}</Code>;
    }
    return <Code>{id}</Code>;
};

const Code = styled.code`
    border: 1px solid transparent;
    &:hover {
        background: ${colors.backgroundAlt};
        color: ${colors.invalid};
        border: 1px solid #ededed;
        border-radius: 2px;
    }
`;

export default ApiUser;
