import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useActions } from "../../../../Actions";
import { useAccountIds } from "../../../../auth/accessToken/components/withAccountIds";
import useScopes from "../../../../auth/accessToken/hooks/useScopes";
import { getUserId } from "../../../../auth/accessToken/selectors";
import Button from "../../../../components/Buttons/Button";
import ButtonGroup from "../../../../components/Buttons/ButtonGroup";
import { validateRequired } from "../../../../helpers/validation/required";
import { validate } from "../../../../helpers/validation/validate";
import { CollectorConnectionCallback } from "../../../../types/management-auth/generated";
import { PatchedCollectorConnectionCallback } from "../actions";
import { isPasswordRequired } from "../utils";
import {
    SpacedWrapper,
    TranslatedValidatedInput,
    countryMapping,
} from "./CollectorCallbackModal";

interface FormProps {
    username: string;
    password: string;
    store_id_nok_b2c?: string;
    store_id_sek_b2c?: string;
    store_id_eur_b2c?: string;
    store_id_nok_b2b?: string;
    store_id_sek_b2b?: string;
    store_id_eur_b2b?: string;
}

const DirectAgreementForm = () => {
    const { t } = useTranslation();
    const { prodAccountId } = useAccountIds();
    const { confirmConnection, closeModal } = useActions(
        "connections.collector_callback",
    );
    const scopes = useScopes();

    const { userId } = useSelector((state) => {
        return {
            userId: getUserId(state),
        };
    });

    const initialFormValues: FormProps = {
        username: "",
        password: "",
    };

    const {
        values,
        errors,
        handleChange,
        handleBlur,
        touched,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        enableReinitialize: true,
        initialValues: initialFormValues,
        validate: (values) => {
            let errors: { [key: string]: string } = {};
            if (!values) {
                // this should not happen but...
                return { error: "account not loaded yet..." };
            }

            const determineStoreIdValidators = () => {
                /**
                 * Trigger requirement validation if all fields are empty
                 */
                if (
                    !values.store_id_nok_b2c &&
                    !values.store_id_sek_b2c &&
                    !values.store_id_eur_b2c &&
                    !values.store_id_nok_b2b &&
                    !values.store_id_sek_b2b &&
                    !values.store_id_eur_b2b
                ) {
                    return [
                        {
                            path: "store_id_nok_b2c",
                            validator: validateRequired(
                                t(
                                    "settings.payment_connections.collector_callback_modal.invalid.store_id.required",
                                ),
                            ),
                        },
                        {
                            path: "store_id_sek_b2c",
                            validator: validateRequired(
                                t(
                                    "settings.payment_connections.collector_callback_modal.invalid.store_id.required",
                                ),
                            ),
                        },
                        {
                            path: "store_id_eur_b2c",
                            validator: validateRequired(
                                t(
                                    "settings.payment_connections.collector_callback_modal.invalid.store_id.required",
                                ),
                            ),
                        },
                        {
                            path: "store_id_nok_b2b",
                            validator: validateRequired(
                                t(
                                    "settings.payment_connections.collector_callback_modal.invalid.store_id_b2b.required",
                                ),
                            ),
                        },
                        {
                            path: "store_id_sek_b2b",
                            validator: validateRequired(
                                t(
                                    "settings.payment_connections.collector_callback_modal.invalid.store_id_b2b.required",
                                ),
                            ),
                        },
                        {
                            path: "store_id_eur_b2b",
                            validator: validateRequired(
                                t(
                                    "settings.payment_connections.collector_callback_modal.invalid.store_id_b2b.required",
                                ),
                            ),
                        },
                    ];
                }
                /**
                 * Trigger requirement validation of a B2C field if the same currency B2B field is filled
                 */
                if (values.store_id_nok_b2b && !values.store_id_nok_b2c) {
                    return [
                        {
                            path: "store_id_nok_b2c",
                            validator: validateRequired(
                                t(
                                    "settings.payment_connections.collector_callback_modal.invalid.store_id.required",
                                ),
                            ),
                        },
                    ];
                }
                if (values.store_id_sek_b2b && !values.store_id_sek_b2c) {
                    return [
                        {
                            path: "store_id_sek_b2c",
                            validator: validateRequired(
                                t(
                                    "settings.payment_connections.collector_callback_modal.invalid.store_id.required",
                                ),
                            ),
                        },
                    ];
                }
                if (values.store_id_eur_b2b && !values.store_id_eur_b2c) {
                    return [
                        {
                            path: "store_id_eur_b2c",
                            validator: validateRequired(
                                t(
                                    "settings.payment_connections.collector_callback_modal.invalid.store_id.required",
                                ),
                            ),
                        },
                    ];
                }
            };

            const determinePasswordValidation = () => {
                if (isPasswordRequired(values.username.trim(), scopes)) {
                    return [
                        {
                            path: "password",
                            validator: validateRequired(
                                t(
                                    "settings.payment_connections.collector_callback_modal.invalid.password.required",
                                ),
                            ),
                        },
                    ];
                }
                return [];
            };

            const validators = [
                {
                    path: "username",
                    validator: validateRequired(
                        t(
                            "settings.payment_connections.collector_callback_modal.invalid.username.required",
                        ),
                    ),
                },
                ...determinePasswordValidation(),
                ...(determineStoreIdValidators() || []),
            ];

            errors = validators.reduce((acc, elem) => {
                return validate(elem.path, elem.validator, values, acc);
            }, errors);

            return errors;
        },
        onSubmit: (values) => {
            const ids = [
                {
                    currency: "NOK",
                    store_id: values.store_id_nok_b2c,
                    store_id_b2b: values.store_id_nok_b2b,
                },
                {
                    currency: "SEK",
                    store_id: values.store_id_sek_b2c,
                    store_id_b2b: values.store_id_sek_b2b,
                },
                {
                    currency: "EUR",
                    store_id: values.store_id_eur_b2c,
                    store_id_b2b: values.store_id_eur_b2b,
                },
            ];

            const validIds = ids.filter((x) => x.store_id || x.store_id_b2b);

            const paymentOptions: CollectorConnectionCallback["payment_options"] =
                [
                    {
                        currencies: ids
                            .filter((x) => x.store_id)
                            .map((x) => x.currency),
                        type: "collector.invoice" as CollectorConnectionCallback["payment_options"][0]["type"],
                    },
                    {
                        currencies: ids
                            .filter((x) => x.store_id_b2b)
                            .map((x) => x.currency),
                        type: "collector.invoice_b2b" as CollectorConnectionCallback["payment_options"][0]["type"],
                    },
                    {
                        currencies: ids
                            .filter((x) => x.store_id || x.store_id_b2b)
                            .map((x) => x.currency),
                        type: "collector.installment" as CollectorConnectionCallback["payment_options"][0]["type"],
                    },
                ].filter((x) => x.currencies.length > 0);

            const stores: CollectorConnectionCallback["stores"] =
                validIds.reduce(
                    (acc: CollectorConnectionCallback["stores"], elem) => {
                        const b2c = elem.store_id
                            ? [
                                  {
                                      currency: elem.currency,
                                      store_id: parseInt(elem.store_id),
                                      country:
                                          countryMapping[
                                              elem.currency as keyof typeof countryMapping
                                          ],
                                      segment: "B2C" as NonNullable<
                                          CollectorConnectionCallback["stores"]
                                      >[0]["segment"],
                                  },
                              ]
                            : [];
                        const b2b = elem.store_id_b2b
                            ? [
                                  {
                                      currency: elem.currency,
                                      store_id: parseInt(elem.store_id_b2b),
                                      country:
                                          countryMapping[
                                              elem.currency as keyof typeof countryMapping
                                          ],
                                      segment: "B2B" as NonNullable<
                                          CollectorConnectionCallback["stores"]
                                      >[0]["segment"],
                                  },
                              ]
                            : [];

                        return [...(acc ?? []), ...b2c, ...b2b];
                    },
                    [] as CollectorConnectionCallback["stores"],
                );

            const payload: PatchedCollectorConnectionCallback = {
                username: values.username.trim(),
                payment_options: paymentOptions,
                stores,
                ...(isPasswordRequired(values.username.trim(), scopes) && {
                    password: values.password.trim(),
                }),
            };
            confirmConnection(prodAccountId!, userId, payload);
        },
    });

    if (!prodAccountId) {
        return null;
    }

    const isPasswordDisabled = !isPasswordRequired(
        values.username.trim(),
        scopes,
    );

    return (
        <form onSubmit={handleSubmit}>
            <SpacedWrapper>
                <SpacedWrapper>
                    <SpacedWrapper>
                        <TranslatedValidatedInput
                            path="username"
                            initialValues={initialFormValues}
                            values={values}
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched}
                            required
                        />
                        <TranslatedValidatedInput
                            path="password"
                            initialValues={initialFormValues}
                            values={values}
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched}
                            required={!isPasswordDisabled}
                            disabled={isPasswordDisabled}
                            type="password"
                        />
                    </SpacedWrapper>
                    <SpacedWrapper>
                        <TranslatedValidatedInput
                            path="store_id_nok_b2c"
                            initialValues={initialFormValues}
                            values={values}
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched}
                        />
                        <TranslatedValidatedInput
                            path="store_id_nok_b2b"
                            initialValues={initialFormValues}
                            values={values}
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched}
                        />
                    </SpacedWrapper>
                    <SpacedWrapper>
                        <TranslatedValidatedInput
                            path="store_id_sek_b2c"
                            initialValues={initialFormValues}
                            values={values}
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched}
                        />
                        <TranslatedValidatedInput
                            path="store_id_sek_b2b"
                            initialValues={initialFormValues}
                            values={values}
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched}
                        />
                    </SpacedWrapper>
                    <SpacedWrapper>
                        <TranslatedValidatedInput
                            path="store_id_eur_b2c"
                            initialValues={initialFormValues}
                            values={values}
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched}
                        />
                        <TranslatedValidatedInput
                            path="store_id_eur_b2b"
                            initialValues={initialFormValues}
                            values={values}
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched}
                        />
                    </SpacedWrapper>
                </SpacedWrapper>
            </SpacedWrapper>
            <ButtonGroup>
                <Button className="alt" onClick={closeModal}>
                    {t(
                        "settings.payment_connections.collector_callback_modal.cancel",
                    )}
                </Button>
                <Button
                    type="submit"
                    disabled={
                        Object.keys(errors || {}).length > 0 ||
                        Object.keys(touched || {}).length === 0
                    }
                >
                    {t(
                        "settings.payment_connections.collector_callback_modal.send_callback_call_to_action",
                    )}
                </Button>
            </ButtonGroup>
        </form>
    );
};

export default DirectAgreementForm;
