import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { Button } from "../../../components/Buttons";
import Card from "../../../components/Card";
import { colors, palette } from "../../../styles/constants";
import { CheckoutConfig } from "../../../types/checkout";
import { Account } from "../../../types/management-auth";
import { ApprovalsPaymentResponse } from "../../../types/management-auth/generated";
import PaymentLogoMark from "./PaymentLogoMark";
import { PaymentMethod } from "./types";

const STATUS_MIN_WIDTH = 150;

type MethodStatusProps = {
    isConfigured: boolean;
    isPending: boolean;
    isDeclined: boolean;
    isFailed: boolean;
    onActivation?: () => void;
};

const MethodStatus = ({
    isConfigured,
    isPending,
    isDeclined,
    isFailed,
    onActivation,
}: MethodStatusProps) => {
    const { t } = useTranslation();
    if (isConfigured) {
        return (
            <BaseStatus background={colors.valid}>
                {t("settings.approvalsPaymentsConfiguration.active")}
            </BaseStatus>
        );
    }
    if (isDeclined) {
        return (
            <BaseStatus
                background={palette.neutral[100]}
                text={palette.neutral[700]}
                borderColor={palette.neutral[200]}
            >
                {t("settings.approvalsPaymentsConfiguration.declined")}
            </BaseStatus>
        );
    }
    if (isFailed) {
        return (
            <BaseStatus
                background={palette.neutral[100]}
                text={palette.neutral[700]}
                borderColor={palette.neutral[200]}
            >
                {t("settings.approvalsPaymentsConfiguration.failed")}
            </BaseStatus>
        );
    }
    if (isPending) {
        return (
            <BaseStatus
                background={palette.neutral[100]}
                text={palette.neutral[700]}
                borderColor={palette.neutral[200]}
            >
                {t("settings.approvalsPaymentsConfiguration.processing")}
            </BaseStatus>
        );
    }
    if (!onActivation) {
        return (
            <EmptyStatus>
                {t("settings.approvalsPaymentsConfiguration.noStatus")}
            </EmptyStatus>
        );
    }
    return (
        <Button className="small outlined" onClick={onActivation}>
            {t("settings.approvalsPayments.activatePaymentMethod")}
        </Button>
    );
};

const resolveConnectionsStatuses = (account: Account, status: string) => {
    // Trying to map the gateway connections to the payment types where
    // possible. Some gateways deliver more than one payment type so in those
    // cases we might not be able to map the connection to all the payment
    // types that are pending / failed or declined.
    const result = [];
    if (
        account?.services?.checkout?.features?.vipps?.status === status ||
        account?.connections?.vipps?.status === status
    ) {
        result.push("vipps");
    }
    if (
        account?.services?.checkout?.features?.creditcard?.status === status ||
        account?.connections?.payex?.status === status
    ) {
        result.push("creditcard");
    }
    if (account?.connections?.bambora?.status === status) {
        result.push("creditcard");
    }
    if (
        account?.services?.checkout?.features?.swish?.status === status ||
        account?.connections?.swish?.status === status
    ) {
        result.push("swish");
    }
    if (
        account?.services?.checkout?.features?.collector?.status === status ||
        account?.connections?.collector?.status === status
    ) {
        result.push("walley");
    }
    if (
        account?.services?.checkout?.features?.instabank?.status === status ||
        account?.connections?.instabank?.status === status
    ) {
        result.push("instabank");
    }
    if (account?.services?.checkout?.features?.applepay?.status === status) {
        result.push("applepay");
    }
    if (account?.services?.checkout?.features?.googlepay?.status === status) {
        result.push("googlepay");
    }
    if (account?.services?.checkout?.features?.clicktopay?.status === status) {
        result.push("clicktopay");
    }
    if (account?.services?.checkout?.features?.mobilepay?.status === status) {
        result.push("mobilepay");
    }
    if (account?.services?.checkout?.features?.klarna?.status === status) {
        result.push("klarna");
    }
    if (account?.services?.checkout?.features?.billie?.status === status) {
        result.push("billie");
    }
    return [...new Set(result)] as PaymentMethod[];
};

type PaymentMethodConfigurationProps = {
    approvals: ApprovalsPaymentResponse[];
    checkoutConfiguration: CheckoutConfig | undefined;
    account: Account;
    onActivation?: (paymentMethod: PaymentMethod) => void;
    isLegacyConfiguration?: boolean;
};
type PaymentTypeGroupProperties = {
    pendingConnections: PaymentMethod[];
    failedConnections: PaymentMethod[];
    declinedConnections: PaymentMethod[];
    configuredPaymentOptions: CheckoutConfig["configuration"]["payment_options"];
    appliedForPaymentMethods: ApprovalsPaymentResponse["payment_methods"];
    approvalDeclined: boolean;
    approvalFailed: boolean;
    hasNonActiveCase: boolean;
    onActivation?: (paymentMethod: PaymentMethod) => void;
    isLegacyConfiguration?: boolean;
};

const checkHasCaseButNotActive = (approvals: ApprovalsPaymentResponse[]) => {
    const active = approvals.some((a) => a.case_status === "ACTIVE");
    return approvals.length > 0 && !active;
};

const PaymentTypeGroupDefaults = ({
    pendingConnections,
    failedConnections,
    declinedConnections,
    configuredPaymentOptions,
    appliedForPaymentMethods,
    approvalDeclined,
    approvalFailed,
    onActivation,
    isLegacyConfiguration,
}: PaymentTypeGroupProperties) => {
    const appliedForCreditcard = appliedForPaymentMethods.some(
        (x) => x.payment_method === "creditcard",
    );
    const appliedForVipps = appliedForPaymentMethods.some(
        (x) => x.payment_method === "vipps",
    );
    const appliedForKlarna = appliedForPaymentMethods.some(
        (x) => x.payment_method === "klarna",
    );
    const appliedForWalley = appliedForPaymentMethods.some(
        (x) => x.payment_method === "walley",
    );

    return (
        <>
            <PaymentType>
                <Label>
                    <PaymentLogoMark logo="visa" label="Visa" />
                    <PaymentLogoMark logo="mastercard" label="Mastercard" />
                    <LabelText>Visa / Mastercard</LabelText>
                </Label>

                <StatusWrapper>
                    <MethodStatus
                        isConfigured={configuredPaymentOptions.some((x) =>
                            ["payex.creditcard", "bambora.creditcard"].includes(
                                x.type,
                            ),
                        )}
                        isPending={
                            appliedForCreditcard ||
                            pendingConnections.some((x) => x === "creditcard")
                        }
                        isFailed={
                            (appliedForCreditcard && approvalFailed) ||
                            failedConnections.some((x) => x === "creditcard")
                        }
                        isDeclined={
                            (appliedForCreditcard && approvalDeclined) ||
                            declinedConnections.some((x) => x === "creditcard")
                        }
                        onActivation={
                            isLegacyConfiguration
                                ? undefined
                                : () => {
                                      onActivation?.("creditcard");
                                  }
                        }
                    />
                </StatusWrapper>
            </PaymentType>
            <PaymentType>
                <Label>
                    <PaymentLogoMark logo="vipps" label="Vipps" />
                    <LabelText>Vipps</LabelText>
                </Label>

                <StatusWrapper>
                    <MethodStatus
                        isConfigured={configuredPaymentOptions.some((x) =>
                            ["vipps", "payex.vipps", "bambora.vipps"].includes(
                                x.type,
                            ),
                        )}
                        isPending={
                            appliedForVipps ||
                            pendingConnections.some((x) => x === "vipps")
                        }
                        isFailed={
                            (appliedForVipps && approvalFailed) ||
                            failedConnections.some((x) => x === "vipps")
                        }
                        isDeclined={
                            (appliedForVipps && approvalDeclined) ||
                            declinedConnections.some((x) => x === "vipps")
                        }
                        onActivation={
                            isLegacyConfiguration
                                ? undefined
                                : () => {
                                      onActivation?.("vipps");
                                  }
                        }
                    />
                </StatusWrapper>
            </PaymentType>
            <PaymentType>
                <Label>
                    <PaymentLogoMark logo="klarna" label="Klarna" />
                    <LabelText>Klarna</LabelText>
                </Label>

                <StatusWrapper>
                    <MethodStatus
                        isConfigured={configuredPaymentOptions.some(
                            (x) => x.type === "klarna.klarna",
                        )}
                        isPending={
                            appliedForKlarna ||
                            pendingConnections.some((x) => x === "klarna")
                        }
                        isFailed={
                            (appliedForKlarna && approvalFailed) ||
                            failedConnections.some((x) => x === "klarna")
                        }
                        isDeclined={
                            (appliedForKlarna && approvalDeclined) ||
                            declinedConnections.some((x) => x === "klarna")
                        }
                        onActivation={
                            isLegacyConfiguration
                                ? undefined
                                : () => {
                                      onActivation?.("klarna");
                                  }
                        }
                    />
                </StatusWrapper>
            </PaymentType>
            <PaymentType>
                <Label>
                    <PaymentLogoMark logo="walley" label="Walley" />
                    <LabelText>Walley</LabelText>
                </Label>

                <StatusWrapper>
                    <MethodStatus
                        isConfigured={configuredPaymentOptions.some((x) =>
                            [
                                "collector.invoice",
                                "collector.finance",
                                "collector.installment",
                                "collector.invoice_b2b",
                                "collector.invoice_b2b_preapproved",
                            ].includes(x.type),
                        )}
                        isPending={
                            appliedForWalley ||
                            pendingConnections.some((x) => x === "walley")
                        }
                        isFailed={
                            (appliedForWalley && approvalFailed) ||
                            failedConnections.some((x) => x === "walley")
                        }
                        isDeclined={
                            (appliedForWalley && approvalDeclined) ||
                            declinedConnections.some((x) => x === "walley")
                        }
                        onActivation={
                            isLegacyConfiguration
                                ? undefined
                                : () => {
                                      onActivation?.("walley");
                                  }
                        }
                    />
                </StatusWrapper>
            </PaymentType>
        </>
    );
};

const PaymentTypeGroupAdditional = ({
    pendingConnections,
    failedConnections,
    declinedConnections,
    configuredPaymentOptions,
    appliedForPaymentMethods,
    approvalDeclined,
    approvalFailed,
    onActivation,
    isLegacyConfiguration,
}: PaymentTypeGroupProperties) => {
    const appliedForApplepay = appliedForPaymentMethods.some(
        (x) => x.payment_method === "applepay",
    );
    const appliedForGooglepay = appliedForPaymentMethods.some(
        (x) => x.payment_method === "googlepay",
    );
    const appliedForClickToPay = appliedForPaymentMethods.some(
        (x) => x.payment_method === "clicktopay",
    );
    const appliedForMobilepay = appliedForPaymentMethods.some(
        (x) => x.payment_method === "mobilepay",
    );
    const appliedForSwish = appliedForPaymentMethods.some(
        (x) => x.payment_method === "swish",
    );
    const appliedForBillie = appliedForPaymentMethods.some(
        (x) => x.payment_method === "billie",
    );
    const appliedForInstabank = appliedForPaymentMethods.some(
        (x) => x.payment_method === "instabank",
    );

    const hasInstabank =
        appliedForInstabank ||
        pendingConnections.some((x) => x === "instabank") ||
        configuredPaymentOptions.some((x) => x.type.startsWith("instabank"));

    return (
        <>
            <PaymentType>
                <Label>
                    <PaymentLogoMark logo="applepay" label="Apple Pay" />
                    <LabelText>Apple Pay</LabelText>
                </Label>

                <StatusWrapper>
                    <MethodStatus
                        isConfigured={configuredPaymentOptions.some((x) =>
                            ["payex.applepay"].includes(x.type),
                        )}
                        isPending={
                            appliedForApplepay ||
                            pendingConnections.some((x) => x === "applepay")
                        }
                        isFailed={
                            (appliedForApplepay && approvalFailed) ||
                            failedConnections.some((x) => x === "applepay")
                        }
                        isDeclined={
                            (appliedForApplepay && approvalDeclined) ||
                            declinedConnections.some((x) => x === "applepay")
                        }
                        onActivation={
                            isLegacyConfiguration
                                ? undefined
                                : () => {
                                      onActivation?.("applepay");
                                  }
                        }
                    />
                </StatusWrapper>
            </PaymentType>
            <PaymentType>
                <Label>
                    <PaymentLogoMark logo="googlepay" label="Google Pay" />
                    <LabelText>Google Pay</LabelText>
                </Label>

                <StatusWrapper>
                    <MethodStatus
                        isConfigured={configuredPaymentOptions.some((x) =>
                            ["payex.googlepay"].includes(x.type),
                        )}
                        isPending={
                            appliedForGooglepay ||
                            pendingConnections.some((x) => x === "googlepay")
                        }
                        isFailed={
                            (appliedForGooglepay && approvalFailed) ||
                            failedConnections.some((x) => x === "googlepay")
                        }
                        isDeclined={
                            (appliedForGooglepay && approvalDeclined) ||
                            declinedConnections.some((x) => x === "googlepay")
                        }
                        onActivation={
                            isLegacyConfiguration
                                ? undefined
                                : () => {
                                      onActivation?.("googlepay");
                                  }
                        }
                    />
                </StatusWrapper>
            </PaymentType>
            <PaymentType>
                <Label>
                    <PaymentLogoMark logo="clicktopay" label="Click to Pay" />
                    <LabelText>Visa Click to Pay</LabelText>
                </Label>

                <StatusWrapper>
                    <MethodStatus
                        isConfigured={configuredPaymentOptions.some((x) =>
                            ["payex.clicktopay"].includes(x.type),
                        )}
                        isPending={
                            appliedForClickToPay ||
                            pendingConnections.some((x) => x === "clicktopay")
                        }
                        isFailed={
                            (appliedForClickToPay && approvalFailed) ||
                            failedConnections.some((x) => x === "clicktopay")
                        }
                        isDeclined={
                            (appliedForClickToPay && approvalDeclined) ||
                            declinedConnections.some((x) => x === "clicktopay")
                        }
                        onActivation={
                            isLegacyConfiguration
                                ? undefined
                                : () => {
                                      onActivation?.("clicktopay");
                                  }
                        }
                    />
                </StatusWrapper>
            </PaymentType>
            <PaymentType>
                <Label>
                    <PaymentLogoMark logo="mobilepay" label="MobilePay" />
                    <LabelText>MobilePay</LabelText>
                </Label>

                <StatusWrapper>
                    <MethodStatus
                        isConfigured={configuredPaymentOptions.some((x) =>
                            ["payex.mobilepay", "bambora.mobilepay"].includes(
                                x.type,
                            ),
                        )}
                        isPending={
                            appliedForMobilepay ||
                            pendingConnections.some((x) => x === "mobilepay")
                        }
                        isFailed={
                            (appliedForMobilepay && approvalFailed) ||
                            failedConnections.some((x) => x === "mobilepay")
                        }
                        isDeclined={
                            (appliedForMobilepay && approvalDeclined) ||
                            declinedConnections.some((x) => x === "mobilepay")
                        }
                        onActivation={
                            isLegacyConfiguration
                                ? undefined
                                : () => {
                                      onActivation?.("mobilepay");
                                  }
                        }
                    />
                </StatusWrapper>
            </PaymentType>
            <PaymentType>
                <Label>
                    <PaymentLogoMark logo="swish" label="Swish" />
                    <LabelText>Swish</LabelText>
                </Label>

                <StatusWrapper>
                    <MethodStatus
                        isConfigured={configuredPaymentOptions.some((x) =>
                            ["swish.swish", "payex.swish"].includes(x.type),
                        )}
                        isPending={
                            appliedForSwish ||
                            pendingConnections.some((x) => x === "swish")
                        }
                        isFailed={
                            (appliedForSwish && approvalFailed) ||
                            failedConnections.some((x) => x === "swish")
                        }
                        isDeclined={
                            (appliedForSwish && approvalDeclined) ||
                            declinedConnections.some((x) => x === "swish")
                        }
                        onActivation={
                            isLegacyConfiguration
                                ? undefined
                                : () => {
                                      onActivation?.("swish");
                                  }
                        }
                    />
                </StatusWrapper>
            </PaymentType>
            <PaymentType>
                <Label>
                    <PaymentLogoMark logo="billie" label="Billie" />
                    <LabelText>Billie</LabelText>
                </Label>

                <StatusWrapper>
                    <MethodStatus
                        isConfigured={configuredPaymentOptions.some(
                            (x) => x.type === "klarna.billie",
                        )}
                        isPending={
                            appliedForBillie ||
                            pendingConnections.some((x) => x === "billie")
                        }
                        isFailed={
                            (appliedForBillie && approvalFailed) ||
                            failedConnections.some((x) => x === "billie")
                        }
                        isDeclined={
                            (appliedForBillie && approvalDeclined) ||
                            declinedConnections.some((x) => x === "billie")
                        }
                        onActivation={
                            isLegacyConfiguration
                                ? undefined
                                : () => {
                                      onActivation?.("billie");
                                  }
                        }
                    />
                </StatusWrapper>
            </PaymentType>
            {hasInstabank && (
                <PaymentType>
                    <Label>
                        <PaymentLogoMark logo="instabank" label="Instabank" />
                        <LabelText>Instabank</LabelText>
                    </Label>
                    <StatusWrapper>
                        <MethodStatus
                            isConfigured={configuredPaymentOptions.some((x) =>
                                x.type.startsWith("instabank"),
                            )}
                            isPending={
                                appliedForInstabank ||
                                pendingConnections.some(
                                    (x) => x === "instabank",
                                )
                            }
                            isFailed={
                                (appliedForInstabank && approvalFailed) ||
                                failedConnections.some((x) => x === "instabank")
                            }
                            isDeclined={
                                (appliedForInstabank && approvalDeclined) ||
                                declinedConnections.some(
                                    (x) => x === "instabank",
                                )
                            }
                        />
                    </StatusWrapper>
                </PaymentType>
            )}
        </>
    );
};

const PaymentMethodConfiguration = ({
    approvals,
    checkoutConfiguration,
    account,
    onActivation,
    isLegacyConfiguration,
}: PaymentMethodConfigurationProps) => {
    const { t } = useTranslation();
    // Legacy connections statuses
    const configuredPaymentOptions =
        checkoutConfiguration?.configuration?.payment_options || [];

    const pendingConnections = resolveConnectionsStatuses(account, "pending");
    const failedConnections = resolveConnectionsStatuses(account, "failed");
    const declinedConnections = resolveConnectionsStatuses(account, "declined");

    const hasNonActiveCase = checkHasCaseButNotActive(approvals);

    const lastApproval = approvals[approvals.length - 1];
    const appliedForPaymentMethods = lastApproval?.payment_methods || [];
    const approvalDeclined = lastApproval?.case_status === "DECLINED";
    const approvalFailed = lastApproval?.case_status === "ERROR";

    if (hasNonActiveCase) {
        return null;
    }

    return (
        <Card noPadding overflowHidden>
            <Header>
                <HeaderTitle>
                    {t("settings.approvalsPayments.tableHeadingPaymentMethod")}
                </HeaderTitle>
                <HeaderTitle className="status">
                    {t("settings.approvalsPayments.tableHeadingStatus")}
                </HeaderTitle>
            </Header>
            <PaymentTypeGroupDefaults
                {...{
                    account,
                    pendingConnections,
                    failedConnections,
                    declinedConnections,
                    hasNonActiveCase,
                    configuredPaymentOptions,
                    appliedForPaymentMethods,
                    approvalDeclined,
                    approvalFailed,
                    onActivation,
                    isLegacyConfiguration,
                }}
            />
            <PaymentTypeGroupAdditional
                {...{
                    account,
                    pendingConnections,
                    failedConnections,
                    declinedConnections,
                    hasNonActiveCase,
                    configuredPaymentOptions,
                    appliedForPaymentMethods,
                    approvalDeclined,
                    approvalFailed,
                    onActivation,
                    isLegacyConfiguration,
                }}
            />
        </Card>
    );
};

const Header = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background: ${palette.neutral[900]};
    color: white;
`;

const HeaderTitle = styled.div`
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;

    padding-inline: 16px;
    padding-block: 12px;
    flex: 1;

    &.status {
        min-width: ${STATUS_MIN_WIDTH}px;
        flex: 0;
    }
`;

const BaseStatus = styled.span<{
    background: string;
    text?: string;
    borderColor?: string;
}>`
    font-weight: 500;
    line-height: normal;
    border-radius: 22px;
    padding: 5px 10px;
    display: inline-block;
    background: ${(props) => props.background};
    color: ${(props) => props.text || "white"};
    position: relative;
    overflow: hidden;
    max-width: 100%;

    ${(props) => props.borderColor && `border: 1px solid ${props.borderColor};`}

    &> span {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 12px;
        line-height: 18px;
    }
`;

const EmptyStatus = styled.span`
    font-weight: 500;
    line-height: normal;
    padding: 5px 10px;
    border-radius: 22px;
    display: inline-block;
    background: transparent;
    border: 1px dashed ${palette.neutral[200]};
    position: relative;
    overflow: hidden;
    max-width: 100%;
    &> span {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 12px;
        line-height: 18px;
        color: ${palette.neutral[700]};
    }
`;

const PaymentType = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline: 16px;
    padding-block: 12px;
    border-bottom: 1px solid ${palette.neutral[200]};
    &:last-child {
        border-bottom: none;
    }
`;

const Label = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    flex: 1;
`;

const LabelText = styled.div`
    font-size: 18px;
    font-weight: 600;
`;

const StatusWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    min-width: ${STATUS_MIN_WIDTH}px;
`;

export default PaymentMethodConfiguration;
