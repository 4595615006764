import ArrowDownOnSquareIcon from "@heroicons/react/24/outline/ArrowDownOnSquareIcon";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { useAccountId } from "../../../auth/useAccountId";
import { ButtonExternalLink, ButtonLink } from "../../../components/Buttons";
import Card from "../../../components/Card";
import Icon from "../../../components/Icons";
import IconWrapper from "../../../components/Icons/IconWrapper";
import { H2, P } from "../../../components/Typography";
import View from "../../../components/View";
import { getCurrentLanguage } from "../../../i18n";
import { palette } from "../../../styles/constants";
import type { Account } from "../../../types/management-auth";
import type { ApprovalsPaymentResponse } from "../../../types/management-auth/generated";
import FinishAccountInfobox from "../../components/FinishAccountInfobox";
import ApprovalsStatusBadge from "./ApprovalStatusBadge";
import NotAvailable from "./NotAvailable";
import { ParagraphWaitingForReview } from "./ParagraphWaitingForReview";
import { toLanguageLink } from "./utils";

const ApprovalsStatus = ({
    approvals,
    account,
}: { approvals: ApprovalsPaymentResponse[]; account: Account }) => {
    const accountId = useAccountId();
    useEffect(() => {
        // scroll to top on mount
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);
    const { t, i18n } = useTranslation();
    const mostRecentCase = approvals[approvals.length - 1];
    if (mostRecentCase) {
        const caseUrl = toLanguageLink(
            mostRecentCase.links?.find((l) => l.rel === "contract_url")?.href,
            getCurrentLanguage(i18n),
        );
        const contractFileUrl = mostRecentCase.links?.find(
            (l) => l.rel === "signed_contract_file_url",
        )?.href;
        return (
            <>
                {mostRecentCase.case_status === "WAITING_FOR_DECLARATION" && (
                    <Card>
                        <H2
                            lineHeight={33}
                            letterSpacing={-0.525}
                            fontWeight={500}
                        >
                            {t(
                                "settings.approvalsPayments.approvalsWaitingDeclaration.title",
                            )}
                        </H2>

                        <View
                            direction="column"
                            justify="flex-start"
                            alignItems="flex-start"
                            gap="8px"
                        >
                            <P fontSize={16} lineHeight={26} fontWeight={400}>
                                {t(
                                    "settings.approvalsPayments.approvalsWaitingDeclaration.paragraph_action",
                                )}
                            </P>
                            <br />
                            {caseUrl && (
                                <ButtonLink
                                    className="large"
                                    to={`payment-connections/declaration?caseUrl=${caseUrl}&accountId=${accountId}`}
                                >
                                    <ButtonInner>
                                        {t(
                                            "settings.approvalsPayments.approvalsWaitingDeclaration.button",
                                        )}
                                    </ButtonInner>
                                </ButtonLink>
                            )}
                            <br />
                            <ParagraphWaitingForReview account={account} />
                        </View>
                    </Card>
                )}
                {mostRecentCase.case_status === "WAITING_FOR_SIGNATURE" && (
                    <Card>
                        <H2
                            lineHeight={33}
                            letterSpacing={-0.525}
                            fontWeight={500}
                        >
                            {t(
                                "settings.approvalsPayments.approvalsWaitingSignatures.title",
                            )}
                        </H2>

                        <View
                            direction="column"
                            justify="flex-start"
                            alignItems="flex-start"
                            gap="8px"
                        >
                            <P>
                                {t(
                                    "settings.approvalsPayments.approvalsWaitingSignatures.paragraph_review",
                                )}
                            </P>
                            <br />
                            {caseUrl && (
                                <ButtonLink
                                    className="large"
                                    to={`payment-connections/declaration?caseUrl=${caseUrl}&accountId=${accountId}`}
                                >
                                    <ButtonInner>
                                        {t(
                                            "settings.approvalsPayments.approvalsWaitingSignatures.button",
                                        )}
                                    </ButtonInner>
                                </ButtonLink>
                            )}
                        </View>
                    </Card>
                )}
                {mostRecentCase.case_status === "WAITING_FOR_DETAILS" && (
                    <>
                        <H2>
                            {t(
                                "settings.approvalsPayments.approvalsWaitingDetails.title",
                            )}
                        </H2>

                        <Stack>
                            <P>
                                {t(
                                    "settings.approvalsPayments.approvalsWaitingDetails.paragraph_action",
                                )}
                            </P>
                            <P>
                                {t(
                                    "settings.approvalsPayments.approvalsWaitingDetails.paragraph_review",
                                )}
                            </P>
                        </Stack>
                    </>
                )}
                {["UNDER_MANUAL_REVIEW", "AUTOMATIC_REVIEW"].includes(
                    mostRecentCase.case_status || "",
                ) && (
                    <AlertCard>
                        <View
                            gap={8}
                            justify="flex-start"
                            alignItems="flex-start"
                        >
                            <Icon icon="info_circle" />
                        </View>
                        <View direction="column" gap={8}>
                            <H2
                                lineHeight={33}
                                letterSpacing={-0.525}
                                fontWeight={500}
                            >
                                {t(
                                    "settings.approvalsPayments.approvalsWaitingReview.title",
                                )}
                            </H2>
                            <P fontSize={16} lineHeight={26} fontWeight={400}>
                                {t(
                                    "settings.approvalsPayments.approvalsWaitingReview.paragraph_review",
                                )}
                            </P>
                        </View>
                    </AlertCard>
                )}
                {mostRecentCase.case_status === "DECLINED" && (
                    <WarningCard>
                        <View
                            gap={8}
                            justify="flex-start"
                            alignItems="flex-start"
                        >
                            <Icon icon="exclamation" />
                        </View>
                        <View direction="column" gap={8}>
                            <H2
                                lineHeight={33}
                                letterSpacing={-0.525}
                                fontWeight={500}
                            >
                                {t(
                                    "settings.approvalsPayments.approvalsDeclined.title",
                                )}
                            </H2>
                            <P fontSize={16} lineHeight={26} fontWeight={400}>
                                {t(
                                    "settings.approvalsPayments.approvalsDeclined.paragraph_action",
                                )}
                            </P>
                        </View>
                    </WarningCard>
                )}
                {mostRecentCase.case_status === "ERROR" && (
                    <WarningCard>
                        <View
                            gap={8}
                            justify="flex-start"
                            alignItems="flex-start"
                        >
                            <Icon icon="exclamation" />
                        </View>
                        <View direction="column" gap={8}>
                            <H2
                                lineHeight={33}
                                letterSpacing={-0.525}
                                fontWeight={500}
                            >
                                {t(
                                    "settings.approvalsPayments.approvalsError.title",
                                )}
                            </H2>
                            <P fontSize={16} lineHeight={26} fontWeight={400}>
                                {t(
                                    "settings.approvalsPayments.approvalsError.paragraph_action",
                                )}
                            </P>
                        </View>
                    </WarningCard>
                )}
                {mostRecentCase.case_status === "ACTIVE" && (
                    <SpacedApartRow>
                        <RowGroup>
                            <Strong>
                                {t(
                                    "settings.approvalsPayments.approvalsActive.declarationForm",
                                )}
                            </Strong>
                            {mostRecentCase.case_status && (
                                <ApprovalsStatusBadge
                                    status="success"
                                    label={t(
                                        "settings.approvalsPayments.signed",
                                    )}
                                />
                            )}
                        </RowGroup>
                        {contractFileUrl && (
                            <ButtonExternalLink
                                href={contractFileUrl}
                                target="_blank"
                            >
                                <ButtonInner>
                                    <IconWrapper>
                                        <ArrowDownOnSquareIcon />
                                    </IconWrapper>
                                    {t(
                                        "settings.approvalsPayments.approvalsActive.action",
                                    )}
                                </ButtonInner>
                            </ButtonExternalLink>
                        )}
                    </SpacedApartRow>
                )}
            </>
        );
    }
    const country = account?.company?.address?.country || "";
    if (!country) {
        return (
            <Card>
                <FinishAccountInfobox />
            </Card>
        );
    }

    if (!["SE", "NO", "DK"].includes(country)) {
        return (
            <Card>
                <NotAvailable productLabel="Checkout" />
            </Card>
        );
    }
    return null;
};

const Strong = styled.strong`
    font-weight: 600;
    display: inline-block;
`;

const RowGroup = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

const SpacedApartRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
`;

const Stack = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin-top: 8px;
`;

const ButtonInner = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

const AlertCard = styled.div`
    display: flex;
    gap: 7.504px;
    align-self: stretch;
    background: ${palette.primary[50]};
    padding: var(--Spacing-1);

    h2 {
        color: ${palette.primary[500]};
        font-size: 16.884px;
        font-style: normal;
        font-weight: 500;
        line-height: 26.264px;
        align-self: stretch;
    }

    p {
        color: ${palette.neutral[900]};
        font-size: 15.008px;
        font-style: normal;
        font-weight: 500;
        line-height: 22.512px;
        align-self: stretch;
    }
`;

const WarningCard = styled.div`
    display: flex;
    gap: 7.504px;
    align-self: stretch;
    background: ${palette.warning[100]};
    padding: var(--Spacing-1);

    h2 {
        color: ${palette.neutral[900]};
        font-size: 16.884px;
        font-style: normal;
        font-weight: 500;
        line-height: 26.264px;
        align-self: stretch;
    }

    p {
        color: ${palette.neutral[900]};
        font-size: 15.008px;
        font-style: normal;
        font-weight: 500;
        line-height: 22.512px;
        align-self: stretch;
    }
`;

export default ApprovalsStatus;
