import { useEffect, useRef } from "react";

/**
 * Usefull hook to invoke a function (callback) whenever arguments to the function changes, but not when the function itself changes.
 * @param callback The function to call when `args` changes. Will not be called when this function changes
 * @param args The arguments to call `callback` with
 */
export default function useEffectCallback<T extends (...args: any[]) => void>(
    callback: T,
    args: Parameters<T>,
) {
    const callbackRef = useRef(callback);
    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Re-run only when args change
    useEffect(() => {
        callbackRef.current(...args);
    }, args);
}
