import { useTranslation } from "react-i18next";

import AllowDinteroRoot from "../../../../auth/accessToken/components/AllowDinteroRoot";
import { ButtonLink } from "../../../../components/Buttons";
import { Divider } from "../../../../components/TopBar/elements";
import View from "../../../../components/View";
import { distances } from "../../../../styles/constants";
import { CheckoutConfig } from "../../../../types/checkout";
import { CheckoutConfigWrite } from "../../../../types/checkout/generated";
import { Account } from "../../../../types/management-auth";
import FinishAccountInfobox from "../../../components/FinishAccountInfobox";
import BamboraConnectionListItem from "./BamboraConnectionListItem";
import CollectorConnectionListItem from "./CollectorConnectionListItem";
import InstabankConnectionListItem from "./InstabankConnectionListItem";
import KlarnaConnectionListItem from "./KlarnaConnectionListItem";
import PayexConnectionListItem from "./PayexConnectionListItem";
import SwishConnectionListItem from "./SwishConnectionListItem";
import VippsConnectionListItem from "./VippsConnectionListItem";

export interface ConnectionsListProps {
    showFinishProfile: boolean;
    account: Account;
    accountId: string;
    openInstabankModal: (accountId: string, account: Account) => void;
    openCollectorModal: (account: Account) => void;
    openCollectorCallbackModal: () => void;
    openPayExCallbackModal: (
        accountId: string,
        account: Account,
        type?: "swap_agreement" | "initial_connection_configuration",
    ) => void;
    checkoutConfig: CheckoutConfig | undefined;
    updateCheckoutConfig: (
        accountId: string,
        checkoutConfig: CheckoutConfigWrite,
    ) => Promise<void>;
    isUpdatedSuccessfully: boolean;
}

const ConnectionsList = ({
    showFinishProfile,
    account,
    accountId,
    openCollectorModal,
    openCollectorCallbackModal,
    openInstabankModal,
    openPayExCallbackModal,
    checkoutConfig,
    updateCheckoutConfig,
    isUpdatedSuccessfully,
}: ConnectionsListProps) => {
    const { t, i18n, ready: tReady } = useTranslation("app");

    const vipps = account.connections?.vipps;
    const collector = account.connections?.collector;
    const swish = account.connections?.swish;
    const klarna = account.connections?.klarna;
    const bambora = account.connections?.bambora;
    const payex =
        account.connections?.payex?.schema === 2
            ? account.connections.payex
            : account.connections?.payex?.creditcard;
    const instabank = account.connections?.instabank;

    return (
        <>
            {showFinishProfile && (
                <FinishAccountInfobox context="payment_connections" />
            )}
            <AllowDinteroRoot
                visibleFrame
                useBackgroundColor={false}
                fallback={
                    <>
                        <ButtonLink
                            to={`${accountId}/settings/payment-connections`}
                        >
                            {t("settings.menu.payment_connections")}
                        </ButtonLink>
                    </>
                }
            >
                <View
                    direction="column"
                    alignItems="flex-start"
                    width="100%"
                    px={distances.small}
                >
                    <VippsConnectionListItem
                        t={t}
                        tReady={tReady}
                        vipps={vipps}
                        account={account}
                        accountId={accountId}
                        disabled={showFinishProfile}
                        i18n={i18n}
                    />
                    <Divider />
                    <PayexConnectionListItem
                        disabled={showFinishProfile}
                        i18n={i18n}
                        t={t}
                        tReady={tReady}
                        account={account}
                        accountId={accountId}
                        openPayExCallbackModal={openPayExCallbackModal}
                        payex={payex}
                        checkoutConfig={checkoutConfig}
                        updateCheckoutConfig={updateCheckoutConfig}
                        isUpdatedSuccessfully={isUpdatedSuccessfully}
                    />
                    <Divider />
                    <CollectorConnectionListItem
                        openCollectorModal={openCollectorModal}
                        openCollectorCallbackModal={openCollectorCallbackModal}
                        collector={collector}
                        disabled={showFinishProfile}
                        i18n={i18n}
                        t={t}
                        tReady={tReady}
                        account={account}
                        accountId={accountId}
                        checkoutConfig={checkoutConfig}
                        updateCheckoutConfig={updateCheckoutConfig}
                        isUpdatedSuccessfully={isUpdatedSuccessfully}
                    />
                    <Divider />
                    <KlarnaConnectionListItem
                        klarna={klarna}
                        disabled={showFinishProfile}
                        account={account}
                        accountId={accountId}
                        checkoutConfig={checkoutConfig}
                        updateCheckoutConfig={updateCheckoutConfig}
                        isUpdatedSuccessfully={isUpdatedSuccessfully}
                    />
                    <Divider />
                    <InstabankConnectionListItem
                        openInstabankModal={openInstabankModal}
                        instabank={instabank}
                        disabled={showFinishProfile}
                        i18n={i18n}
                        t={t}
                        tReady={tReady}
                        account={account}
                        accountId={accountId}
                    />
                    <Divider />
                    <SwishConnectionListItem
                        swish={swish}
                        disabled={showFinishProfile}
                        i18n={i18n}
                        t={t}
                        tReady={tReady}
                        account={account}
                        accountId={accountId}
                    />
                    <Divider />
                    <BamboraConnectionListItem
                        bambora={bambora}
                        disabled={showFinishProfile}
                        account={account}
                        accountId={accountId}
                        checkoutConfig={checkoutConfig}
                        updateCheckoutConfig={updateCheckoutConfig}
                        isUpdatedSuccessfully={isUpdatedSuccessfully}
                    />
                </View>
            </AllowDinteroRoot>
        </>
    );
};

export default ConnectionsList;
