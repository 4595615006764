import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { formatCountryCode } from "../helpers/formatters";

import { Muted } from "./Typography";

interface AddressProps {
    first_name?: string;
    last_name?: string;
    organization_number?: string;
    address_line?: string;
    address_line_2?: string;
    co_address?: string;
    business_name?: string;
    postal_code?: string;
    postal_place?: string;
    country?: string;
}

const Address = (props: AddressProps) => {
    const [t] = useTranslation();
    return (
        <Wrapper>
            {props.organization_number && (
                <Line>
                    <Muted>{t("payments.fields.organization_number")}</Muted>{" "}
                    {props.organization_number}
                </Line>
            )}
            {props.co_address && (
                <Line>
                    <Muted>c/o</Muted> {props.co_address}
                </Line>
            )}
            {(props.first_name || props.last_name) && (
                <Line>
                    {props.first_name} {props.last_name}
                </Line>
            )}
            {props.business_name && <Line>{props.business_name}</Line>}
            {props.address_line && <Line>{props.address_line}</Line>}
            {props.address_line_2 && <Line>{props.address_line_2}</Line>}
            {(props.postal_code || props.postal_place) && (
                <Line>
                    {props.postal_code} {props.postal_place}
                </Line>
            )}
            {props.country && <Line>{formatCountryCode(props.country)}</Line>}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: block;
    line-height: 32px;
`;

const Line = styled.div`
    display: block;
`;

export default Address;
