import { Account } from "../../../types/management-auth";
import { ActionTypes, Actions, SearchedCompany } from "./actions";

export interface State {
    isLoading: boolean;
    error: boolean;
    account: Account | undefined;
    results: SearchedCompany[];
}

export const initialState: State = {
    isLoading: false,
    error: false,
    account: undefined,
    results: [],
};

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {
        case ActionTypes.FetchAccount:
            return {
                ...initialState,
                isLoading: true,
            };

        case ActionTypes.FetchAccountResponse:
            return {
                ...state,
                isLoading: false,
                account: action.payload.account,
            };

        case ActionTypes.SearchCompanyNameResponse:
            return {
                ...state,
                results: action.payload.results,
            };

        default:
            return state;
    }
}
