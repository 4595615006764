import deepmerge from "deepmerge";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components/macro";
import {
    Email,
    Input,
    OrganizationNumber,
    Url,
    ValidationState,
} from "../../../../components/Forms";
import { getValueAt } from "../../../../helpers/getValueAt";
import { globalColumnMaxWidth, grid } from "../../../../styles/constants";

interface ValidatedInputProps extends WithTranslation {
    values: any;
    errors: any;
    path: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    touched: any;
    required?: boolean;
    setFieldValue?: (field: string, value: string) => void;
    setTouched?: (fields: any) => void;
    initialValues?: any;
    disabled?: boolean;
}

const ValidatedInput = ({
    t,
    values,
    errors,
    path,
    onChange,
    onBlur,
    touched,
    required,
    setFieldValue,
    setTouched,
    initialValues,
    disabled,
}: ValidatedInputProps) => {
    const value = getValueAt(path, values);
    const error = getValueAt(path, errors);
    const was_toched = getValueAt(path, touched);
    const validation = error
        ? {
              state: ValidationState.Invalid,
              message: error,
          }
        : undefined;
    const label = t(`settings.edit_account.fields.${path}`);

    if (path === "company.website" && setFieldValue) {
        return (
            <InputWrapper>
                <Url
                    label={label}
                    placeholder={t(
                        `settings.edit_account.placeholders.${path}`,
                    )}
                    name={path}
                    value={value || ""}
                    onBlur={(e) => {
                        onChange(e);
                        onBlur(e);
                    }}
                    validation={was_toched && validation}
                    setFieldValue={setFieldValue}
                    disabled={disabled}
                    required={required}
                />
            </InputWrapper>
        );
    }
    if (path === "billing.email" && setFieldValue) {
        return (
            <InputWrapper>
                <Email
                    label={label}
                    placeholder={t(
                        `settings.edit_account.placeholders.${path}`,
                    )}
                    name={path}
                    value={value || ""}
                    onBlur={(e) => {
                        onChange(e);
                        onBlur(e);
                    }}
                    validation={was_toched && validation}
                    setFieldValue={setFieldValue}
                    disabled={disabled}
                    required={required}
                />
            </InputWrapper>
        );
    }
    if (
        ["company.organization_number", "billing.organization_number"].includes(
            path,
        ) &&
        setFieldValue &&
        setTouched
    ) {
        const parent = path.split(".")[0];
        const initialValue = getValueAt(path, initialValues);
        return (
            <InputWrapper>
                <OrganizationNumber
                    label={label}
                    placeholder={t(
                        `settings.edit_account.placeholders.${path}`,
                    )}
                    name={path}
                    value={value || ""}
                    setFieldValue={setFieldValue}
                    onBlur={(e) => {
                        setTouched(
                            deepmerge(touched || {}, {
                                [parent]: {
                                    organization_number: true,
                                },
                            }),
                        );
                    }}
                    validation={(was_toched || initialValue) && validation}
                    country={
                        getValueAt(`${parent}.address.country`, values) || ""
                    }
                    disabled={disabled}
                    required={required}
                />
            </InputWrapper>
        );
    }
    return (
        <InputWrapper>
            <Input
                label={label}
                placeholder={t(`settings.edit_account.placeholders.${path}`)}
                type="text"
                name={path}
                value={value || ""}
                onChange={(e) => {
                    onChange(e);
                }}
                onBlur={(e) => {
                    onChange(e);
                    onBlur(e);
                }}
                validation={was_toched && validation}
                disabled={disabled}
                required={required}
            />
        </InputWrapper>
    );
};

const TranslatedValidatedInput = withTranslation()(ValidatedInput);

const InputWrapper = styled.div`
    width: ${grid.spans.span4};
    margin-right: ${grid.gutter};

    &:last-child {
        margin: 0;
    }

    @media (max-width: ${globalColumnMaxWidth}px) {
        width: 100%;
    }
`;

export { TranslatedValidatedInput, ValidatedInput };
