import AllowPartner from "./AllowPartner";

const AllowDinteroRoot = (props: {
    children: JSX.Element;
    visibleFrame?: boolean;
    fallback?: JSX.Element;
    useBackgroundColor?: boolean;
}) => <AllowPartner {...props} onlyDinteroAdmin={true} />;

export default AllowDinteroRoot;
