import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { Button } from "../../../../components/Buttons";
import Modal from "../../../../components/Modal";
import { H1, P } from "../../../../components/Typography";
import { distances } from "../../../../styles/constants";
import { AccountAdminUser } from "../../../../types/management-auth";

export interface DeleteUserModalProps extends WithTranslation {
    accountId: string;
    user: AccountAdminUser;
    dismissRemoveUserModal: () => void;
    removeUser: (payload: { accountId: string; userId: string }) => void;
}

const RemoveUserModal = ({
    t,
    user,
    accountId,
    removeUser,
    dismissRemoveUserModal,
}: DeleteUserModalProps) => {
    return (
        <Modal>
            <H1>{t("settings.remove_user.title", { email: user.email })}</H1>

            <TextWrapper>
                <P>{t("settings.remove_user.description")}</P>
            </TextWrapper>
            <Button
                onClick={() => {
                    removeUser({
                        accountId,
                        userId: user.id || "",
                    });
                }}
            >
                {t("settings.remove_user.remove_user_call_to_action")}
            </Button>
            <ButtonSpacer />
            <Button className="alt" onClick={dismissRemoveUserModal}>
                {t("settings.remove_user.go_back")}
            </Button>
        </Modal>
    );
};

export default withTranslation()(RemoveUserModal);

const ButtonSpacer = styled.span`
    display: inline-block;
    width: ${distances.tiny};
`;

const TextWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.normal};
`;
