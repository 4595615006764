import React from "react";

interface PaymentsProps {
    width?: string;
    height?: string;
    fill?: string;
}

const Payments = ({
    width = "100%",
    height = "100%",
    fill = "#0000FF",
}: PaymentsProps) => (
    <svg
        width={width}
        height={width}
        viewBox="0 0 32 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="Group"
                transform="translate(0.000000, 2.000000)"
                fill={fill}
                fillRule="nonzero"
            >
                <rect
                    id="Rectangle"
                    x="0"
                    y="20.6521739"
                    width="31.884058"
                    height="2.29468599"
                ></rect>
                <rect
                    id="Rectangle"
                    x="0"
                    y="25.2415459"
                    width="31.884058"
                    height="2.29468599"
                ></rect>
                <path
                    d="M25.0517599,6.88405797 C23.7939686,6.88405797 22.7743271,7.91142388 22.7743271,9.17874396 C22.7743271,10.446064 23.7939686,11.47343 25.0517599,11.47343 C26.3095512,11.47343 27.3291926,10.446064 27.3291926,9.17874396 C27.3291926,7.91142388 26.3095512,6.88405797 25.0517599,6.88405797 Z"
                    id="Path"
                ></path>
                <path
                    d="M15.942029,13.7681159 C13.4264463,13.7681159 11.3871636,11.7133841 11.3871636,9.17874396 C11.3871636,6.64410381 13.4264463,4.58937199 15.942029,4.58937199 C18.4576117,4.58937199 20.4968943,6.64410381 20.4968943,9.17874396 C20.4940704,11.7122046 18.456441,13.7652706 15.942029,13.7681159 L15.942029,13.7681159 Z M15.942029,6.88405797 C14.6842377,6.88405797 13.6645963,7.91142388 13.6645963,9.17874396 C13.6645963,10.446064 14.6842377,11.47343 15.942029,11.47343 C17.1998203,11.47343 18.2194617,10.446064 18.2194617,9.17874396 C18.2182064,7.91194819 17.1993,6.88532287 15.942029,6.88405797 Z"
                    id="Shape"
                ></path>
                <path
                    d="M6.83229813,6.88405797 C5.57450678,6.88405797 4.55486542,7.91142388 4.55486542,9.17874396 C4.55486542,10.446064 5.57450678,11.47343 6.83229813,11.47343 C8.09008949,11.47343 9.10973086,10.446064 9.10973086,9.17874396 C9.10973086,7.91142388 8.09008949,6.88405797 6.83229813,6.88405797 Z"
                    id="Path"
                ></path>
                <path
                    d="M29.6066252,18.357488 L2.27743271,18.357488 C1.02096582,18.3542719 0.00319179745,17.3287875 0,16.0628019 L0,2.29468599 C0.00319179745,1.02870041 1.02096582,0.00321597774 2.27743271,0 L29.6066252,0 C30.8630922,0.00321597774 31.8808662,1.02870041 31.884058,2.29468599 L31.884058,16.0628019 C31.8823643,17.3294145 30.8637143,18.3557814 29.6066252,18.357488 Z M29.6066252,2.29468599 L2.27743271,2.29468599 L2.27743271,16.0628019 L29.6066252,16.0628019 L29.6066252,2.29468599 Z"
                    id="Shape"
                ></path>
            </g>
        </g>
    </svg>
);

export default Payments;
