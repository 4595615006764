import {
    CheckoutConfig,
    NewProfileConfiguration,
    ProfileConfiguration,
} from "../../../types/checkout";
import { Account } from "../../../types/management-auth";

export enum ActionTypes {
    ResetForm = "[settings/newPaymentProfile] RESET_FORM",
    GetCheckoutConfiguration = "[settings/newPaymentProfile] GET_CHECKOUT_CONFIGURATION",
    FetchCheckoutConfigurationResponse = "[settings/newPaymentProfile] FETCH_CHECKOUT_CONFIGURATION_RESPONSE",
    CreatePaymentProfile = "[settings/newPaymentProfile] CREATE_PAYMENT_PROFILE",
    CreatePaymentProfileResponse = "[settings/newPaymentProfile] FETCH_POST_PAYMENT_PROFILE_RESPONSE",
}

export interface ResetForm {
    type: ActionTypes.ResetForm;
    payload: {};
}

export function resetForm(): ResetForm {
    return {
        type: ActionTypes.ResetForm,
        payload: {},
    };
}

export interface GetCheckoutConfiguration {
    type: ActionTypes.GetCheckoutConfiguration;
    payload: {
        accountId: string;
    };
}

export function getCheckoutConfiguration(
    accountId: string,
): GetCheckoutConfiguration {
    return {
        type: ActionTypes.GetCheckoutConfiguration,
        payload: {
            accountId: accountId,
        },
    };
}

export interface FetchCheckoutConfigurationResponse {
    type: ActionTypes.FetchCheckoutConfigurationResponse;
    payload: {
        configuration: CheckoutConfig;
        account: Account;
    };
}

export interface CreatePaymentProfile {
    type: ActionTypes.CreatePaymentProfile;
    payload: {
        accountId: string;
        newProfile: NewProfileConfiguration;
    };
}

export interface FetchCreatePaymentProfileResponse {
    type: ActionTypes.CreatePaymentProfileResponse;
    payload: {
        paymentProfile: ProfileConfiguration;
        accountId: string;
    };
    meta: {
        cause: CreatePaymentProfile;
    };
}

export function createPaymentProfile(
    accountId: string,
    newProfile: NewProfileConfiguration,
): CreatePaymentProfile {
    return {
        type: ActionTypes.CreatePaymentProfile,
        payload: {
            accountId,
            newProfile,
        },
    };
}

export type Actions =
    | GetCheckoutConfiguration
    | FetchCheckoutConfigurationResponse
    | CreatePaymentProfile
    | FetchCreatePaymentProfileResponse
    | ResetForm;
