const getEnv = (key: string) => {
    const result = process.env[key];
    if (!result) {
        throw new Error(`${key} environment variable is not set`);
    }
    return result;
};

const getEnvOrUndefined = (key: string) => {
    return process.env[key];
};

export const getOrigin = (d: Document) => {
    const origin = d.location && d.location.origin;
    if (!origin) {
        throw new Error(`Unable to get document.location.origin`);
    }
    return origin;
};

export type Environment = "dev" | "test" | "prod" | "";
export const NODE_ENV = getEnv("NODE_ENV");
export const AWS_REGION = getEnv("REACT_APP_REACT_APP_AWS_REGION");
export const AWS_COGNITO_DOMAIN = getEnv("REACT_APP_COGNITO_DOMAIN") || "";
export const AWS_COGNITO_CLIENT = getEnv("REACT_APP_COGNITO_CLIENT") || "";
export const CORE_API_HOSTNAME =
    getEnv("REACT_APP_DINTERO_CORE_API_HOSTNAME") || "";
export const CHECKOUT_API_HOSTNAME = CORE_API_HOSTNAME + "/checkouts";
export const PAYOUT_API_HOSTNAME =
    getEnvOrUndefined("REACT_APP_DINTERO_PAYOUT_API_HOSTNAME") ||
    CORE_API_HOSTNAME;
export const ONBOARDING_HOSTNAME =
    getEnv("REACT_APP_DINTERO_ONBOARDING_HOSTNAME") || "";
export const DOCUMENTED_CORE_API_HOSTNAME =
    getEnv("REACT_APP_DOCUMENTED_DINTERO_CORE_API_HOSTNAME") || "";
export const ENVIRONMENT = (getEnv("REACT_APP_ENVIRONMENT") ||
    "") as Environment;
export const DINTERO_STANDARD_PAYEX_PAYEE_ID =
    getEnv("REACT_APP_DINTERO_STANDARD_PAYEX_PAYEE_ID") || "";
export const DINTERO_STANDARD_2_PAYEX_PAYEE_ID =
    getEnv("REACT_APP_DINTERO_STANDARD_2_PAYEX_PAYEE_ID") || "";
export const PUBLIC_URL = getEnvOrUndefined("PUBLIC_URL");
export const DETAILED_ITEMS_FEATURE =
    getEnv("REACT_APP_DETAILED_ITEMS_FEATURE") || "";
export const GOOGLE_API_KEY =
    getEnvOrUndefined("REACT_APP_GOOGLE_API_KEY") || "";
export const COGNITO_FEDERATED_ISSUER =
    getEnvOrUndefined("REACT_APP_COGNITO_FEDERATED_ISSUER") || "";
export const COGNITO_FEDERATED_GOOGLE_SIGNIN_URL =
    getEnvOrUndefined("REACT_APP_COGNITO_FEDERATED_GOOGLE_SIGNIN_URL") || "";
export const COGNITO_FEDERATED_SIGNOUT_URL =
    getEnvOrUndefined("REACT_APP_COGNITO_FEDERATED_SIGNOUT_URL") || "";
export const AUTO_EXTEND_AUTH_FEATURE_START_DATE =
    getEnvOrUndefined("REACT_APP_AUTO_EXTEND_AUTH_FEATURE_START_DATE") ||
    "2024-06-25";

const cognitoUrls = (
    domain: string,
    client_id: string,
): { login: string; logout: string } => {
    const redirectUrl = getOrigin(document);
    const server = `https://${domain}`;
    return {
        login: `${server}/login?response_type=token&client_id=${client_id}&redirect_uri=${redirectUrl}`,
        logout: `${server}/logout?client_id=${client_id}&logout_uri=${redirectUrl}`,
    };
};

// resolve cognito configuration from a single URL where the hostname
// of the URL is the host where the configuration should be used
// https://vy.test.dintero.com?domain=vy.login.test.dintero.com&client_id=4f4bm5beav283q242pjb92ci0p
const cognitoConfigFromUrl = (
    uri?: string,
): { origin: string; domain: string; client_id: string } | undefined => {
    if (!uri) {
        return undefined;
    }

    try {
        const url = new URL(uri);
        const domain = url.searchParams.get("domain");
        const clientId = url.searchParams.get("client_id");
        if (!domain || !clientId) {
            return undefined;
        }
        return {
            origin: url.origin,
            domain,
            client_id: clientId,
        };
    } catch (err) {
        return undefined;
    }
};

export const VY_COGNITO = cognitoConfigFromUrl(
    getEnvOrUndefined("REACT_APP_VY_COGNITO_URI"),
);

export const cognito = (): {
    login: string;
    logout: string;
    external: boolean;
} => {
    if (VY_COGNITO && VY_COGNITO.origin === getOrigin(document)) {
        return {
            ...cognitoUrls(VY_COGNITO.domain, VY_COGNITO.client_id),
            external: true,
        };
    }
    return {
        ...cognitoUrls(AWS_COGNITO_DOMAIN, AWS_COGNITO_CLIENT),
        external: false,
    };
};

export const version = getEnvOrUndefined("REACT_APP_SOURCE_VERSION");

export const ACCOUNTS_WITH_MCC_CODE_DISCOUNTS_ONLY =
    getEnvOrUndefined("REACT_APP_ACCOUNTS_WITH_MCC_CODE_DISCOUNTS_ONLY") || "";
