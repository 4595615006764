import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { EnvironmentBadge } from "../../../../components/EnvironmentBadge";
import { DateTime } from "../../../../components/Formatters";
import { LoadingOverlay } from "../../../../components/Loading";
import Page, { PageButtonLink } from "../../../../components/Page";
import {
    LinkTr,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from "../../../../components/Table/Fake";
import { H1 } from "../../../../components/Typography";

import { useAccountId } from "../../../../auth/useAccountId";
import Icon from "../../../../components/Icons";
import useUsers from "../hooks/useUsers";

const UsersList = () => {
    const { t } = useTranslation();
    const { isLoading, users, refresh } = useUsers();
    const accountId = useAccountId();

    return (
        <Page
            title={
                <React.Fragment>
                    <Title>{t("settings.users.title")}</Title>
                    <PageButtonLink to={`/${accountId}/settings/users/new`}>
                        <Icon icon="plus" fill="currentColor" />
                        {t("settings.users.create_new_user")}
                    </PageButtonLink>
                </React.Fragment>
            }
        >
            <Wrapper>
                <Table className="no-wrap">
                    <Thead>
                        <Tr>
                            <Th className="first-child">
                                {t("settings.users.fields.email")}
                            </Th>
                            <ThDateTime>
                                {t("settings.users.fields.last_logged_in_at")}
                            </ThDateTime>
                            <ThDateTime className="collapse-1200">
                                {t("settings.users.fields.created_at")}
                            </ThDateTime>
                            <ThShort className="collapse-1200 align-right">
                                {t("settings.users.fields.settings")}
                            </ThShort>
                            <ThShort className="last-child align-right">
                                {t("settings.users.fields.authentication_type")}
                            </ThShort>
                        </Tr>
                    </Thead>
                    {users.length > 0 && (
                        <Tbody>
                            {users.map((user) => (
                                <LinkTr
                                    key={user.id}
                                    to={`/${accountId}/settings/users/${user.id}`}
                                >
                                    <Td className="first-child">
                                        {user.email}
                                    </Td>
                                    <Td>
                                        <DateTime
                                            isoString={user.last_seen_at || ""}
                                            boldTime
                                        />
                                    </Td>
                                    <Td className="collapse-1200">
                                        <DateTime
                                            isoString={user.created_at || ""}
                                            boldTime
                                        />
                                    </Td>
                                    <Td className="collapse-1200 align-right">
                                        {(user?.configuration?.mfa?.sms
                                            ?.enabled ||
                                            user?.configuration?.mfa?.totp
                                                ?.enabled) && (
                                            <EnvironmentBadge
                                                environment="unknown"
                                                label="MFA"
                                            />
                                        )}
                                    </Td>

                                    <Td className="collapse-1200 align-right">
                                        {user.authentication_type ===
                                            "external" && (
                                            <EnvironmentBadge
                                                environment="unknown"
                                                label={t(
                                                    "settings.users.authentication_type_external",
                                                )}
                                            />
                                        )}
                                    </Td>
                                </LinkTr>
                            ))}
                        </Tbody>
                    )}
                </Table>
                {isLoading && <LoadingOverlay />}
            </Wrapper>
        </Page>
    );
};

export default UsersList;

const Wrapper = styled.div`
    position: relative;
`;

const Title = styled(H1)`
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

const ThDateTime = styled(Th)`
    width: 200px;
`;

const ThShort = styled(Th)`
    width: 150px;
`;
