import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { formatDate } from "../../helpers/formatters";

interface DateProps extends WithTranslation {
    isoString: string;
}

const DateComponent = ({ isoString, i18n }: DateProps) => {
    if (!isoString) {
        return null;
    }

    const dateString = formatDate(isoString, i18n.language);
    return <Wrapper>{dateString}</Wrapper>;
};

const Wrapper = styled.span`
    white-space: nowrap;
    font-feature-settings: 'tnum';
`;

export default withTranslation()(DateComponent);
