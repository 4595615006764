import { useActions } from "../../../Actions";
import { useAccountIds } from "../../../auth/accessToken/components/withAccountIds";
import useHasAnyOfScopes from "../../../common/hooks/useHasAnyOfScopes";
import type { Account } from "../../../types/management-auth";
import type { PayoutAccountService } from "../../../types/management-auth/generated";
import useEffectCallback from "../../../util/useEffectCallback";
import useAccount from "../../account/hooks/useAccount";

export default function useSplitPayment(params?: {
    account: Account;
    partnerId: string;
    onAdminUpdateAccountSuccess?: (account: Account) => void;
}) {
    const { optimisticProdAccountId } = useAccountIds();
    const isDinteroAdmin = useHasAnyOfScopes(["admin:dintero"]);
    const {
        isLoading: loadingAccount,
        account: reqAccount,
        updateAccount,
        adminUpdateAccount,
        isAdminUpdatingAccount,
    } = useAccount({
        onAdminUpdateAccountSuccess: params?.onAdminUpdateAccountSuccess,
    });
    const { getPayoutDestinations } = useActions("payout.config");
    const account = params?.account || reqAccount;
    useEffectCallback(getPayoutDestinations, [optimisticProdAccountId]);

    const features = account?.services?.payout
        ?.features as PayoutAccountService["features"];
    const isLoading = loadingAccount;

    const activateSplitPayment = async () => {
        if (!account) return;
        const newSettings = {
            ...account,
            services: {
                payout: {
                    features: {
                        split_payment: {
                            requested: "enable",
                        },
                    },
                },
            },
        };
        await updateAccount({
            accountId: optimisticProdAccountId,
            account: newSettings,
        });
    };

    const adminUpdateStatus = async (
        status: "enabled" | "disabled" | "pending" | "declined",
    ) => {
        if (!account || !isDinteroAdmin || !params) return;
        const newSettings = {
            ...account,
            services: {
                payout: {
                    features: {
                        split_payment: {
                            ...features?.split_payment,
                            status,
                        },
                    },
                },
            },
        };
        await adminUpdateAccount({
            accountId: `${account.livemode ? "P" : "T"}${account.account_id}`,
            partnerId: params.partnerId,
            account: newSettings,
        });
    };

    return {
        isLoading,
        status: features?.split_payment?.status,
        requested: features?.split_payment?.requested,
        activateSplitPayment,
        adminUpdateStatus,
        isAdminUpdatingAccount,
    };
}
