import { WithTranslation, withTranslation } from "react-i18next";
import { Input, ValidationState } from "../../../../components/Forms";
import { getValueAt } from "../../../../helpers/getValueAt";

interface ValidatedInputProps extends WithTranslation {
    values: any;
    initialValues: any;
    errors: any;
    path: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    touched: any;
    required?: boolean;
    maxlength?: number;
    formatter?: (value: string) => string;
    className?: string;
}

const ValidatedInput = ({
    t,
    values,
    initialValues,
    errors,
    path,
    onChange,
    onBlur,
    touched,
    required,
    maxlength,
    className,
}: ValidatedInputProps) => {
    const value = getValueAt(path, values);
    const initialValue = getValueAt(path, initialValues);
    const error = getValueAt(path, errors);
    const was_touched = getValueAt(path, touched);
    const validation =
        (was_touched || initialValue) && error
            ? {
                  state: ValidationState.Invalid,
                  message: error,
              }
            : undefined;
    const isCurrencyNamespace = path.includes("currencies");
    if (isCurrencyNamespace) {
        const namespace = path.split(".").slice(0, -1).join(".");
        const type = path.split(".").shift();
        const field = path.split(".").slice(-1)[0];
        const currency = getValueAt(
            `${namespace}.currency`,
            values,
        ).toLowerCase();
        const label =
            field === "store_id"
                ? t(
                      `settings.payment_connections.collector_callback_modal.fields.store_id_${currency}_${type}`,
                  )
                : t(
                      `settings.payment_connections.collector_callback_modal.fields.${field}`,
                  );
        const placeholder =
            field === "store_id"
                ? t(
                      `settings.payment_connections.collector_callback_modal.placeholders.store_id_${currency}_${type}`,
                  )
                : t(
                      `settings.payment_connections.collector_callback_modal.placeholders.${field}`,
                  );
        return (
            <Input
                label={label}
                placeholder={placeholder}
                type="text"
                name={path}
                value={value || ""}
                onChange={(e) => {
                    onChange(e);
                }}
                onBlur={(e) => {
                    onChange(e);
                    onBlur(e);
                }}
                validation={validation}
                maxlength={maxlength}
                required={required}
                className={className}
            />
        );
    }
    const label = t(
        `settings.payment_connections.collector_callback_modal.fields.${path}`,
    );
    return (
        <Input
            label={label}
            placeholder={t(
                `settings.payment_connections.collector_callback_modal.placeholders.${path}`,
            )}
            type="text"
            name={path}
            value={value || ""}
            onChange={(e) => {
                onChange(e);
            }}
            onBlur={(e) => {
                onChange(e);
                onBlur(e);
            }}
            validation={validation}
            maxlength={maxlength}
            required={required}
            className={className}
        />
    );
};

const TranslatedValidatedInput = withTranslation()(ValidatedInput);

export default TranslatedValidatedInput;
