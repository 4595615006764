import React from "react";
import { Route } from "react-router-dom";

import { EditPaymentProfile } from "./editProfile";
import { NewPaymentProfile } from "./newProfile";
import { ProfileDetails } from "./profileDetails";
import { Profiles } from "./profiles";

const Routes = [
    <Route
        key="payment-profiles"
        path="/:accountId/settings/payment-profiles"
        exact
        component={Profiles}
    />,
    <Route
        key="payment-profiles-new"
        path="/:accountId/settings/payment-profiles/new"
        exact
        component={NewPaymentProfile}
    />,
    <Route
        key="payment-profile-details"
        path="/:accountId/settings/payment-profiles/:profileAccountId/:profileId"
        exact
        component={ProfileDetails}
    />,
    <Route
        key="payment-profile-edit"
        path="/:accountId/settings/payment-profiles/:profileAccountId/:profileId/edit"
        exact
        component={EditPaymentProfile}
    />,
];

export default Routes;
