import compareDesc from "date-fns/compareDesc";
import { parseISOStrictOr } from "../helpers/date";
import { PaymentType, ProfileConfiguration } from "../types/checkout";

interface Sortable {
    deleted_at?: string;
    created_at?: string;
}

export const sortElements = <T extends Sortable>(elements: T[]) => {
    const active = elements.filter(
        (element) => element.deleted_at === undefined,
    );
    const deleted = elements.filter(
        (element) => element.deleted_at !== undefined,
    );
    return [
        ...active.sort((a, b) =>
            compareDesc(
                parseISOStrictOr(a.created_at, new Date(0)),
                parseISOStrictOr(b.created_at, new Date(0)),
            ),
        ),
        ...deleted.sort((a, b) =>
            compareDesc(
                parseISOStrictOr(a.deleted_at, new Date(0)),
                parseISOStrictOr(b.deleted_at, new Date(0)),
            ),
        ),
    ];
};

export const getPaymentProfilePaymentMethods = (
    profile: ProfileConfiguration,
) => {
    let paymentTypes: {
        type: string;
        dynamic_descriptor: string | undefined;
    }[] = [];
    if (profile.configuration.instabank) {
        const enabledInstabankTypes = Object.entries(
            profile.configuration.instabank,
        )
            .filter(([, value]) => value.enabled)
            .map(([key]) => ({
                type: `instabank.${key}`,
                dynamic_descriptor: undefined,
            }));
        paymentTypes = [...paymentTypes, ...enabledInstabankTypes];
    }
    if (profile.configuration.collector) {
        const enabledCollectorTypes = Object.entries(
            profile.configuration.collector,
        )
            .filter(([, value]) => value.enabled)
            .map(([key]) => ({
                type: `collector.${key}`,
                dynamic_descriptor: undefined,
            }));
        paymentTypes = [...paymentTypes, ...enabledCollectorTypes];
    }
    if (profile.configuration.santander) {
        const enabledSantanderTypes = Object.entries(
            profile.configuration.santander,
        )
            .filter(([, value]) => value.enabled)
            .map(([key]) => ({
                type: `santander.${key}`,
                dynamic_descriptor: undefined,
            }));
        paymentTypes = [...paymentTypes, ...enabledSantanderTypes];
    }
    if (profile.configuration.vipps && profile.configuration.vipps.enabled) {
        paymentTypes = [
            ...paymentTypes,
            {
                type: "vipps",
                dynamic_descriptor:
                    profile.configuration.vipps.dynamic_descriptor,
            },
        ];
    }
    if (profile.configuration.payex) {
        const enabledPayexTypes = Object.entries(profile.configuration.payex)
            .filter(([, value]) => value.enabled)
            .map(([key]) => ({
                type: `payex.${key}`,
                dynamic_descriptor: undefined,
            }));
        paymentTypes = [...paymentTypes, ...enabledPayexTypes];
    }
    if (profile.configuration.swish) {
        const enabledSwishTypes = Object.entries(profile.configuration.swish)
            .filter(([, value]) => value.enabled)
            .map(([key]) => ({
                type: `swish.${key}`,
                dynamic_descriptor: undefined,
            }));
        paymentTypes = [...paymentTypes, ...enabledSwishTypes];
    }
    if (profile.configuration.netaxept) {
        const enableNetaxept = Object.entries(profile.configuration.netaxept)
            .filter(([, value]) => value.enabled)
            .map(([key]) => ({
                type: `netaxept.${key}`,
                dynamic_descriptor: undefined,
            }));
        paymentTypes = [...paymentTypes, ...enableNetaxept];
    }
    if (profile.configuration.bambora) {
        const enabledBamboraTypes = Object.entries(
            profile.configuration.bambora,
        )
            .filter(([, value]) => value.enabled)
            .map(([key]) => ({
                type: `bambora.${key}`,
                dynamic_descriptor: undefined,
            }));
        paymentTypes = [...paymentTypes, ...enabledBamboraTypes];
    }
    if (profile.configuration.klarna) {
        const enabledKlarnaTypes = Object.entries(profile.configuration.klarna)
            .filter(([, value]) => value.enabled)
            .map(([key]) => ({
                type: `klarna.${key}`,
                dynamic_descriptor: undefined,
            }));
        paymentTypes = [...paymentTypes, ...enabledKlarnaTypes];
    }
    return paymentTypes as {
        type: PaymentType;
        dynamic_descriptor: string | undefined;
    }[];
};

export const fallbackIfEmpty = <T>(list: T[], fallback: T[]): T[] => {
    return list.length > 0 ? list : fallback;
};
