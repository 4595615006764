import { TFunction } from "i18next";
import { SalesLocation } from "../../../types/customers";
import { getPhonePrefixFromCountry } from "../shared/utils";

export const mapValuesFromBackend = (location: SalesLocation, t: TFunction) => {
    const getTypeLabel = (type: string) => {
        if (type === "Mobile") {
            return t("locations.location_form.fields.online");
        }
        return t("locations.location_form.fields.physical");
    };

    const getCountryLabel = (country: string) => {
        if (country === "SE") {
            return t("locations.location_form.fields.sweden");
        }
        return t("locations.location_form.fields.norway");
    };

    const countryPrefix = getPhonePrefixFromCountry(location.address?.country);

    return {
        locationId: location.location_id ?? "",
        name: location.name ?? "",
        email: location.email ?? "",
        status: location.status ?? "Active",
        organizationNumber: location.organization_number ?? "",
        businessName: location.business_name ?? "",
        type: {
            value: location.type ?? "Physical",
            label: getTypeLabel(location.type ?? ""),
        },
        addressLine: location.address?.address_line ?? "",
        addressLine2: location.address?.address_line_2 ?? "",
        postalCode: location.address?.postal_code ?? "",
        postalPlace: location.address?.postal_place ?? "",
        country: {
            value: location.address?.country ?? "NO",
            label: getCountryLabel(location.address?.country ?? ""),
        },
        countryPrefix,
        phoneNumber: location.phone_number
            ? location.phone_number.slice(countryPrefix.length)
            : "",
        websiteUrl: location.website_url ?? "",
        accountId: location.account_id ?? "",
        latitude: location.address?.latitude ?? undefined,
        longitude: location.address?.longitude ?? undefined,
        franchise: !!location.franchise,
        franchiseName: location.franchise ?? "",
    };
};
