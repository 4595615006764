import { Route } from "react-router-dom";
import ThirdPartyPage from "./components/ThirdPartyList";

const Routes: JSX.Element[] = [
    <Route
        key="third_party"
        path="/:accountId/settings/third-party"
        exact
        component={ThirdPartyPage}
    />,
];

export default Routes;
