import React from "react";
import styled from "styled-components/macro";

import { WithTranslation, withTranslation } from "react-i18next";
import { InputValidation } from "../../../../components/Forms";
import Dropdown, {
    DropdownOption,
} from "../../../../components/Forms/Dropdown";
import { colors, distances } from "../../../../styles/constants";

import { Label } from "../../../../components/Typography";

export interface TransactionsVolumeDropdownProps extends WithTranslation {
    value: string;
    name: string;
    label: string;
    placeholder: string;
    onChange: (value: string) => void;
    validation?: InputValidation;
}

export const optionValues = [
    "less_than_1M",
    "1M_10M",
    "10M_50M",
    "more_than_50M",
];

const TransactionsVolumeDropdown = ({
    label,
    name,
    value,
    onChange,
    validation,
    placeholder,
    t,
}: TransactionsVolumeDropdownProps) => {
    const options: DropdownOption[] = optionValues.map((value) => ({
        label: t(
            `settings.payment_connections.transaction_volume_options.${value}`,
        ),
        value,
    }));
    const selectedOption = options.find((option) => option.value === value);
    return (
        <Wrapper>
            <Label htmlFor={name}>{label}</Label>
            {selectedOption && (
                <Dropdown
                    name={name}
                    value={selectedOption}
                    options={options}
                    onChange={(val) => {
                        onChange(val.value);
                    }}
                />
            )}
            {validation && validation.message && (
                <Message htmlFor={name} className={validation.state}>
                    {validation.message}
                </Message>
            )}
        </Wrapper>
    );
};

const Message = styled.label`
    position: absolute;
    top: 66px;
    left: 0;
    font-size: 11px;
    line-height: ${distances.small};

    &.invalid {
        color: ${colors.invalid};
    }

    &.valid {
        color: ${colors.valid};
    }
`;

const Wrapper = styled.div`
    position: relative;
    width: 100%;
`;

export default withTranslation()(TransactionsVolumeDropdown);
