import React, {} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { Money } from "../../../../components/Formatters";
import { P } from "../../../../components/Typography";
import { distances } from "../../../../styles/constants";
import { EXAMPLE_VALUE_CURRENCY } from "../../constants";

interface PayoutRuleGraphProps {
    exampleValue: number;
    graphValues: {
        value: number;
        valueNormalized: number;
        color: string;
    }[];
}

const PayoutRuleGraph = ({
    exampleValue,
    graphValues,
}: PayoutRuleGraphProps) => {
    const { t } = useTranslation();

    return (
        <>
            <P>
                {t("payout_rules.payout_rules_graph.title")}{" "}
                <Money
                    amount={exampleValue * 100}
                    currency={EXAMPLE_VALUE_CURRENCY}
                    hideCurrency
                />
            </P>
            <Graph>
                {graphValues.map((element, index) => (
                    <Bar
                        key={index}
                        value={element.valueNormalized}
                        color={element.color}
                        title={element.value.toString()}
                    >
                        <StyledP>
                            <Money
                                amount={element.value * 100}
                                currency={EXAMPLE_VALUE_CURRENCY}
                                hideCurrency
                            />
                        </StyledP>
                    </Bar>
                ))}
            </Graph>
        </>
    );
};

const Graph = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: ${distances.normal};
`;

const Bar = styled.div<{ value: number; color: string; title: string }>`
    height: ${distances.normal};
    width: ${(props) => `${props.value}%`};
    background-color: ${(props) => props.color};
    margin: 1px;
    border-radius: 2px;
    transition: all .375s cubic-bezier(0.175, 0.885, 0.32, 1.275);
`;

const StyledP = styled(P as any)`
    position: relative;
    top: 30px;
    white-space: nowrap;
    text-align: center;
`;

export default PayoutRuleGraph;
