import { useSelect } from "downshift";
import styled from "styled-components/macro";
import {
    border,
    colors,
    defaultRadius,
    distances,
    palette,
    shadows,
} from "../../styles/constants";
import { cn } from "../../util/css";
import Chevron from "../Icons/chevron";

type Item<T> = {
    label: React.ReactNode;
    value: any;
    disabled?: boolean;
} & T;

type SelectProps<T> = {
    items: Item<T>[];
    label?: string;
    defaultValue?: string;
    onChange: (value: any) => void;
    onBlur?: () => void;
    style?: React.CSSProperties;
    required?: boolean;
    autoFocus?: boolean;
    error?: React.ReactNode;
    disabled?: boolean;
    hideSelected?: boolean;
    placeholder?: string;
    [key: `data-${string}`]: any;
};

export const Select = <T extends Item<any>>({
    items,
    label,
    defaultValue,
    onChange,
    onBlur,
    style,
    required,
    autoFocus,
    error,
    disabled,
    hideSelected,
    placeholder,
    ...dataProps
}: SelectProps<T>) => {
    const {
        isOpen,
        selectedItem,
        getToggleButtonProps,
        getMenuProps,
        getLabelProps,
        getItemProps,
        highlightedIndex,
    } = useSelect({
        items,
        itemToString: (item) => String(item?.value) || "",
        onSelectedItemChange: ({ selectedItem }) => {
            if (selectedItem) {
                onChange(selectedItem.value);
                onBlur?.();
            }
        },
        onStateChange: ({ type }) => {
            switch (type) {
                case useSelect.stateChangeTypes.MenuBlur:
                    onBlur?.();
                    break;
                default:
                    break;
            }
        },
        defaultSelectedItem: items.find((item) => item.value === defaultValue),
    });

    const value = selectedItem?.label || defaultValue;

    return (
        <Wrapper style={style} {...dataProps}>
            {label && <Label {...getLabelProps()}>{label}</Label>}
            <SelectWrapper
                data-error={!!error}
                data-active={isOpen}
                type="button"
                {...getToggleButtonProps({
                    autoFocus: autoFocus,
                    required: required,
                    disabled: disabled,
                    type: "button",
                })}
            >
                <SelectedItemElement data-placeholder={!value && !!placeholder}>
                    {value || placeholder}
                </SelectedItemElement>
                <ChevronWrapper data-is-open={isOpen}>
                    <Chevron />
                </ChevronWrapper>
            </SelectWrapper>
            <DropdownWrapper>
                <Dropdown
                    {...getMenuProps({
                        disabled: disabled,
                    })}
                    className={cn(!(isOpen && items.length) && "hidden")}
                >
                    {isOpen &&
                        items.map((item, index) => (
                            <DropdownItem
                                key={item.value}
                                {...getItemProps({
                                    item,
                                    index,
                                    disabled: item.disabled,
                                })}
                                className={cn(
                                    item.value === selectedItem?.value &&
                                        "selected",
                                    highlightedIndex === index && "highlighted",
                                    item.disabled && "disabled",
                                    hideSelected &&
                                        item.value === selectedItem?.value &&
                                        "hidden",
                                )}
                                type="button"
                            >
                                {item.label}
                            </DropdownItem>
                        ))}
                </Dropdown>
            </DropdownWrapper>
            <ErrorElementWrapper>
                {error && <ErrorElement>{error}</ErrorElement>}
            </ErrorElementWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div<{ mb?: React.CSSProperties["marginBottom"] }>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;

    margin-bottom: ${(props) => props.mb || distances.normal};
`;

const Label = styled.label`
    padding: ${distances.micro} 0;
    font-weight: 500;
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.text};
`;

const SelectWrapper = styled.button`
    display: flex;
    border: none;
    outline: none;
    position: relative;
    border: ${border.normal} solid ${palette.neutral[400]};
    border-radius: ${defaultRadius};
    background: ${colors.background};
    padding: ${distances.tiny} ${distances.small12};
    width: 100%;
    min-height: 40px;
    transition: all 150ms cubic-bezier(0.2, 0, 0.2, 1);
    flex-wrap: wrap;
    gap: ${distances.tiny};

    &:hover {
        border: ${border.normal} solid ${palette.primary[300]};
        cursor: pointer;
    }

    &:focus, &:focus-within, &[data-active="true"] {
        outline: none;
        border: ${border.normal} solid ${palette.primary[300]};
        box-shadow: 0 0 0 3px ${palette.primary[100]};
    }

    &:disabled {
        background: ${palette.neutral[50]};
        border: 1px solid ${palette.neutral[200]};
        color: ${palette.neutral[500]};
    }

    &[data-error="true"] {
        border: ${border.normal} solid ${palette.destructive[300]};
        &:focus, &:focus-within {
            border: ${border.normal} solid ${palette.destructive[300]};
            box-shadow: 0 0 0 3px ${palette.destructive[100]};
        }
    }
`;

const SelectedItemElement = styled.span`
    align-items: center;
    display: flex;
    &[data-placeholder="true"] {
        color: ${palette.neutral[500]};
    }
`;

const ChevronWrapper = styled.div`
    appearance: none;
    border: none;
    outline: none;
    background: none;
    margin: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    & > svg {
        width: 18px;
        height: 18px;
        margin: auto;
    }
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 150ms cubic-bezier(0.2, 0, 0.2, 1);
    &[data-is-open="true"] {
        transform: translateY(-50%) rotate(180deg);
    }
    display: flex;
`;

const ErrorElementWrapper = styled.div`
    position: relative;
`;

const ErrorElement = styled.div`
    position: absolute;
    top: ${distances.tiny};
    left: 0;
    font-size: 14px;
    line-height: ${distances.small};
    color: ${palette.destructive[500]};
`;

const DropdownWrapper = styled.div`
    position: relative;
    width: inherit;
`;

const Dropdown = styled.ul`
    list-style: none;
    position: absolute;
    width: inherit;
    padding-left: 0;
    padding-block: ${distances.micro};
    background: ${colors.background};
    box-shadow: ${shadows.large};
    max-height: 400px;
    overflow-y: auto;
    z-index: 10;
    margin-top: ${distances.micro};
    border-radius: ${defaultRadius};
    top: 0;
    &.hidden {
        opacity: 0;
        box-shadow: none;
        transform: translateY(-10px);
        pointer-events: none;
    }
    transition: all 150ms cubic-bezier(0.2, 0, 0.2, 1);
    border: ${border.normal} solid ${palette.neutral[200]};
    outline: none;
`;

const DropdownItem = styled.li`
    list-style: none;
    font-size: 14px;
    margin-inline: ${distances.micro};
    padding: ${distances.small12} ${distances.small12};
    white-space: nowrap;
    cursor: pointer;
    border-radius: ${defaultRadius};
    &:hover, &.highlighted, &:focus {
        background-color: ${palette.primary[500]};
        color: white;
        & > * > * {
            color: white;
        }
        &.disabled {
            background-color: ${palette.neutral[50]};
            color: ${palette.neutral[300]};
        }
    }
    &.selected {
        background-color: ${palette.primary[50]};
        color: ${palette.primary[500]};
    }
    &.disabled {
        color: ${palette.neutral[300]};
        cursor: not-allowed;
    }
    &.hidden {
        display: none;
    }
    & > * {
        pointer-events: none;
    }
`;
