import { useTranslation } from "react-i18next";
import { Redirect, useParams } from "react-router-dom";
import styled from "styled-components/macro";

import { useEffect } from "react";
import Card from "../../../../components/Card";
import Icon from "../../../../components/Icons";
import { LoadingOverlay } from "../../../../components/Loading";
import Page, {
    Back,
    PageButton,
    PageButtonLink,
} from "../../../../components/Page";
import { H1 } from "../../../../components/Typography";
import View from "../../../../components/View";
import { colors, distances } from "../../../../styles/constants";
import { useUserDetails } from "../hooks/useUserDetails";
import { actions } from "../observables/userDetailsStore";
import Details from "./Details";
import NotFound from "./NotFound";
import RemoveUserModal from "./RemoveUserModal";

const UserPage = () => {
    const { accountId, userId } = useParams();
    const { t } = useTranslation();
    const user = useUserDetails(userId);

    useEffect(() => {
        return () => {
            actions.resetState();
        };
    }, []);

    if (user.isDeleted) {
        return <Redirect to={`/${accountId}/settings/users`} />;
    }

    return (
        <Page
            title={
                <>
                    <Back />
                    <Title>{t("settings.user.title")}</Title>
                    <View ml="auto" gap={8}>
                        <PageButton
                            className="outlined red"
                            onClick={actions.openRemoveUserModal}
                            disabled={!user.user}
                        >
                            <Icon icon="delete" fill="red" />
                            {t("settings.user.remove_user")}
                        </PageButton>
                        <PageButtonLink
                            to={`/${accountId}/settings/users/${userId}/edit`}
                            className={!user.user ? "disabled" : ""}
                        >
                            <Icon icon="document" fill="currentColor" />
                            {t("settings.user.edit_user")}
                        </PageButtonLink>
                    </View>
                </>
            }
        >
            <Card title={undefined} noPadding>
                <Wrapper>
                    {user.user && (
                        <Details user={user.user} users={user.users} />
                    )}
                    {!user.isLoading && !user.user && (
                        <NotFound userId={userId} accountId={accountId} />
                    )}
                    {user.isLoading && <LoadingOverlay />}
                </Wrapper>
            </Card>
            {user.user && user.isShowingRemoveModal && (
                <RemoveUserModal
                    accountId={accountId}
                    user={user.user}
                    removeUser={actions.removeUser}
                    dismissRemoveUserModal={actions.dismissRemoveUserModal}
                />
            )}
        </Page>
    );
};

const Wrapper = styled.div`
    position: relative;
    min-height: calc(${distances.ginormous} + ${distances.large});
`;

const Title = styled(H1)`
    color: ${colors.text};
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

export default UserPage;
