import React from "react";
import styled from "styled-components/macro";

import { useTranslation } from "react-i18next";
import { InputValidation } from "../../components/Forms";
import Dropdown, { DropdownOption } from "../../components/Forms/Dropdown";
import { colors, distances } from "../../styles/constants";

import { Label } from "../../components/Typography";

type RequireEqualBillingShippingAddressDropdownProps = {
    value: "none" | "b2c" | "b2b" | "b2b_b2c";
    name: string;
    label: string;
    onChange: (value: "none" | "b2c" | "b2b" | "b2b_b2c") => void;
    validation?: InputValidation;
    disabled?: boolean;
};

const optionValues = ["none", "b2c", "b2b", "b2b_b2c"];

const AllowDifferentBillingShippingAddressDropdown = ({
    label,
    name,
    value,
    onChange,
    validation,
    disabled,
}: RequireEqualBillingShippingAddressDropdownProps) => {
    const { t } = useTranslation();
    const options: DropdownOption[] = optionValues.map((value) => ({
        label: t(
            "settings.payment_profile.values.allow_different_billing_shipping_address",
            { context: value?.toString() },
        ),
        value: value,
    }));

    const selectedOption = options.find((option) => option.value === value);
    return (
        <Wrapper>
            <Label htmlFor={name}>{label}</Label>
            {selectedOption && (
                <Dropdown
                    name={name}
                    value={selectedOption!}
                    options={options}
                    onChange={(val) => {
                        onChange(
                            val.value as "none" | "b2c" | "b2b" | "b2b_b2c",
                        );
                    }}
                    disabled={disabled}
                />
            )}
            {validation && validation.message && (
                <Message htmlFor={name} className={validation.state}>
                    {validation.message}
                </Message>
            )}
        </Wrapper>
    );
};

const Message = styled.label`
    position: absolute;
    top: 66px;
    left: 0;
    font-size: 11px;
    line-height: ${distances.small};

    &.invalid {
        color: ${colors.invalid};
    }

    &.valid {
        color: ${colors.valid};
    }
`;

const Wrapper = styled.div`
    position: relative;
    width: 100%;
`;

export default AllowDifferentBillingShippingAddressDropdown;
