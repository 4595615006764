import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import styled from "styled-components/macro";

import { useDispatch, useSelector } from "react-redux";
import { useActions } from "../../../../Actions";
import { Button } from "../../../../components/Buttons";
import Card from "../../../../components/Card";
import { LoadingOverlay } from "../../../../components/Loading";
import Page, { Back, Title } from "../../../../components/Page";
import { H2 } from "../../../../components/Typography";
import { distances } from "../../../../styles/constants";
import {
    checkoutConfiguration,
    isLoading as checkoutConfigurationLoading,
} from "../../../checkoutConfigurationSelectors";
import {
    isLoading as salesLocationsLoading,
    salesLocationsRaw,
} from "../../selectors";
import { putOverride } from "../actions";
import { locationDetailsStateSelector } from "../selectors";
import { mapGateways } from "../utils";
import DeleteLocationModal from "./DeleteLocationModal";
import LocationGateway from "./LocationGateway";
import LocationMap from "./LocationMap";
import OptionalField from "./OptionalField";
import PayexOverrideDialog from "./dialogs/PayexOverrideDialog";
import SantanderOverrideDialog from "./dialogs/SantanderOverrideDialog";
import SwishOverrideDialog from "./dialogs/SwishOverrideDialog";
import VippsOverrideDialog from "./dialogs/VippsOverrideDialog";

const overrideDialogs = {
    swish: SwishOverrideDialog,
    vipps: VippsOverrideDialog,
    santander: SantanderOverrideDialog,
    payex: PayexOverrideDialog,
} as const;

type OverrideableGateway = keyof typeof overrideDialogs;

const LocationDetails = () => {
    const { accountId, locationId } = useParams();
    const history = useHistory();
    const { t } = useTranslation("app");
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const dispatch = useDispatch();

    const { getLocation, deleteLocation } = useActions("settings.locations");

    const { getCheckoutConfig } = useActions("checkout.config");

    // biome-ignore lint/correctness/useExhaustiveDependencies: Re-run only when accountId changes
    useEffect(() => {
        if (accountId && locationId) {
            getLocation(locationId, accountId);
        }
    }, [accountId, locationId, getLocation, dispatch]);

    const { location, checkoutConfig, loading, recentlyOverridenGateways } =
        useSelector((state) => {
            return {
                location: salesLocationsRaw(state)[accountId]?.[locationId],
                loading:
                    salesLocationsLoading(state)(accountId) ||
                    checkoutConfigurationLoading(state, accountId) ||
                    locationDetailsStateSelector(state).isLoading,
                checkoutConfig: checkoutConfiguration(state, accountId),
                recentlyOverridenGateways:
                    locationDetailsStateSelector(state)
                        .recentlyOverridenGateways,
            };
        });

    // biome-ignore lint/correctness/useExhaustiveDependencies: Re-run only when accountId changes
    useEffect(() => {
        if (accountId) {
            getCheckoutConfig(accountId);
        }
    }, [accountId, getCheckoutConfig, recentlyOverridenGateways]);

    if (!location || loading) {
        return <LoadingOverlay />;
    }

    const connectedPutOverride = (
        gateway: string,
        credentials: Record<string, string | undefined>,
    ) => {
        dispatch(putOverride(accountId, locationId, gateway, credentials));
    };

    const { address } = location;
    const addressLine = address?.address_line ?? "";
    const addressLine2 = address?.address_line_2 ?? "";
    const city = address?.postal_place ?? "";
    const postalNumber = address?.postal_code ?? "";

    const gateways = mapGateways(checkoutConfig, locationId, t);

    const onDeleteLocation = () => {
        if (accountId && location.location_id) {
            deleteLocation(location.location_id, accountId);
        }
    };

    return (
        <Page
            title={
                <Fragment>
                    <Back />
                    <Title>{t("locations.location_details.title")}</Title>
                </Fragment>
            }
        >
            <Card title="">
                <HeaderWrapper>
                    <H2>{`${location.name} - ${city} ${addressLine}`}</H2>
                    <HeadButtonsWrapper>
                        <Button
                            onClick={() =>
                                history.push(
                                    `/${accountId}/settings/locations/${location.location_id}/edit`,
                                )
                            }
                        >
                            {t("locations.location_details.edit_button")}
                        </Button>
                        <Button
                            className="alt"
                            onClick={() => setShowDeleteModal(true)}
                        >
                            {t("locations.location_details.delete_button")}
                        </Button>
                    </HeadButtonsWrapper>
                </HeaderWrapper>
            </Card>
            <LocationMap location={location} />
            <CardsWrapper>
                <VerticalCards>
                    <Card
                        title={t("locations.location_details.contact.title")}
                        marginBottom={distances.small}
                    >
                        <ColumnsWrapper>
                            <Column>
                                <OptionalField
                                    title={t(
                                        "locations.location_details.contact.fields.address",
                                    )}
                                    text={`${addressLine} ${addressLine2}`}
                                />
                                <OptionalField
                                    title={t(
                                        "locations.location_details.contact.fields.postal",
                                    )}
                                    text={`${postalNumber} ${city}`}
                                />
                                <OptionalField
                                    title={t(
                                        "locations.location_details.contact.fields.phone",
                                    )}
                                    text={location.phone_number}
                                />
                            </Column>
                            <Column>
                                <OptionalField
                                    title={t(
                                        "locations.location_details.contact.fields.email",
                                    )}
                                    text={location.email}
                                />
                                <OptionalField
                                    title={t(
                                        "locations.location_details.contact.fields.website",
                                    )}
                                    text={location.website_url}
                                />
                            </Column>
                        </ColumnsWrapper>
                    </Card>
                    {!!checkoutConfig && (
                        <Card
                            title={t(
                                "locations.location_details.payment_connections.title",
                            )}
                            marginBottom={distances.small}
                        >
                            <Grid>
                                {gateways.map((gateway) => {
                                    const overrideDialog =
                                        overrideDialogs[
                                            gateway.name as OverrideableGateway
                                        ];
                                    return (
                                        <div key={gateway.name}>
                                            <LocationGateway
                                                gateway={gateway}
                                                putOverride={
                                                    connectedPutOverride
                                                }
                                                loading={loading}
                                                recentlyOverridenGateways={
                                                    recentlyOverridenGateways[
                                                        locationId
                                                    ] || []
                                                }
                                                OverrideDialog={overrideDialog}
                                            />
                                        </div>
                                    );
                                })}
                            </Grid>
                        </Card>
                    )}
                </VerticalCards>
                <SingleCard>
                    <Card title={t("locations.location_details.id_data.title")}>
                        <ColumnsWrapper>
                            <Column>
                                <OptionalField
                                    title={t(
                                        "locations.location_details.id_data.fields.id",
                                    )}
                                    text={location.location_id}
                                />
                                <OptionalField
                                    title={t(
                                        "locations.location_details.id_data.fields.status",
                                    )}
                                    text={location.status}
                                />
                                <OptionalField
                                    title={t(
                                        "locations.location_details.id_data.fields.type",
                                    )}
                                    text={location.type}
                                />
                            </Column>
                            <Column>
                                <OptionalField
                                    title={t(
                                        "locations.location_details.id_data.fields.org_nr",
                                    )}
                                    text={location.organization_number}
                                />
                                <OptionalField
                                    title={t(
                                        "locations.location_details.id_data.fields.legal_name",
                                    )}
                                    text={location.business_name}
                                />
                            </Column>
                        </ColumnsWrapper>
                    </Card>
                </SingleCard>
            </CardsWrapper>
            {showDeleteModal && (
                <DeleteLocationModal
                    name={location.name ?? ""}
                    deleteLocation={onDeleteLocation}
                    dismissModal={() => setShowDeleteModal(false)}
                />
            )}
        </Page>
    );
};

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${distances.normal};

  > div {
    flex: 50%;
  }
`;

const CardsWrapper = styled.div`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const HeadButtonsWrapper = styled.div`
    > *:first-child {
        margin-right: ${distances.small};
    }
`;

const VerticalCards = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 592px;
    width: 50%;
    margin-right: ${distances.small};

    @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
    }
`;

const ColumnsWrapper = styled.div`
    display: flex;
    margin-top: ${distances.normal};

    > *:first-child {
        margin-right: ${distances.tiny};
    }
`;

const Column = styled.div`
    width: 50%;
`;

const SingleCard = styled.div`
    width: 50%;
    max-width: 592px;

    @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
    }
`;

export default LocationDetails;
