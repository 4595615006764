import { useFormik } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { useHistory, useLocation } from "react-router-dom";

import { useAccountIds } from "../../../auth/accessToken/components/withAccountIds";
import { isPartner } from "../../../auth/helpers";
import { useCurrentUser } from "../../../common/hooks/useCurrentUser";
import { Button } from "../../../components/Buttons";
import Card from "../../../components/Card";
import { LoadingOverlay } from "../../../components/Loading";
import { H2, P } from "../../../components/Typography";
import View from "../../../components/View";
import { getCountryCurrency } from "../../../helpers/countryCurrency";
import { Account } from "../../../types/management-auth";
import NotAvailablePartner from "./NotAvailableParner";
import { ParagraphWaitingForReview } from "./ParagraphWaitingForReview";
import { usePaymentApprovals } from "./hooks";
import { PaymentMethod } from "./types";
import { getDefaultPaymentMethods } from "./utils";
const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

type FormValues = {
    country: string;
    paymentMethods: PaymentMethod[];
    product: "checkout" | "split_payment";
};

const normalizeOrgNumber = (orgNumber: string | undefined) => {
    if (orgNumber) {
        return orgNumber.replace(/\D/g, "");
    }
};

const ApprovalsPaymentsForm = ({
    account,
    legacy,
}: {
    account: Account;
    legacy?: boolean;
}) => {
    const { prodAccountId, urlAccountId } = useAccountIds();
    const { t } = useTranslation();
    const query = useQuery();
    const history = useHistory();
    const { postApproval, isPosting, posted } = usePaymentApprovals();
    const initialProduct = query.get("product") || "checkout";
    const country =
        query.get("country") || account?.company?.address?.country || "";
    const currency = query.get("currency") || getCountryCurrency(country) || "";
    const { currentUser } = useCurrentUser();

    const formik = useFormik<FormValues>({
        initialValues: {
            product: initialProduct as "checkout" | "split_payment",
            country,
            paymentMethods: getDefaultPaymentMethods(country),
        },
        onSubmit: async (values) => {
            if (
                !prodAccountId ||
                values.paymentMethods.length === 0 ||
                !values.country ||
                !values.product
            ) {
                return;
            }
            const result = await postApproval(prodAccountId, {
                country_code: values.country,
                organization_number:
                    normalizeOrgNumber(account?.company.organization_number) ||
                    "",
                bank_accounts: [
                    {
                        payout_currency: currency,
                        bank_account_currency: currency,
                    },
                ],
                products: [{ product: values.product }],
                payment_methods: values.paymentMethods.map((p) => ({
                    payment_method: p,
                })),
                form_submitter: currentUser
                    ? {
                          email: currentUser.email,
                          name: currentUser.name,
                      }
                    : undefined,
            });
            const url = result.links?.find(
                (x) => x.rel === "declaration_url",
            )?.href;
            if (url) {
                history.push(
                    `/${urlAccountId}/settings/payment-connections/declaration?caseUrl=${url}&accountId=${urlAccountId}`,
                );
            }
        },
    });

    if (!isPosting && Boolean(posted)) {
        return (
            <Redirect to={`/${urlAccountId}/settings/payment-connections`} />
        );
    }
    const partner = isPartner(urlAccountId);
    if (partner) {
        return (
            <Card>
                <H2>{t("settings.approvalsPayments.heading")}</H2>
                <NotAvailablePartner productLabel={"Checkout"} />
            </Card>
        );
    }

    return (
        <Card
            style={{
                ...(legacy && {
                    order: 1,
                }),
            }}
        >
            <H2 lineHeight={33} letterSpacing={-0.525} fontWeight={500}>
                {t(
                    "settings.approvalsPayments.approvalsWaitingDeclaration.title",
                    {
                        context: legacy ? "legacy" : "",
                    },
                )}
            </H2>

            <View
                direction="column"
                as="form"
                onSubmit={formik.handleSubmit}
                justify="flex-start"
                alignItems="flex-start"
            >
                <P fontSize={16} lineHeight={26} fontWeight={400}>
                    {t(
                        "settings.approvalsPayments.approvalsWaitingDeclaration.paragraph_action",
                        {
                            context: legacy ? "legacy" : "",
                        },
                    )}
                </P>
                <br />
                <Button className="large" type="submit" disabled={isPosting}>
                    {t("settings.approvalsPayments.applyForPaymentsButton", {
                        context: legacy ? "legacy" : "",
                    })}
                </Button>
                <br />
                <ParagraphWaitingForReview account={account} />
            </View>
            {isPosting && <LoadingOverlay />}
        </Card>
    );
};

export default ApprovalsPaymentsForm;
