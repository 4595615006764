import * as lib from "libphonenumber-js";

export type PhoneNumber = {
    countryPrefix: string;
    phoneNumber: string;
};

export const countryPrefix = (countryCode: string): string | undefined => {
    try {
        return `+${lib.getCountryCallingCode(`${countryCode}`.toUpperCase() as any)}`;
    } catch (err) {
        return "";
    }
};

export const parse = (number: string): PhoneNumber => {
    const result = lib.parsePhoneNumberFromString(number);
    if (!result || !result.countryCallingCode) {
        return {
            countryPrefix: "",
            phoneNumber: number,
        };
    }

    return {
        countryPrefix: `+${result.countryCallingCode.toString()}`,
        phoneNumber: result.formatNational(),
    };
};

export const toString = (value: PhoneNumber): string | undefined => {
    const phoneNumber = value.phoneNumber
        .replace(/\s/g, "") // space be gone
        .replace(/-/g, ""); // dashes away

    if (phoneNumber === "") {
        return undefined;
    }

    if (value.countryPrefix === "") {
        return phoneNumber;
    }

    const result = `${value.countryPrefix}${phoneNumber}`.replace(
        "+4607",
        "+467",
    ); // remove leading 0 from swedish phone numbers
    if (!lib.parsePhoneNumberFromString(result)) {
        // unexpected invalid value?
        return undefined;
    }
    return result;
};
