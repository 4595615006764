import { ExportToCsv } from "export-to-csv";
import { TFunction } from "i18next";
import { CORE_API_HOSTNAME } from "../../env";
import fulfill from "../../fulfill";
import { parseISOStrict } from "../../helpers/date";
import { downloadDataTablePdf } from "../../helpers/download/dataTablePdf";
import {
    formatDate,
    formatTime,
    toCommonCurrencyAmount,
} from "../../helpers/formatters";
import { getLocaleNumeralDecimal } from "../../helpers/intl";
import { DestinationIdLabelMap } from "../../util/usePayoutDestinations";
import { Attachment, Settlement, SettlementResponse } from "./types";

export const isExternalReport = (attachment: Attachment) => {
    return !["dintero", "payout"].includes(attachment.created_by || "");
};

const initiateFileDownload = (filename: string, url: string) => {
    const a = document.createElement("a");
    a.setAttribute("download", filename);
    a.setAttribute("target", "_blank");
    a.style.display = "none";
    a.href = url;
    const addedElement = document.body.appendChild(a);
    a.click();
    document.body.removeChild(addedElement);
};

export const downloadSettlement = async (
    account_id: string,
    filename: string,
    settlementId: string,
    attachmentId: string,
) => {
    await fulfill.get({
        accountId: account_id,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${account_id}/settlements/${settlementId}/attachments/${attachmentId}`,
        handlers: {
            200: (payload: any) => {
                initiateFileDownload(filename, payload.url);
            },
        },
    });
};

export const downloadSettlementByUrl = async (
    account_id: string,
    url: string,
    filename: string,
) => {
    await fulfill.get({
        accountId: account_id,
        url: url,
        handlers: {
            200: (payload: any) => {
                initiateFileDownload(filename, payload.url);
            },
        },
    });
};

export const toCurrencyAmount = (amount: number, currency: string) => {
    if (!amount) {
        return "0";
    }
    return toCommonCurrencyAmount(amount, currency);
};

export const ensureNegative = (amount: number) => {
    if (!amount) {
        return 0;
    }
    if (amount > 0) {
        return -amount;
    }
    return amount;
};

export const mapPayoutLine = (
    settlement: Settlement,
    t: TFunction,
    language: string,
    payoutDestinationLabelMap?: DestinationIdLabelMap,
    hasStoreId?: boolean,
) => {
    const hasAnyPayoutDestinationId = !!payoutDestinationLabelMap;
    const payoutDestinationLabel =
        (payoutDestinationLabelMap &&
            payoutDestinationLabelMap[
                settlement.payout_destination_id || ""
            ]) ||
        settlement.payout_destination_id ||
        "";
    const payoutDestinationId = settlement.payout_destination_id || "";
    if (!settlement.amounts || settlement.amounts.length === 0) {
        // Adds a 0 amount to the download with unspecified currency
        return [
            {
                [t("settlements.fields.date")]: formatDate(
                    settlement.settled_at,
                    language,
                ),
                [t("settlements.fields.currency")]: "N/A",
                [t("settlements.fields.amount")]: 0,
                [t("settlements.fields.captured")]: 0,
                [t("settlements.fields.refunded")]: 0,
                [t("settlements.fields.fee")]: 0,
                [t("settlements.fields.provider")]: t(
                    `payments.payment_product.${settlement.provider}`,
                ),
                [t("settlements.fields.settlement_id")]:
                    settlement.provider_reference,
                ...(hasAnyPayoutDestinationId && {
                    [t("settlements.fields.payout_destination")]:
                        payoutDestinationLabel,
                }),
                ...(hasAnyPayoutDestinationId && {
                    [t("settlements.fields.payout_destination_id")]:
                        payoutDestinationId,
                }),
                ...(hasStoreId && {
                    [t("settlements.fields.store_id")]:
                        settlement.store_id || "",
                }),
            },
        ];
    }

    return settlement.amounts.map((amount) => {
        return {
            [t("settlements.fields.date")]: formatDate(
                settlement.settled_at,
                language,
            ),
            [t("settlements.fields.currency")]: amount.currency,
            [t("settlements.fields.amount")]: toCurrencyAmount(
                amount.amount,
                amount.currency,
            ),
            [t("settlements.fields.captured")]: toCurrencyAmount(
                amount.capture,
                amount.currency,
            ),
            [t("settlements.fields.refunded")]: toCurrencyAmount(
                ensureNegative(amount.refund),
                amount.currency,
            ),
            [t("settlements.fields.fee")]: toCurrencyAmount(
                ensureNegative(amount.fee),
                amount.currency,
            ),
            [t("settlements.fields.provider")]: t(
                `payments.payment_product.${settlement.provider}`,
            ),
            [t("settlements.fields.settlement_id")]:
                settlement.provider_reference,
            ...(hasAnyPayoutDestinationId && {
                [t("settlements.fields.payout_destination")]:
                    payoutDestinationLabel,
            }),
            ...(hasAnyPayoutDestinationId && {
                [t("settlements.fields.payout_destination_id")]:
                    payoutDestinationId,
            }),
            ...(hasStoreId && {
                [t("settlements.fields.store_id")]: settlement.store_id || "",
            }),
        };
    });
};

export const createSettlementsCsv = (
    aid: string,
    settlements: Settlement[],
    t: TFunction,
    language: string,
    title: string | undefined,
    filename: string | undefined,
    payoutDestinationLabelMap: DestinationIdLabelMap | undefined,
) => {
    if (settlements.length === 0) {
        return;
    }
    try {
        const hasStoreId = settlements.some((s) => s.store_id);
        const data = settlements
            .map((txn) =>
                mapPayoutLine(
                    txn,
                    t,
                    language,
                    payoutDestinationLabelMap,
                    hasStoreId,
                ),
            )
            .reduce((acc, item) => [...acc, ...item], []);
        if (data.length === 0) {
            return;
        }
        const now = new Date().toISOString();
        const nowFormatted = `${formatDate(now, language)}T${formatTime(now, language)}`;
        const options = {
            fieldSeparator: ";",
            quoteStrings: '"',
            decimalSeparator: getLocaleNumeralDecimal(language),
            showLabels: true,
            showTitle: true,
            title:
                title || `Dintero settlements download ${aid}, ${nowFormatted}`,
            filename:
                filename ||
                `${aid}_${nowFormatted.replace(":", "-")}_Dintero-settlement`,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
        };
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);
    } catch (e) {
        // csv download failed for some unknown reason
        console.error(e, { settlements });
    }
};

export const createSettlementsCsvPdf = (
    accountId: string,
    settlements: Settlement[],
    t: TFunction,
    language: string,
    title: string | undefined,
    filename: string | undefined,
    payoutDestinationLabelMap: DestinationIdLabelMap | undefined,
) => {
    if (settlements.length === 0) {
        return;
    }
    const now = new Date().toISOString();
    const created_at = `${formatDate(now, language)} ${formatTime(now, language)}`;
    const hasStoreId = settlements.some((s) => s.store_id);
    const data = settlements
        .map((txn) =>
            mapPayoutLine(
                txn,
                t,
                language,
                payoutDestinationLabelMap,
                hasStoreId,
            ),
        )
        .reduce((acc, item) => [...acc, ...item], []);
    downloadDataTablePdf(
        accountId,
        title || `Dintero settlements download ${accountId}, ${created_at}`,
        created_at,
        data,
        language,
        filename ||
            `${accountId}_${created_at.replace(":", "-")}_Dintero-settlement`,
    );
};

export const includeYearInParamsIfNoDateFilter = (
    params: URLSearchParams,
    pagingYear: number | undefined,
    prevSettlementResponse: SettlementResponse | undefined,
) => {
    const changedParams = new URLSearchParams(params);
    if (
        !(params.get("created_at.gte") || params.get("created_at.lte")) &&
        pagingYear
    ) {
        changedParams.append("created_at.lte", `${pagingYear}-12-31`);
        if (prevSettlementResponse?.items.length) {
            // Only set lower bounds limit if we already have some items from a previous response
            // so that if a merchant has "gap years" we will get data from the next year with actual
            // reports.
            changedParams.append("created_at.gte", `${pagingYear}-01-01`);
        }
    }
    return changedParams;
};

const fetchSettlementsReq = async (
    accountId: string,
    params: URLSearchParams,
): Promise<SettlementResponse> => {
    return fulfill.get({
        accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/settlements/?${params.toString()}`,
        handlers: {
            200: (settlementResponse: SettlementResponse) => settlementResponse,
            404: () => ({ items: [] }),
            500: () => ({ items: [] }), // seems to happen if date filer is in conflict with paging date
        },
    });
};

export const getFirstItemYear = (settlementResponse: SettlementResponse) => {
    const item = settlementResponse.items.find(
        (settlement) => settlement.payment_status !== "postponed",
    );
    if (item) {
        return parseInt(item.settled_at.substring(0, 4));
    }
};

const combineSettlementResponses = (
    prevResponse: SettlementResponse | undefined,
    nextResponse: SettlementResponse,
    nextParams: URLSearchParams,
) => {
    if (prevResponse?.items.length) {
        // We are appending to a previous result so no need to filter data in nextResponse.
        return {
            ...nextResponse,
            items: [...prevResponse.items, ...nextResponse.items],
        };
    }
    const hasDateFilters =
        nextParams.get("created_at.gte") || nextParams.get("created_at.lte");
    const firstItemYear = getFirstItemYear(nextResponse);

    if (!hasDateFilters && firstItemYear) {
        // If no date filters or paging year is set we use the year from the first item in the response and
        // discard all the items not matching the paging year.
        return {
            ...nextResponse,
            items: nextResponse.items.filter((item) =>
                item.settled_at.startsWith(firstItemYear.toString()),
            ),
        };
    }

    // Some filtering on date is going on or no items are returned, so we do not alter the nextResponse
    return nextResponse;
};

const checkIfStartingAfterParamIsOutOfBounds = (
    nextParams: URLSearchParams,
) => {
    const createdAtLte = parseISOStrict(nextParams.get("created_at.lte") || "");
    const createdAtGte = parseISOStrict(nextParams.get("created_at.gte") || "");
    const startingAfterDate = parseISOStrict(
        nextParams.get("starting_after_date") || "",
    );

    if (createdAtLte && createdAtGte && startingAfterDate) {
        // startingAfterDate greater than upper bound or less than lower bound
        return (
            startingAfterDate > createdAtLte || startingAfterDate < createdAtGte
        );
    }
    if (createdAtLte && startingAfterDate) {
        // startingAfterDate greater than upper bound
        return startingAfterDate > createdAtLte;
    }
    if (createdAtGte && startingAfterDate) {
        // startingAfterDate less than lower bound
        return startingAfterDate < createdAtGte;
    }
    return false;
};

export const checkHasNext = async (
    accountId: string,
    params: URLSearchParams,
    pagingYear: number | undefined,
) => {
    if (
        params.get("created_at.gte") ||
        params.get("created_at.lte") ||
        !pagingYear
    ) {
        return false;
    }
    const prevYearParams = new URLSearchParams(params);
    // no lower bound in has next check
    prevYearParams.append("created_at.lte", `${pagingYear - 1}-12-31`);
    prevYearParams.delete("limit");
    prevYearParams.append("limit", "1");
    const response = await fetchSettlementsReq(accountId, prevYearParams);
    return response.items.length > 0;
};

export const recursivelyFetchSettlements = async (
    accountId: string,
    params: URLSearchParams,
    pagingYear: number | undefined,
    prevSettlementResponse: SettlementResponse | undefined,
): Promise<SettlementResponse> => {
    const nextParams = includeYearInParamsIfNoDateFilter(
        params,
        pagingYear,
        prevSettlementResponse,
    );
    const lastSettlementItem =
        prevSettlementResponse &&
        prevSettlementResponse.items[prevSettlementResponse.items.length - 1];
    const starting_after_id =
        prevSettlementResponse?.last_evaluated_key?.id ||
        lastSettlementItem?.id;
    const starting_after_date =
        prevSettlementResponse?.last_evaluated_key?.settled_at ||
        lastSettlementItem?.settled_at;
    if (starting_after_id && starting_after_date) {
        nextParams.append("starting_after_id", starting_after_id);
        nextParams.append("starting_after_date", starting_after_date);
    }
    if (
        prevSettlementResponse &&
        checkIfStartingAfterParamIsOutOfBounds(nextParams)
    ) {
        // No need to do a subsequent request if the paging is out of bounds of the date filtering.
        // Also server is at the time of this writing returning 500 responses on these requests/
        return prevSettlementResponse;
    }
    const nextSettlementsResponse = await fetchSettlementsReq(
        accountId,
        nextParams,
    );

    const combinedSettlements = combineSettlementResponses(
        prevSettlementResponse,
        nextSettlementsResponse,
        params,
    );

    if (
        nextSettlementsResponse.items.length > 0 ||
        nextSettlementsResponse.last_evaluated_key
    ) {
        // Get all items from then service until an empty list is returned without a paging key in the response since filtering server side is kind of weird,
        // in some cases the result item length will be less than the limit even though there are still more data to fetch.
        return recursivelyFetchSettlements(
            accountId,
            params,
            getFirstItemYear(nextSettlementsResponse),
            combinedSettlements,
        );
    }
    return combinedSettlements;
};
