import { Formik } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import styled from "styled-components/macro";

import { Button, ButtonGroup } from "../../../../components/Buttons";
import Card from "../../../../components/Card";
import {
    BackButton,
    Checkbox,
    Email,
    Input,
    ValidationState,
} from "../../../../components/Forms";
import { LoadingOverlay } from "../../../../components/Loading";
import Page, { Dismiss, PageButton } from "../../../../components/Page";
import { H1, H2, Muted } from "../../../../components/Typography";
import {
    colors,
    distances,
    globalColumnMaxWidth,
    grid,
} from "../../../../styles/constants";

import { AccountUser } from "../../../../types/management-auth";

import useAuth from "../../../../auth/accessToken/hooks/useAuth";
import { useAccountId } from "../../../../auth/useAccountId";
import Hr from "../../../../components/Hr";
import Icon from "../../../../components/Icons";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../../../components/Table";
import useAccount from "../../../account/hooks/useAccount";
import {
    handleAdminReadScopeToggle,
    hasAll,
    isChecked,
    services,
    toggleAllAdminRead,
} from "../../../scopes";
import { useNewUser } from "../hooks/useNewUser";
import { actions } from "../observables/newUserStore";

const emailRegex = new RegExp(
    /^(([^<>()\\\\.,;:\s@"]+(\.[^<>()\\\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

const validateEmail = (email: string) => {
    return emailRegex.test(email);
};
interface FormikErrors {
    email?: string;
}

interface FormikValues {
    email: string;
    name: string;
    scope: string[];
    authentication_type: "external" | undefined;
    [key: string]: any;
}

const NewUserForm = () => {
    const { t } = useTranslation();
    const accountId = useAccountId();
    const { account } = useAccount();
    const { isLoading, user } = useNewUser();
    const currentAccount = useAuth((state) => state.account_user).accounts.find(
        (account) => account.account_id === accountId,
    );

    useEffect(() => {
        return () => {
            actions.resetForm();
        };
    }, []);

    if (user) {
        return <Redirect to={`/${accountId}/settings/users/${user.id}`} />;
    }

    if (!currentAccount) {
        return null;
    }

    if (!account) {
        return null;
    }

    const domain = currentAccount.user.email.split("@")[1];
    const jwks = account.jwks as undefined | { name: string };

    return (
        <Formik
            initialValues={
                {
                    email: "",
                    name: "",
                    scope: [],
                    authentication_type: undefined,
                } as FormikValues
            }
            validate={(values) => {
                const errors: FormikErrors = {};

                if (!values.email) {
                    errors.email = t(
                        "settings.new_user.validation.email_required",
                    );
                }
                if (values.email && !validateEmail(values.email)) {
                    errors.email = t(
                        "settings.new_user.validation.email_invalid",
                    );
                }

                return errors;
            }}
            validateOnChange={false}
            onSubmit={(values) => {
                const newUser: AccountUser = {
                    email: values.email,
                    scope: values.scope,
                };

                if (values.authentication_type) {
                    newUser.authentication_type = values.authentication_type;
                }

                if (values.name) {
                    newUser.name = values.name;
                }
                actions.createUser(accountId, newUser);
            }}
        >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
            }) => (
                <Form onSubmit={handleSubmit} autoComplete="nope">
                    <Page
                        title={
                            <React.Fragment>
                                <Dismiss />
                                <Title>{t("settings.new_user.title")}</Title>
                                <PageButton
                                    disabled={
                                        values.email === "" ||
                                        errors.email !== undefined ||
                                        values.scope.length === 0
                                    }
                                >
                                    <Icon icon="save" fill="currentColor" />
                                    {t("settings.new_user.create_user")}
                                </PageButton>
                            </React.Fragment>
                        }
                    >
                        <Card title={undefined}>
                            <Wrapper>
                                <FormGroup>
                                    <H2>{t("settings.new_user.account")}</H2>
                                    <Hr />
                                    <Flex>
                                        <InputWrapper>
                                            <Email
                                                label={t(
                                                    "settings.new_user.fields.email",
                                                )}
                                                placeholder={`new.user@${domain}`}
                                                name="email"
                                                value={values.email}
                                                setFieldValue={setFieldValue}
                                                onBlur={handleBlur}
                                                validation={{
                                                    state: errors.email
                                                        ? ValidationState.Invalid
                                                        : ValidationState.Pristine,
                                                    message: errors.email,
                                                }}
                                                required
                                            />
                                        </InputWrapper>
                                        <InputWrapper>
                                            <Input
                                                label={t(
                                                    "settings.new_user.fields.name",
                                                )}
                                                placeholder={t(
                                                    "settings.new_user.placeholders.name",
                                                )}
                                                type="text"
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </InputWrapper>
                                    </Flex>
                                    {jwks && (
                                        <Checkbox
                                            name="authentication_type"
                                            label={
                                                <>
                                                    {t(
                                                        "settings.new_user.fields.authentication_type",
                                                    )}
                                                    <ExternalName>
                                                        {jwks.name}
                                                    </ExternalName>
                                                </>
                                            }
                                            checked={
                                                values.authentication_type !==
                                                undefined
                                            }
                                            onChange={(e) =>
                                                setFieldValue(
                                                    "authentication_type",
                                                    e.currentTarget.checked
                                                        ? "external"
                                                        : undefined,
                                                )
                                            }
                                        />
                                    )}
                                </FormGroup>

                                <H2>{t("settings.new_user.access")}</H2>
                                <Hr />
                                <Muted>
                                    {t(
                                        "settings.scopes.accounts_admin_description",
                                    )}
                                </Muted>
                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th>
                                                {t("settings.scopes.service")}
                                            </Th>
                                            <Th>
                                                {t(
                                                    "settings.scopes.description",
                                                )}
                                            </Th>
                                            <Th className="align-center">
                                                {t("settings.scopes.admin")}
                                            </Th>
                                            <Th className="align-center">
                                                {t("settings.scopes.read")}
                                            </Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {Object.entries(services).map(
                                            ([service, access]) => (
                                                <Tr key={service}>
                                                    <Scope>
                                                        <code>{service}</code>
                                                    </Scope>
                                                    <Description>
                                                        {
                                                            t([
                                                                `settings.scopes.service_descriptions.${service}`,
                                                                "",
                                                            ] as any) /* keep logic as-is */
                                                        }
                                                    </Description>
                                                    <Admin className="align-center">
                                                        {access.includes(
                                                            "admin",
                                                        ) ? (
                                                            <Checkbox
                                                                label=""
                                                                name="scope"
                                                                checked={isChecked(
                                                                    values.scope,
                                                                    service,
                                                                    "admin",
                                                                )}
                                                                value={`admin:${service}`}
                                                                onChange={(e) =>
                                                                    setFieldValue(
                                                                        "scope",
                                                                        handleAdminReadScopeToggle(
                                                                            values.scope,
                                                                            e,
                                                                        ),
                                                                    )
                                                                }
                                                            />
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </Admin>
                                                    <Read className="align-center">
                                                        {access.includes(
                                                            "read",
                                                        ) ? (
                                                            <Checkbox
                                                                label=""
                                                                name="scope"
                                                                checked={isChecked(
                                                                    values.scope,
                                                                    service,
                                                                    "read",
                                                                )}
                                                                value={`read:${service}`}
                                                                onChange={(e) =>
                                                                    setFieldValue(
                                                                        "scope",
                                                                        handleAdminReadScopeToggle(
                                                                            values.scope,
                                                                            e,
                                                                        ),
                                                                    )
                                                                }
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Read>
                                                </Tr>
                                            ),
                                        )}
                                        <Tr>
                                            <Td></Td>
                                            <Td></Td>
                                            <Td className="align-center">
                                                <Button
                                                    className="alt tiny"
                                                    onClick={() => {
                                                        setFieldValue(
                                                            "scope",
                                                            toggleAllAdminRead(
                                                                values.scope,
                                                                "admin",
                                                            ),
                                                        );
                                                    }}
                                                    type="button"
                                                >
                                                    {t(
                                                        hasAll(
                                                            values.scope,
                                                            "admin",
                                                        )
                                                            ? "settings.scopes.disable_all"
                                                            : "settings.scopes.enable_all",
                                                    )}
                                                </Button>
                                            </Td>
                                            <Td className="align-center">
                                                <Button
                                                    className="alt tiny"
                                                    onClick={() => {
                                                        setFieldValue(
                                                            "scope",
                                                            toggleAllAdminRead(
                                                                values.scope,
                                                                "read",
                                                            ),
                                                        );
                                                    }}
                                                    type="button"
                                                >
                                                    {t(
                                                        hasAll(
                                                            values.scope,
                                                            "read",
                                                        )
                                                            ? "settings.scopes.disable_all"
                                                            : "settings.scopes.enable_all",
                                                    )}
                                                </Button>
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                                <ButtonGroup>
                                    <BackButton
                                        children={t("settings.new_user.abort")}
                                    />
                                    <Button
                                        disabled={
                                            values.email === "" ||
                                            errors.email !== undefined ||
                                            values.scope.length === 0
                                        }
                                    >
                                        {t("settings.new_user.create_user")}
                                    </Button>
                                </ButtonGroup>
                                {isLoading && <LoadingOverlay />}
                            </Wrapper>
                        </Card>
                    </Page>
                </Form>
            )}
        </Formik>
    );
};

export default NewUserForm;

const Form = styled.form`
    width: 100%;
`;

const Scope = styled(Td)`
    width: 20%;
`;
const Description = styled(Td)`
    width: 50%;
`;
const Read = styled(Td)`
    width: 15%;
`;
const Admin = styled(Td)`
    width: 15%;
`;

const Wrapper = styled.div`
    position: relative;
    max-width: ${grid.spans.span8};
    margin: 0 auto;
`;

const FormGroup = styled.div`
    margin-bottom: ${distances.normal};
`;

const Title = styled(H1)`
    color: ${colors.text};
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

const Flex = styled.div`
    display: flex;

    @media (max-width: ${globalColumnMaxWidth}px) {
        flex-direction: column;
    }
`;

const InputWrapper = styled.div`
    width: ${grid.spans.span4};
    margin-right: ${grid.gutter};

    &:last-child {
        margin: 0;
    }

    @media (max-width: ${globalColumnMaxWidth}px) {
        width: 100%;
    }
`;

const ExternalName = styled.span`
    font-weight: 700;
`;
