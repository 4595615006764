import { useFormik } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { useActions } from "../../../../Actions";
import { useAccountIds } from "../../../../auth/accessToken/components/withAccountIds";
import { Button, ButtonGroup } from "../../../../components/Buttons";
import { Checkbox, Input } from "../../../../components/Forms";
import Modal from "../../../../components/Modal";
import { Toggle } from "../../../../components/Toggle";
import { H2, H4, P } from "../../../../components/Typography";
import View from "../../../../components/View";
import { payoutInfoSelector } from "../../../../payout/merchant/config/selectors";
import {
    colors,
    defaultRadius,
    distances,
    palette,
} from "../../../../styles/constants";
import { CheckoutConfig } from "../../../../types/checkout";
import { CheckoutConfigWrite } from "../../../../types/checkout/generated";

type PaymentOptionConfig =
    CheckoutConfigWrite["configuration"]["payment_options"][number];
type PaymentOptionsDialogProps = {
    isDialogOpen: boolean;
    handleCloseDialog: () => void;
    checkoutConfig: CheckoutConfig;
    availablePaymentOptions: PaymentOptionConfig[];
    handleSavePaymentOptions: (
        newCheckoutConfiguration: CheckoutConfigWrite,
    ) => void;
    message?: string;
};

const bamboraTokenScope = (config: CheckoutConfig): string | undefined => {
    const paymentOptions = config.configuration?.payment_options || [];
    const bamboraCreditcard = paymentOptions.find((po) => {
        return (
            po.type === "bambora.creditcard" && po.details?.bambora_token_scope
        );
    });
    return (
        bamboraCreditcard?.details?.bambora_token_scope ||
        config.gateways?.bambora?.token_scope
    );
};

export const PaymentOptionsDialog = ({
    isDialogOpen,
    handleCloseDialog,
    availablePaymentOptions,
    checkoutConfig,
    handleSavePaymentOptions,
    message,
}: PaymentOptionsDialogProps) => {
    const { t } = useTranslation();

    const accountIds = useAccountIds();
    const getPayoutAccountConfig = useActions("payout.config").getAccountConfig;
    const { loading, hasPayout } = useSelector(
        payoutInfoSelector(accountIds.optimisticProdAccountId),
    );
    useEffect(() => {
        getPayoutAccountConfig(accountIds.optimisticProdAccountId);
    }, [accountIds.optimisticProdAccountId, getPayoutAccountConfig]);
    const showInForm = availablePaymentOptions.map((option) => option.type);
    const activePaymentOptions = checkoutConfig.configuration.payment_options;
    const otherPaymentOptions = activePaymentOptions.filter(
        (option) => !showInForm.includes(option.type),
    );
    const initiallySelectedPaymentOptions = activePaymentOptions
        .filter((option) => showInForm.includes(option.type))
        .map((option) => option.type);
    const initiallySelectedCurrencies = activePaymentOptions
        .filter((option) => showInForm.includes(option.type))
        .flatMap((option) =>
            option.currencies.map((currency) => `${option.type}-${currency}`),
        );
    const initiallySetDetails = activePaymentOptions
        .filter((option) => showInForm.includes(option.type))
        .map((option) => {
            const paymentOption = activePaymentOptions.find(
                (x) => x.type === option.type,
            );
            const details: Required<PaymentOptionConfig>["details"] &
                Pick<PaymentOptionConfig, "type"> = {
                type: option.type,
                ...option.details,
                ...(paymentOption && paymentOption.details
                    ? {
                          enable_on_hold:
                              paymentOption.details.enable_on_hold ??
                              checkoutConfig.gateways?.collector?.options
                                  ?.enable_on_hold ??
                              false,
                      }
                    : checkoutConfig.gateways &&
                      checkoutConfig.gateways.collector && {
                          enable_on_hold:
                              checkoutConfig.gateways.collector.options
                                  ?.enable_on_hold ?? false,
                      }),
            };

            if (option.type === "bambora.creditcard") {
                details.bambora_token_scope = bamboraTokenScope(checkoutConfig);
            }
            return details;
        });
    const currentPaymentProduct = initiallySelectedPaymentOptions
        .find((option) => option)
        ?.split(".")?.[0] as
        | "payex"
        | "bambora"
        | "collector"
        | "klarna"
        | undefined;
    const hasPayoutEnabled =
        checkoutConfig.configuration.payout?.payment_products.some(
            (x) => x.payment_product === currentPaymentProduct,
        );
    const formik = useFormik({
        initialValues: {
            paymentOptions: initiallySelectedPaymentOptions,
            currencies: initiallySelectedCurrencies,
            details: initiallySetDetails,
            enablePayout: hasPayoutEnabled ?? false,
            bamboraTokenScope: bamboraTokenScope(checkoutConfig) ?? "",
            configuredAgreements: initiallySelectedPaymentOptions,
        },
        validate: (values) => {
            const errors: { [key: string]: string } = {};

            const bamboraOptions = values.paymentOptions.filter((option) =>
                option.startsWith("bambora."),
            );
            const missingAgreements = bamboraOptions.filter(
                (option) => !values.configuredAgreements.includes(option),
            );
            if (missingAgreements.length > 0) {
                errors.configuredAgreements = t(
                    "settings.payment_connections.edit_payment_options.errors.require_agreements",
                );
            }
            return values.paymentOptions.reduce((acc, option) => {
                const currenciesForOption = values.currencies
                    .filter((currency) => currency.startsWith(option))
                    .map((currency) => currency.split("-")[1]);
                if (currenciesForOption.length === 0) {
                    return {
                        ...acc,
                        [option]: t(
                            "settings.payment_connections.edit_payment_options.errors.require_currency",
                        ),
                    };
                }
                return acc;
            }, errors);
        },
        onSubmit: (values) => {
            const newPaymentOptions = values.paymentOptions.map((option) => {
                const existingConfigForPaymentOption =
                    activePaymentOptions.find(
                        (existing) => existing.type === option,
                    ) ?? {};
                const currenciesForOption = [
                    ...new Set(
                        values.currencies
                            .filter((currency) => currency.startsWith(option))
                            .map((currency) => currency.split("-")[1]),
                    ),
                ];
                const detailsForOption = values.details.find(
                    (d) => d.type === option,
                );
                if (
                    existingConfigForPaymentOption &&
                    "details" in existingConfigForPaymentOption &&
                    "enable_on_hold" in
                        (existingConfigForPaymentOption as any).details &&
                    !option.startsWith("collector.")
                ) {
                    // TS doesn't really seem to know that this is a payment option with this check
                    delete (existingConfigForPaymentOption as any).details
                        .enable_on_hold;
                }

                const newOption: CheckoutConfig["configuration"]["payment_options"][number] =
                    {
                        details: {},
                        ...existingConfigForPaymentOption,
                        type: option,
                        currencies: currenciesForOption,
                        ...(option.startsWith("collector.")
                            ? detailsForOption
                                ? {
                                      details: {
                                          enable_on_hold:
                                              detailsForOption.enable_on_hold,
                                      },
                                  }
                                : {
                                      details: {
                                          enable_on_hold:
                                              checkoutConfig.gateways?.collector
                                                  ?.options?.enable_on_hold ??
                                              false,
                                      },
                                  }
                            : undefined),
                    };
                if (option === "bambora.creditcard") {
                    newOption.details = {
                        ...newOption.details,
                        bambora_token_scope: values.bamboraTokenScope,
                    };
                }
                return newOption;
            });
            const asPayoutConfiguration = (options?: any) => {
                if (!options || !options?.length) {
                    return {};
                }
                return {
                    payout: {
                        payment_products: options,
                    },
                };
            };
            const newPayoutConfiguration = values.enablePayout
                ? [{ payment_product: currentPaymentProduct }]
                : [];
            const newConfiguration = {
                configuration: {
                    ...checkoutConfig.configuration,
                    payment_options: [
                        ...newPaymentOptions,
                        ...otherPaymentOptions,
                    ],
                    ...asPayoutConfiguration([
                        ...(checkoutConfig.configuration.payout?.payment_products.filter(
                            (p) => p.payment_product !== currentPaymentProduct,
                        ) || []),
                        ...newPayoutConfiguration,
                    ]),
                },
            };
            handleSavePaymentOptions(newConfiguration);
        },
    });
    if (loading) return null;

    const currencyErrors =
        Object.entries(formik.errors || {}).find(
            (entry) => entry[0] !== "configuredAgreements",
        ) || [];
    const missingCurrencyError = currencyErrors[1];
    const missingAgreementsError = formik.errors.configuredAgreements;
    return (
        <form onSubmit={formik.handleSubmit}>
            {isDialogOpen ? (
                <Modal width="800px" onClose={handleCloseDialog}>
                    <View
                        gap={24}
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                    >
                        <View
                            direction="column"
                            justify="flex-start"
                            alignItems="flex-start"
                            gap={distances.small}
                        >
                            <H2>
                                {t(
                                    "settings.payment_connections.edit_payment_options.title",
                                )}
                            </H2>
                            {message && <P>{message}</P>}
                            {availablePaymentOptions.map(
                                (paymentOption, index) => {
                                    const initialChecked =
                                        formik.initialValues.paymentOptions.includes(
                                            paymentOption.type,
                                        );
                                    const isChecked =
                                        formik.values.paymentOptions.includes(
                                            paymentOption.type,
                                        );
                                    const error =
                                        formik.errors?.[
                                            paymentOption.type as keyof typeof formik.errors
                                        ];
                                    const agreementMissingError =
                                        !initialChecked &&
                                        isChecked &&
                                        paymentOption.type.includes(
                                            "bambora",
                                        ) &&
                                        !formik.values.configuredAgreements.includes(
                                            paymentOption.type,
                                        );
                                    return (
                                        <PaymentOptionElements.PaymentOption
                                            key={paymentOption.type}
                                            style={{
                                                ...((error ||
                                                    agreementMissingError) && {
                                                    backgroundColor:
                                                        palette.destructive[50],
                                                    borderColor:
                                                        palette
                                                            .destructive[500],
                                                }),
                                            }}
                                        >
                                            <PaymentOptionElements.PaymentOptionHeader>
                                                <View
                                                    justify="space-between"
                                                    width="100%"
                                                    gap={distances.small}
                                                >
                                                    <View
                                                        direction="row"
                                                        alignItems="flex-start"
                                                        gap={distances.small}
                                                    >
                                                        <P
                                                            fontWeight={600}
                                                            style={{
                                                                ...(error && {
                                                                    color: palette
                                                                        .destructive[500],
                                                                }),
                                                            }}
                                                        >
                                                            {paymentOption.type}
                                                        </P>
                                                        <View
                                                            direction="row"
                                                            alignItems="center"
                                                            gap={distances.tiny}
                                                        >
                                                            {isChecked && (
                                                                <>
                                                                    {paymentOption.currencies.map(
                                                                        (
                                                                            currency,
                                                                        ) => {
                                                                            const optionCurrencyKey = `${paymentOption.type}-${currency}`;
                                                                            const currencyChecked =
                                                                                formik.values.currencies.includes(
                                                                                    optionCurrencyKey,
                                                                                );
                                                                            if (
                                                                                !currencyChecked
                                                                            ) {
                                                                                return null;
                                                                            }
                                                                            return (
                                                                                <Badge
                                                                                    key={
                                                                                        optionCurrencyKey
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        currency
                                                                                    }
                                                                                </Badge>
                                                                            );
                                                                        },
                                                                    )}
                                                                    {paymentOption.type ===
                                                                        "bambora.creditcard" &&
                                                                        formik
                                                                            .values
                                                                            .bamboraTokenScope && (
                                                                            <Badge>
                                                                                {t(
                                                                                    "settings.payment_connections.edit_payment_options.tokenScope",
                                                                                )}
                                                                            </Badge>
                                                                        )}
                                                                </>
                                                            )}
                                                        </View>
                                                    </View>
                                                    <View>
                                                        <P>
                                                            {t(
                                                                "settings.payment_connections.edit_payment_options.enable",
                                                            )}
                                                        </P>
                                                        <Toggle
                                                            value={isChecked}
                                                            onClick={() =>
                                                                formik.setFieldValue(
                                                                    "paymentOptions",
                                                                    isChecked
                                                                        ? formik.values.paymentOptions.filter(
                                                                              (
                                                                                  po,
                                                                              ) =>
                                                                                  po !==
                                                                                  paymentOption.type,
                                                                          )
                                                                        : [
                                                                              ...formik
                                                                                  .values
                                                                                  .paymentOptions,
                                                                              paymentOption.type,
                                                                          ],
                                                                )
                                                            }
                                                            onFill={
                                                                palette
                                                                    .primary[500]
                                                            }
                                                        />
                                                    </View>
                                                </View>
                                            </PaymentOptionElements.PaymentOptionHeader>
                                            {isChecked && (
                                                <PaymentOptionElements.PaymentOptionContent>
                                                    {!initialChecked &&
                                                        isChecked &&
                                                        paymentOption.type.includes(
                                                            "bambora",
                                                        ) && (
                                                            <>
                                                                <View
                                                                    direction="row"
                                                                    alignItems="flex-start"
                                                                    gap={
                                                                        distances.small
                                                                    }
                                                                >
                                                                    <Checkbox
                                                                        key={`agreement-${paymentOption.type}`}
                                                                        name="configuredAgreements"
                                                                        label={t(
                                                                            "settings.payment_connections.edit_payment_options.bambora.check_label",
                                                                        )}
                                                                        checked={formik.values.configuredAgreements.includes(
                                                                            paymentOption.type,
                                                                        )}
                                                                        onChange={
                                                                            formik.handleChange
                                                                        }
                                                                        value={
                                                                            paymentOption.type
                                                                        }
                                                                    />
                                                                </View>
                                                            </>
                                                        )}

                                                    <P>
                                                        {t(
                                                            "settings.payment_connections.edit_payment_options.currencies",
                                                        )}
                                                    </P>
                                                    <View
                                                        direction="row"
                                                        alignItems="flex-start"
                                                        gap={distances.small}
                                                    >
                                                        {paymentOption.currencies.map(
                                                            (currency) => {
                                                                const optionCurrencyKey = `${paymentOption.type}-${currency}`;
                                                                const currencyChecked =
                                                                    formik.values.currencies.includes(
                                                                        optionCurrencyKey,
                                                                    );
                                                                return (
                                                                    <CurrencyCheckbox
                                                                        key={
                                                                            optionCurrencyKey
                                                                        }
                                                                        name="currencies"
                                                                        label={
                                                                            currency
                                                                        }
                                                                        checked={
                                                                            currencyChecked
                                                                        }
                                                                        onChange={
                                                                            formik.handleChange
                                                                        }
                                                                        value={
                                                                            optionCurrencyKey
                                                                        }
                                                                    />
                                                                );
                                                            },
                                                        )}
                                                    </View>
                                                    {paymentOption.type ===
                                                        "bambora.creditcard" && (
                                                        <>
                                                            <Input
                                                                className="stretch"
                                                                type="text"
                                                                name="bamboraTokenScope"
                                                                placeholder="Required for doing MIT"
                                                                label={t(
                                                                    "settings.payment_connections.edit_payment_options.tokenScope",
                                                                )}
                                                                autoComplete="off"
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .bamboraTokenScope
                                                                }
                                                                onBlur={
                                                                    formik.handleBlur
                                                                }
                                                                onChange={
                                                                    formik.handleChange
                                                                }
                                                                mb="0px"
                                                            />
                                                        </>
                                                    )}
                                                    {paymentOption.type.startsWith(
                                                        "collector.",
                                                    ) &&
                                                        formik.values.details[
                                                            index
                                                        ] && (
                                                            <>
                                                                <H4>
                                                                    {t(
                                                                        "settings.payment_connections.edit_payment_options.details",
                                                                    )}
                                                                </H4>
                                                                {Object.entries(
                                                                    formik
                                                                        .values
                                                                        .details[
                                                                        index
                                                                    ],
                                                                ).map(
                                                                    ([
                                                                        key,
                                                                        value,
                                                                    ]) => {
                                                                        const detailsKey = `details[${index}].${key}`;
                                                                        if (
                                                                            key !==
                                                                            "type"
                                                                        )
                                                                            return (
                                                                                <Checkbox
                                                                                    key={
                                                                                        detailsKey
                                                                                    }
                                                                                    name={
                                                                                        detailsKey
                                                                                    }
                                                                                    label={
                                                                                        key
                                                                                    }
                                                                                    checked={
                                                                                        value as boolean
                                                                                    }
                                                                                    value={
                                                                                        detailsKey
                                                                                    }
                                                                                    onChange={
                                                                                        formik.handleChange
                                                                                    }
                                                                                />
                                                                            );
                                                                        return null;
                                                                    },
                                                                )}
                                                            </>
                                                        )}
                                                </PaymentOptionElements.PaymentOptionContent>
                                            )}
                                        </PaymentOptionElements.PaymentOption>
                                    );
                                },
                            )}
                            {missingCurrencyError && (
                                <ErrorMessage>
                                    {missingCurrencyError}
                                </ErrorMessage>
                            )}
                            {missingAgreementsError && (
                                <ErrorMessage>
                                    {missingAgreementsError}
                                </ErrorMessage>
                            )}
                        </View>
                        <ButtonGroup>
                            <Button
                                type="button"
                                className="alt"
                                onClick={handleCloseDialog}
                            >
                                {t(
                                    "settings.payment_connections.edit_payment_options.cancel",
                                )}
                            </Button>
                            <Button type="submit" disabled={!formik.isValid}>
                                {t(
                                    "settings.payment_connections.edit_payment_options.submit",
                                )}
                            </Button>
                        </ButtonGroup>
                    </View>
                </Modal>
            ) : null}
        </form>
    );
};

const CurrencyCheckbox = styled(Checkbox)`
  margin-right: ${distances.tiny};
`;

const ErrorMessage = styled.p`
    color: ${colors.invalid};
    font-size: 0.8em;
`;

const PaymentOptionElements = {
    PaymentOption: styled.div`
        width: 100%;
        display: flex;
        flex-direction: column;
        border: 1px solid ${palette.neutral[300]};
        border-radius: ${defaultRadius};
        padding: ${distances.small};
        gap: ${distances.small};
    `,
    PaymentOptionHeader: styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
    `,
    PaymentOptionContent: styled.div`
        display: flex;
        flex-direction: column;
        gap: ${distances.tiny};
    `,
};

const Badge = styled.div`
    border: 1px solid ${palette.primary[300]};
    background: ${palette.primary[50]};
    color: ${palette.primary[500]};
    padding: 0px 6px;
    border-radius: 50px;
    font-size: 0.8em;
    margin: 0;
    line-height: 20px;
    font-weight: 600;
    text-transform: uppercase;
`;
