import React, {} from "react";
import { withTranslation } from "react-i18next";

import CheckboxListFilter, {
    Option,
} from "../../../../../components/Filters/CheckboxListFilter";
import { FilterProps } from "./FilterProps";

export interface CountryFilterProps extends FilterProps {
    countries: string[];
}

const CountryFilter = ({
    queryParams,
    t,
    onChange,
    countries,
}: CountryFilterProps) => {
    const options: Option[] = countries.map((value) => ({
        label: value,
        value,
    }));

    const values = queryParams.getAll("country");

    return (
        <CheckboxListFilter
            label={t("locations.filter.country.label", {
                count: values.length,
                value: values.join(", "),
            })}
            title={t("locations.filter.country.title")}
            name="country"
            values={values}
            options={options}
            onApply={(name, values) => {
                onChange({ [name]: values });
            }}
            onDelete={(name) => {
                onChange({ [name]: [] });
            }}
        />
    );
};

export default withTranslation()(CountryFilter);
