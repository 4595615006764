import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { useSelector } from "react-redux";
import { useActions } from "../../../../Actions";
import { useAccountIds } from "../../../../auth/accessToken/components/withAccountIds";
import { Button, ButtonGroup } from "../../../../components/Buttons";
import { LoadingOverlay } from "../../../../components/Loading";
import Modal from "../../../../components/Modal";
import { H1, P } from "../../../../components/Typography";
import { distances } from "../../../../styles/constants";
import { usePaymentApprovals } from "../../approvalsPayments/hooks";
import { ModalState, namespace } from "../actions";
import CollectorForm from "./CollectorForm";

const CollectorModal = () => {
    const { t } = useTranslation();
    const { closeModal } = useActions("connections.collector");
    const { prodAccountId } = useAccountIds();
    const { approvals } = usePaymentApprovals();

    const { modalState, account } = useSelector((state) => {
        return {
            modalState: state[namespace].modalState,
            account: state[namespace].account,
        };
    });
    const activeApprovals = (approvals || []).filter(
        (x) => x?.case_status === "ACTIVE",
    );

    if (!prodAccountId) {
        return null;
    }

    if (modalState === ModalState.Closed) {
        return null;
    }

    if (!account) {
        return <LoadingOverlay />;
    }
    return (
        <Modal fixedWidth>
            {[
                ModalState.ApplicationFormOpened,
                ModalState.InitiateApplicationLoading,
            ].includes(modalState) && (
                <>
                    <H1>
                        {t(
                            "settings.payment_connections.collector_modal.title_intro",
                        )}
                    </H1>
                    <CollectorForm activeApprovals={activeApprovals} />
                    {modalState === ModalState.InitiateApplicationLoading && (
                        <LoadingOverlay />
                    )}
                </>
            )}
            {modalState === ModalState.ApplicationReceived && (
                <>
                    <H1>
                        {t(
                            "settings.payment_connections.collector_modal.title_last_step",
                        )}
                    </H1>

                    <SpacedWrapper>
                        <P>
                            <Trans i18nKey="settings.payment_connections.collector_modal.last_step" />
                        </P>
                    </SpacedWrapper>

                    <ButtonGroup>
                        <Button onClick={closeModal}>
                            {t(
                                "settings.payment_connections.collector_modal.close",
                            )}
                        </Button>
                    </ButtonGroup>
                </>
            )}
        </Modal>
    );
};

const SpacedWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.normal};
`;

export default CollectorModal;
