import { TFunction } from "i18next";

import { CheckoutConfig } from "../../../types/checkout";
import { GatewayDetails, GatewayParsed } from "./types";

const gatewayDetails = (
    gwName: keyof CheckoutConfig["gateways"],
    gateway: any,
): GatewayDetails[] => {
    switch (gwName) {
        case "bambora":
            return [{ key: "gateway_id", value: gateway.merchant_number }];
        case "swish":
            return [{ key: "gateway_id", value: gateway.swish_number }];
        case "vipps":
            return [
                { key: "gateway_id", value: gateway.merchant_serial_number },
            ];
        case "payex":
            return [
                { key: "gateway_id", value: gateway.payee_id },
                { key: "subsite", value: gateway.subsite },
            ];
        case "instabank":
            return [{ key: "gateway_id", value: gateway.organization_number }];
        case "collector":
            return [{ key: "gateway_id", value: gateway.store_id }];
        case "santander":
            return [{ key: "gateway_id", value: gateway.store_email }];
        case "netaxept":
            return [{ key: "gateway_id", value: gateway.merchant_id }];
        default:
            return [];
    }
};

export const mapGateways = (
    checkoutConfig: CheckoutConfig | null,
    locationId: string,
    t: TFunction,
): GatewayParsed[] => {
    if (!(checkoutConfig && checkoutConfig.gateways)) {
        return [];
    }
    const gateways = checkoutConfig.gateways;
    return (
        Object.keys(gateways) as Array<keyof CheckoutConfig["gateways"]>
    ).map((name) => {
        const gateway = gateways[name] as {
            overrides?: { [key: string]: any };
        };
        if (gateway?.overrides?.[locationId]) {
            return {
                name,
                status: t(
                    "locations.location_details.payment_connections.status_value.specific",
                ),
                gatewayDetails: gatewayDetails(
                    name,
                    gateway.overrides[locationId].override,
                ),
            };
        }
        return {
            name,
            status: t(
                "locations.location_details.payment_connections.status_value.default",
            ),
            gatewayDetails: gatewayDetails(name, gateway),
        };
    });
};
