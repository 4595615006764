import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import styled from "styled-components/macro";

import { useActions } from "../../../Actions";
import { useAccountIds } from "../../../auth/accessToken/components/withAccountIds";
import { useAccountId } from "../../../auth/useAccountId";
import Card from "../../../components/Card";
import Icon from "../../../components/Icons";
import { LoadingOverlay } from "../../../components/Loading";
import Page, { PageButton } from "../../../components/Page";
import { H1, P } from "../../../components/Typography";
import { State } from "../../../reducer";
import useEffectCallback from "../../../util/useEffectCallback";
import usePayoutDestinations from "../../../util/usePayoutDestinations";
import useAccount from "../../account/hooks/useAccount";
import { namespace } from "../actions";
import ConfigurationsTable from "./ConfigurationsTable";
import CreateConfigurationModal from "./CreateConfigurationModal";
import DeleteConfigurationModal from "./DeleteConfigurationModal";
import EditConfigurationModal from "./EditConfigurationModal";
import NoData from "./NoData";

const configurationListSelector = (state: State) => state[namespace];

const reportListSelector = createSelector(
    configurationListSelector,
    (configurationState) => configurationState,
);

const ReportList = () => {
    const {
        fetchConfigurations,
        openDeleteModal,
        openNewModal,
        closeNewModal,
        postNewConfiguration,
        closeDeleteModal,
        removeConfiguration,
        openEditModal,
        closeEditModal,
        updateConfiguration,
    } = useActions(namespace);
    const { accountIds } = useAccountIds();
    const { t } = useTranslation();
    const {
        account_id,
        configurationToDelete,
        configurations,
        isLoading,
        createError,
        showNewConfigurationModal,
        configurationToEdit,
    } = useSelector(reportListSelector);
    const { account } = useAccount();
    const accountId = useAccountId();

    useEffectCallback(
        (accountIds: string[]) => {
            fetchConfigurations(accountIds);
        },
        [accountIds],
    );

    const hasConfigurations = Boolean(
        accountId &&
            configurations[accountId] &&
            configurations[accountId].length > 0,
    );

    const { destinationOptions, destinationIdLabelMap } =
        usePayoutDestinations();

    return (
        <>
            <Page
                title={
                    <>
                        <Title>
                            {t("settings.settlement_report_config.title")}
                        </Title>
                        <PageButton onClick={openNewModal}>
                            <Icon icon="plus" fill="currentColor" />
                            {t(
                                "settings.settlement_report_config.create.call_to_action",
                            )}
                        </PageButton>
                    </>
                }
            >
                <Card
                    title={t(
                        "settings.settlement_report_config.introduction.title",
                    )}
                >
                    <P>
                        {t(
                            "settings.settlement_report_config.introduction.description",
                        )}
                    </P>
                    <br />
                    <P>
                        {t(
                            "settings.settlement_report_config.introduction.description_2",
                        )}
                    </P>
                </Card>
                {hasConfigurations && (
                    <ConfigurationsTable
                        accountId={accountId}
                        configurations={configurations[accountId] || []}
                        openDeleteModal={openDeleteModal}
                        openEditModal={openEditModal}
                        accountBillingEmailAddress={account?.billing.email}
                        destinationIdLabelMap={destinationIdLabelMap}
                    />
                )}
                {isLoading && <LoadingOverlay />}
                {!isLoading && !hasConfigurations && (
                    <NoData openNewModal={openNewModal} />
                )}
            </Page>
            {account_id && configurationToDelete && (
                <DeleteConfigurationModal
                    accountId={account_id}
                    configuration={configurationToDelete}
                    removeConfiguration={removeConfiguration}
                    dismissModal={closeDeleteModal}
                />
            )}
            {showNewConfigurationModal && (
                <CreateConfigurationModal
                    destinations={destinationOptions}
                    dismissModal={closeNewModal}
                    accountIds={accountIds}
                    createConfiguration={postNewConfiguration}
                    createError={createError}
                    account={account}
                />
            )}
            {account_id && configurationToEdit && (
                <EditConfigurationModal
                    destinations={destinationOptions}
                    dismissModal={closeEditModal}
                    accountIds={accountIds}
                    updateConfiguration={updateConfiguration}
                    createError={createError}
                    accountBillingEmailAddress={account?.billing.email}
                    configuration={configurationToEdit}
                />
            )}
        </>
    );
};
const Title = styled(H1 as any)`
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

export default ReportList;
