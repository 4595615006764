import { useTranslation } from "react-i18next";

type HumanBooleanProps = {
    value: boolean;
};

const HumanBoolean = ({ value }: HumanBooleanProps) => {
    const { t } = useTranslation();
    return (
        <>
            {t("components.formatters.human_boolean.value", {
                context: value.toString(),
            })}
        </>
    );
};

export default HumanBoolean;
