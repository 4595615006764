import { ProfileConfiguration } from "../../../types/checkout";

export enum ActionTypes {
    GetProfiles = "[settings/hooks] GET_PROFILES",
    FetchProfilesResponse = "[settings/hooks] FETCH_PROFILES_RESPONSE",
}

export interface GetProfiles {
    type: ActionTypes.GetProfiles;
    payload: {
        accountId: string;
    };
}

export interface FetchProfilesResponse {
    type: ActionTypes.FetchProfilesResponse;
    payload: {
        profiles: ProfileConfiguration[];
    };
    meta: {
        cause: GetProfiles;
    };
}

export function getProfiles(accountId: string): GetProfiles {
    return {
        type: ActionTypes.GetProfiles,
        payload: {
            accountId: accountId,
        },
    };
}

export type Actions = GetProfiles | FetchProfilesResponse;
