import deepEqual from "fast-deep-equal";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Redirect, RouteComponentProps } from "react-router-dom";

import {
    AccountIdsProps,
    withAccountIds,
} from "../../../../auth/accessToken/components/withAccountIds";
import { LoadingOverlay } from "../../../../components/Loading";
import {
    fromMonetaryAmountString,
    toMonetaryAmountString,
} from "../../../../helpers/formatters";
import { BillingPayoutRule } from "../../../../types/billing";
import {
    BillingPayoutRuleDestination,
    BillingPayoutRuleDestinationNested,
} from "../../../../types/orders/generated";
import { EXAMPLE_VALUE_CURRENCY } from "../../constants";
import PayoutRuleForm from "../../newPayoutRule/components/PayoutRuleForm";
import { PayoutRuleFormikValues } from "../../newPayoutRule/components/types";
import { PayoutDestinationGroup } from "../../payoutRules/types";
import { State } from "../reducer";

interface MatchParams {
    accountId: string;
    payoutRuleId: string;
}

interface PayoutRuleEditProps
    extends WithTranslation,
        RouteComponentProps<MatchParams>,
        AccountIdsProps {
    payoutRuleEditState: State;
    fetchPayoutRule: (accountId: string, payoutRuleId: string) => void;
    editPayoutRule: (
        accountId: string,
        payoutRule: BillingPayoutRule,
        payoutRuleId: string,
    ) => void;
    resetForm: () => void;
    fetchLocations: (accountId: string) => void;
}

const mapDestinations = (
    destinations: BillingPayoutRuleDestination[],
    lng: string,
): BillingPayoutRuleDestination[] => {
    return destinations.map((dest) => ({
        ...dest,
        value:
            dest.type === "flat_amount"
                ? toMonetaryAmountString(
                      lng,
                      parseFloat(dest.value || "0"),
                      EXAMPLE_VALUE_CURRENCY,
                  )
                : dest.value,
        destinations: dest.destinations
            ? mapDestinations(
                  dest.destinations as BillingPayoutRuleDestination[],
                  lng,
              )
            : undefined,
    })) as BillingPayoutRuleDestination[];
};

class PayoutRuleEdit extends Component<PayoutRuleEditProps> {
    componentDidMount() {
        this.props.resetForm();
        const accountId = this.props.match.params.accountId;
        const payoutRuleId = this.props.match.params.payoutRuleId;
        if (accountId && payoutRuleId) {
            this.props.fetchPayoutRule(accountId, payoutRuleId);
        }
        if (accountId) {
            this.props.fetchLocations(accountId);
        }
    }

    componentWillUnmount() {
        this.props.resetForm();
    }

    render() {
        const {
            i18n,
            payoutRuleEditState,
            editPayoutRule,
            resetForm,
            accountIds,
        } = this.props;
        const accountId = this.props.match.params.accountId;
        const payoutRuleId = this.props.match.params.payoutRuleId;
        const lng = i18n.language;

        const { payoutRule, isLoading, result, locations } =
            payoutRuleEditState;

        if (!payoutRule) {
            return <LoadingOverlay />;
        }

        const initialValues = {
            rule_id: payoutRule.rule_id,
            rule_type: payoutRule.rule_type,
            destinations: mapDestinations(payoutRule.destinations, lng),
        } as PayoutRuleFormikValues;

        const onSubmit = (values: PayoutRuleFormikValues) => {
            if (
                !deepEqual(values, initialValues) &&
                accountId &&
                payoutRuleId
            ) {
                const mapDestinations = (
                    destinations: PayoutDestinationGroup,
                ): PayoutDestinationGroup => {
                    return destinations.map((dest) => {
                        // destination and destinations can't be defined at the same time
                        return {
                            ...dest,
                            value:
                                dest.type === "flat_amount"
                                    ? fromMonetaryAmountString(
                                          lng,
                                          dest.value as string,
                                          EXAMPLE_VALUE_CURRENCY,
                                      ).toString()
                                    : dest.value,
                            destination: dest.destination
                                ? dest.destination
                                : undefined,
                            destinations:
                                dest.destinations &&
                                dest.destinations.length > 0
                                    ? mapDestinations(
                                          dest.destinations as PayoutDestinationGroup,
                                      )
                                    : undefined,
                        };
                    });
                };

                const newPayoutRule: BillingPayoutRule = {
                    rule_id: values.rule_id,
                    rule_type: values.rule_type,
                    destinations: mapDestinations(
                        values.destinations,
                    ) as BillingPayoutRuleDestination[],
                };
                editPayoutRule(accountId, newPayoutRule, payoutRuleId);
            }
        };

        if (result) {
            return (
                <Redirect
                    to={`/${accountId}/settings/payout-rules/${payoutRuleId}`}
                />
            );
        }

        return (
            <PayoutRuleForm
                initialValues={{ ...initialValues, accountId }}
                accountIds={accountIds}
                onSubmit={onSubmit}
                resetForm={resetForm}
                isLoading={isLoading}
                isEdit
                locations={locations}
            />
        );
    }
}

export default withAccountIds(withTranslation()(PayoutRuleEdit));
