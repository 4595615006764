import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components/macro";

import {
    colors,
    defaultRadius,
    distances,
    grid,
    shadows,
    zIndex,
} from "../../styles/constants";
import { Button } from "../Buttons";
import FilterIcon from "../Icons/filter";
import { H3 } from "../Typography";

export type OnChangeFunction = (event: Event) => void;

interface FilterSelectProps extends WithTranslation {
    name: string;
    disabled?: boolean;
    options: React.ReactNode[];
    children?: React.ReactNode;
}

interface FilterSelectState {
    open: boolean;
}

class FilterSelect extends Component<FilterSelectProps, FilterSelectState> {
    wrapperRef: any = null;

    constructor(props: FilterSelectProps, context: any) {
        super(props, context);
        this.state = {
            open: false,
        };
        this.wrapperRef = React.createRef();
    }
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    setWrapperRef = (node: any) => {
        this.wrapperRef = node;
    };

    handleClick = (event: any) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ open: false });
        }
    };

    toggleOpen = () => {
        this.setState({ open: !this.state.open });
    };

    render() {
        const { name, options, t, children } = this.props;
        const { open } = this.state;

        return (
            <Wrapper ref={this.setWrapperRef}>
                <FilterButton
                    id={name}
                    name={name}
                    onClick={this.toggleOpen}
                    className="alt"
                >
                    <OffsetIcon />
                    {t("components.filters.filter_select.add_filter")}
                </FilterButton>
                <Options open={open} onClick={this.toggleOpen}>
                    {children}
                    <H3>
                        {t("components.filters.filter_select.select_a_filter")}
                    </H3>
                    {options}
                </Options>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    position: relative;
`;

const FilterButton = styled(Button)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 40px;
`;

const OffsetIcon = styled(FilterIcon)`
    position: relative;
`;
interface OptionsProps {
    open: boolean;
}
const Options = styled.ul<OptionsProps>`
    min-width: ${grid.spans.span2};
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 40px;
    z-index: ${zIndex.top};
    background-color: ${colors.background};
    box-shadow: ${shadows.large};
    transition: all 0.15s ease;
    transform-origin: top center;
    overflow: hidden;
    padding: ${distances.micro} ${distances.small12};
    border: 1px solid ${colors.borderDark};
    border-radius: ${defaultRadius};
    ${(props) =>
        props.open
            ? `
        transform: translateY(0);
        max-height: 800px;
        opacity: 1;
    `
            : `
        transform: translateY(-1%);
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0;
        border-width: 0;
        opacity: 0;
    `};


    > button, a {
        white-space: nowrap;
        width: 100%;
        margin-bottom: ${distances.tiny};
        text-align: left;
        display: flex;
        align-items: center;
    }
`;
export default withTranslation()<React.ComponentType<FilterSelectProps>>(
    FilterSelect,
);
