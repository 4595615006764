import { BillingPayoutRule } from "../../../types/billing";

export enum ActionTypes {
    FetchPayoutRule = "[payout_rules/fetch_payout_rule] FETCH_PAYOUT_RULE",
    FetchPayoutRuleResponse = "[payout_rules/fetch_payout_rule] FETCH_PAYOUT_RULE_RESPONSE",
    DeletePayoutRule = "[payout_rules/delete_payout_rule] DELETE_PAYOUT_RULE",
    DeletePayoutRuleResponse = "[payout_rules/delete_payout_rule] DELETE_PAYOUT_RULE_RESPONSE",
    ClearFetchedData = "[payout_rules/delete_payout_rule] CLEAR_FETCHED_DATA",
}

export interface FetchPayoutRule {
    type: ActionTypes.FetchPayoutRule;
    payload: {
        accountId: string;
        payoutRuleId: string;
    };
}

export interface FetchPayoutRuleResponse {
    type: ActionTypes.FetchPayoutRuleResponse;
    payload: {
        payoutRules: BillingPayoutRule[];
        payoutRuleId: string;
    };
    meta: {
        cause: FetchPayoutRule;
    };
}

export function fetchPayoutRule(
    accountId: string,
    payoutRuleId: string,
): FetchPayoutRule {
    return {
        type: ActionTypes.FetchPayoutRule,
        payload: {
            accountId,
            payoutRuleId,
        },
    };
}

export interface DeletePayoutRule {
    type: ActionTypes.DeletePayoutRule;
    payload: {
        accountId: string;
        payoutRuleId: string;
    };
}

export interface DeletePayoutRuleResponse {
    type: ActionTypes.DeletePayoutRuleResponse;
    meta: {
        cause: DeletePayoutRule;
    };
}

export function deletePayoutRule(
    accountId: string,
    payoutRuleId: string,
): DeletePayoutRule {
    return {
        type: ActionTypes.DeletePayoutRule,
        payload: {
            accountId,
            payoutRuleId,
        },
    };
}

export interface ClearFetchedData {
    type: ActionTypes.ClearFetchedData;
}

export const clearFetchedData = () => {
    return {
        type: ActionTypes.ClearFetchedData,
    };
};

export type Actions =
    | FetchPayoutRule
    | FetchPayoutRuleResponse
    | DeletePayoutRule
    | DeletePayoutRuleResponse
    | ClearFetchedData;
