import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { Button } from "../../../components/Buttons";
import Modal from "../../../components/Modal";
import { H1, P } from "../../../components/Typography";
import { SettlementReportConfig } from "../../../reports/settlementList/types";
import { distances } from "../../../styles/constants";

export interface DeleteConfigurationModalProps {
    accountId: string;
    configuration: SettlementReportConfig;
    dismissModal: () => void;
    removeConfiguration: (accountId: string, configuration_id: string) => void;
}

const DeleteConfigurationModal = ({
    configuration,
    accountId,
    dismissModal,
    removeConfiguration,
}: DeleteConfigurationModalProps) => {
    const { t } = useTranslation();
    return (
        <Modal fixedWidth>
            <H1>
                {t("settings.settlement_report_config.delete.title", {
                    id: configuration.id,
                })}
            </H1>

            <TextWrapper>
                <P>
                    {t("settings.settlement_report_config.delete.description")}
                </P>
            </TextWrapper>

            <Button
                onClick={() => {
                    removeConfiguration(accountId, configuration.id || "");
                }}
            >
                {t("settings.settlement_report_config.delete.call_to_action")}
            </Button>
            <ButtonSpacer />
            <Button className="alt" onClick={dismissModal}>
                {t("settings.settlement_report_config.delete.go_back")}
            </Button>
        </Modal>
    );
};

export default DeleteConfigurationModal;

const ButtonSpacer = styled.span`
    display: inline-block;
    width: ${distances.tiny};
`;

const TextWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.normal};
`;
