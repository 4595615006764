import { Dispatch } from "redux";
import apiRequest, { isApiError } from "../../../apiRequest";
import { createAccountIdChangedCaseReducer } from "../../../auth/useAccountId";
import { CORE_API_HOSTNAME } from "../../../env";
import { Causes, errorExternalStore } from "../../../errors";
import fulfill from "../../../fulfill";
import { Account } from "../../../types/management-auth";
import {
    type BamboraConnectionCallback,
    BamboraDirectConnectionSignup,
    BamboraPayFacConnectionSignup,
} from "../../../types/management-auth/generated";
import { createSwitchlessReducer } from "../../../util/switchlessReducer";
import { getAccount } from "../connections/actions";

export const namespace = "connections.bambora";

export enum ModalState {
    Closed = "Closed",
    InitiateApplicationLoading = "InitiateApplicationLoading",
    ApplicationFormOpened = "ApplicationFormOpened",
    ApplicationReceived = "ApplicationReceived",
    BamboraApiError = "BamboraApiError",
}

export interface State {
    modalState: ModalState;
    account: Account | undefined;
}

export const initialState: State = {
    modalState: ModalState.Closed,
    account: undefined,
};

export const { reducer, actions } = createSwitchlessReducer({
    namespace: namespace,
    initialState,
    reducers: {
        openModal: (state: State, account: Account) => {
            return {
                ...state,
                modalState: ModalState.ApplicationFormOpened,
                account: account,
            };
        },
        closeModal: (state: State) => {
            return {
                ...state,
                modalState: ModalState.Closed,
            };
        },
        postConnection: (state) => {
            return {
                ...state,
                modalState: ModalState.InitiateApplicationLoading,
            };
        },
        postConnectionSuccess: (state) => {
            return {
                ...state,
                modalState: ModalState.ApplicationReceived,
            };
        },
        postConnectionFailure: (state) => {
            return {
                ...state,
                modalState: ModalState.BamboraApiError,
            };
        },
        postConnectionComplete: (state) => {
            return {
                ...state,
                modalState: ModalState.Closed,
            };
        },
    },
    globalReducer: createAccountIdChangedCaseReducer(() => initialState),
});

export const createConnectedActions = (dispatch: Dispatch) => {
    const openModal = (account: Account) => {
        dispatch(actions.openModal(account));
    };

    const closeModal = () => {
        dispatch(actions.closeModal());
    };

    const signupForConnection = async (
        account_id: string,
        agreement_type: "payfac" | "direct",
        data: BamboraPayFacConnectionSignup | BamboraDirectConnectionSignup,
    ) => {
        const url = `${CORE_API_HOSTNAME}/v1/accounts/${account_id}/management/settings/connections/bambora-${agreement_type}`;
        dispatch(actions.postConnection());

        try {
            await apiRequest("POST", account_id, url, {}, { signup: data });
            dispatch(actions.postConnectionSuccess());
            dispatch(getAccount(account_id));
        } catch (err) {
            console.error(err);
            dispatch(actions.closeModal());
            if (isApiError(err)) {
                errorExternalStore.dispatch("setError", {
                    cause: Causes.UnhandledStatus,
                    status: err.status,
                    statusText: err.statusText,
                    message: err.message,
                    "request-id": err.requestId || err.traceId,
                });
            } else {
                throw err;
            }
        }
    };

    const finishRegistration = async (payload: {
        accountId?: string;
        userId?: string;
        body: {
            agreement_type: "payfac" | "direct";
            signup?:
                | BamboraPayFacConnectionSignup
                | BamboraDirectConnectionSignup;
            callback?: BamboraConnectionCallback;
        };
        account?: Account;
    }) => {
        const { accountId, body, userId, account } = payload;
        if (!accountId || !userId || !account) {
            return;
        }
        dispatch(actions.postConnection());
        await fulfill.post({
            url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/management/settings/connections/bambora-${body.agreement_type}`,
            accountId,
            json: {
                signup: body.signup,
            },
            handlers: {
                200: () => {
                    dispatch(actions.postConnectionSuccess());
                    dispatch(getAccount(accountId));
                },
                500: () => {
                    dispatch(actions.postConnectionFailure());
                },
                501: () => {
                    dispatch(actions.postConnectionFailure());
                },
                502: () => {
                    dispatch(actions.postConnectionFailure());
                },
                503: () => {
                    dispatch(actions.postConnectionFailure());
                },
                504: () => {
                    dispatch(actions.postConnectionFailure());
                },
            },
        });

        if (body.agreement_type === "direct") {
            await fulfill.post({
                url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/management/settings/connections/bambora/${userId}`,
                accountId,
                json: body.callback,
                handlers: {
                    202: () => {
                        dispatch(actions.postConnectionComplete());
                        dispatch(getAccount(accountId));
                    },
                    500: () => {
                        dispatch(actions.postConnectionFailure());
                    },
                    501: () => {
                        dispatch(actions.postConnectionFailure());
                    },
                    502: () => {
                        dispatch(actions.postConnectionFailure());
                    },
                    503: () => {
                        dispatch(actions.postConnectionFailure());
                    },
                    504: () => {
                        dispatch(actions.postConnectionFailure());
                    },
                },
            });
        }
    };

    return {
        openModal,
        closeModal,
        signupForConnection,
        finishRegistration,
    };
};
