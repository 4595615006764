import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { Button } from "../../../../components/Buttons";
import Modal from "../../../../components/Modal";
import { H1, P } from "../../../../components/Typography";
import { distances } from "../../../../styles/constants";
import { Subscription } from "../../../../types/webhooks";

export interface DeleteHookModalProps extends WithTranslation {
    accountId: string;
    hook: Subscription;
    dismissDeleteHookModal: () => void;
    deleteHook: (accountId: string, hook_id: string) => void;
}

const DeleteHookModal = ({
    t,
    hook,
    accountId,
    deleteHook,
    dismissDeleteHookModal,
}: DeleteHookModalProps) => {
    return (
        <Modal>
            <H1>{t("settings.delete_hook.title", { name: hook.id })}</H1>

            <TextWrapper>
                <P>{t("settings.delete_hook.description")}</P>
            </TextWrapper>
            <Button
                onClick={() => {
                    deleteHook(accountId, hook.id || "");
                }}
            >
                {t("settings.delete_hook.delete_hook_call_to_action")}
            </Button>
            <ButtonSpacer />
            <Button className="alt" onClick={dismissDeleteHookModal}>
                {t("settings.delete_hook.go_back")}
            </Button>
        </Modal>
    );
};

export default withTranslation()(DeleteHookModal);

const ButtonSpacer = styled.span`
    display: inline-block;
    width: ${distances.tiny};
`;

const TextWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.normal};
`;
