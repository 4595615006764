import styled from "styled-components/macro";

const IconWrapper = styled.span`
     display: inline-block;
     width: 16px;
     height: 16px;
     margin-bottom: 4px;
 `;

export default IconWrapper;
