import { Grant, HasSecretClientResponse } from "../types/management-auth";

export const clientPaste = (
    accountId: string,
    client: HasSecretClientResponse,
    grants: Grant[],
) => {
    const audiences: string[] = [];
    grants
        .filter((g) => g.deleted_at === undefined)
        .forEach((g) => audiences.push(`client_audience: ${g.audience}`));
    return `account_id: ${accountId}
client_id: ${client.client_id}
client_secret: ${client.client_secret}
${audiences.join("\n")}
`;
};
