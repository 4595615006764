import { createContext, useContext, useEffect, useRef, useState } from "react";

type MenuContextType = {
    menus: [string, boolean][];
    set: (id: string, isOpen: boolean) => void;
};

const context = createContext<MenuContextType>({
    menus: [],
    set: () => {},
});

export const useMenuContext = (id: string) => {
    const { menus, set } = useContext(context);
    const setRef = useRef(set);
    const idRef = useRef(id);
    useEffect(() => {
        setRef.current(idRef.current, false);
    }, []);
    return { menus, set: setRef.current };
};

export default function MenuProvider(props: { children: React.ReactNode }) {
    const [menus, setMenus] = useState<[string, boolean][]>([]);

    const set = (id: string, isOpen: boolean) => {
        const newMenus = menus.filter(([menuId]) => menuId !== id);
        setMenus([...newMenus, [id, isOpen]]);
    };

    return (
        <context.Provider value={{ menus, set }}>
            {props.children}
        </context.Provider>
    );
}
