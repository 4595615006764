import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { useAccountId } from "../../auth/useAccountId";
import { ButtonLink } from "../../components/Buttons";
import { Title } from "../../components/Page";
import { P } from "../../components/Typography";

type FinishAccountInfoboxProps = {
    context?:
        | "payment_connections"
        | "payout_destinations"
        | "payout_destination";
};

const FinishAccountInfobox = ({ context }: FinishAccountInfoboxProps) => {
    const { t } = useTranslation();
    const accountId = useAccountId();

    return (
        <InfoBox>
            <Title>{t(`settings.finish_profile_setup.title`)}</Title>
            <P>{t(`settings.finish_profile_setup.body`, { context })}</P>
            <br />
            <ButtonLink to={`/${accountId}/settings/account`}>
                {t(`settings.finish_profile_setup.call_to_action`)}
            </ButtonLink>
        </InfoBox>
    );
};

const InfoBox = styled.div`
    &:last-child {
        margin-bottom: 0;
    }
`;

export default FinishAccountInfobox;
