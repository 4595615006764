import {
    CheckoutConfig,
    NewProfileConfiguration,
    ProfileConfiguration,
} from "../../../types/checkout";
import { Account } from "../../../types/management-auth";

export enum ActionTypes {
    ResetForm = "[settings/editPaymentProfile] RESET_FORM",
    GetCheckoutConfiguration = "[settings/editPaymentProfile] GET_CHECKOUT_CONFIGURATION",
    FetchCheckoutConfigurationResponse = "[settings/editPaymentProfile] FETCH_CHECKOUT_CONFIGURATION_RESPONSE",
    EditPaymentProfile = "[settings/editPaymentProfile] EDIT_PAYMENT_PROFILE",
    FetchEditPaymentProfileResponse = "[settings/editPaymentProfile] FETCH_PUT_PAYMENT_PROFILE_RESPONSE",
}

export interface ResetForm {
    type: ActionTypes.ResetForm;
    payload: {};
}

export function resetForm(): ResetForm {
    return {
        type: ActionTypes.ResetForm,
        payload: {},
    };
}

export interface GetCheckoutConfiguration {
    type: ActionTypes.GetCheckoutConfiguration;
    payload: {
        accountId: string;
        profileId: string;
    };
}

export function getCheckoutConfiguration(
    accountId: string,
    profileId: string,
): GetCheckoutConfiguration {
    return {
        type: ActionTypes.GetCheckoutConfiguration,
        payload: {
            accountId: accountId,
            profileId: profileId,
        },
    };
}

export interface FetchCheckoutConfigurationResponse {
    type: ActionTypes.FetchCheckoutConfigurationResponse;
    payload: {
        configuration: CheckoutConfig;
        account: Account;
        profile: ProfileConfiguration;
    };
}

export interface EditPaymentProfile {
    type: ActionTypes.EditPaymentProfile;
    payload: {
        accountId: string;
        profileId: string;
        newProfile: NewProfileConfiguration;
    };
}
export function editPaymentProfile(
    accountId: string,
    profileId: string,
    newProfile: NewProfileConfiguration,
): EditPaymentProfile {
    return {
        type: ActionTypes.EditPaymentProfile,
        payload: {
            accountId,
            profileId,
            newProfile,
        },
    };
}

export interface FetchEditPaymentProfileResponse {
    type: ActionTypes.FetchEditPaymentProfileResponse;
    payload: {
        accountId: string;
        paymentProfile: ProfileConfiguration;
    };
    meta: {
        cause: EditPaymentProfile;
    };
}

export type Actions =
    | GetCheckoutConfiguration
    | FetchCheckoutConfigurationResponse
    | EditPaymentProfile
    | FetchEditPaymentProfileResponse
    | ResetForm;
