import styled from "styled-components/macro";
import { palette } from "../../../styles/constants";
import useAuth from "../hooks/useAuth";

interface AllowPartnerProps {
    children: JSX.Element;
    fallback?: JSX.Element;
    visibleFrame?: boolean;
    partnerId?: string;
    onlyDinteroAdmin?: boolean;
    useBackgroundColor?: boolean;
}

const AllowPartner = ({
    children,
    fallback,
    visibleFrame,
    partnerId,
    onlyDinteroAdmin,
    useBackgroundColor = true,
}: AllowPartnerProps) => {
    const exchangeTokenPartnerId = useAuth(
        (state) => state.exchangeTokenPartnerId,
    );
    if (
        exchangeTokenPartnerId &&
        (!partnerId || partnerId === exchangeTokenPartnerId) &&
        (!onlyDinteroAdmin || exchangeTokenPartnerId === "P00000000")
    ) {
        if (visibleFrame) {
            return (
                <AllowPartnerFrame data-use-bg={useBackgroundColor}>
                    <PartnerFrameMessage>
                        {exchangeTokenPartnerId === "P00000000"
                            ? "admin:dintero"
                            : "admin:partner"}
                    </PartnerFrameMessage>
                    {children}
                </AllowPartnerFrame>
            );
        }
        return children;
    }
    return fallback || null;
};

const AllowPartnerFrame = styled.div`
    border: 1px solid ${palette.destructive[500]};
    border-radius: 4px;
    padding: 8px;
    &[data-use-bg='true'] {
        background: rgba(189, 4, 75, 0.01);
    }
    position: relative;
    margin: 16px -8px;
`;

const PartnerFrameMessage = styled.div`
    color: ${palette.destructive[500]};
    position: absolute;
    top: -20px;
    left: 8px;
    font-size: 11px;
`;

export default AllowPartner;
