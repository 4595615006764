import qs from "qs";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router";

type SetValueCallback<T extends Record<string, unknown>> =
    | Partial<T>
    | ((prev: Partial<T> & { [key: string]: any }) => Partial<T>);
export type UseUrlSearchReturn<T extends Record<string, unknown>> = readonly [
    value: Partial<T> & { [key: string]: any },
    setValue: (valueOrCallback: SetValueCallback<T>) => void,
];

/**
 * TODO: Can be removed in favor of `useSearchParams` from react-router v6 once we upgrade
 * @returns Partial of T since user can at any time add/remove properties of the search
 */
export const useUrlSearchState = <
    T extends Record<string, unknown> = Record<string, any>,
>(): UseUrlSearchReturn<T> => {
    const history = useHistory();
    const location = useLocation();
    const value = useMemo(
        () =>
            qs.parse(location.search, {
                ignoreQueryPrefix: true,
            }) as Partial<T>,
        [location.search],
    );

    const setValue = useCallback(
        (valueOrCallback: SetValueCallback<T>) => {
            const next =
                typeof valueOrCallback === "function"
                    ? valueOrCallback(value)
                    : valueOrCallback;
            // setting the new value in the location, not setValueInternal as that state is updated by the effect
            history.push(
                location.pathname +
                    qs.stringify(next, {
                        addQueryPrefix: true,
                        indices: false,
                    }),
            ); // indices false for interop with UrlSearchParams
        },
        [history, location.pathname, value],
    );

    return [value, setValue] as const;
};
