import { useAccountId } from "../../../../auth/useAccountId";
import useStore from "../../../../common/hooks/useExternalStore";
import useAccount from "../../../account/hooks/useAccount";
import { newClientStore } from "../observables/newClientStore";

export const useNewClient = () => {
    const accountId = useAccountId();
    const { account } = useAccount();
    const { state } = useStore(newClientStore);

    return {
        ...state,
        accountId,
        account,
    };
};
