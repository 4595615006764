import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { namespace } from "..";
import { useActions } from "../../../../Actions";
import { useAccountIds } from "../../../../auth/accessToken/components/withAccountIds";
import { getUserId } from "../../../../auth/accessToken/selectors";
import { Button, ButtonGroup } from "../../../../components/Buttons";
import { Checkbox, Input, ValidationState } from "../../../../components/Forms";
import { P } from "../../../../components/Typography";
import View from "../../../../components/View";
import { getValueAt } from "../../../../helpers/getValueAt";
import {
    validate,
    validateHexadecimal,
    validateLength,
    validateRequired,
    validateUuid,
} from "../../../../helpers/validation";
import { distances, palette } from "../../../../styles/constants";

interface ValidatedInputProps {
    values: any;
    initialValues: any;
    errors: any;
    path: "payee_id" | "token";
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    touched: any;
    required?: boolean;
    maxlength?: number;
    formatter?: (value: string) => string;
}

const TranslatedValidatedInput = ({
    values,
    initialValues,
    errors,
    path,
    onChange,
    onBlur,
    touched,
    required,
    maxlength,
}: ValidatedInputProps) => {
    const { t } = useTranslation();
    const value = getValueAt(path, values);
    const initialValue = getValueAt(path, initialValues);
    const error = getValueAt(path, errors);
    const was_touched = getValueAt(path, touched);
    const validation =
        (was_touched || initialValue) && error
            ? {
                  state: ValidationState.Invalid,
                  message: error,
              }
            : undefined;
    const label = t(
        `settings.payment_connections.payex_callback_modal.fields.${path}`,
    );
    return (
        <Input
            label={label}
            placeholder={t(
                `settings.payment_connections.payex_callback_modal.placeholders.${path}`,
            )}
            type="text"
            name={path}
            value={value || ""}
            onChange={(e) => {
                onChange(e);
            }}
            onBlur={(e) => {
                onChange(e);
                onBlur(e);
            }}
            validation={validation}
            maxlength={maxlength}
            required={required}
        />
    );
};

export default function PayExDirectForm() {
    const { t } = useTranslation();
    const { optimisticProdAccountId } = useAccountIds();
    const { closePayExModal, finishRegistration } = useActions(namespace);
    const userId = useSelector((state) => getUserId(state));
    const initialValues = {
        payee_id: "",
        token: "",
        payment_options: [
            {
                type: "payex.creditcard",
                enabled: false,
                currencies: [
                    {
                        currency: "NOK",
                        enabled: false,
                    },
                    {
                        currency: "SEK",
                        enabled: false,
                    },
                    {
                        currency: "DKK",
                        enabled: false,
                    },
                    {
                        currency: "EUR",
                        enabled: false,
                    },
                ],
            },
            // TODO: Enable these when they are supported by the API
            // {
            //     type: 'payex.vipps',
            //     enabled: false,
            //     currencies: [
            //         {
            //             currency: 'NOK',
            //             enabled: false,
            //         },
            //     ],
            // },
            // {
            //     type: 'payex.swish',
            //     enabled: false,
            //     currencies: [
            //         {
            //             currency: 'SEK',
            //             enabled: false,
            //         },
            //     ],
            // },
            // {
            //     type: 'payex.mobilepay',
            //     enabled: false,
            //     currencies: [
            //         {
            //             currency: 'DKK',
            //             enabled: false,
            //         },
            //         {
            //             currency: 'EUR',
            //             enabled: false,
            //         },
            //     ],
            // },
            // {
            //     type: 'payex.applepay',
            //     enabled: false,
            //     currencies: [
            //         {
            //             currency: 'NOK',
            //             enabled: false,
            //         },
            //         {
            //             currency: 'SEK',
            //             enabled: false,
            //         },
            //         {
            //             currency: 'DKK',
            //             enabled: false,
            //         },
            //         {
            //             currency: 'EUR',
            //             enabled: false,
            //         },
            //     ],
            // },
            // {
            //     type: 'payex.googlepay',
            //     enabled: false,
            //     currencies: [
            //         {
            //             currency: 'NOK',
            //             enabled: false,
            //         },
            //         {
            //             currency: 'SEK',
            //             enabled: false,
            //         },
            //         {
            //             currency: 'DKK',
            //             enabled: false,
            //         },
            //         {
            //             currency: 'EUR',
            //             enabled: false,
            //         },
            //     ],
            // },
            // {
            //     type: 'payex.clicktopay',
            //     enabled: false,
            //     currencies: [
            //         {
            //             currency: 'NOK',
            //             enabled: false,
            //         },
            //         {
            //             currency: 'SEK',
            //             enabled: false,
            //         },
            //         {
            //             currency: 'DKK',
            //             enabled: false,
            //         },
            //         {
            //             currency: 'EUR',
            //             enabled: false,
            //         },
            //     ],
            // },
        ],
    };
    const onSubmit = async (values: typeof initialValues) => {
        await finishRegistration({
            accountId: optimisticProdAccountId,
            userId,
            body: {
                payee_id: values.payee_id.trim(),
                payment_options: values.payment_options
                    .filter((x) => x.enabled)
                    .map((option) => ({
                        type: option.type,
                        currencies: option.currencies
                            .filter((currency) => currency.enabled)
                            .map((currency) => currency.currency),
                    })),
                token: values.token.trim(),
            },
        });
    };
    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
            validate={(values) => {
                let errors: { [key: string]: string } = {};
                if (!values) {
                    // this should not happen but...
                    return { error: "account not loaded yet..." };
                }
                const validators = [
                    {
                        path: "token",
                        validator: validateHexadecimal(
                            t(
                                "settings.payment_connections.payex_callback_modal.invalid.token.invalid",
                            ),
                        ),
                    },
                    {
                        path: "token",
                        validator: validateLength(
                            64,
                            t(
                                "settings.payment_connections.payex_callback_modal.invalid.token.invalid",
                            ),
                        ),
                    },
                    {
                        path: "token",
                        validator: validateRequired(
                            t(
                                "settings.payment_connections.payex_callback_modal.invalid.token.required",
                            ),
                        ),
                    },
                    {
                        path: "payment_options",
                        validator: (
                            value: {
                                type: string;
                                enabled: boolean;
                                currencies: {
                                    currency: string;
                                    enabled: boolean;
                                }[];
                            }[],
                        ) => {
                            const enabledPaymentOptions = value.filter(
                                (option) => option.enabled,
                            );
                            if (enabledPaymentOptions.length === 0) {
                                return "At least one payment option must be enabled";
                            }
                            if (
                                enabledPaymentOptions.some(
                                    (option) =>
                                        option.currencies.filter(
                                            (currency) => currency.enabled,
                                        ).length === 0,
                                )
                            ) {
                                return "At least one currency must be enabled for each payment option";
                            }
                            return;
                        },
                    },
                    {
                        path: "payee_id",
                        validator: validateUuid(
                            t(
                                "settings.payment_connections.payex_callback_modal.invalid.payee_id.invalid",
                            ),
                        ),
                    },
                    {
                        path: "payee_id",
                        validator: validateRequired(
                            t(
                                "settings.payment_connections.payex_callback_modal.invalid.payee_id.required",
                            ),
                        ),
                    },
                ];

                errors = validators.reduce((acc, elem) => {
                    return validate(elem.path, elem.validator, values, acc);
                }, errors);

                return errors;
            }}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
            }) => (
                <form onSubmit={handleSubmit}>
                    <TranslatedValidatedInput
                        path="payee_id"
                        initialValues={initialValues}
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        required
                    />
                    <TranslatedValidatedInput
                        path="token"
                        initialValues={initialValues}
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        required
                    />
                    <View
                        direction="column"
                        width="100%"
                        gap={distances.nano}
                        alignItems="flex-start"
                        pb={distances.normal}
                    >
                        <P>
                            {t(
                                "settings.payment_connections.payex_modal.fields.payment_options_enabled",
                            )}
                        </P>
                        <View direction="column" width="100%">
                            {values.payment_options.map(
                                (paymentOption, index) => (
                                    <CheckboxSection key={index}>
                                        <Checkbox
                                            name={`payment_options[${index}].enabled`}
                                            label={paymentOption.type}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            checked={paymentOption.enabled}
                                        />
                                        {paymentOption.enabled && (
                                            <CheckboxSection inset>
                                                <P>
                                                    {t(
                                                        "settings.payment_connections.payex_modal.fields.currencies",
                                                    )}
                                                </P>
                                                <CheckboxGroup>
                                                    {paymentOption.currencies.map(
                                                        (
                                                            currency,
                                                            currencyIndex,
                                                        ) => (
                                                            <Checkbox
                                                                key={
                                                                    currencyIndex
                                                                }
                                                                name={`payment_options[${index}].currencies[${currencyIndex}].enabled`}
                                                                label={
                                                                    currency.currency
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                checked={
                                                                    currency.enabled
                                                                }
                                                            />
                                                        ),
                                                    )}
                                                </CheckboxGroup>
                                            </CheckboxSection>
                                        )}
                                    </CheckboxSection>
                                ),
                            )}
                        </View>
                        {errors.payment_options && (
                            <P color={palette.destructive[500]}>
                                {errors.payment_options}
                            </P>
                        )}
                    </View>
                    <ButtonGroup>
                        <Button
                            className="alt"
                            type="button"
                            onClick={closePayExModal}
                        >
                            {t(
                                "settings.payment_connections.payex_modal.cancel",
                            )}
                        </Button>
                        <Button type="submit">
                            {t(
                                "settings.payment_connections.payex_modal.setup_payex",
                            )}
                        </Button>
                    </ButtonGroup>
                </form>
            )}
        </Formik>
    );
}

const CheckboxSection = styled.section<{ inset?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: ${distances.tiny};
    width: 100%;
    ${({ inset }) =>
        !inset &&
        `
        border-bottom: 1px solid ${palette.neutral[300]};
        padding-block: ${distances.small};
        &:last-child {
            border-bottom: none;
        }
    `}
    ${({ inset }) => inset && `padding-left: 24px;`}
`;

const CheckboxGroup = styled.div`
    display: flex;
    gap: ${distances.tiny};
`;
