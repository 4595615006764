import React from "react";
import styled from "styled-components/macro";

import { WithTranslation, withTranslation } from "react-i18next";
import { InputValidation } from "../../../../components/Forms";
import Dropdown, {
    DropdownOption,
} from "../../../../components/Forms/Dropdown";
import { colors, distances } from "../../../../styles/constants";

import { Label } from "../../../../components/Typography";

export interface AgreementTypeDropdownProps extends WithTranslation {
    value: string;
    name: string;
    label: string;
    disabled: boolean;
    onChange: (value: string) => void;
    validation?: InputValidation;
}

const BamboraAgreementTypeDropdown = ({
    label,
    name,
    value,
    onChange,
    validation,
    disabled,
    t,
}: AgreementTypeDropdownProps) => {
    const optionValues = ["payfac", "direct"];

    const options: DropdownOption[] = optionValues.map((value) => ({
        label: t(
            `settings.payment_connections.bambora_modal.agreement_type_options.${value}`,
        ),
        value,
    }));

    const selectedOption = options.find((option) => option.value === value);

    return (
        <>
            <Label htmlFor={name}>{label}</Label>
            {selectedOption && (
                <Dropdown
                    name={name}
                    value={selectedOption}
                    options={options}
                    disabled={disabled}
                    onChange={(val) => {
                        onChange(val.value);
                    }}
                />
            )}
            {validation && validation.message && (
                <Message htmlFor={name} className={validation.state}>
                    {validation.message}
                </Message>
            )}
        </>
    );
};

const Message = styled.label`
    position: absolute;
    top: 66px;
    left: 0;
    font-size: 11px;
    line-height: ${distances.small};

    &.invalid {
        color: ${colors.invalid};
    }

    &.valid {
        color: ${colors.valid};
    }
`;

export default withTranslation()(BamboraAgreementTypeDropdown);
