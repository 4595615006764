import { Dispatch } from "redux";
import apiRequest, { isApiError } from "../../../apiRequest";
import { createAccountIdChangedCaseReducer } from "../../../auth/useAccountId";
import { CORE_API_HOSTNAME } from "../../../env";
import { Causes, errorExternalStore } from "../../../errors";
import { Account } from "../../../types/management-auth";
import { KlarnaConnectionSignup } from "../../../types/management-auth/generated";
import { createSwitchlessReducer } from "../../../util/switchlessReducer";
import { getAccount } from "../connections/actions";

export const namespace = "connections.klarna";

export enum ModalState {
    Closed = "Closed",
    InitiateApplicationLoading = "InitiateApplicationLoading",
    ApplicationFormOpened = "ApplicationFormOpened",
    ApplicationReceived = "ApplicationReceived",
}

export interface State {
    modalState: ModalState;
    account: Account | undefined;
}

export const initialState: State = {
    modalState: ModalState.Closed,
    account: undefined,
};

export const { reducer, actions } = createSwitchlessReducer({
    namespace: namespace,
    initialState,
    reducers: {
        openModal: (state: State, account: Account) => {
            return {
                ...state,
                modalState: ModalState.ApplicationFormOpened,
                account: account,
            };
        },
        closeModal: (state: State) => {
            return {
                ...state,
                modalState: ModalState.Closed,
            };
        },
        postConnection: (state) => {
            return {
                ...state,
                modalState: ModalState.InitiateApplicationLoading,
            };
        },
        postConnectionSuccess: (state) => {
            return {
                ...state,
                modalState: ModalState.ApplicationReceived,
            };
        },
    },
    globalReducer: createAccountIdChangedCaseReducer(() => initialState),
});

export const createConnectedActions = (dispatch: Dispatch) => {
    const openModal = (account: Account) => {
        dispatch(actions.openModal(account));
    };

    const closeModal = () => {
        dispatch(actions.closeModal());
    };

    const signupForConnection = async (
        account_id: string,
        data: KlarnaConnectionSignup,
    ) => {
        const url = `${CORE_API_HOSTNAME}/v1/accounts/${account_id}/management/settings/connections/klarna`;
        dispatch(actions.postConnection());

        try {
            await apiRequest("POST", account_id, url, {}, { signup: data });
            dispatch(actions.postConnectionSuccess());
            dispatch(getAccount(account_id));
        } catch (err) {
            console.error(err);
            dispatch(actions.closeModal());
            if (isApiError(err)) {
                errorExternalStore.dispatch("setError", {
                    cause: Causes.UnhandledStatus,
                    status: err.status,
                    statusText: err.statusText,
                    message: err.message,
                    "request-id": err.requestId || err.traceId,
                });
            } else {
                throw err;
            }
        }
    };
    return {
        openModal,
        closeModal,
        signupForConnection,
    };
};
