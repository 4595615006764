import {
    reducer as newPayoutRule,
    namespace as newPayoutRuleNamespace,
} from "./newPayoutRule";
import {
    reducer as payoutRuleDetails,
    namespace as payoutRuleDetailsNamespace,
} from "./payoutRuleDetails";
import {
    reducer as payoutRuleEdit,
    namespace as payoutRuleEditNamespace,
} from "./payoutRuleEdit";
import {
    reducer as payoutRules,
    namespace as payoutRulesNamespace,
} from "./payoutRules";

export interface State {
    [payoutRulesNamespace]: payoutRules.State;
    [newPayoutRuleNamespace]: newPayoutRule.State;
    [payoutRuleEditNamespace]: payoutRuleEdit.State;
    [payoutRuleDetailsNamespace]: payoutRuleDetails.State;
}

export const initialState: State = {
    [payoutRulesNamespace]: payoutRules.initialState,
    [newPayoutRuleNamespace]: newPayoutRule.initialState,
    [payoutRuleEditNamespace]: payoutRuleEdit.initialState,
    [payoutRuleDetailsNamespace]: payoutRuleDetails.initialState,
};

export const reducers = {
    [payoutRulesNamespace]: payoutRules.reducer,
    [newPayoutRuleNamespace]: newPayoutRule.reducer,
    [payoutRuleEditNamespace]: payoutRuleEdit.reducer,
    [payoutRuleDetailsNamespace]: payoutRuleDetails.reducer,
};
