import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { Button } from "../../../components/Buttons";
import type { ServiceFeatureStatus } from "../../../types/management-auth/generated";

type SplitPaymentToggleButtonProps = {
    status?: ServiceFeatureStatus;
    onClick: () => void;
    disabled: boolean;
};

export default function SplitPaymentToggleButton({
    status,
    onClick,
    disabled,
}: SplitPaymentToggleButtonProps) {
    const { t } = useTranslation();
    if (status === "pending") {
        return (
            <BoxInner>
                <Button className="large outlined" disabled>
                    {t(
                        "settings.additional_services.split_payment.enable_in_progress",
                    )}
                </Button>
            </BoxInner>
        );
    }
    if (["declined", "enabled"].includes(status || "")) {
        return null;
    }
    return (
        <BoxInner>
            <Button
                className="large outlined"
                disabled={disabled}
                onClick={onClick}
            >
                {t("settings.additional_services.split_payment.enable")}
            </Button>
        </BoxInner>
    );
}

const BoxInner = styled.div.attrs((props) => ({
    ...props,
    className: ["inner", props.className].filter((x) => x).join(" "),
}))`
    display: flex;
    flex-direction: column;
`;
