import { Route } from "react-router-dom";

import ClientPage from "./clientDetails/components/ClientPage";
import ClientList from "./clients/components/ClientList";
import NewCheckoutClientForm from "./newClient/components/NewCheckoutClientForm";
import NewClientForm from "./newClient/components/NewClientForm";
import SelectClientForm from "./newClient/components/SelectClientForm";
import { NewClientGrant } from "./newClientGrant";

const ClientRoutes = [
    <Route
        key="clients"
        path="/:accountId/settings/clients"
        exact
        component={ClientList}
    />,
    <Route
        key="new_client"
        path="/:accountId/settings/clients/new"
        exact
        component={NewClientForm}
    />,
    <Route
        key="new_checkout_client"
        path="/:accountId/settings/clients/new-checkout"
        exact
        component={NewCheckoutClientForm}
    />,
    <Route
        key="select_client_form"
        path="/:accountId/settings/clients/select"
        exact
        component={SelectClientForm}
    />,
    <Route
        key="client_details"
        path="/:accountId/settings/clients/:clientId"
        exact
        component={ClientPage}
    />,
    <Route
        key="new_client_grant"
        path="/:accountId/settings/clients/:clientId/grants/new"
        exact
        component={NewClientGrant}
    />,
];

export default ClientRoutes;
