import _keyBy from "lodash/keyBy";
import qs from "qs";
import type { Dispatch } from "redux";
import apiRequest, { isApiError } from "../../apiRequest";
import { createAccountIdChangedCaseReducer } from "../../auth/useAccountId";
import { CORE_API_HOSTNAME } from "../../env";
import { Causes, errorExternalStore } from "../../errors";
import type { SalesLocation } from "../../types/customers";
import { createSwitchlessReducer } from "../../util/switchlessReducer";

export type SalesLocationQuery = {
    /**
     * 1..100, default is 10
     */
    limit: number;
    /**
     * location_id of the last location in the previous page
     */
    starting_after?: string;

    /**
     * search term matching name, phone_number, email, organization_number or business_name
     */
    search?: string;

    status?: string[];

    country?: string[];

    phone_number?: string[];

    organization_number?: string[];

    email?: string[];

    type?: string[];
};

export type SalesLocationsState = {
    /**
     * keyed by accountId
     */
    isLoading: Record<string, boolean>;
    /**
     * keyed by accountId then salesLocation.location_id
     */
    locations: Record<string, Record<string, SalesLocation>>;
    /**
     * keyed by accountId, contains array of location_id's
     */
    queriedIds: Record<string, ReadonlyArray<string>>;
    /**
     * keyed by accountId, contains last query for that accountId
     */
    queries: Record<string, SalesLocationQuery>;
    /**
     * key = accountId, value = last location_id of query results if result.length === query.limit
     */
    nextPageStartingAfter: Record<string, string | undefined>;
};

const initialState: SalesLocationsState = {
    isLoading: {},
    locations: {},
    queries: {},
    queriedIds: {},
    nextPageStartingAfter: {},
};

const namespace = "settings.locations" as const;

const { setState, reducer } = createSwitchlessReducer({
    initialState,
    namespace,
    globalReducer: createAccountIdChangedCaseReducer(() => initialState),
});

const setError = (err: any) => {
    if (isApiError(err)) {
        return errorExternalStore.dispatch("setError", {
            cause: Causes.UnhandledStatus,
            status: err.status,
            statusText: err.statusText,
            message: err.message,
            "request-id": err.requestId,
        });
    }
    return errorExternalStore.dispatch("setError", {
        cause: Causes.UnhandledStatus,
        message: err.message,
    });
};

const toggleLoading =
    (accountId: string) =>
    (state: SalesLocationsState): Partial<SalesLocationsState> => ({
        isLoading: {
            ...state.isLoading,
            [accountId]: !state.isLoading[accountId],
        },
    });

const createLocationActions = (dispatch: Dispatch, accountId: string) => {
    const baseUrl = `${CORE_API_HOSTNAME}/v1/accounts`;

    const getLocation = async (locationId: string, aid = accountId) => {
        dispatch(setState("getLocationAttempt", toggleLoading(aid)));
        try {
            const location = await apiRequest<SalesLocation>(
                "GET",
                aid,
                `${baseUrl}/${aid}/locations/${locationId}`,
            );
            dispatch(
                setState("getLocationSuccess", (state) => ({
                    ...state,
                    ...toggleLoading(aid)(state),
                    locations: {
                        ...state.locations,
                        [aid]: {
                            ...state.locations[aid],
                            [location.location_id!]: location,
                        },
                    },
                })),
            );
        } catch (err) {
            dispatch(setState("getLocationFailure", toggleLoading(aid)));
            errorExternalStore.dispatch("setError", err);
        }
    };

    const getLocations = async (query: SalesLocationQuery, aid = accountId) => {
        dispatch(setState("getLocationsAttempt", toggleLoading(aid)));
        const loadingMore = !!query.starting_after;
        try {
            const locations = await apiRequest<SalesLocation[]>(
                "GET",
                aid,
                `${baseUrl}/${aid}/locations/?${qs.stringify(query)}`,
            );
            dispatch(
                setState("getLocationsSuccess", (state) => ({
                    ...state,
                    ...toggleLoading(aid)(state),
                    locations: {
                        ...state.locations,
                        [aid]: {
                            ...state.locations[aid],
                            ..._keyBy(locations, "location_id"),
                        },
                    },
                    queriedIds: {
                        ...state.queriedIds,
                        [aid]: [
                            ...(loadingMore
                                ? (state.queriedIds[aid] ?? [])
                                : []),
                            ...locations.map((l) => l.location_id!),
                        ],
                    },
                    queries: {
                        ...state.queries,
                        [aid]: query,
                    },
                    nextPageStartingAfter: {
                        ...state.nextPageStartingAfter,
                        [aid]:
                            locations.length === query.limit
                                ? locations[locations.length - 1].location_id!
                                : undefined,
                    },
                })),
            );
        } catch (err) {
            dispatch(setState("getLocationsFailure", toggleLoading(aid)));
            errorExternalStore.dispatch("setError", err);
        }
    };

    const createLocation = async (location: SalesLocation, aid = accountId) => {
        dispatch(setState("createLocationAttempt", toggleLoading(aid)));
        try {
            const created = await apiRequest<SalesLocation>(
                "POST",
                aid,
                `${baseUrl}/${aid}/locations`,
                undefined,
                location as any,
            );
            dispatch(
                setState("createLocationSuccess", (state) => ({
                    ...state,
                    ...toggleLoading(aid)(state),
                    locations: {
                        ...state.locations,
                        [aid]: {
                            ...state.locations[aid],
                            [created.location_id!]: created,
                        },
                    },
                })),
            );
        } catch (err) {
            dispatch(setState("createLocationFailure", toggleLoading(aid)));
            errorExternalStore.dispatch("setError", err);
        }
    };

    const updateLocation = async (location: SalesLocation, aid = accountId) => {
        dispatch(setState("updateLocationAttempt", toggleLoading(aid)));
        try {
            const updated = await apiRequest<SalesLocation>(
                "PUT",
                aid,
                `${baseUrl}/${aid}/locations/${location.location_id!}`,
                undefined,
                location as any,
            );
            dispatch(
                setState("updateLocationSuccess", (state) => ({
                    ...state,
                    ...toggleLoading(aid)(state),
                    locations: {
                        ...state.locations,
                        [aid]: {
                            ...state.locations[aid],
                            [updated.location_id!]: updated,
                        },
                    },
                })),
            );
            return true;
        } catch (err) {
            dispatch(setState("updateLocationFailure", toggleLoading(aid)));
            errorExternalStore.dispatch("setError", err);

            return false;
        }
    };

    const deleteLocation = async (locationId: string, aid = accountId) => {
        dispatch(setState("deleteLocationAttempt", toggleLoading(aid)));
        try {
            const deleted = await apiRequest<SalesLocation>(
                "DELETE",
                aid,
                `${baseUrl}/${aid}/locations/${locationId}`,
            );
            dispatch(
                setState("deleteLocationSuccess", (state) => ({
                    ...toggleLoading(aid)(state),
                    locations: {
                        ...state.locations,
                        [aid]: {
                            ...state.locations[aid],
                            [locationId]: deleted,
                        },
                    },
                    queriedIds: {
                        ...state.queriedIds,
                        [aid]: (state.queriedIds[aid] ?? []).filter(
                            (id) => id !== locationId,
                        ),
                    },
                })),
            );
            return true;
        } catch (err) {
            dispatch(setState("deleteLocationFailure", toggleLoading(aid)));
            errorExternalStore.dispatch("setError", err);

            return false;
        }
    };

    return {
        getLocation,
        getLocations,
        createLocation,
        updateLocation,
        deleteLocation,
    };
};

export { createLocationActions, initialState, namespace, reducer };
