import { Trans } from "react-i18next";
import { P } from "../../../components/Typography";
import type { Account } from "../../../types/management-auth";

export const ParagraphWaitingForReview = ({
    account,
}: { account: Account }) => {
    const country = account?.company?.address?.country || "";
    const key = ["DK", "SE"].includes(country)
        ? `settings.approvalsPayments.approvalsWaitingDeclaration.paragraph_review_${country.toLowerCase()}`
        : "settings.approvalsPayments.approvalsWaitingDeclaration.paragraph_review";
    // Trans component does not contain the "context" prop here.
    return (
        <Trans
            i18nKey={key as any}
            ns="app"
            components={[
                <P
                    fontSize={18}
                    fontWeight={600}
                    lineHeight={26}
                    letterSpacing={-0.375}
                    key="p"
                />,
                <P
                    as="li"
                    fontSize={16}
                    lineHeight={26}
                    fontWeight={400}
                    key="li"
                />,
            ]}
        />
    );
};
