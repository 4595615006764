import { AnyAction, Dispatch, Middleware } from "redux";
import type { State } from "./reducer";

/** A side effect function */
export type AnySideEffectFunction = (
    action: AnyAction,
    getState: () => State,
) => Promise<AnyAction>;
export type SideEffectFunction<T, V> = (
    action: T,
    getState: () => State,
) => Promise<V>;

/** Interface for side effects that can be added to the side effect middleware */
type SideEffect = AnySideEffectFunction | SideEffectFunction<any, any>;

export interface SideEffects {
    [key: string]: SideEffect | SideEffect[];
}

interface SideEffectsMiddleware extends Middleware {
    add(sideEffects: SideEffects): void;
}

/** Create an instance of the sideEffects redux middleware */
function createSideEffects() {
    interface AllSideEffects {
        [type: string]: Array<AnySideEffectFunction>;
    }

    //  A list of side SideEffectFunction per action type
    const allSideEffects: AllSideEffects = {};

    const middleware: SideEffectsMiddleware =
        ({ dispatch, getState }) =>
        (next: Dispatch) =>
        (action) => {
            if (!action) {
                // If we dispatch an undefined action, return current state.
                return getState();
            }
            const returnValue = next(action);

            // Get all side effects that are added for the current action
            const typeSideEffects = allSideEffects[action.type] || [];

            // For every side effect, run it and dispatch the result from the promises
            typeSideEffects.forEach((sideEffect) => {
                // Side effect returns a promise with a
                Promise.resolve(sideEffect(action, getState)).then((result) => {
                    // Dispatch the side effect result of type AnyAction
                    dispatch(result);
                });
            });
            return returnValue;
        };

    middleware.add = (sideEffects: SideEffects) => {
        Object.entries(sideEffects).forEach(([key, value]) => {
            const valueArray = Array.isArray(value) ? value : [value];
            if (key in allSideEffects) {
                allSideEffects[key] = allSideEffects[key].concat(valueArray);
            } else {
                allSideEffects[key] = valueArray;
            }
        });
    };

    return middleware;
}

export { createSideEffects };
