import decode from "jwt-decode";
import { CORE_API_HOSTNAME } from "../../env";
import fulfill from "../../fulfill";
import { parseISOStrictOr } from "../../helpers/date";
import type { AuthenticatedAccountUser } from "../../types/management-auth";
import { authenticate } from "../cognito";
import type { AuthenticatedUser } from "../types";
import authStore from "./observables/authStore";

/**Get the account_id the most recently used account for an authenticatedUser  */
export const getInitialAccount = (accountUser: AuthenticatedAccountUser) => {
    const mostRecentAccount = accountUser.accounts.reduce(
        (accumulator, account) => {
            if (!accumulator) {
                return account;
            }
            const accumulatorTimestamp = parseISOStrictOr(
                accumulator.user.last_seen_at,
                new Date(0),
            );
            const accountTimestamp = parseISOStrictOr(
                account.user.last_seen_at,
                new Date(0),
            );

            if (accountTimestamp > accumulatorTimestamp) {
                return account;
            }
            return accumulator;
        },
    );

    const mostRecentProdAccountId = `P${mostRecentAccount.account_id.substr(1)}`;
    const mostRecentProdAccount = accountUser.accounts.find(
        (account) => account.account_id === mostRecentProdAccountId,
    );
    return mostRecentProdAccount
        ? mostRecentProdAccountId
        : mostRecentAccount.account_id;
};

export const getAccountUserScope = (
    accountId: string,
    user: AuthenticatedUser,
) => {
    const account = user.account_user.accounts.find(
        (account) => account.account_id === accountId,
    );
    if (!accountId || !account) {
        return [];
    }
    return account.user.scope || [];
};

export const hasAnyOfScopes = (
    accountId: string,
    user: AuthenticatedUser,
    scope: string[][],
): boolean => {
    const userAccountScope = getAccountUserScope(accountId, user);
    return isAllowed(scope, userAccountScope);
};

export const isAllowed = (
    allowedScopes: string[][],
    userScopes: string[],
): boolean => {
    return (
        allowedScopes
            .map((scopeArray) =>
                scopeArray.reduce((acc: boolean, singleScope: string) => {
                    return acc && userScopes.includes(singleScope);
                }, true),
            )
            .find((result) => result === true) || false
    );
};

export const decodeTokenScopes = (token: string | undefined) => {
    if (!token) return [];
    const decoded = decode(token) as { scopes: string } | undefined;
    if (!decoded || !decoded.scopes) return [];
    return decoded.scopes.split(" ") as string[];
};

export const inspectAccount = async (
    accountId: string,
    subAccountId: string,
) => {
    const result: {
        access_token: string;
    } | null = await fulfill.post({
        url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/auth/exchange_token`,
        accountId: accountId,
        json: {
            account_id: subAccountId,
        },
        handlers: {
            200: (response: { access_token: string }) => response,
            403: () => null,
        },
    });

    if (result) {
        const authenticatedUser = await authenticate({
            cognito_expires: "0",
            cognito_access_token: result.access_token,
        });
        if (authenticatedUser) {
            authStore.dispatch("setInspectAuthenticatedUser", {
                accountUser: authenticatedUser,
                exchangeTokenPartnerId: accountId,
            });
        }
    }
};
