import { Route } from "react-router-dom";

import { AccountDetails } from "./accountDetails";
import { EditAccount } from "./editAccount";

const Routes = [
    <Route
        key="account_details"
        path="/:accountId/settings/account"
        exact
        component={AccountDetails}
    />,
    <Route
        key="account_edit_user"
        path="/:accountId/settings/account/edit"
        exact
        component={EditAccount}
    />,
];

export default Routes;
