import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useActions } from "../../../../Actions";
import { useAccountIds } from "../../../../auth/accessToken/components/withAccountIds";
import { getUserId } from "../../../../auth/accessToken/selectors";
import Button from "../../../../components/Buttons/Button";
import ButtonGroup from "../../../../components/Buttons/ButtonGroup";
import Checkbox from "../../../../components/Forms/Checkbox";
import { H4Label } from "../../../../components/Typography";
import { validateRequired } from "../../../../helpers/validation/required";
import { validate } from "../../../../helpers/validation/validate";
import { CollectorConnectionCallback } from "../../../../types/management-auth/generated";
import { PatchedCollectorConnectionCallback } from "../actions";
import { getPasswordlessUsernamesKey } from "../utils";
import { Row, SpacedWrapper, countryMapping } from "./CollectorCallbackModal";

interface FormProps {
    currencies: string[];
}

const SUB_MERCHANT_CODES = {
    NOK: {
        B2C: "9508",
        B2B: "9509",
    },
    SEK: {
        B2C: "9506",
        B2B: "9507",
    },
};

const SubMerchantForm = () => {
    const { t } = useTranslation();
    const { prodAccountId } = useAccountIds();
    const { confirmConnection, closeModal } = useActions(
        "connections.collector_callback",
    );
    const { userId } = useSelector((state) => {
        return {
            userId: getUserId(state),
        };
    });

    const initialFormValues: FormProps = {
        currencies: [],
    };

    const {
        values,
        errors,
        handleChange,
        handleBlur,
        touched,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        enableReinitialize: true,
        initialValues: initialFormValues,
        validate: (values) => {
            let errors: { [key: string]: string } = {};
            if (!values) {
                // this should not happen but...
                return { error: "account not loaded yet..." };
            }

            const validators = [
                {
                    path: "currencies",
                    validator: validateRequired(
                        t(
                            "settings.payment_connections.collector_callback_modal.invalid.currencies.required",
                        ),
                    ),
                },
            ];

            errors = validators.reduce((acc, elem) => {
                return validate(elem.path, elem.validator, values, acc);
            }, errors);

            return errors;
        },
        onSubmit: (values) => {
            const paymentOptions: CollectorConnectionCallback["payment_options"] =
                [
                    {
                        currencies: values.currencies,
                        type: "collector.invoice",
                    },
                    {
                        currencies: values.currencies,
                        type: "collector.invoice_b2b",
                    },
                    {
                        currencies: values.currencies,
                        type: "collector.installment",
                    },
                ];
            const stores: CollectorConnectionCallback["stores"] =
                values.currencies.reduce(
                    (a, currency) => {
                        const country =
                            countryMapping[
                                currency as keyof typeof countryMapping
                            ];
                        const storeIdB2C = parseInt(
                            SUB_MERCHANT_CODES[
                                currency as keyof typeof SUB_MERCHANT_CODES
                            ].B2C,
                        );
                        const storeIdB2B = parseInt(
                            SUB_MERCHANT_CODES[
                                currency as keyof typeof SUB_MERCHANT_CODES
                            ].B2B,
                        );
                        return [
                            ...(a || []),
                            {
                                country,
                                store_id: storeIdB2C,
                                currency:
                                    currency as keyof typeof countryMapping,
                                segment: "B2C",
                            },
                            {
                                country,
                                store_id: storeIdB2B,
                                currency:
                                    currency as keyof typeof countryMapping,
                                segment: "B2B",
                            },
                        ];
                    },
                    [] as CollectorConnectionCallback["stores"],
                );
            const subMerchantUsername =
                getPasswordlessUsernamesKey() === "test"
                    ? "dintero_api"
                    : "dintero_SM";
            const payload: PatchedCollectorConnectionCallback = {
                username: subMerchantUsername,
                payment_options: paymentOptions,
                stores,
            };
            confirmConnection(prodAccountId!, userId, payload);
        },
    });

    if (!prodAccountId) {
        return null;
    }

    return (
        <form onSubmit={handleSubmit}>
            <SpacedWrapper>
                <H4Label>
                    {t(
                        "settings.payment_connections.collector_callback_modal.fields.currency",
                    )}
                </H4Label>
                <Row>
                    <Checkbox
                        name="currencies-nok"
                        label={"NOK"}
                        checked={values.currencies.includes("NOK")}
                        onChange={(e) => {
                            if (values.currencies.includes("NOK")) {
                                setFieldValue(
                                    "currencies",
                                    values.currencies.filter(
                                        (currency) => currency !== "NOK",
                                    ),
                                );
                            } else {
                                setFieldValue("currencies", [
                                    ...values.currencies,
                                    "NOK",
                                ]);
                            }
                        }}
                    />
                    <Checkbox
                        name="currencies-sek"
                        label={"SEK"}
                        checked={values.currencies.includes("SEK")}
                        onChange={(e) => {
                            if (values.currencies.includes("SEK")) {
                                setFieldValue(
                                    "currencies",
                                    values.currencies.filter(
                                        (currency) => currency !== "SEK",
                                    ),
                                );
                            } else {
                                setFieldValue("currencies", [
                                    ...values.currencies,
                                    "SEK",
                                ]);
                            }
                        }}
                    />
                </Row>
            </SpacedWrapper>
            <ButtonGroup>
                <Button className="alt" onClick={closeModal}>
                    {t(
                        "settings.payment_connections.collector_callback_modal.cancel",
                    )}
                </Button>
                <Button
                    type="submit"
                    disabled={
                        Object.keys(errors || {}).length > 0 ||
                        values.currencies.length === 0
                    }
                >
                    {t(
                        "settings.payment_connections.collector_callback_modal.send_callback_call_to_action",
                    )}
                </Button>
            </ButtonGroup>
        </form>
    );
};

export default SubMerchantForm;
