import { connect } from "react-redux";

import { getAccountUser } from "../../../../auth/accessToken/selectors";
import { State } from "../../../../reducer";
import { createPayoutRule, fetchLocations, resetForm } from "../actions";
import { getNewPayoutRuleSelector } from "../selectors";

import NewPayoutRule from "../components/NewPayoutRule";

const mapStateToProps = (state: State) => ({
    newPayoutRuleState: getNewPayoutRuleSelector(state),
    accountUser: getAccountUser(state),
});

const mapDispatchToProps = {
    createPayoutRule,
    resetForm,
    fetchLocations,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPayoutRule);
