import { TFunction } from "i18next";
import React, {} from "react";
import { withTranslation } from "react-i18next";

import CheckboxListFilter, {
    Option,
} from "../../../../../components/Filters/CheckboxListFilter";
import { FilterProps } from "./FilterProps";

export type StatusFilterProps = FilterProps;

const getStatusName = (t: TFunction, value: string) => {
    const key = `locations.location.status.${value || "undefined"}`;
    const translated = t(key);
    if (translated === key) {
        return value;
    }
    return translated;
};

const StatusFilter = ({ queryParams, t, onChange }: StatusFilterProps) => {
    const optionValues = ["active", "inactive"];

    const options: Option[] = optionValues.map((value) => ({
        label: t(`locations.location.status.${value}`),
        value,
    }));

    const values = queryParams.getAll("status");

    return (
        <CheckboxListFilter
            label={t("locations.filter.status.label", {
                count: values.length,
                value: values
                    .map((value) => getStatusName(t, value))
                    .join(", "),
            })}
            title={t("locations.filter.status.title")}
            name="status"
            values={values}
            options={options}
            onApply={(name, values) => {
                onChange({ [name]: values });
            }}
            onDelete={(name) => {
                onChange({ [name]: [] });
            }}
        />
    );
};

export default withTranslation()(StatusFilter);
