import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components/macro";

import {
    colors,
    defaultRadius,
    distances,
    grid,
    palette,
    shadows,
    zIndex,
} from "../../styles/constants";
import { Button, IconButton } from "../Buttons";
import Icon, { iconMap } from "../Icons";
import { H3 } from "../Typography";

export type OnChangeFunction = (event: Event) => void;

interface DownloadSelectProps extends WithTranslation {
    name: string;
    disabled?: boolean;
    options: {
        format: string;
        icon: keyof typeof iconMap;
        onClick: () => void;
    }[];
}

interface DownloadSelectState {
    open: boolean;
}

class DownloadSelect extends Component<
    DownloadSelectProps,
    DownloadSelectState
> {
    wrapperRef: any = null;

    constructor(props: DownloadSelectProps, context: any) {
        super(props, context);
        this.state = {
            open: false,
        };
        this.wrapperRef = React.createRef();
    }
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    setWrapperRef = (node: any) => {
        this.wrapperRef = node;
    };

    handleClick = (event: any) => {
        if (
            this.wrapperRef &&
            this.wrapperRef.contains &&
            !this.wrapperRef.contains(event.target)
        ) {
            this.setState({ open: false });
        }
    };

    toggleOpen = () => {
        this.setState({ open: !this.state.open });
    };

    render() {
        const { name, options, t } = this.props;
        const { open } = this.state;
        if (options.length === 0) {
            return null;
        }
        if (options.length === 1) {
            return (
                <FilterButton
                    id={name}
                    name={name}
                    onClick={options[0].onClick}
                    className="alt tiny"
                    title={t(
                        "components.filters.download_select.download_format",
                        {
                            format: options[0].format,
                        },
                    )}
                    tooltip={t(
                        "components.filters.download_select.download_format",
                        {
                            format: options[0].format,
                        },
                    )}
                >
                    <OffsetIcon icon="download" />
                </FilterButton>
            );
        }
        return (
            <Wrapper ref={this.setWrapperRef}>
                <FilterButton
                    id={name}
                    name={name}
                    onClick={this.toggleOpen}
                    className="alt"
                    title={t("components.filters.download_select.title")}
                    tooltip={t("components.filters.download_select.title")}
                >
                    <Icon icon="download" />
                </FilterButton>
                <Options open={open} onClick={this.toggleOpen}>
                    <H3>
                        {t(
                            "components.filters.download_select.select_a_format",
                        )}
                    </H3>
                    {options.map((option) => (
                        <IconButton
                            key={option.format}
                            className="alt stretch"
                            onClick={option.onClick}
                            title={t(
                                "components.filters.download_select.download_format",
                                {
                                    format: option.format,
                                },
                            )}
                            icon={<Icon icon={option.icon} />}
                        >
                            {t(
                                "components.filters.download_select.download_format",
                                {
                                    format: option.format,
                                },
                            )}
                        </IconButton>
                    ))}
                </Options>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    position: relative;
    display: inline-block;
`;

const FilterButton = styled(Button)<{ tooltip?: string }>`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: 40px;
    display: flex;
    align-items: center;
    &::before {
        content: '${(props) => props.tooltip || ""}';
        position: absolute;
        background: ${palette.neutral[900]};
        color: white;
        border-radius: 3px;
        padding: 4px 8px;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        z-index: 10;
        top: -100%;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.1s cubic-bezier(0.39, 0.575, 0.565, 1);
    }

    &::after {
        content: '';
        position: absolute;
        height: 10px;
        width: 10px;
        background: ${palette.neutral[900]};
        clip-path: polygon(0 0, 100% 0, 0 100%);
        z-index: 10;
        top: -17px;
        left: 50%;
        transform: translateX(-50%) rotate(226deg);
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.1s cubic-bezier(0.39, 0.575, 0.565, 1);
    }

    &:hover::before {
        opacity: 1;
    }

    &:hover::after {
        opacity: 1;
    }
`;

const OffsetIcon = styled(Icon)`
    top: -1px;
    position: relative;
`;

interface OptionsProps {
    open: boolean;
}
const Options = styled.ul<OptionsProps>`
    min-width: calc(${grid.spans.span2} + ${distances.small});
    list-style: none;
    margin: 0;
    position: absolute;
    right: 0;
    top: 40px;
    background-color: ${colors.background};
    box-shadow: ${shadows.large};
    transition: all 0.15s ease;
    transform-origin: top center;
    overflow: hidden;
    padding: ${distances.micro} ${distances.small12};
    border: 1px solid ${colors.borderDark};
    border-radius: ${defaultRadius};
    z-index: ${zIndex.top};
    ${(props) =>
        props.open
            ? `
        transform: translateY(0);
        max-height: 800px;
        opacity: 1;
    `
            : `
        transform: translateY(-1%);
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0;
        border-width: 0;
        opacity: 0;
    `};
    > button {
        white-space: nowrap;
        width: 100%;
        margin-bottom: ${distances.tiny};
        text-align: left;
    }

    > div > button {
        margin-bottom: ${distances.tiny};
    }
`;
export default withTranslation()<React.ComponentType<DownloadSelectProps>>(
    DownloadSelect,
);
