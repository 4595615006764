import deepmerge from "deepmerge";
import { Formik } from "formik";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Redirect, RouteComponentProps } from "react-router-dom";
import styled from "styled-components/macro";

import Card from "../../../../components/Card";
import {
    BackButton,
    Checkbox,
    Input,
    Radiobutton,
    ValidationState,
} from "../../../../components/Forms";
import { LoadingOverlay } from "../../../../components/Loading";
import Page, { Dismiss, PageButton } from "../../../../components/Page";
import { H1, H2, H3, P } from "../../../../components/Typography";
import {
    border,
    colors,
    distances,
    globalColumnMaxWidth,
    grid,
} from "../../../../styles/constants";

import AllowPartner from "../../../../auth/accessToken/components/AllowPartner";
import {
    AccountIdsProps,
    withAccountIds,
} from "../../../../auth/accessToken/components/withAccountIds";
import {
    Button,
    ButtonGroup,
    ButtonLink,
} from "../../../../components/Buttons";
import Hr from "../../../../components/Hr";
import Icon from "../../../../components/Icons";
import { getProfilePaymentTypeLabel } from "../../../../helpers/getProfilePaymentTypeLabel";
import { getValueAt } from "../../../../helpers/getValueAt";
import {
    validate,
    validateRequired,
    validateUrl,
} from "../../../../helpers/validation";
import {
    NewProfileConfiguration,
    PaymentConfiguration,
    ProfileConfiguration,
} from "../../../../types/checkout";
import AllowDifferentBillingShippingAddressDropdown from "../../../components/AllowDifferentBillingShippingAddressDropdown";
import CustomerTypeDropdown from "../../../components/CustomerTypeDropdown";
import ProfilePreview from "../../components/ProfilePreview";
import { smsOrPush } from "../../helpers";
import { State } from "../reducer";
const FALLBACK_MERCHANT_LOGO =
    "https://checkout.dintero.com/assets/images/merchants/default.png";

interface MatchParams {
    accountId: string;
}

const COUNTRIES = ["NO", "SE", "DK", "FI", "GB", "DE"];

const buildOption = (path: string[]): any => {
    const [first, ...rest] = path;
    if (rest.length === 0) {
        return {
            [first]: { enabled: true },
        };
    } else {
        return {
            [first]: buildOption(rest),
        };
    }
};

const toConfiguration = (strings: string[]): PaymentConfiguration => {
    return strings.reduce((acc, path) => {
        return deepmerge(acc, buildOption(path.split(".")));
    }, {});
};

export interface NewPaymentProfileProps
    extends WithTranslation,
        RouteComponentProps<MatchParams>,
        AccountIdsProps {
    resetForm: () => void;
    createPaymentProfile: (
        accountId: string,
        newPaymentProfile: NewProfileConfiguration,
    ) => void;
    getCheckoutConfiguration: (accountId: string) => void;
    paymentProfile: State;
}

interface ValidatedInputProps extends WithTranslation {
    values: any;
    errors: any;
    path: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    touched: any;
    required?: boolean;
}

const ValidatedInput = ({
    t,
    values,
    errors,
    path,
    onChange,
    onBlur,
    touched,
    required,
}: ValidatedInputProps) => {
    const value = getValueAt(path, values);
    const error = getValueAt(path, errors);
    const was_touched = getValueAt(path, touched);
    const validation = error
        ? {
              state: ValidationState.Invalid,
              message: error,
          }
        : undefined;
    const label = t(`settings.new_payment_profile.fields.${path}`);
    return (
        <InputWrapper>
            <Input
                label={label}
                placeholder={t(
                    `settings.new_payment_profile.placeholders.${path}`,
                )}
                type="text"
                name={path}
                value={value || ""}
                onChange={(e) => {
                    onChange(e);
                }}
                onBlur={(e) => {
                    onChange(e);
                    onBlur(e);
                }}
                validation={was_touched && validation}
                required={required}
            />
        </InputWrapper>
    );
};

const TranslatedValidatedInput = withTranslation()(ValidatedInput);

interface FormikValues {
    accountId: string;
    description: string;
    payment_types: string[];
    merchant: {
        name: string;
        logo_url: string;
    };
    url?: {
        merchant_terms_url?: string;
    };
    auto_capture: boolean;
    allow_countries: string[];
    deny_countries: string[];
    preferred_country?: string;
    publish_sms_checkout_link: boolean;
    default_customer_type: "b2b" | "b2c";
    allow_different_billing_shipping_address:
        | "none"
        | "b2b"
        | "b2c"
        | "b2b_b2c";
}

const getAccountId = (accountId: string, envParam: string | null) => {
    if (envParam) {
        const envPrefix = envParam === "production" ? "P" : "T";
        return `${envPrefix}${accountId.substring(1)}`;
    }
    return accountId;
};

class NewPaymentProfileForm extends Component<NewPaymentProfileProps> {
    componentDidMount() {
        this.props.getCheckoutConfiguration(this.props.match.params.accountId);
    }

    componentWillUnmount() {
        this.props.resetForm();
    }

    render() {
        const { t, paymentProfile } = this.props;
        const { isLoading } = this.props.paymentProfile;
        const searchParams = new URLSearchParams(
            this.props.location.search.substring(1),
        );

        const accountId = getAccountId(
            this.props.match.params.accountId,
            searchParams.get("environment"),
        );

        if (paymentProfile.result) {
            return (
                <Redirect
                    to={`/${paymentProfile.result.accountId}/settings/payment-profiles/${paymentProfile.result.accountId}/${paymentProfile.result.paymentProfile.id}`}
                />
            );
        }
        const showFinishProfile = !this.props.prodAccountId;
        const showAddConnections =
            !showFinishProfile &&
            Object.keys(paymentProfile.checkoutConfiguration || {}).length ===
                1;

        const initialAccountId = accountId;

        const defaultMerchantName =
            (paymentProfile.account &&
                paymentProfile.account.company &&
                paymentProfile.account.company.business_name) ||
            "";

        return (
            <Formik
                enableReinitialize
                initialValues={
                    {
                        merchant: {
                            name: defaultMerchantName,
                            logo_url: "",
                        },
                        url: {
                            merchant_terms_url:
                                paymentProfile?.account?.company?.terms_url,
                        },
                        accountId: initialAccountId,
                        description: searchParams.get("description") || "",
                        payment_types: [],
                        publish_sms_checkout_link:
                            searchParams.get("publish_sms_checkout_link") ||
                            false,
                        auto_capture: searchParams.get("auto_capture") || false,
                        allow_countries: [],
                        deny_countries: [],
                        preferred_country: undefined,
                        default_customer_type: "b2c",
                        allow_different_billing_shipping_address: "none",
                    } as FormikValues
                }
                validate={(values) => {
                    let errors: { [key: string]: string } = {};
                    const validators = [
                        {
                            path: "description",
                            validator: validateRequired(
                                t(
                                    "settings.new_payment_profile.invalid.description",
                                ),
                            ),
                        },
                        {
                            path: "merchant.name",
                            validator: validateRequired(
                                t(
                                    "settings.new_payment_profile.invalid.merchant.name",
                                ),
                            ),
                        },
                        {
                            path: "merchant.logo_url",
                            validator: validateUrl(
                                t(
                                    "settings.new_payment_profile.invalid.merchant.logo_url.invalid",
                                ),
                            ),
                        },
                        {
                            path: "url.merchant_terms_url",
                            validator: validateUrl(
                                t(
                                    "settings.edit_payment_profile.invalid.url.merchant_terms_url",
                                ),
                            ),
                        },
                    ];
                    errors = validators.reduce((acc, elem) => {
                        return validate(elem.path, elem.validator, values, acc);
                    }, errors);

                    if (values.payment_types.length === 0) {
                        errors.payment_types = t(
                            "settings.new_payment_profile.invalid.payment_types",
                        );
                    }

                    return errors;
                }}
                validateOnChange={true}
                onSubmit={(values) => {
                    const merchant_terms_url =
                        values.url && values.url.merchant_terms_url
                            ? values.url.merchant_terms_url
                            : undefined;
                    const url = values.url
                        ? { ...values.url, merchant_terms_url }
                        : undefined;

                    const newProfile = {
                        description: values.description,
                        configuration: toConfiguration(values.payment_types),
                        merchant: {
                            ...values.merchant,
                        },
                        url,
                    };
                    if (values.publish_sms_checkout_link) {
                        newProfile.configuration.publish = [
                            {
                                channel: "sms",
                                type: "checkout-link",
                            },
                        ];
                    }
                    if (!newProfile.merchant.logo_url) {
                        newProfile.merchant.logo_url = FALLBACK_MERCHANT_LOGO;
                    }
                    if (values.auto_capture) {
                        newProfile.configuration.auto_capture =
                            values.auto_capture;
                    }
                    if (values.default_customer_type === "b2b") {
                        newProfile.configuration.default_customer_type =
                            values.default_customer_type;
                    }
                    const mapping = {
                        none: [],
                        b2b: ["b2b"],
                        b2c: ["b2c"],
                        b2b_b2c: ["b2b", "b2c"],
                    };
                    newProfile.configuration.allow_different_billing_shipping_address =
                        (mapping[
                            values.allow_different_billing_shipping_address
                        ] || []) as ("b2b" | "b2c")[];
                    if (
                        values.allow_countries.length > 0 ||
                        values.deny_countries.length > 0 ||
                        values.preferred_country
                    ) {
                        newProfile.configuration.countries = {};
                    }
                    this.props.createPaymentProfile(
                        values.accountId,
                        newProfile,
                    );
                }}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    touched,
                    setFieldTouched,
                }) => {
                    return (
                        <Form onSubmit={handleSubmit} autoComplete="nope">
                            <Page
                                title={
                                    <>
                                        <Dismiss />
                                        <Title>
                                            {t(
                                                "settings.new_payment_profile.title",
                                            )}
                                        </Title>
                                        <PageButton
                                            type="submit"
                                            disabled={
                                                Object.keys(errors || {})
                                                    .length > 0 ||
                                                Object.keys(touched || {})
                                                    .length === 0
                                            }
                                        >
                                            <Icon
                                                icon="save"
                                                fill="currentColor"
                                            />
                                            {t(
                                                "settings.new_payment_profile.create_payment_profile",
                                            )}
                                        </PageButton>
                                    </>
                                }
                            >
                                <Card title={undefined}>
                                    <Wrapper>
                                        {showFinishProfile && (
                                            <IncompleteSetup>
                                                <H3>
                                                    {t(
                                                        "settings.new_payment_profile.finish_profile_setup.title",
                                                    )}
                                                </H3>
                                                <P>
                                                    {t(
                                                        "settings.new_payment_profile.finish_profile_setup.body",
                                                    )}
                                                </P>
                                                <br />
                                                <ButtonLink
                                                    to={`/${accountId}/settings/account`}
                                                >
                                                    {t(
                                                        "settings.new_payment_profile.finish_profile_setup.call_to_action",
                                                    )}
                                                </ButtonLink>
                                            </IncompleteSetup>
                                        )}
                                        {showAddConnections && (
                                            <IncompleteSetup>
                                                <H3>
                                                    {t(
                                                        "settings.new_payment_profile.add_payment_connections.title",
                                                    )}
                                                </H3>
                                                <P>
                                                    {t(
                                                        "settings.new_payment_profile.add_payment_connections.body",
                                                    )}
                                                </P>
                                                <br />
                                                <ButtonLink
                                                    to={`/${accountId}/settings/payment-connections`}
                                                >
                                                    {t(
                                                        "settings.new_payment_profile.add_payment_connections.call_to_action",
                                                    )}
                                                </ButtonLink>
                                            </IncompleteSetup>
                                        )}

                                        <FormGroup>
                                            <H2>
                                                {t(
                                                    "settings.new_payment_profile.payment_profile",
                                                )}
                                            </H2>
                                            <Hr />
                                            <Flex>
                                                <TranslatedValidatedInput
                                                    path="description"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    required
                                                />
                                                <TranslatedValidatedInput
                                                    path="merchant.name"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    required
                                                />
                                            </Flex>
                                            <Flex>
                                                <TranslatedValidatedInput
                                                    path="url.merchant_terms_url"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                />
                                                <TranslatedValidatedInput
                                                    path="merchant.logo_url"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                />
                                            </Flex>
                                            {paymentProfile.checkoutConfiguration && (
                                                <PreviewWrapper>
                                                    <ProfilePreview
                                                        configuration={
                                                            paymentProfile.checkoutConfiguration
                                                        }
                                                        profile={
                                                            {
                                                                merchant:
                                                                    values.merchant,
                                                                configuration:
                                                                    toConfiguration(
                                                                        values.payment_types,
                                                                    ),
                                                            } as ProfileConfiguration
                                                        }
                                                        countryCode={
                                                            paymentProfile
                                                                .account
                                                                ?.company
                                                                ?.address
                                                                ?.country ||
                                                            undefined
                                                        }
                                                    />
                                                </PreviewWrapper>
                                            )}
                                        </FormGroup>
                                        <H2>
                                            {t(
                                                "settings.new_payment_profile.payment_methods",
                                            )}
                                        </H2>
                                        <Hr />
                                        <FormGroup>
                                            <OptionList>
                                                {(
                                                    paymentProfile.checkoutConfiguration || {
                                                        configuration: {
                                                            payment_options: [],
                                                        },
                                                    }
                                                ).configuration.payment_options.map(
                                                    (option) => {
                                                        return (
                                                            <Checkbox
                                                                key={
                                                                    option.type
                                                                }
                                                                name="payment_types"
                                                                checked={values.payment_types.includes(
                                                                    option.type,
                                                                )}
                                                                label={getProfilePaymentTypeLabel(
                                                                    option.type,
                                                                    t,
                                                                )}
                                                                value={
                                                                    option.type
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                setFieldTouched={
                                                                    setFieldTouched
                                                                }
                                                            />
                                                        );
                                                    },
                                                )}
                                            </OptionList>
                                            {errors.payment_types &&
                                                touched.payment_types && (
                                                    <InvalidMessage>
                                                        {errors.payment_types}
                                                    </InvalidMessage>
                                                )}
                                        </FormGroup>

                                        <H2>
                                            {t(
                                                "settings.edit_payment_profile.express_settings_title",
                                            )}
                                        </H2>
                                        <Hr />
                                        <FormGroup>
                                            <CustomerTypeDropdown
                                                label={t(
                                                    "settings.edit_payment_profile.fields.default_customer_type",
                                                )}
                                                name="default_customer_type"
                                                value={
                                                    values.default_customer_type
                                                }
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "default_customer_type",
                                                        value,
                                                    );
                                                    setFieldTouched(
                                                        "default_customer_type",
                                                        true,
                                                    );
                                                }}
                                            />
                                            <AllowDifferentBillingShippingAddressDropdown
                                                label={t(
                                                    "settings.edit_payment_profile.fields.allow_different_billing_shipping_address",
                                                )}
                                                name="allow_different_billing_shipping_address"
                                                value={
                                                    values.allow_different_billing_shipping_address
                                                }
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "allow_different_billing_shipping_address",
                                                        value,
                                                    );
                                                    setFieldTouched(
                                                        "allow_different_billing_shipping_address",
                                                        true,
                                                    );
                                                }}
                                            />
                                        </FormGroup>
                                        <P>
                                            {t(
                                                "settings.new_payment_profile.countries_description",
                                            )}
                                        </P>
                                        <br />
                                        {values.allow_countries.length === 0 &&
                                            values.deny_countries.length ===
                                                0 && (
                                                <FormGroup>
                                                    <Button
                                                        className={"alt tiny"}
                                                        onClick={() => {
                                                            setFieldValue(
                                                                "allow_countries",
                                                                COUNTRIES,
                                                            );
                                                            setFieldTouched(
                                                                "allow_countries",
                                                                true,
                                                            );
                                                        }}
                                                    >
                                                        {t(
                                                            "settings.new_payment_profile.countries_enable_button",
                                                        )}
                                                    </Button>
                                                </FormGroup>
                                            )}

                                        {values.allow_countries.length !==
                                            0 && (
                                            <>
                                                <H3>
                                                    {t(
                                                        "settings.new_payment_profile.countries_allow_title",
                                                    )}
                                                </H3>
                                                <FormGroup>
                                                    <OptionList>
                                                        {COUNTRIES.map((x) => (
                                                            <Checkbox
                                                                key={x}
                                                                name={`allow_countries`}
                                                                label={t(
                                                                    `settings.country_options.${x}`,
                                                                    {
                                                                        defaultValue:
                                                                            x,
                                                                    },
                                                                )}
                                                                checked={(
                                                                    values.allow_countries ||
                                                                    []
                                                                ).includes(x)}
                                                                value={x}
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                setFieldTouched={
                                                                    setFieldTouched
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                disabled={values.deny_countries.includes(
                                                                    x,
                                                                )}
                                                            />
                                                        ))}
                                                    </OptionList>
                                                </FormGroup>
                                            </>
                                        )}
                                        {values.deny_countries.length > 0 && (
                                            <>
                                                <H3>
                                                    {t(
                                                        "settings.new_payment_profile.countries_deny_title",
                                                    )}
                                                </H3>
                                                <FormGroup>
                                                    <OptionList>
                                                        {COUNTRIES.map((x) => (
                                                            <Checkbox
                                                                key={x}
                                                                name={`deny_countries`}
                                                                label={t(
                                                                    `settings.country_options.${x}`,
                                                                    {
                                                                        defaultValue:
                                                                            x,
                                                                    },
                                                                )}
                                                                checked={values.deny_countries.includes(
                                                                    x,
                                                                )}
                                                                value={x}
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                setFieldTouched={
                                                                    setFieldTouched
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                disabled={values.allow_countries.includes(
                                                                    x,
                                                                )}
                                                            />
                                                        ))}
                                                    </OptionList>
                                                </FormGroup>
                                            </>
                                        )}
                                        {(values.preferred_country ||
                                            values.allow_countries.length > 0 ||
                                            values.deny_countries.length >
                                                0) && (
                                            <>
                                                <H3>
                                                    {t(
                                                        "settings.new_payment_profile.countries_prefer_title",
                                                    )}
                                                </H3>
                                                <FormGroup>
                                                    <P>
                                                        {t(
                                                            "settings.new_payment_profile.countries_prefer_description",
                                                        )}
                                                    </P>
                                                    <br />
                                                    <OptionList>
                                                        {(values.allow_countries
                                                            .length > 0
                                                            ? values.allow_countries
                                                            : COUNTRIES
                                                        )
                                                            .filter(
                                                                (x) =>
                                                                    !values.deny_countries.includes(
                                                                        x,
                                                                    ),
                                                            )
                                                            .map((x) => (
                                                                <Radiobutton
                                                                    key={x}
                                                                    name={`deny_countries`}
                                                                    label={t(
                                                                        `settings.country_options.${x}`,
                                                                        {
                                                                            defaultValue:
                                                                                x,
                                                                        },
                                                                    )}
                                                                    value={
                                                                        values.preferred_country ===
                                                                        x
                                                                    }
                                                                    onChange={(
                                                                        name,
                                                                        value,
                                                                    ) => {
                                                                        setFieldValue(
                                                                            "preferred_country",
                                                                            x,
                                                                        );
                                                                        setFieldTouched(
                                                                            "preferred_country",
                                                                            true,
                                                                        );
                                                                    }}
                                                                />
                                                            ))}
                                                        <Radiobutton
                                                            key={"undefined"}
                                                            name={`deny_countries`}
                                                            label={t(
                                                                `settings.country_options.none`,
                                                                {
                                                                    defaultValue:
                                                                        "None",
                                                                },
                                                            )}
                                                            value={
                                                                !values.preferred_country
                                                            }
                                                            onChange={(
                                                                name,
                                                                value,
                                                            ) => {
                                                                setFieldValue(
                                                                    "preferred_country",
                                                                    undefined,
                                                                );
                                                                setFieldTouched(
                                                                    "preferred_country",
                                                                    true,
                                                                );
                                                            }}
                                                        />
                                                    </OptionList>
                                                </FormGroup>
                                            </>
                                        )}

                                        <H2>
                                            {t(
                                                "settings.new_payment_profile.publish_title",
                                            )}
                                        </H2>
                                        <Hr />
                                        <FormGroup>
                                            <P>
                                                {t(
                                                    `settings.new_payment_profile.about_publish_${smsOrPush(
                                                        (
                                                            paymentProfile.checkoutConfiguration || {
                                                                configuration: {
                                                                    payment_options:
                                                                        [],
                                                                },
                                                            }
                                                        ).configuration.payment_options.map(
                                                            (v) => v.type,
                                                        ),
                                                        values.payment_types,
                                                    )}`,
                                                )}
                                            </P>
                                            <br />
                                            <OptionList>
                                                <Checkbox
                                                    name="publish_sms_checkout_link"
                                                    label={t(
                                                        `settings.new_payment_profile.fields.publish_${smsOrPush(
                                                            (
                                                                paymentProfile.checkoutConfiguration || {
                                                                    configuration:
                                                                        {
                                                                            payment_options:
                                                                                [],
                                                                        },
                                                                }
                                                            ).configuration.payment_options.map(
                                                                (v) => v.type,
                                                            ),
                                                            values.payment_types,
                                                        )}_checkout_link_checkout_link`,
                                                    )}
                                                    checked={
                                                        values.publish_sms_checkout_link
                                                    }
                                                    onChange={handleChange}
                                                    setFieldTouched={
                                                        setFieldTouched
                                                    }
                                                />
                                            </OptionList>
                                        </FormGroup>
                                        <AllowPartner visibleFrame>
                                            <>
                                                <H2>
                                                    {t(
                                                        "settings.new_payment_profile.payment_settings_title",
                                                    )}
                                                </H2>
                                                <Hr />
                                                <FormGroup>
                                                    <OptionList>
                                                        <Checkbox
                                                            name="auto_capture"
                                                            label={t(
                                                                `settings.new_payment_profile.fields.auto_capture`,
                                                            )}
                                                            checked={
                                                                values.auto_capture
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            setFieldTouched={
                                                                setFieldTouched
                                                            }
                                                        />
                                                    </OptionList>
                                                </FormGroup>
                                            </>
                                        </AllowPartner>
                                        <ButtonGroup>
                                            <BackButton>
                                                {t(
                                                    "settings.new_payment_profile.abort",
                                                )}
                                            </BackButton>
                                            <Button
                                                type="submit"
                                                disabled={
                                                    Object.keys(errors || {})
                                                        .length > 0 ||
                                                    Object.keys(touched || {})
                                                        .length === 0
                                                }
                                            >
                                                {t(
                                                    "settings.new_payment_profile.create_payment_profile",
                                                )}
                                            </Button>
                                        </ButtonGroup>

                                        {isLoading && <LoadingOverlay />}
                                    </Wrapper>
                                </Card>
                            </Page>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}

export default withAccountIds(withTranslation()(NewPaymentProfileForm));

const Form = styled.form`
    width: 100%;
`;

const Wrapper = styled.div`
    position: relative;
    max-width: ${grid.spans.span8};
    margin: 0 auto;
`;

const FormGroup = styled.div`
    margin-bottom: ${distances.normal};
`;

const Title = styled(H1)`
    color: ${colors.text};
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

const Flex = styled.div`
    display: flex;

    @media (max-width: ${globalColumnMaxWidth}px) {
        flex-direction: column;
    }
`;

const OptionList = styled.div`
    display: flex;
    flex-direction: column;
    > label {
        padding-bottom: ${distances.small};
    }
`;
const InputWrapper = styled.div`
    width: ${grid.spans.span4};
    margin-right: ${grid.gutter};

    &:last-child {
        margin: 0;
    }

    @media (max-width: ${globalColumnMaxWidth}px) {
        width: 100%;
    }
`;

const IncompleteSetup = styled.div`
    border: ${colors.borderLight} 2px solid;
    padding: ${distances.normal};
    margin-bottom: ${distances.normal};
`;

const InvalidMessage = styled.span`
    position: absolute;
    font-size: 11px;
    line-height: ${distances.small};
    color: ${colors.invalid};
`;

const PreviewWrapper = styled.div`
    padding: ${distances.normal};
`;
