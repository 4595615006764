import styled from "styled-components/macro";
import { colors } from "../styles/constants";

const A = styled.a`
    color: ${colors.primary};

    &:hover {
        color: ${colors.primaryHover};
    }
`;

export const ABreakAll = styled(A)`
    word-break: break-all;
`;

export default A;
