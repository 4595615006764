import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useActions } from "../../../../Actions";
import { Button, ButtonGroup } from "../../../../components/Buttons";
import { Dd, Dl, Dt } from "../../../../components/DescriptionList/Horizontal";
import { DateTime } from "../../../../components/Formatters";
import { H2, P } from "../../../../components/Typography";
import View from "../../../../components/View";
import { distances } from "../../../../styles/constants";
import { CheckoutConfig } from "../../../../types/checkout";
import { CheckoutConfigWrite } from "../../../../types/checkout/generated";
import { Account, KlarnaConnection } from "../../../../types/management-auth";
import { PaymentOptionsDialog } from "./PaymentOptionsDialog";
import {
    ConnectionAside,
    ConnectionText,
    ConnectionsStatus,
    Details,
    Flex,
    PaymentConnection,
    ToggleDetails,
} from "./styledListItemComponents";

const availablePaymentOptions: CheckoutConfigWrite["configuration"]["payment_options"] =
    [
        {
            type: "klarna.klarna",
            currencies: ["NOK", "SEK", "EUR", "DKK", "GBP"],
        },
        { type: "klarna.billie", currencies: ["NOK", "SEK"] },
    ];

export interface KlarnaConnectionListItemProps {
    klarna: KlarnaConnection | undefined;
    disabled: boolean;
    account: Account;
    accountId: string;
    checkoutConfig: CheckoutConfig | undefined;
    updateCheckoutConfig: (
        accountId: string,
        checkoutConfig: CheckoutConfigWrite,
    ) => Promise<void>;
    isUpdatedSuccessfully: boolean;
}

const KlarnaConnectionListItem = ({
    klarna,
    disabled,
    account,
    accountId,
    checkoutConfig,
    updateCheckoutConfig,
    isUpdatedSuccessfully,
}: KlarnaConnectionListItemProps) => {
    const { t } = useTranslation();
    const [klarnaDetails, setKlarnaDetails] = useState(false);
    const [showPaymentOptionsDialog, setShowPaymentOptionsDialog] =
        useState(false);
    useEffect(() => {
        if (isUpdatedSuccessfully) {
            setShowPaymentOptionsDialog(false);
        }
    }, [isUpdatedSuccessfully]);
    const signups = klarna?.signups || [];
    const lastSignup = signups[signups.length - 1];
    const { openModal } = useActions("connections.klarna");
    return (
        <PaymentConnection disabled={disabled}>
            <View alignItems="flex-start" gap={distances.small}>
                <H2>{t(`settings.payment_connections.klarna.name`)}</H2>
                {klarna && klarna.status && (
                    <ConnectionsStatus status={klarna.status}>
                        {t(
                            `settings.payment_connections.status.${klarna.status}`,
                        )}
                    </ConnectionsStatus>
                )}
            </View>
            <Flex>
                <ConnectionText>
                    <P>
                        {t(`settings.payment_connections.klarna.description`)}
                    </P>
                </ConnectionText>
                <ConnectionAside>
                    {!klarna && (
                        <Button
                            className="stretch"
                            onClick={() => {
                                openModal(account);
                            }}
                        >
                            {t(
                                "settings.payment_connections.klarna.call_to_action",
                            )}
                        </Button>
                    )}
                </ConnectionAside>
            </Flex>
            {klarna && (
                <>
                    <ToggleDetails
                        onClick={() => setKlarnaDetails(!klarnaDetails)}
                    >
                        {klarnaDetails
                            ? t("settings.payment_connections.collapse_details")
                            : t("settings.payment_connections.expand_details")}
                    </ToggleDetails>
                    <Details className={klarnaDetails ? "open" : "closed"}>
                        {klarna.status === "pending" && (
                            <Dl>
                                <Dt doubleColumn>
                                    {t(
                                        "settings.payment_connections.fields.instructions",
                                    )}
                                </Dt>
                                <Dd>
                                    {t(
                                        "settings.payment_connections.klarna.pending",
                                    )}
                                </Dd>
                            </Dl>
                        )}
                        <Dl>
                            <Dt doubleColumn>
                                {t(
                                    "settings.payment_connections.fields.created_at",
                                )}
                            </Dt>
                            <Dd>
                                {lastSignup?.created_at ? (
                                    <DateTime
                                        isoString={lastSignup.created_at}
                                    />
                                ) : (
                                    <em>
                                        {t(
                                            "settings.payment_connections.fields.no_callback_yet",
                                        )}
                                    </em>
                                )}
                            </Dd>
                        </Dl>
                        <Dl>
                            <Dt doubleColumn>
                                {t(
                                    "settings.payment_connections.fields.callback_at",
                                )}
                            </Dt>
                            <Dd>
                                {lastSignup?.callback_at ? (
                                    <DateTime
                                        isoString={lastSignup.callback_at}
                                    />
                                ) : (
                                    <em>
                                        {t(
                                            "settings.payment_connections.fields.no_callback_yet",
                                        )}
                                    </em>
                                )}
                            </Dd>
                        </Dl>
                        {lastSignup?.case_id && (
                            <Dl>
                                <Dt doubleColumn>
                                    {t(
                                        "settings.payment_connections.fields.case_id",
                                    )}
                                </Dt>
                                <Dd>
                                    <code>{lastSignup?.case_id}</code>
                                </Dd>
                            </Dl>
                        )}
                        {lastSignup?.price_package && (
                            <Dl>
                                <Dt doubleColumn>
                                    {t("settings.account.fields.price_package")}
                                </Dt>
                                <Dd>
                                    <code>{lastSignup?.price_package}</code>
                                </Dd>
                            </Dl>
                        )}

                        {checkoutConfig && klarna.status !== "failed" && (
                            <ButtonGroup>
                                <Button
                                    type="button"
                                    className="alt"
                                    onClick={() =>
                                        setShowPaymentOptionsDialog(true)
                                    }
                                >
                                    {t(
                                        "settings.payment_connections.edit_payment_options_button",
                                    )}
                                </Button>
                                <PaymentOptionsDialog
                                    availablePaymentOptions={
                                        availablePaymentOptions
                                    }
                                    isDialogOpen={showPaymentOptionsDialog}
                                    handleCloseDialog={() =>
                                        setShowPaymentOptionsDialog(false)
                                    }
                                    handleSavePaymentOptions={(
                                        newCheckoutConfiguration,
                                    ) =>
                                        updateCheckoutConfig(
                                            accountId,
                                            newCheckoutConfiguration,
                                        )
                                    }
                                    checkoutConfig={checkoutConfig}
                                />
                            </ButtonGroup>
                        )}
                    </Details>
                </>
            )}
        </PaymentConnection>
    );
};

export default KlarnaConnectionListItem;
