import {
    namespace as locationDetailsNamespace,
    reducer as locationDetailsReducer,
} from "./locationDetails";
import {
    namespace as newLocationNamespace,
    reducer as newLocationReducer,
} from "./newLocation";

export interface State {
    [locationDetailsNamespace]: locationDetailsReducer.State;
    [newLocationNamespace]: newLocationReducer.State;
}

export const initialState: State = {
    [locationDetailsNamespace]: locationDetailsReducer.initialState,
    [newLocationNamespace]: newLocationReducer.initialState,
};

export const reducers = {
    [locationDetailsNamespace]: locationDetailsReducer.reducer,
    [newLocationNamespace]: newLocationReducer.reducer,
};
