import { Route } from "react-router-dom";
import AdditionalServicesPage from "./components/AdditionalServicesPage";

const Routes = [
    <Route
        key="/additional-services"
        path="/:accountId/settings/additional-services"
        exact
        component={AdditionalServicesPage}
    />,
];

export default Routes;
