import cc, { CurrencyCodeRecord } from "currency-codes";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { numberFormat } from "../../helpers/intl";

type MoneyProps = {
    amount: number | undefined;
    currency: string;
    boldCurrency?: boolean;
    hideCurrency?: boolean;
};

const Money = ({
    currency,
    amount,
    boldCurrency,
    hideCurrency,
}: MoneyProps) => {
    const { i18n } = useTranslation();
    const exponent =
        currency && cc.code(currency)
            ? (cc.code(currency) as CurrencyCodeRecord).digits
            : 2;
    const normalCurrencyAmount = (amount || 0) / Math.pow(10, exponent);
    const amountString =
        amount === undefined
            ? "?"
            : numberFormat(i18n.language, {
                  minimumFractionDigits: exponent,
              }).format(normalCurrencyAmount);
    if (hideCurrency) {
        return <Wrapper title={amountString}>{amountString}</Wrapper>;
    }
    if (boldCurrency) {
        return (
            <Wrapper title={`${amountString} ${currency}`}>
                {amountString} <Currency>{currency}</Currency>
            </Wrapper>
        );
    }
    return (
        <Wrapper title={`${amountString} ${currency}`}>
            {amountString} {currency}
        </Wrapper>
    );
};

const Wrapper = styled.span`
    white-space: nowrap;
    font-feature-settings: 'tnum';
    position: relative;
`;

const Currency = styled.span`
    font-size: 11px;
    font-weight: 700;
`;

export default Money;
