import styled, { keyframes } from "styled-components/macro";
import { P } from "../../../../components/Typography";
import { colors, distances } from "../../../../styles/constants";

const TextWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.normal};
`;

const flash = keyframes`
  from {
    background: transparent;
    color: ${colors.invalid};
  }
  to {
    background: ${colors.invalid};
    color: ${colors.background};

  }
`;

const WarningWrapper = styled(TextWrapper)`
    color: ${colors.invalid};
    border: 1px solid ${colors.invalid};
    padding: ${distances.small};
    border-radius: 4px;
    > p {
        color: inherit;
        font-weight: bold;
    }
    &.flash {
        animation: ${flash} 1s linear infinite;
    }
`;

export const Warning = ({ flash }: { flash: boolean }) => {
    return (
        <WarningWrapper className={flash ? "flash" : ""}>
            <P>
                YOU ARE MAKING CHANGES TO THE DINTERO ROOT ACCOUNT ID P00000000
                - DO NOT EDIT UNLESS YOU REALLY MEAN IT
            </P>
        </WarningWrapper>
    );
};
