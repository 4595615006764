import useSWRMutation from "swr/mutation";
import { useAccountId } from "../../../../auth/useAccountId";
import { CORE_API_HOSTNAME } from "../../../../env";
import fulfill from "../../../../fulfill";
import type { Subscription } from "../../../../types/webhooks";

const fetchCreateHook = async (
    key: string,
    options: {
        arg: {
            accountId: string;
            newHook: Subscription;
        };
    },
): Promise<Subscription> => {
    return await fulfill.post<Subscription>({
        accountId: options.arg.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${options.arg.accountId}/hooks/subscriptions`,
        json: options.arg.newHook,
        handlers: {
            200: (hook: Subscription) => hook,
        },
    });
};

export const useCreateHook = () => {
    const accountId = useAccountId();
    const {
        trigger,
        data,
        isMutating: isLoading,
        error,
    } = useSWRMutation(`createHook-${accountId}`, fetchCreateHook);

    const createHook = async (newHook: Subscription) => {
        await trigger({
            accountId,
            newHook,
        });
    };

    return {
        createHook,
        isLoading,
        error,
        hook: data,
    };
};
