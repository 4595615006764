import type { TFuncKey } from "react-i18next";
import type { ConfigBankAccountDraft } from "../../../payout/types";
import type { ValidationSchema } from "../../../util/useValidatedSchema";

import * as validators from "../../../helpers/validation";

// just a helper to get typescript help here
const tKey = (key: TFuncKey<"payout">) => key;

export const bankAccountSchema: ValidationSchema<ConfigBankAccountDraft, any> =
    {
        bank_account_bic: [
            validators.validateRequired(tKey("general.validations.required")),
        ],
        bank_account_country_code: [
            validators.validateRequired(tKey("general.validations.required")),
            validators.validateISO_3166_1(tKey("general.validations.invalid")),
        ],
        bank_account_currency: [
            validators.validateRequired(tKey("general.validations.required")),
            validators.validateCurrency(tKey("general.validations.invalid")),
        ],
        bank_account_number: [
            validators.validateRequired(tKey("general.validations.required")),
            (value, adjacentValues) => {
                if (adjacentValues.bank_account_type === "bban")
                    return validators.getAccountNumberValidator(
                        adjacentValues.bank_account_country_code,
                        tKey("general.validations.invalid"),
                    )(value);
                return "Not supported"; // TODO: Support iban
            },
        ],
        bank_account_type: [
            (value) =>
                ["iban", "bban"].includes(value)
                    ? undefined
                    : tKey("general.validations.invalid"),
        ],
        is_default_for_currency: [],
        nickname: [
            validators.validateRequired(tKey("general.validations.required")),
            validators.validateMinLength(
                2,
                tKey("general.validations.invalid"),
            ),
        ],
        owner_address: [
            validators.validateRequired(tKey("general.validations.required")),
            validators.validateMinLength(
                5,
                tKey("general.validations.invalid"),
            ),
        ],
        owner_city: [
            validators.validateRequired(tKey("general.validations.required")),
            validators.validateMinLength(
                2,
                tKey("general.validations.invalid"),
            ),
        ],
        owner_country_code: [
            validators.validateRequired(tKey("general.validations.required")),
            validators.validateISO_3166_1(tKey("general.validations.invalid")),
        ],
        owner_name: [
            validators.validateRequired(tKey("general.validations.required")),
            validators.validateMinLength(
                2,
                tKey("general.validations.required"),
            ),
        ],
        owner_orgno: [
            validators.validateRequired(tKey("general.validations.required")),
            (value, adjacent) =>
                validators.getOrgNumberValidator(
                    adjacent.bank_account_country_code,
                    tKey("general.validations.invalid"),
                )(value),
        ],
        owner_postal_code: [
            validators.validateRequired(tKey("general.validations.required")),
            (value, adjacent) =>
                validators.getPostalCodeValidator(
                    adjacent.bank_account_country_code,
                    tKey("general.validations.invalid"),
                )(value),
        ],
    };
