import { Dispatch } from "redux";
import { createAccountIdChangedCaseReducer } from "../../../auth/useAccountId";
import { CORE_API_HOSTNAME } from "../../../env";
import fulfill from "../../../fulfill";
import { Account } from "../../../types/management-auth";
import { createSwitchlessReducer } from "../../../util/switchlessReducer";
import { getAccount } from "../connections/actions";

export const namespace = "connections.vipps";

export enum VippsModalState {
    Closed = "Closed",
    InitiateApplicationLoading = "InitiateApplicationLoading",
    ApplicationFormOpened = "ApplicationFormOpened",
    ApplicationReceived = "ApplicationReceived",
    VippsApiError = "VippsApiError",
}

export interface State {
    modalState: VippsModalState;
    account: Account | undefined;
}

export const initialState: State = {
    modalState: VippsModalState.Closed,
    account: undefined,
};

export const { reducer, actions } = createSwitchlessReducer({
    namespace,
    initialState,
    reducers: {
        openVippsModal: (state: State, account: Account) => {
            return {
                ...state,
                modalState: VippsModalState.ApplicationFormOpened,
                account,
            };
        },
        closeVippsModal: (_: State) => {
            return initialState;
        },
        postConnection: (state: State) => {
            return {
                ...state,
                modalState: VippsModalState.InitiateApplicationLoading,
            };
        },
        postConnectionSuccess: (state: State) => {
            return {
                ...state,
                modalState: VippsModalState.ApplicationReceived,
            };
        },
        postConnectionFailure: (state: State) => {
            return {
                ...state,
                modalState: VippsModalState.VippsApiError,
            };
        },
        postConnectionComplete: (state: State) => {
            return {
                ...state,
                modalState: VippsModalState.Closed,
            };
        },
    },
    globalReducer: createAccountIdChangedCaseReducer(() => initialState),
});

export const createConnectedActions = (dispatch: Dispatch) => {
    const openVippsModal = (account: Account) => {
        dispatch(actions.openVippsModal(account));
    };

    const closeVippsModal = () => {
        dispatch(actions.closeVippsModal());
    };

    const finishRegistration = async (payload: {
        accountId?: string;
        body: {
            [key: string]: any;
        };
        userId?: string;
    }) => {
        const { accountId, body, userId } = payload;
        if (!accountId || !userId) return;
        dispatch(actions.postConnection());
        let signup = body?.["signup-id"];
        if (!signup?.length) {
            signup = await fulfill.post({
                accountId,
                url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/management/settings/connections/vipps`,
                handlers: {
                    200: (response) => {
                        dispatch(actions.postConnectionSuccess());
                        return response?.signup?.id;
                    },
                    500: () => {
                        dispatch(actions.postConnectionFailure());
                    },
                    501: () => {
                        dispatch(actions.postConnectionFailure());
                    },
                    502: () => {
                        dispatch(actions.postConnectionFailure());
                    },
                    503: () => {
                        dispatch(actions.postConnectionFailure());
                    },
                    504: () => {
                        dispatch(actions.postConnectionFailure());
                    },
                },
            });
        }
        await fulfill.post({
            accountId,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/management/settings/connections/vipps/${userId}`,
            json: {
                ...body,
                "signup-id": signup,
            },
            handlers: {
                202: () => {
                    dispatch(getAccount(accountId));
                    dispatch(actions.postConnectionComplete());
                },
                500: () => {
                    dispatch(actions.postConnectionFailure());
                },
                501: () => {
                    dispatch(actions.postConnectionFailure());
                },
                502: () => {
                    dispatch(actions.postConnectionFailure());
                },
                503: () => {
                    dispatch(actions.postConnectionFailure());
                },
                504: () => {
                    dispatch(actions.postConnectionFailure());
                },
            },
        });
    };

    return {
        openVippsModal,
        closeVippsModal,
        finishRegistration,
    };
};
