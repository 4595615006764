import { FieldArray, Formik } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { Button, ButtonGroup } from "../../../../components/Buttons";
import Card from "../../../../components/Card";
import { BackButton } from "../../../../components/Forms";
import Icon from "../../../../components/Icons";
import { LoadingOverlay } from "../../../../components/Loading";
import Page, { Dismiss, PageButton } from "../../../../components/Page";
import { H1 } from "../../../../components/Typography";
import { getValueAt } from "../../../../helpers/getValueAt";
import { border, colors, distances, grid } from "../../../../styles/constants";
import { SalesLocation } from "../../../../types/customers";
import Destination, { defaultDestination } from "./Destination";
import {
    FormGroup,
    HalfNHalf,
    OverallError,
    TranslatedValidatedLocationSelect,
} from "./common";
import { PayoutRuleFormikValues } from "./types";
import { buildPayoutRuleValidations } from "./validation";

interface PayoutRuleFormProps {
    initialValues: PayoutRuleFormikValues;
    accountIds: string[];
    onSubmit: (values: PayoutRuleFormikValues) => void;
    resetForm: () => void;
    isLoading: boolean;
    isEdit?: boolean;
    locations: SalesLocation[] | undefined;
}

const PayoutRuleForm = ({
    initialValues,
    accountIds,
    onSubmit,
    resetForm,
    isLoading,
    isEdit,
    locations,
}: PayoutRuleFormProps) => {
    const { t } = useTranslation();

    // biome-ignore lint/correctness/useExhaustiveDependencies: Run once
    useEffect(() => {
        return () => resetForm();
    }, []);
    return (
        <Formik
            initialValues={initialValues}
            validate={(values) => {
                return buildPayoutRuleValidations(values, t);
            }}
            validateOnChange={true}
            onSubmit={onSubmit}
        >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                touched,
                setFieldTouched,
            }) => {
                const submitDisabled =
                    Object.keys(errors || {}).length > 0 ||
                    Object.keys(touched || {}).length === 0;
                const overallDestinationsError = getValueAt(
                    `destinations_overall`,
                    errors,
                );
                const accountLocations = locations || [];
                const locationNames = accountLocations.reduce(
                    (acc: { [id: string]: string }, loc: SalesLocation) => {
                        if (!loc.location_id || !loc.name) {
                            return acc;
                        }
                        return {
                            ...acc,
                            [loc.location_id]: loc.name,
                        };
                    },
                    {},
                );
                return (
                    <Form onSubmit={handleSubmit} autoComplete="nope">
                        <Page
                            title={
                                <React.Fragment>
                                    <Dismiss />
                                    <Title>
                                        {isEdit
                                            ? t(
                                                  "payout_rules.new_payout_rule.title_edit",
                                              )
                                            : t(
                                                  "payout_rules.new_payout_rule.title_create",
                                              )}
                                    </Title>
                                    <PageButton
                                        disabled={submitDisabled}
                                        type="submit"
                                    >
                                        <Icon icon="save" fill="currentColor" />
                                        {isEdit
                                            ? t(
                                                  "payout_rules.new_payout_rule.edit_payout_rule",
                                              )
                                            : t(
                                                  "payout_rules.new_payout_rule.create_payout_rule",
                                              )}
                                    </PageButton>
                                </React.Fragment>
                            }
                        >
                            <Card title={undefined}>
                                <Wrapper>
                                    <EditWrapper isEditable={!isEdit}>
                                        <FormGroup>
                                            <HalfNHalf>
                                                <TranslatedValidatedLocationSelect
                                                    path="rule_type"
                                                    values={values}
                                                    errors={errors}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    required
                                                    autoFocus
                                                    setFieldValue={
                                                        setFieldValue
                                                    }
                                                    locations={accountLocations}
                                                    locationNames={
                                                        locationNames
                                                    }
                                                    setFieldTouched={
                                                        setFieldTouched
                                                    }
                                                />
                                                <TranslatedValidatedLocationSelect
                                                    path="rule_id"
                                                    values={values}
                                                    errors={errors}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    required
                                                    setFieldValue={
                                                        setFieldValue
                                                    }
                                                    locations={accountLocations}
                                                    locationNames={
                                                        locationNames
                                                    }
                                                    setFieldTouched={
                                                        setFieldTouched
                                                    }
                                                />
                                            </HalfNHalf>
                                        </FormGroup>
                                    </EditWrapper>
                                    <FormGroup>
                                        <FieldArray
                                            name="destinations"
                                            render={(arrayHelpers) => (
                                                <div>
                                                    {values.destinations.map(
                                                        (destination, idx) => {
                                                            return (
                                                                <Destination
                                                                    key={idx}
                                                                    destination={
                                                                        destination
                                                                    }
                                                                    setFieldValue={
                                                                        setFieldValue
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    arrayHelpers={
                                                                        arrayHelpers
                                                                    }
                                                                    index={idx}
                                                                    path={`destinations.${idx}`}
                                                                    levels={3}
                                                                    values={
                                                                        values
                                                                    }
                                                                    errors={
                                                                        errors
                                                                    }
                                                                    touched={
                                                                        touched
                                                                    }
                                                                    setFieldTouched={
                                                                        setFieldTouched
                                                                    }
                                                                    locations={
                                                                        accountLocations
                                                                    }
                                                                    locationNames={
                                                                        locationNames
                                                                    }
                                                                />
                                                            );
                                                        },
                                                    )}
                                                    <AddStep
                                                        onClick={() =>
                                                            arrayHelpers.push(
                                                                defaultDestination(
                                                                    values.destinations,
                                                                ),
                                                            )
                                                        }
                                                    >
                                                        +{" "}
                                                        {t(
                                                            "payout_rules.new_payout_rule.add_step",
                                                        )}
                                                    </AddStep>
                                                    {overallDestinationsError && (
                                                        <OverallError
                                                            message={
                                                                overallDestinationsError
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        />
                                    </FormGroup>
                                    <ButtonGroup>
                                        <BackButton>
                                            {t(
                                                "payout_rules.new_payout_rule.abort",
                                            )}
                                        </BackButton>
                                        <Button
                                            disabled={submitDisabled}
                                            type="submit"
                                        >
                                            {isEdit
                                                ? t(
                                                      "payout_rules.new_payout_rule.edit_payout_rule",
                                                  )
                                                : t(
                                                      "payout_rules.new_payout_rule.create_payout_rule",
                                                  )}
                                        </Button>
                                    </ButtonGroup>
                                    {isLoading && <LoadingOverlay />}
                                </Wrapper>
                            </Card>
                        </Page>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default PayoutRuleForm;

const Form = styled.form`
    width: 100%;
`;

const Wrapper = styled.div`
    position: relative;
    max-width: ${grid.spans.span8};
    margin: 0 auto;
    width: 100%;
`;

const Title = styled(H1)`
    color: ${colors.text};
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

const AddStep = styled.div`
    padding: ${distances.small} ${distances.normal};
    background-color: ${colors.backgroundAlt};
    cursor: pointer;
    margin-bottom: 20px;
    border-radius: 2px;
    border: ${border.normal} solid transparent;

    &:hover {
        border: ${border.normal} solid ${colors.interactiveBackgroundHover};
    }
`;

const EditWrapper = styled.div<{ isEditable: boolean }>`
    pointer-events: ${(props) => (props.isEditable ? "auto" : "none")};
    opacity: ${(props) => (props.isEditable ? "1" : "0.6")};
`;
