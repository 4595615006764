import { connect } from "react-redux";

import { State } from "../../../../reducer";
import { getProfiles } from "../actions";
import { getProfilesSelector } from "../selectors";

import ProfilesList from "../components/ProfilesList";

const mapStateToProps = (state: State) => ({
    profiles: getProfilesSelector(state),
});

const mapDispatchToProps = {
    getProfiles,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilesList);
