import createExternalStore from "../../../../common/utils/createExternalStore";
import { CORE_API_HOSTNAME } from "../../../../env";
import fulfill from "../../../../fulfill";
import type {
    Client,
    Grant,
    HasSecretClientResponse,
} from "../../../../types/management-auth";

export interface State {
    isLoading: boolean;
    client: HasSecretClientResponse | undefined;
    grants: Grant[] | undefined;
    isSecretSafe: boolean;
}

export const initialState: State = {
    isLoading: false,
    client: undefined,
    grants: undefined,
    isSecretSafe: false,
};

export const newClientStore = createExternalStore(
    initialState,
    {
        createClient: (state) => ({
            ...state,
            isLoading: true,
        }),
        createClientResponse: (state, client: HasSecretClientResponse) => ({
            ...state,
            isLoading: false,
            client,
        }),
        createGrantsResponse: (state, grants: Grant[]) => ({
            ...state,
            isLoading: false,
            grants,
        }),
        toggleSecretIsSafe: (state, value: boolean) => ({
            ...state,
            isSecretSafe: value,
        }),
        resetForm: () => initialState,
    },
    "newClient",
);

export const actions = {
    resetForm: () => newClientStore.dispatch("resetForm"),
    createClient: async (newClient: Client, newGrants: Grant[]) => {
        newClientStore.dispatch("createClient");
        const audience = newGrants[0].audience;
        const account = audience.split("/").pop();
        const result: HasSecretClientResponse = await fulfill.post<Client>({
            accountId: account || "",
            url: `${CORE_API_HOSTNAME}/v1/accounts/${account}/auth/clients`,
            json: newClient,
            handlers: {
                200: (client: HasSecretClientResponse) => client,
            },
        });
        newClientStore.dispatch("createClientResponse", result);
        const grants = newGrants.map((grant) => ({
            ...grant,
            client_id: result.client_id,
        }));
        const results = await Promise.all(
            grants.map((grant) => {
                const audience = grant.audience;
                const account = audience.split("/").pop();
                return fulfill.post<Client>({
                    accountId: account || "",
                    url: `${CORE_API_HOSTNAME}/v1/accounts/${account}/auth/client-grants`,
                    json: grant,
                    handlers: {
                        200: (createdGrant: Grant) => ({
                            success: true,
                            grant: createdGrant,
                        }),
                    },
                });
            }),
        );
        const error = results.find((result) => result.success !== true);
        if (error) {
            return error;
        }

        newClientStore.dispatch(
            "createGrantsResponse",
            results.map((result) => result.grant),
        );

        return result;
    },
    toggleSecretIsSafe: (value: boolean) =>
        newClientStore.dispatch("toggleSecretIsSafe", value),
};
