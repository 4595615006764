import cc, { CurrencyCodeRecord } from "currency-codes";
import { FieldArray, Formik, getIn } from "formik";
import type { i18n } from "i18next";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import useConfirmationDialog from "../../../../common/hooks/useConfirmationDialog";
import { Button, ButtonGroup } from "../../../../components/Buttons";
import { Money } from "../../../../components/Formatters";
import { FormattedInput, ValidationState } from "../../../../components/Forms";
import Icon from "../../../../components/Icons";
import { Select } from "../../../../components/SimpleSelect/Select";
import { H2, H6, P } from "../../../../components/Typography";
import View from "../../../../components/View";
import {
    fromMonetaryAmountString,
    toMonetaryAmountString,
} from "../../../../helpers/formatters";
import {
    getLocaleNumeralDecimal,
    getLocaleNumeralDelimiter,
} from "../../../../helpers/intl";
import { getCurrentLanguage } from "../../../../i18n";
import {
    defaultRadius,
    distances,
    palette,
} from "../../../../styles/constants";
import ExclamationIconSvg from "../../../admin/bankfile/icons/ExclamationIcon.svg";
import type { PayoutBuffer } from "../../../types";

type PayoutBufferEditorProps<
    T extends {
        payout_buffer?: PayoutBuffer;
    },
> = {
    payoutBuffer: T["payout_buffer"];
    updatePayoutBuffer: (payoutBuffer: T["payout_buffer"]) => void;
    onClose?: () => void;
    defaultCurrency?: string;
    disabled?: boolean;
    inModal?: boolean;
    willContinue?: boolean;
};

const supportedCurrencies = ["NOK", "SEK", "DKK", "EUR", "GBP"];

function createKey<T extends { id: string }[]>(existingItems: T) {
    const existingKeys = existingItems.map((item) => item.id);
    let key = "";
    do {
        key = Math.random().toString(36).substring(7);
    } while (existingKeys.includes(key));
    return key;
}

function initalizeBuffers<
    T extends {
        payout_buffer?: PayoutBuffer;
    },
>(payoutBuffer: T["payout_buffer"] | undefined, i18n: i18n) {
    const currentBuffers = (payoutBuffer?.buffers || []).map((buffer) => ({
        ...buffer,
        id: createKey([]),
        type: "current",
        humanAmount: buffer.fixed_amount
            ? toMonetaryAmountString(
                  getCurrentLanguage(i18n),
                  Number.parseInt(buffer.fixed_amount),
                  buffer.currency,
              )
            : "",
    }));
    if (!currentBuffers.length) {
        return [
            {
                id: createKey([]),
                currency: "",
                fixed_amount: "",
                humanAmount: "",
                type: "new",
            },
        ];
    }
    return currentBuffers;
}

export default function PayoutBufferEditor<
    T extends {
        payout_buffer?: PayoutBuffer;
    },
>(props: PayoutBufferEditorProps<T>) {
    const {
        payoutBuffer,
        updatePayoutBuffer,
        disabled,
        inModal,
        onClose,
        willContinue,
    } = props;
    const { t, i18n } = useTranslation("payout");
    const { modal, triggerConfirmation } = useConfirmationDialog();
    const lng = getCurrentLanguage(i18n);

    const Title = inModal ? H2 : H6;
    const delimiter = getLocaleNumeralDelimiter(lng);
    const numeralDecimalMark = getLocaleNumeralDecimal(lng);

    return (
        <>
            <Formik
                initialValues={{
                    buffers: initalizeBuffers(payoutBuffer, i18n),
                }}
                onSubmit={(values) => {
                    if (disabled) {
                        return;
                    }
                    updatePayoutBuffer({
                        buffers: values.buffers.map((b) => ({
                            currency: b.currency,
                            fixed_amount: `${fromMonetaryAmountString(lng, b.humanAmount || b.fixed_amount!, b.currency)}`,
                        })),
                    });
                }}
                validate={(values) => {
                    const errors: {
                        buffers?: {
                            currency?: string;
                            fixed_amount?: string;
                        }[];
                    } = {};
                    values.buffers.forEach((buffer, index) => {
                        if (!buffer.currency) {
                            errors.buffers = errors.buffers || [];
                            errors.buffers[index] = errors.buffers[index] || {};
                            errors.buffers[index].currency = t(
                                "payout_buffer.form.fields.currency.errors.required",
                            );
                        }
                        if (!buffer.fixed_amount) {
                            errors.buffers = errors.buffers || [];
                            errors.buffers[index] = errors.buffers[index] || {};
                            errors.buffers[index].fixed_amount = t(
                                "payout_buffer.form.fields.fixed_amount.errors.required",
                            );
                        } else if (isNaN(parseFloat(buffer.fixed_amount))) {
                            errors.buffers = errors.buffers || [];
                            errors.buffers[index] = errors.buffers[index] || {};
                            errors.buffers[index].fixed_amount = t(
                                "payout_buffer.form.fields.fixed_amount.errors.invalid",
                            );
                        }
                    });
                    return errors;
                }}
            >
                {(form) => {
                    const hasErrors = Object.keys(form.errors).length > 0;
                    const hasTouched = Object.keys(form.touched).length > 0;
                    const currencies = supportedCurrencies.filter(
                        (currency) =>
                            !form.values.buffers.some(
                                (buffer) => buffer.currency === currency,
                            ),
                    );
                    return (
                        <Wrapper onSubmit={form.handleSubmit}>
                            {disabled && !form.values.buffers.length ? null : (
                                <View
                                    width="100%"
                                    justify="space-between"
                                    mb={distances.small}
                                >
                                    {inModal ? (
                                        <H2>{t("payout_buffer.form.title")}</H2>
                                    ) : (
                                        <P>{t("payout_buffer.form.title")}</P>
                                    )}
                                </View>
                            )}
                            <FieldArray
                                name="buffers"
                                render={(arrayHelpers) => (
                                    <View
                                        width="100%"
                                        alignItems="flex-start"
                                        flex={1}
                                        direction="column"
                                    >
                                        {form.values.buffers.map(
                                            (buffer, index) => {
                                                const exponent = cc.code(
                                                    buffer.currency,
                                                )
                                                    ? (
                                                          cc.code(
                                                              buffer.currency,
                                                          ) as CurrencyCodeRecord
                                                      ).digits
                                                    : 2;
                                                const currencyError = getIn(
                                                    form.errors,
                                                    `buffers[${index}].currency`,
                                                );
                                                const fixedAmountError = getIn(
                                                    form.errors,
                                                    `buffers[${index}].fixed_amount`,
                                                );
                                                const currencyTouched = getIn(
                                                    form.touched,
                                                    `buffers[${index}].currency`,
                                                );
                                                const fixedAmountTouched =
                                                    getIn(
                                                        form.touched,
                                                        `buffers[${index}].fixed_amount`,
                                                    );
                                                const onRemoveBuffer =
                                                    async () => {
                                                        if (
                                                            !buffer.currency ||
                                                            !buffer.fixed_amount
                                                        ) {
                                                            // empty buffers can be removed without confirmation
                                                            arrayHelpers.remove(
                                                                index,
                                                            );
                                                            return;
                                                        }
                                                        const confirm =
                                                            await triggerConfirmation(
                                                                {
                                                                    children: (
                                                                        confirm,
                                                                        cancel,
                                                                    ) => (
                                                                        <View
                                                                            gap={
                                                                                distances.small
                                                                            }
                                                                            alignItems="center"
                                                                            direction="column"
                                                                            width="100%"
                                                                        >
                                                                            <View
                                                                                gap={
                                                                                    distances.tiny
                                                                                }
                                                                                alignItems="center"
                                                                                direction="column"
                                                                                width="100%"
                                                                            >
                                                                                <IconWrapper>
                                                                                    <ExclamationIconSvg />
                                                                                </IconWrapper>
                                                                                <P
                                                                                    fontWeight={
                                                                                        500
                                                                                    }
                                                                                    fontSize={
                                                                                        18
                                                                                    }
                                                                                    textAlign="center"
                                                                                    style={{
                                                                                        wordBreak:
                                                                                            "break-word",
                                                                                        whiteSpace:
                                                                                            "pre-wrap",
                                                                                    }}
                                                                                >
                                                                                    {t(
                                                                                        "payout_buffer.form.confirmation_remove",
                                                                                    )}
                                                                                </P>
                                                                            </View>
                                                                            <BufferToBeDeleted>
                                                                                <P fontSize="20px">
                                                                                    <Money
                                                                                        amount={fromMonetaryAmountString(
                                                                                            lng,
                                                                                            buffer.humanAmount ||
                                                                                                buffer.fixed_amount!,
                                                                                            buffer.currency,
                                                                                        )}
                                                                                        currency={
                                                                                            buffer.currency
                                                                                        }
                                                                                        boldCurrency
                                                                                    />
                                                                                </P>
                                                                            </BufferToBeDeleted>
                                                                            <View
                                                                                direction="column"
                                                                                width="100%"
                                                                                gap={
                                                                                    distances.tiny
                                                                                }
                                                                            >
                                                                                <Button
                                                                                    onClick={
                                                                                        confirm
                                                                                    }
                                                                                    type="button"
                                                                                    className="stretch red"
                                                                                >
                                                                                    {t(
                                                                                        "payout_buffer.form.actions.confirm.remove",
                                                                                    )}
                                                                                </Button>
                                                                                <Button
                                                                                    onClick={
                                                                                        cancel
                                                                                    }
                                                                                    type="button"
                                                                                    className="alt stretch"
                                                                                >
                                                                                    {t(
                                                                                        "payout_buffer.form.actions.confirm.cancel",
                                                                                    )}
                                                                                </Button>
                                                                            </View>
                                                                        </View>
                                                                    ),
                                                                    wrapperStyle:
                                                                        {
                                                                            minWidth:
                                                                                "200px",
                                                                            maxWidth:
                                                                                "300px",
                                                                            padding:
                                                                                distances.small,
                                                                            borderRadius:
                                                                                "10px",
                                                                        },
                                                                },
                                                            );
                                                        if (confirm) {
                                                            arrayHelpers.remove(
                                                                index,
                                                            );
                                                        }
                                                    };
                                                return (
                                                    <View
                                                        direction="column"
                                                        gap={distances.normal}
                                                        alignItems="flex-start"
                                                        key={buffer.id}
                                                        style={{
                                                            border: `1px solid ${palette.neutral[300]}`,
                                                            padding: "1rem",
                                                            borderRadius: "4px",
                                                            marginBottom:
                                                                "1rem",
                                                        }}
                                                    >
                                                        <View
                                                            width="100%"
                                                            justify="space-between"
                                                            alignItems="flex-start"
                                                        >
                                                            <Title>
                                                                {t(
                                                                    "payout_buffer.form.entity",
                                                                    {
                                                                        index:
                                                                            index +
                                                                            1,
                                                                    },
                                                                )}
                                                            </Title>
                                                            <Button
                                                                className="small small-icon icon"
                                                                onClick={
                                                                    onRemoveBuffer
                                                                }
                                                                type="button"
                                                                data-testid={`remove-buffer-${index}`}
                                                            >
                                                                <Icon icon="delete" />
                                                            </Button>
                                                        </View>
                                                        <View
                                                            width="100%"
                                                            justify="space-between"
                                                            alignItems="flex-start"
                                                            gap={
                                                                distances.normal
                                                            }
                                                        >
                                                            <View
                                                                gap={
                                                                    distances.small
                                                                }
                                                                width="100%"
                                                            >
                                                                <Select
                                                                    items={currencies.map(
                                                                        (
                                                                            currency,
                                                                        ) => ({
                                                                            value: currency,
                                                                            label: currency,
                                                                        }),
                                                                    )}
                                                                    onChange={(
                                                                        value,
                                                                    ) =>
                                                                        form.setFieldValue(
                                                                            `buffers[${index}].currency`,
                                                                            value,
                                                                        )
                                                                    }
                                                                    onBlur={() => {
                                                                        form.setFieldTouched(
                                                                            `buffers[${index}].currency`,
                                                                            true,
                                                                        );
                                                                    }}
                                                                    defaultValue={
                                                                        buffer.currency
                                                                    }
                                                                    label={t(
                                                                        "payout_buffer.form.fields.currency.label",
                                                                    )}
                                                                    disabled={
                                                                        disabled ||
                                                                        buffer.type ===
                                                                            "current"
                                                                    }
                                                                    style={{
                                                                        marginBottom:
                                                                            "20px",
                                                                    }}
                                                                    error={
                                                                        currencyTouched &&
                                                                        currencyError
                                                                            ? currencyError
                                                                            : undefined
                                                                    }
                                                                    data-testid={`buffer-currency-${index}`}
                                                                />
                                                                <FormattedInput
                                                                    type="tel"
                                                                    inputMode="numeric"
                                                                    name={`payout-buffer-${buffer.id}.fixed_amount`}
                                                                    onChange={(
                                                                        e,
                                                                    ) => {
                                                                        const value =
                                                                            e
                                                                                .target
                                                                                ?.value ||
                                                                            "";
                                                                        const fixed_amount =
                                                                            fromMonetaryAmountString(
                                                                                lng,
                                                                                value,
                                                                                buffer.currency,
                                                                            );
                                                                        const humanAmount =
                                                                            toMonetaryAmountString(
                                                                                lng,
                                                                                fixed_amount,
                                                                                buffer.currency,
                                                                            );
                                                                        form.setFieldValue(
                                                                            `buffers[${index}].fixed_amount`,
                                                                            fixed_amount,
                                                                        );
                                                                        form.setFieldValue(
                                                                            `buffers[${index}].humanAmount`,
                                                                            humanAmount,
                                                                        );
                                                                    }}
                                                                    placeholder={toMonetaryAmountString(
                                                                        lng,
                                                                        0,
                                                                        buffer.currency,
                                                                    )}
                                                                    value={
                                                                        buffer.humanAmount ||
                                                                        toMonetaryAmountString(
                                                                            lng,
                                                                            Number.parseInt(
                                                                                buffer.fixed_amount ||
                                                                                    "0",
                                                                            ),
                                                                            buffer.currency,
                                                                        )
                                                                    }
                                                                    onBlur={() => {
                                                                        form.setFieldTouched(
                                                                            `buffers[${index}].fixed_amount`,
                                                                            true,
                                                                        );
                                                                    }}
                                                                    className="stretch"
                                                                    label={t(
                                                                        "payout_buffer.form.fields.fixed_amount.label",
                                                                    )}
                                                                    required
                                                                    validation={
                                                                        fixedAmountTouched &&
                                                                        fixedAmountError
                                                                            ? {
                                                                                  state: ValidationState.Invalid,
                                                                                  message:
                                                                                      fixedAmountError,
                                                                              }
                                                                            : undefined
                                                                    }
                                                                    readOnly={
                                                                        disabled
                                                                    }
                                                                    options={{
                                                                        numeral: true,
                                                                        numericOnly: true,
                                                                        numeralThousandsGroupStyle:
                                                                            "thousand",
                                                                        delimiter,
                                                                        numeralDecimalMark,
                                                                        numeralPositiveOnly: true,
                                                                        numeralDecimalScale:
                                                                            exponent,
                                                                    }}
                                                                    postfix={
                                                                        buffer.currency
                                                                    }
                                                                    testId={`buffer-amount-${index}`}
                                                                    mb="20px"
                                                                />
                                                            </View>
                                                        </View>
                                                    </View>
                                                );
                                            },
                                        )}
                                        {!disabled &&
                                            form.values.buffers.length !==
                                                supportedCurrencies.length && (
                                                <View
                                                    justify="flex-start"
                                                    gap={distances.small}
                                                >
                                                    <Button
                                                        className="outlined left-icon"
                                                        onClick={() => {
                                                            arrayHelpers.push({
                                                                id: createKey(
                                                                    form.values
                                                                        .buffers,
                                                                ),
                                                                currency: "",
                                                                fixed_amount:
                                                                    "",
                                                                humanAmount: "",
                                                                type: "new",
                                                            });
                                                        }}
                                                        type="button"
                                                        data-testid="add-buffer"
                                                    >
                                                        <Icon icon="plus" />
                                                        {t(
                                                            "payout_buffer.form.actions.add_buffer",
                                                        )}
                                                    </Button>
                                                </View>
                                            )}
                                    </View>
                                )}
                            />
                            {!disabled && (
                                <ButtonGroup>
                                    <Button
                                        className="alt"
                                        onClick={onClose}
                                        type="button"
                                        data-test-id="close-buffer-editor"
                                    >
                                        {t("payout_buffer.form.actions.cancel")}
                                    </Button>
                                    <Button
                                        disabled={
                                            form.values.buffers.length === 0 &&
                                            form.dirty
                                                ? false
                                                : hasErrors || !hasTouched
                                        }
                                        type="submit"
                                        data-testid="save-buffer"
                                    >
                                        {willContinue
                                            ? t(
                                                  "payout_buffer.form.actions.continue",
                                              )
                                            : t(
                                                  "payout_buffer.form.actions.save_buffer",
                                              )}
                                    </Button>
                                </ButtonGroup>
                            )}
                        </Wrapper>
                    );
                }}
            </Formik>
            {modal}
        </>
    );
}

const Wrapper = styled.form`
    display: flex;
    flex-direction: column;
    min-height: inherit;
    &[data-submitting='true'] {
        pointer-events: none;
        opacity: 0.5;
    }
`;

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: ${palette.destructive[100]};
    svg {
        color: ${palette.destructive[500]};
        width: 30px;
        height: 30px;
    }
`;

const BufferToBeDeleted = styled.div`
    border-radius: ${defaultRadius};
    padding: ${distances.tiny};
`;
