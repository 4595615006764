import {
    ComponentType,
    ReactNode,
    createContext,
    forwardRef,
    useContext,
    useMemo,
} from "react";
import { useDispatch } from "react-redux";
import { useAccountId } from "./auth/useAccountId";
import { PAYOUT_API_HOSTNAME } from "./env";
import {
    createSellerApprovalsActions,
    namespace as sellerApprovalsNamespace,
} from "./settings/approvalsActions";
import {
    namespace as billingNamespace,
    createConnectedActions as createbillingActions,
} from "./settings/billingActions";
import {
    namespace as cddNamespace,
    createCddActions,
} from "./settings/cddActions";
import {
    namespace as checkoutConfigurationNamespace,
    createCheckoutConfigurationActions,
} from "./settings/checkoutConfigurationActions";
import {
    createLocationActions,
    namespace as locationNamespace,
} from "./settings/locations/locationActions";
import {
    createPaymentsApprovalsActions,
    namespace as paymentsApprovalsNamespace,
} from "./settings/paymentConnections/approvalsPayments/actions";
import {
    createConnectedActions as createReportSettingsActions,
    namespace as reportSettingsNamespace,
} from "./settings/reports/actions";

import {
    createConnectedActions as createSettlementReportSettingsActions,
    namespace as settlementReportSettingsNamespace,
} from "./settings/settlementReports/actions";

import {
    PaymentConnectionActions,
    createPaymentConnectionActions,
} from "./settings/paymentConnections/actions";

import {
    createConfigActions as createPayoutConfigActions,
    namespace as payoutConfigNamespace,
} from "./payout/merchant/config/actions";

type Actions = PaymentConnectionActions & {
    [payoutConfigNamespace]: ReturnType<typeof createPayoutConfigActions>;
    [cddNamespace]: ReturnType<typeof createCddActions>;
    [sellerApprovalsNamespace]: ReturnType<typeof createSellerApprovalsActions>;
    [locationNamespace]: ReturnType<typeof createLocationActions>;
    [reportSettingsNamespace]: ReturnType<typeof createReportSettingsActions>;
    [settlementReportSettingsNamespace]: ReturnType<
        typeof createSettlementReportSettingsActions
    >;
    [checkoutConfigurationNamespace]: ReturnType<
        typeof createCheckoutConfigurationActions
    >;
    [billingNamespace]: ReturnType<typeof createbillingActions>;
    [paymentsApprovalsNamespace]: ReturnType<
        typeof createPaymentsApprovalsActions
    >;
};

const ActionsContext = createContext<Actions | undefined>(undefined);

type ActionsProviderProps = {
    children: ReactNode;
};

export const ActionsProvider = ({ children }: ActionsProviderProps) => {
    const accountId = useAccountId();
    const dispatch = useDispatch();

    const actions = useMemo<Actions>(
        () => ({
            [payoutConfigNamespace]: createPayoutConfigActions(
                dispatch,
                accountId,
                PAYOUT_API_HOSTNAME,
            ),
            [cddNamespace]: createCddActions(dispatch, accountId),
            [sellerApprovalsNamespace]: createSellerApprovalsActions(
                dispatch,
                accountId,
            ),
            [locationNamespace]: createLocationActions(dispatch, accountId),
            [reportSettingsNamespace]: createReportSettingsActions(dispatch),
            [settlementReportSettingsNamespace]:
                createSettlementReportSettingsActions(dispatch),
            [checkoutConfigurationNamespace]:
                createCheckoutConfigurationActions(dispatch, accountId),
            [billingNamespace]: createbillingActions(dispatch),
            ...createPaymentConnectionActions(dispatch),
            [paymentsApprovalsNamespace]:
                createPaymentsApprovalsActions(dispatch),
        }),
        [dispatch, accountId],
    );

    return (
        <ActionsContext.Provider value={actions}>
            {children}
        </ActionsContext.Provider>
    );
};

export const useActions = <Scope extends keyof Actions>(
    scope: Scope,
): Actions[Scope] => useContext(ActionsContext)![scope];

export type WithActions<Scope extends keyof Actions> = Actions[Scope];

export const withActions = <
    Scope extends keyof Actions,
    TProps extends WithActions<Scope>,
    TComponent extends ComponentType<TProps>,
>(
    Component: TComponent,
    scope: Scope,
) =>
    forwardRef<TComponent, Omit<TProps, keyof WithActions<Scope>>>(
        (props, ref) => (
            <ActionsContext.Consumer>
                {(actions) => (
                    <Component
                        ref={ref}
                        {...(actions![scope] as any)}
                        {...props}
                    />
                )}
            </ActionsContext.Consumer>
        ),
    );
