import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { useActions } from "../../../Actions";
import { useAccountIds } from "../../../auth/accessToken/components/withAccountIds";
import { useInterval } from "../../../common/hooks/useInterval";
import useDocumentVisibleAgainEffect from "../../../util/useDocumentVisibleAgainEffect";
import { namespace } from "./actions";
import { getApprovalsSelector } from "./selectors";

export const usePaymentApprovals = (options?: {
    forceCheck?: boolean;
}) => {
    const { postApproval, fetchPaymentApprovals } = useActions(namespace);
    const fetchPaymentApprovalsRef = useRef(fetchPaymentApprovals);
    const { prodAccountId } = useAccountIds();
    const {
        approvals,
        isLoading,
        accountId: stateAccountId,
        isPosting,
        posted,
    } = useSelector(getApprovalsSelector);

    // Load on initial use and when prodAccountId changes
    // biome-ignore lint/correctness/useExhaustiveDependencies: Re-run on changes
    useEffect(() => {
        if (prodAccountId && prodAccountId !== stateAccountId) {
            fetchPaymentApprovalsRef.current(
                prodAccountId,
                undefined,
                options?.forceCheck,
            );
        }
    }, [
        prodAccountId,
        stateAccountId,
        fetchPaymentApprovalsRef,
        options?.forceCheck,
    ]);

    // Load on regained browser tab focus
    useDocumentVisibleAgainEffect(() => {
        if (prodAccountId && !isLoading && !isPosting) {
            fetchPaymentApprovalsRef.current(
                prodAccountId,
                false,
                options?.forceCheck,
            );
        }
    });

    // Update approval status silently once every 10 seconds
    useInterval(() => {
        if (prodAccountId && !isLoading && !isPosting) {
            fetchPaymentApprovalsRef.current(
                prodAccountId,
                true,
                options?.forceCheck,
            );
        }
    }, 10 * 1000);

    return {
        isLoading,
        isPosting,
        approvals: approvals || [],
        postApproval,
        posted,
    };
};
