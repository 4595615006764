import { useAccountId } from "../../useAccountId";
import { decodeTokenScopes } from "../helpers";
import useAuth from "./useAuth";

export default function useScopes() {
    const accountId = useAccountId();
    const tokens = useAuth((state) => state.tokens);
    const token = tokens[accountId];
    return decodeTokenScopes(token);
}
