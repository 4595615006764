import { connect } from "react-redux";

import { getAccountUser } from "../../../../auth/accessToken/selectors";
import { State } from "../../../../reducer";
import { createGrant, getClient, resetForm } from "../actions";
import { getClientSelector } from "../selectors";

import NewClientGrant from "../components/NewClientGrant";

const mapStateToProps = (state: State) => ({
    accountUser: getAccountUser(state),
    client: getClientSelector(state),
});

const mapDispatchToProps = {
    resetForm,
    getClient,
    createGrant,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewClientGrant);
