import { useLocation } from "react-router-dom";
import styled, { keyframes } from "styled-components/macro";
import { ButtonLink } from "../../../components/Buttons";
import { palette, zIndex } from "../../../styles/constants";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingOverlay } from "../../../components/Loading";
import closeSvg from "../../../components/TopBar/icons/close.svg";

export default function EmbeddedQuickr() {
    const { t } = useTranslation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const caseUrl = searchParams.get("caseUrl");
    const accountId = searchParams.get("accountId");
    const [initIFrame, setInitIFrame] = useState(false);
    const [loading, setLoading] = useState(true);

    if (!caseUrl || !accountId) {
        return null;
    }

    const handleLoad = () => {
        setLoading(false);
    };

    return (
        <Wrapper onAnimationEnd={() => setInitIFrame(true)}>
            <Toolbar>
                <ButtonLink
                    to={`/${accountId}/settings/payment-connections`}
                    className="outlined small neutral-on-darkmode has-icons"
                >
                    <CloseIcon />
                    {t("settings.approvalsPayments.exit_quickr")}
                </ButtonLink>
            </Toolbar>
            <Container>
                {initIFrame && <Iframe onLoad={handleLoad} src={caseUrl} />}
                {loading && <LoadingOverlay />}
            </Container>
        </Wrapper>
    );
}

const appear = keyframes`
    from {
        opacity: 0;
        transform: translateY(100%);
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 1);
    }
    to {
        opacity: 1;
        transform: translateY(0);
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: ${zIndex.top};
    animation: ${appear} 0.5s cubic-bezier(0.1, 0.9, 0.2, 1);
`;

const Toolbar = styled.div`
    min-height: var(--topbar-main-height);
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    background: ${palette.darkmode[400]};
    padding: 0 var(--Spacing-1);
`;

const Iframe = styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
    flex: 1;
    background: ${palette.darkmode[400]};
`;

const Container = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    background: ${palette.darkmode[400]};
    position: relative;
`;

const CloseIcon = styled.div.attrs({ className: "icon" })`
    mask-image: url(${closeSvg});
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: ${palette.neutral[50]};
    width: 15px;
    height: 15px;
`;
