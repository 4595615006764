import createExternalStore from "../../../../common/utils/createExternalStore";
import { CORE_API_HOSTNAME } from "../../../../env";
import fulfill from "../../../../fulfill";
import type { AccountAdminUser } from "../../../../types/management-auth";
import type { UpdateAccountUserBody } from "../types";

export interface State {
    isLoading: boolean;
    isEdited: boolean;
}

export const initialState: State = {
    isLoading: false,
    isEdited: false,
};

export const editUserStore = createExternalStore(
    initialState,
    {
        resetForm: () => initialState,
        editUser: (state) => ({
            ...state,
            isLoading: true,
        }),
        fetchEditUserResponse: (state) => ({
            ...state,
            isEdited: true,
        }),
    },
    "editUser",
);

export const actions = {
    getUser: async (payload: { accountId: string; userId: string }) => {
        const result: { user: AccountAdminUser; users: AccountAdminUser[] } =
            await fulfill.get({
                accountId: payload.accountId,
                url: `${CORE_API_HOSTNAME}/v1/accounts/${payload.accountId}/management/users`,
                handlers: {
                    200: (users: AccountAdminUser[]) => {
                        const user = users.find(
                            (user) => user.id === payload.userId,
                        )!;
                        return { user };
                    },
                },
            });
        return result;
    },
    editUser: async (payload: {
        accountId: string;
        userId: string;
        user: UpdateAccountUserBody;
    }) => {
        editUserStore.dispatch("editUser");
        const result = await fulfill.put({
            accountId: payload.accountId,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${payload.accountId}/management/users/${payload.userId}`,
            json: payload.user,
            handlers: {
                200: () => editUserStore.dispatch("fetchEditUserResponse"),
            },
        });
        return result;
    },
    resetForm: () => editUserStore.dispatch("resetForm"),
};
