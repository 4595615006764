import { Dispatch } from "redux";
import { createAccountIdChangedCaseReducer } from "../../../auth/useAccountId";
import { CORE_API_HOSTNAME } from "../../../env";
import fulfill from "../../../fulfill";
import { Account } from "../../../types/management-auth";
import { createSwitchlessReducer } from "../../../util/switchlessReducer";
import { getAccount } from "../connections/actions";

export const namespace = "connections.payex";

export enum PayExModalState {
    Closed = "Closed",
    InitiateApplicationLoading = "InitiateApplicationLoading",
    ApplicationFormOpened = "ApplicationFormOpened",
    ApplicationReceived = "ApplicationReceived",
    PayExApiError = "PayExApiError",
}

export interface State {
    modalState: PayExModalState;
    account: Account | undefined;
}

export const initialState: State = {
    modalState: PayExModalState.Closed,
    account: undefined,
};

export const { reducer, actions } = createSwitchlessReducer({
    namespace,
    initialState,
    reducers: {
        openPayExModal: (state: State, account: Account) => {
            return {
                ...state,
                modalState: PayExModalState.ApplicationFormOpened,
                account,
            };
        },
        closePayExModal: (_: State) => {
            return initialState;
        },
        postConnection: (state: State) => {
            return {
                ...state,
                modalState: PayExModalState.InitiateApplicationLoading,
            };
        },
        postConnectionSuccess: (state: State) => {
            return {
                ...state,
                modalState: PayExModalState.ApplicationReceived,
            };
        },
        postConnectionFailure: (state: State) => {
            return {
                ...state,
                modalState: PayExModalState.PayExApiError,
            };
        },
        postConnectionComplete: (state: State) => {
            return {
                ...state,
                modalState: PayExModalState.Closed,
            };
        },
    },
    globalReducer: createAccountIdChangedCaseReducer(() => initialState),
});

export const createConnectedActions = (dispatch: Dispatch) => {
    const openPayExModal = (account: Account) => {
        dispatch(actions.openPayExModal(account));
    };

    const closePayExModal = () => {
        dispatch(actions.closePayExModal());
    };

    const finishRegistration = async (payload: {
        accountId: string;
        userId: string;
        body: {
            payee_id: string;
            payment_options: {
                type: string;
                currencies: string[];
            }[];
            token?: string;
            subsite?: string;
        };
    }) => {
        const { accountId, body, userId } = payload;
        dispatch(actions.postConnection());
        await fulfill.post({
            accountId,
            json: {
                signup: {
                    payee_id: body.payee_id,
                    payment_options: body.payment_options,
                    token: body.token,
                    subsite: body.subsite,
                },
            },
            url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/management/settings/connections/payex/payex.creditcard`,
            handlers: {
                200: () => {
                    dispatch(actions.postConnectionSuccess());
                },
                500: () => {
                    dispatch(actions.postConnectionFailure());
                },
                501: () => {
                    dispatch(actions.postConnectionFailure());
                },
                502: () => {
                    dispatch(actions.postConnectionFailure());
                },
                503: () => {
                    dispatch(actions.postConnectionFailure());
                },
                504: () => {
                    dispatch(actions.postConnectionFailure());
                },
            },
        });
        await fulfill.post({
            accountId,
            json: body,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/management/settings/connections/payex/payex.creditcard/${userId}`,
            handlers: {
                202: () => {
                    dispatch(getAccount(accountId));
                    dispatch(actions.postConnectionComplete());
                },
                500: () => {
                    dispatch(actions.postConnectionFailure());
                },
                501: () => {
                    dispatch(actions.postConnectionFailure());
                },
                502: () => {
                    dispatch(actions.postConnectionFailure());
                },
                503: () => {
                    dispatch(actions.postConnectionFailure());
                },
                504: () => {
                    dispatch(actions.postConnectionFailure());
                },
            },
        });
    };

    return {
        openPayExModal,
        closePayExModal,
        finishRegistration,
    };
};
