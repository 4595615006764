import _uniq from "lodash/uniq";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import LineBreaks from "../../../components/Formatters/LineBreaks";
import {
    LinkTr,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from "../../../components/Table/Fake";
import Tooltip from "../../../components/Tooltip";
import { Muted } from "../../../components/Typography";
import { formatOrgNumber } from "../../../helpers/formatters";
import { Account } from "../../../types/management-auth";
import { ApprovalsPayoutDestinationResponse } from "../../../types/management-auth/generated";
import { PayoutDestinationWithBankAccounts } from "../PayoutDestinationsList";
import PayoutDestinationStatus from "./PayoutDestinationStatus";

type PayoutDestinationTableProps = {
    accountId: string;
    approvalsWithoutPayoutDestination: ApprovalsPayoutDestinationResponse[];
    payoutDestinations: PayoutDestinationWithBankAccounts[];
    account: Account;
};

type ColonSeparatedProps = { prefix?: string; value?: string };
const ColonSeparated = ({ prefix, value }: ColonSeparatedProps) => (
    <>{[prefix, value].filter((x) => x).join(": ")}</>
);

const ApprovalName = ({
    approval,
    defaultReference,
}: {
    approval: ApprovalsPayoutDestinationResponse;
    defaultReference: string;
}) => {
    // We default to setting the reference to the merchants on plattform name. If the name
    // is missing we fall back to showing the reference for the seller approval, given
    // that it is not the default reference.

    const { t } = useTranslation(["app", "payout"]);
    if (approval.payout_destination_name) {
        return <>{approval.payout_destination_name}</>;
    }
    if (
        approval.payout_reference &&
        approval.payout_reference !== defaultReference
    ) {
        return <em>Ref: {approval.payout_reference}</em>;
    }
    return <Muted>{t("payout:payout_destination.no_name_approval")}</Muted>;
};

export const PayoutDestinationTable = ({
    accountId,
    approvalsWithoutPayoutDestination,
    payoutDestinations,
    account,
}: PayoutDestinationTableProps) => {
    const { t } = useTranslation(["app", "payout"]);
    const defaultReference =
        account?.company.display_name || account?.company.business_name || "";
    return (
        <Table className="no-wrap">
            <Thead>
                <Tr>
                    <PayoutListTh className="first-child">
                        {t("payout:payout_destination.payout_destination_id")}
                    </PayoutListTh>
                    <PayoutListTh>
                        {t("payout:payout_destination.name")}
                    </PayoutListTh>
                    <PayoutListTh>
                        {t("payout:bank_account.owner_orgno")}
                    </PayoutListTh>
                    <PayoutListTh>
                        {t("payout:payout_destination.linked_bank_account")}
                    </PayoutListTh>
                    <PayoutListTh className="last-child">
                        {t("payout:payout_destination.status")}
                    </PayoutListTh>
                </Tr>
            </Thead>
            <Tbody>
                {approvalsWithoutPayoutDestination.map((approval) => {
                    const hasPayoutDestination =
                        approval.payout_destination_id === approval.id;
                    return (
                        <LinkTr
                            key={approval.id}
                            to={`/${accountId}/settings/sellers/approvals/${approval.id}`}
                        >
                            <Td className="first-child ellipsis">
                                {approval.payout_destination_id}
                            </Td>
                            <Td className="ellipsis">
                                <ApprovalName
                                    approval={approval}
                                    defaultReference={defaultReference}
                                />
                            </Td>
                            <Td>
                                <ColonSeparated
                                    prefix={approval.country_code.toLocaleUpperCase()}
                                    value={formatOrgNumber(
                                        approval.country_code.toLocaleUpperCase(),
                                        approval.organization_number,
                                    )}
                                />
                            </Td>
                            <Td>
                                <ColonSeparated
                                    prefix={
                                        approval.bank_accounts[0]
                                            ?.bank_account_currency || ""
                                    }
                                    value={
                                        approval.bank_accounts[0]
                                            ?.bank_account_number || ""
                                    }
                                />
                            </Td>
                            <Td className="last-child">
                                <Tooltip
                                    title={t(
                                        `app:settings.account.payout.case_status_description.${approval.case_status}` as any,
                                    )}
                                >
                                    <PayoutDestinationStatus
                                        status={approval.case_status || "ERROR"}
                                    />
                                </Tooltip>
                            </Td>
                        </LinkTr>
                    );
                })}
                {payoutDestinations.map((pd) => {
                    return (
                        <LinkTr
                            key={pd.id}
                            to={`/${accountId}/settings/sellers/${pd.id}`}
                        >
                            <Td className="first-child ellipsis">
                                {pd.payout_destination_id}
                            </Td>
                            <Td className="ellipsis">
                                {pd.name ? (
                                    pd.name
                                ) : (
                                    <Muted>
                                        {t("payout:payout_destination.no_name")}
                                    </Muted>
                                )}
                            </Td>
                            <Td>
                                <LineBreaks
                                    lines={_uniq(
                                        pd.bankAccounts.map((ba) => (
                                            <ColonSeparated
                                                prefix={ba.owner_country_code}
                                                value={formatOrgNumber(
                                                    ba.owner_country_code,
                                                    ba.owner_orgno,
                                                )}
                                            />
                                        )),
                                    )}
                                />
                            </Td>
                            <Td>
                                <LineBreaks
                                    lines={pd.bankAccounts.map((ba) => (
                                        <ColonSeparated
                                            prefix={ba.bank_account_currency}
                                            value={ba.bank_account_number}
                                        />
                                    ))}
                                />
                            </Td>
                            <Td className="last-child">
                                <PayoutDestinationStatus
                                    status={
                                        pd.lastApprovalCaseStatus ||
                                        "PAYOUT_DESTINATION"
                                    }
                                />
                            </Td>
                        </LinkTr>
                    );
                })}
            </Tbody>
        </Table>
    );
};

const PayoutListTh = styled(Th)`
    width: 20%;
`;
