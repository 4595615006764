import { useState } from "react";
import { Redirect } from "react-router-dom";
import { useAccountIds } from "../../../../auth/accessToken/components/withAccountIds";
import { Account } from "../../../../types/management-auth";
import { checkAccountReadyForProduction } from "../../helpers";
import useAccount from "../../hooks/useAccount";
import EditFullAccount from "./EditFullAccount";
import PrefillAccount from "./PrefillAccount";

export type formSubmissionType = "notSubmitted" | "submitted";

const EditAccountPage = () => {
    const { account, isLoading, updateAccount } = useAccount();
    const isNotReadyForProduction =
        account && !checkAccountReadyForProduction(account);
    const [prefill, setPrefill] = useState<Account | undefined>();
    const [submitted, setWasSubmitted] =
        useState<formSubmissionType>("notSubmitted");
    const { urlAccountId } = useAccountIds();

    const resetForm = () => {
        setPrefill(undefined);
        setWasSubmitted("notSubmitted");
    };

    if (submitted === "submitted") {
        return <Redirect to={`/${urlAccountId}/settings/account`} />;
    }

    if (!account) {
        return null;
    }

    if (isNotReadyForProduction && !prefill) {
        return (
            <PrefillAccount
                account={account || prefill}
                setPrefill={setPrefill}
            />
        );
    } else {
        return (
            <EditFullAccount
                account={prefill || account}
                isLoading={isLoading}
                isPrefilled={!!prefill}
                resetForm={resetForm}
                setWasSubmitted={setWasSubmitted}
                updateAccount={updateAccount}
            />
        );
    }
};

export default EditAccountPage;
