import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import Card from "../../../../components/Card";
import { Dd, Dl, Dt } from "../../../../components/DescriptionList/Horizontal";
import { distances, grid } from "../../../../styles/constants";
import { Account } from "../../../../types/management-auth";
import { checkAccountReadyForProduction } from "../../helpers";

import CompanyAndBilling from "./CompanyAndBilling";
import TestModeBox from "./TestModeBox";

import PayoutInfo from "./PayoutInfo";

const InfoWrapper = styled.div`
    width: 100%;
    margin-right: ${grid.gutter};
`;

export interface AccountDetailsProps {
    account: Account;
    isLoading: boolean;
}

const AccountDetails = ({ account, isLoading }: AccountDetailsProps) => {
    const { t } = useTranslation("app");
    const showTestModeBox = useMemo(
        () => !checkAccountReadyForProduction(account),
        [account],
    );
    const showPayoutInfo = account?.company?.address?.country === "NO";
    return (
        <>
            {showTestModeBox && (
                <InfoWrapper>
                    <Card>
                        <TestModeBox accountId={account.account_id || ""} />
                    </Card>
                </InfoWrapper>
            )}
            {showTestModeBox && (
                <InfoWrapper>
                    <Card title={t("settings.account.account_info")}>
                        <Dl>
                            <Dt doubleColumn>
                                {t("settings.account.fields.id")}
                            </Dt>
                            <Dd>{account.account_id}</Dd>
                        </Dl>
                    </Card>
                </InfoWrapper>
            )}
            {!showTestModeBox && (
                <>
                    <InfoWrapper>
                        <Card marginBottom={distances.normal}>
                            <CompanyAndBilling
                                account={account}
                                isLoading={isLoading}
                            />
                        </Card>
                    </InfoWrapper>
                    {showPayoutInfo && (
                        <InfoWrapper>
                            <PayoutInfo account={account} />
                        </InfoWrapper>
                    )}
                </>
            )}
        </>
    );
};

export default AccountDetails;
