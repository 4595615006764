import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router";

import deepEqual from "fast-deep-equal";

import { useActions } from "../../../../Actions";
import Card from "../../../../components/Card";
import { LoadingOverlay } from "../../../../components/Loading";
import Page, { Back, Title } from "../../../../components/Page";
import InvalidAddressModal from "../../shared/InvalidAddressModal";
import LocationForm from "../../shared/LocationForm";
import { getGeoLocation, mapValues } from "../../shared/utils";
import { LocationFormValues } from "../../types";
import { mapValuesFromBackend } from "../utils";

import { useSelector } from "react-redux";
import { isLoading, salesLocationsRaw } from "../../selectors";

interface MatchParams {
    accountId: string;
    locationId: string;
}

const LocationEdit = () => {
    const { t, i18n } = useTranslation("app");
    const history = useHistory();
    const { getLocation, updateLocation } = useActions("settings.locations");
    const { accountId, locationId } = useRouteMatch<MatchParams>().params;
    const { location, loading } = useSelector((state) => ({
        location: salesLocationsRaw(state)[accountId]?.[locationId],
        loading: isLoading(state)(accountId),
    }));
    const [openErrorModal, setOpenErrorModal] = useState(false);

    const mappedValues = useMemo(
        () => (location ? mapValuesFromBackend(location, t) : undefined),
        [location, t],
    );

    const onSubmit = useCallback(
        async (values: LocationFormValues) => {
            if (loading) return;
            const geoLocation = await getGeoLocation(
                {
                    address_line: values.addressLine,
                    country: values.country.value,
                    postal_place: values.postalCode,
                    address_line_2: values.addressLine2,
                    postal_code: values.postalCode,
                } as any,
                i18n.language,
            );
            if (geoLocation) {
                values.latitude = geoLocation.latitude;
                values.longitude = geoLocation.longitude;
            } else {
                values.latitude = undefined;
                values.longitude = undefined;
            }

            if (!deepEqual(values, mappedValues)) {
                if (await updateLocation(mapValues(values), accountId))
                    history.push(
                        `/${accountId}/settings/locations/${values.locationId!}`,
                    );
            }
        },
        [
            accountId,
            updateLocation,
            i18n.language,
            loading,
            history,
            mappedValues,
        ],
    );

    useEffect(() => {
        if (!location && !loading) {
            getLocation(locationId, accountId);
        }
    }, [accountId, locationId, getLocation, location, loading]);

    return (
        <Page
            title={
                <>
                    <Back />
                    <Title>{t("locations.location_edit.title")}</Title>
                </>
            }
        >
            <Card title={t("locations.location_edit.subtitle")}>
                {location ? (
                    <LocationForm
                        locationFormValues={mappedValues!}
                        onSubmit={onSubmit}
                        isEdit
                    />
                ) : (
                    <LoadingOverlay />
                )}
            </Card>
            {openErrorModal && (
                <InvalidAddressModal
                    dismissModal={() => setOpenErrorModal(false)}
                />
            )}
        </Page>
    );
};

export default LocationEdit;
