import { useTranslation } from "react-i18next";
import type { ServiceFeatureStatus } from "../../../types/management-auth/generated";
import { EnabledBadge, PendingBadge } from "./elements";

type DailyPayoutsEnabledStatusProps = {
    status?: ServiceFeatureStatus;
    requested?: "enable" | "disable";
    active: boolean;
    isLoading: boolean;
};

export default function DailyPayoutsEnabledStatus({
    status,
    active,
    isLoading,
    requested,
}: DailyPayoutsEnabledStatusProps) {
    const { t } = useTranslation();

    if (isLoading) {
        return null;
    }

    if (status === "pending" && !active && requested === "enable") {
        return (
            <PendingBadge>
                <span>
                    {t("settings.additional_services.daily_payouts.pending")}
                </span>
            </PendingBadge>
        );
    }

    if (active || (status === "pending" && requested === "disable")) {
        return (
            <EnabledBadge>
                <span>
                    {t("settings.additional_services.daily_payouts.on")}
                </span>
            </EnabledBadge>
        );
    }

    return null;
}
