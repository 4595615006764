import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components/macro";

import Hr from "../../../../components/Hr";
import { H2 } from "../../../../components/Typography";
import { distances, grid } from "../../../../styles/constants";
import {
    Delivery,
    DeliveryDetails,
    Subscription,
} from "../../../../types/webhooks";
import DeliveriesTable from "./DeliveriesTable";
import DetailsInfo from "./DetailsInfo";
import Hero from "./Hero";
export interface HookDetailsProps extends WithTranslation {
    hook: Subscription;
    deliveries: Delivery[];
    deliveryDetails: DeliveryDetails | undefined;
    getDeliveryDetails: (params: {
        accountId: string;
        hookId: string;
        deliveryId: string;
    }) => void;
    openPingHookModal: () => void;
}

const HookDetails = ({
    t,
    hook,
    deliveries,
    deliveryDetails,
    getDeliveryDetails,
    openPingHookModal,
}: HookDetailsProps) => {
    return (
        <Wrapper>
            <Hero hook={hook} openPingHookModal={openPingHookModal} />
            <DetailsInfo hook={hook} />
            <DeliveryWrapper>
                <H2>{t("settings.hook.deliveries.title")}</H2>
                <Hr />
                <DeliveriesTable
                    hook={hook}
                    deliveries={deliveries}
                    deliveryDetails={deliveryDetails}
                    getDeliveryDetails={getDeliveryDetails}
                />
            </DeliveryWrapper>
        </Wrapper>
    );
};

export default withTranslation()(HookDetails);

const Wrapper = styled.div`
    position: relative;
`;

const DeliveryWrapper = styled.div`
    max-width: ${grid.spans.span8};
    margin: 0 auto;
    padding: ${distances.normal} ${distances.small};
`;
