import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import {
    LinkTr,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from "../../../../components/Table/Fake";
import { colors } from "../../../../styles/constants";

import { BillingPayoutRule } from "../../../../types/billing";

interface RowProps {
    payoutRule: BillingPayoutRule;
    accountId: string;
}

const Row = ({ payoutRule }: RowProps) => {
    const { t } = useTranslation();
    const Status = payoutRule.deleted_at ? Deleted : Active;
    return (
        <RuleTr
            key={payoutRule.id}
            to={`/${payoutRule.account_id}/settings/payout-rules/${payoutRule.id}`}
            className={`stop-payout_rules-before-edges ${payoutRule.deleted_at ? "deleted" : ""}`}
        >
            <Td className="first-child"> {payoutRule.rule_type}</Td>
            <Td>{payoutRule.rule_id}</Td>
            <Td className="last-child">
                <Status>
                    {t("payout_rules.payout_rules.fields.statustext", {
                        context:
                            payoutRule.deleted_at && payoutRule.deleted_by
                                ? "deleted"
                                : "active",
                    })}
                </Status>
            </Td>
        </RuleTr>
    );
};

export interface PayoutRuleTableProps {
    payoutRules: BillingPayoutRule[];
    accountId: string;
}

const PayoutRulesTable = ({ payoutRules, accountId }: PayoutRuleTableProps) => {
    const { t } = useTranslation();
    return (
        <Table className="no-wrap">
            <Thead>
                <Tr>
                    <Th className="first-child">
                        {t("payout_rules.payout_rules.fields.type")}
                    </Th>
                    <Th>{t("payout_rules.payout_rules.fields.id")}</Th>
                    <Th className="last-child">
                        {t("payout_rules.payout_rules.fields.status")}
                    </Th>
                </Tr>
            </Thead>
            {payoutRules.length > 0 && (
                <Tbody>
                    {payoutRules.map((payoutRule) => (
                        <Row
                            key={`${payoutRule.account_id}:${payoutRule.rule_type}:${payoutRule.rule_id}`}
                            payoutRule={payoutRule}
                            accountId={accountId}
                        />
                    ))}
                </Tbody>
            )}
        </Table>
    );
};

const RuleTr = styled(LinkTr)`
    &.deleted {
        background-color: ${colors.backgroundAlt};
    }
`;
const Active = styled.span`
    color: ${colors.primary};
`;

const Deleted = styled.span`
    color: ${colors.invalid};
`;

export default PayoutRulesTable;
