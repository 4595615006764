import debounce from "lodash/debounce";
import { FormEvent, ReactNode, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import { newLocationStateSelector } from "../selectors";

import {
    getOrgNumberValidator,
    validateRequired,
} from "../../../../helpers/validation";
import useDraft from "../../../../util/useDraft";
import {
    ValidationSchema,
    useValidatedSchema,
} from "../../../../util/useValidatedSchema";
import { searchCompanyName } from "../actions";

import { Button, ButtonGroup } from "../../../../components/Buttons";

import { useAccountIds } from "../../../../auth/accessToken/components/withAccountIds";
import Grid, { GridItem } from "../../../../components/Grid";
import { formatCountryCode } from "../../../../helpers/formatters";
import { getPhonePrefixFromCountry } from "../../shared/utils";

import { Input, ValidationState } from "../../../../components/Forms";
import Select from "../../../../components/Forms/Select";
import CountryDropdown from "../../../components/CountryDropdown";

const searchOrgNoSelector = createSelector(
    newLocationStateSelector,
    (newLocationState) =>
        newLocationState.results.map((a) => {
            return {
                organizationNumber: a.organization_number,
                businessName: a.business_name,
                name: a.business_name,
                addressLine: a.address.address_line,
                addressLine2: a.address.address_line_2,
                countryCode: a.address.country,
                postalCode: a.address.postal_code,
                postalPlace: a.address.postal_place,
                countryPrefix: getPhonePrefixFromCountry(a.address.country),
                website: a.website,
            };
        }),
);

type Org = ReturnType<typeof searchOrgNoSelector>[number];
const blankOrg: Org = {
    addressLine: "",
    addressLine2: "",
    countryCode: "",
    countryPrefix: "",
    name: "",
    organizationNumber: "",
    postalCode: "",
    postalPlace: "",
    website: "",
    businessName: "",
};

export type NewLocationEntryValues = Org & {
    accountId: string;
    locationId: string;
    organizationNumber: string;
    countryCode: string;
};

export type NewLocationEntryProps = {
    disabled?: boolean;
    readOnly?: boolean;
    initialValues: Partial<NewLocationEntryValues>;
    onChange?: (
        draft: Partial<NewLocationEntryValues>,
        combined: NewLocationEntryValues,
    ) => void;
    onSubmit: (values: NewLocationEntryValues) => void;
    children?: ReactNode;
};

export default function NewLocationEntry({
    disabled,
    readOnly,
    initialValues,
    onSubmit: propOnSubmit,
    onChange,
    children,
}: NewLocationEntryProps) {
    const { t } = useTranslation("app");
    const { urlAccountId } = useAccountIds();
    const dispatch = useDispatch();
    const orgSearchResults = useSelector(searchOrgNoSelector);

    const { dirty, combined, setValue, isTouched, setTouched, setSubmit } =
        useDraft<NewLocationEntryValues>(
            {
                ...blankOrg,
                accountId: urlAccountId,
                countryCode: "NO",
                locationId: "",
                organizationNumber: "",
                ...initialValues,
            },
            onChange,
        );

    const schema: ValidationSchema<NewLocationEntryValues> = useMemo(
        () => ({
            locationId: validateRequired(
                t("locations.location_form.validation.required", {
                    fieldName: t("locations.location_form.fields.location_id"),
                }),
            ),
            organizationNumber: [
                validateRequired(
                    t("locations.location_form.validation.required", {
                        fieldName: t("locations.location_form.fields.org_nr"),
                    }),
                ),
                (value, adjacent) =>
                    getOrgNumberValidator(
                        adjacent.countryCode.toUpperCase(),
                        t("locations.location_form.validation.invalid", {
                            fieldName: t(
                                "locations.location_form.fields.org_nr",
                            ),
                        }),
                    )(value),
            ],
        }),
        [t],
    );

    const debouncedSearchCompanyName = useMemo(
        () =>
            debounce((value: string) => {
                dispatch(searchCompanyName(value, urlAccountId));
            }, 500),
        [urlAccountId, dispatch],
    );

    const onChangeOrgNoInput = (value: string) => {
        if (combined.countryCode === "NO") {
            debouncedSearchCompanyName(value);
        }
        setValue("organizationNumber", value);
    };

    const onChangeField = useCallback(
        (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setValue(event.currentTarget.name, event.currentTarget.value);
        },
        [setValue],
    );

    const onBlurField = useCallback(
        (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setValue(event.currentTarget.name, event.currentTarget.value);
            setTouched(event.currentTarget.name);
        },
        [setValue, setTouched],
    );

    const onChangeOrgNo = useCallback(
        (_: any, option: string | Org | null) => {
            if (!!option) {
                setTouched("organizationNumber");
                if (typeof option === "string") {
                    setValue("organizationNumber", option);
                } else {
                    Object.entries(option).forEach(([key, value]) =>
                        setValue(key, value),
                    );
                }
            }
        },
        [setValue, setTouched],
    );
    const { hasErrors, result: errors } = useValidatedSchema({
        schema,
        value: combined,
        disableValidateOnMount: true,
    });

    const onSubmit = () => {
        setSubmit();
        if (hasErrors || !dirty || disabled) return;
        propOnSubmit(combined);
    };

    return (
        <>
            <Grid>
                <GridItem>
                    <Input
                        autoFocus
                        name="locationId"
                        label={t("locations.location_form.fields.location_id")}
                        placeholder={t(
                            "locations.location_form.placeholders.location_id",
                        )}
                        value={combined.locationId}
                        onChange={onChangeField}
                        onBlur={onBlurField}
                        required
                        autoComplete="off"
                        readOnly={readOnly}
                        validation={
                            isTouched("locationId") && errors.locationId
                                ? {
                                      state: ValidationState.Invalid,
                                      message: errors.locationId,
                                  }
                                : undefined
                        }
                    />
                </GridItem>
                <GridItem>
                    <CountryDropdown
                        name="countryCode"
                        label={t("locations.location_form.fields.country")}
                        value={combined.countryCode}
                        onChange={(value) => {
                            setValue("countryCode", value);
                            setValue("organizationNumber", "");
                        }}
                        placeholder=""
                    />
                </GridItem>
                <GridItem>
                    {combined.countryCode === "NO" && (
                        <Select
                            name="organizationNumber"
                            label={t("locations.location_form.fields.org_nr")}
                            placeholder={t(
                                "locations.location_form.placeholders.org_nr",
                            )}
                            value={combined.organizationNumber}
                            options={orgSearchResults}
                            getOptionLabel={(option) =>
                                `${option.name} - ${option.organizationNumber}`
                            }
                            getOptionValue={(option) =>
                                option.organizationNumber
                            }
                            onBlur={onBlurField}
                            maxOptions={10}
                            onInputChange={(e) =>
                                onChangeOrgNoInput(e.target.value)
                            }
                            onChange={(selection) =>
                                onChangeOrgNo(null, selection)
                            }
                            disabled={readOnly}
                            required
                            validation={
                                isTouched("organizationNumber") &&
                                errors.organizationNumber
                                    ? {
                                          state: ValidationState.Invalid,
                                          message: errors.organizationNumber,
                                      }
                                    : undefined
                            }
                        />
                    )}
                    {combined.countryCode !== "NO" && (
                        <Input
                            name="organizationNumber"
                            label={t("locations.location_form.fields.org_nr")}
                            placeholder={t(
                                "locations.location_form.placeholders.org_nr",
                            )}
                            value={combined.organizationNumber}
                            onChange={onChangeField}
                            onBlur={onBlurField}
                            required
                            autoComplete="off"
                            readOnly={readOnly}
                        />
                    )}
                </GridItem>
                {children}
            </Grid>
            <ButtonGroup width="100%">
                {!disabled && (
                    <Button
                        type="submit"
                        onClick={onSubmit}
                        disabled={!dirty || hasErrors}
                    >
                        {t("locations.new_location.move_on")}
                    </Button>
                )}
            </ButtonGroup>
        </>
    );
}
