import { createSelector } from "reselect";

import { State } from "../../../reducer";
import { namespace } from "./actions";

const getApprovalsState = (state: State) => state[namespace];
export const getApprovalsSelector = createSelector(
    [getApprovalsState],
    (s) => s,
);
