import { Account } from "../../../types/management-auth";
import { ActionTypes, Actions } from "./actions";

export enum PayExCallbackModalState {
    Closed = "Closed",
    ApplicationForm = "ApplicationForm",
    InitiateApplicationLoading = "InitiateApplicationLoading",
    ApplicationReceived = "ApplicationReceived",
    Error = "Error",
}

export interface State {
    payexModalState: PayExCallbackModalState;
    account: Account | undefined;
    type?: "swap_agreement" | "initial_connection_configuration";
    error?: any;
}

export const initialState: State = {
    payexModalState: PayExCallbackModalState.Closed,
    account: undefined,
};

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {
        case ActionTypes.OpenPayExCallbackModal:
            return {
                ...state,
                payexModalState: PayExCallbackModalState.ApplicationForm,
                account: action.payload.account,
                type: action.payload.type,
            };

        case ActionTypes.CompletePayExConnection:
            return {
                ...state,
                payexModalState:
                    PayExCallbackModalState.InitiateApplicationLoading,
            };

        case ActionTypes.PayExCreateCredentials:
            return {
                ...state,
                payexModalState:
                    PayExCallbackModalState.InitiateApplicationLoading,
            };

        case ActionTypes.FetchCompletePayExConnectionResponse:
            return {
                ...state,
                payexModalState: PayExCallbackModalState.ApplicationReceived,
            };

        case ActionTypes.FetchPayExCreateCredentialsResponse:
            return {
                ...state,
                payexModalState: PayExCallbackModalState.ApplicationReceived,
            };

        case ActionTypes.PayExApiError:
            return {
                ...state,
                payexModalState: PayExCallbackModalState.Error,
                error: action.payload,
            };

        case ActionTypes.ClosePayExCallbackModal:
            return {
                ...initialState,
            };

        default:
            return state;
    }
}
