import React from "react";
import {
    Trans,
    WithTranslation,
    useTranslation,
    withTranslation,
} from "react-i18next";
import styled from "styled-components/macro";

import { Formik } from "formik";
import { useSelector } from "react-redux";
import { useActions } from "../../../../Actions";
import { useAccountIds } from "../../../../auth/accessToken/components/withAccountIds";
import { Button, ButtonGroup } from "../../../../components/Buttons";
import {
    Checkbox,
    Dropdown,
    Input,
    ValidationState,
} from "../../../../components/Forms";
import { LoadingOverlay } from "../../../../components/Loading";
import Modal from "../../../../components/Modal";
import { H1, P } from "../../../../components/Typography";
import { getValueAt } from "../../../../helpers/getValueAt";
import {
    validate,
    validatePositiveNumber,
    validateRequired,
    validateUrl,
} from "../../../../helpers/validation";
import { distances, grid } from "../../../../styles/constants";
import { CollectorCredentialsData, ModalState, namespace } from "../actions";
import DirectAgreementForm from "./DirectAgreementForm";
import SubMerchantForm from "./SubMerchantForm";

interface ValidatedInputProps extends WithTranslation {
    values: any;
    initialValues: any;
    errors: any;
    path: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    touched: any;
    required?: boolean;
    maxlength?: number;
    type?: "text" | "password";
    formatter?: (value: string) => string;
    autoFocus?: boolean;
    disabled?: boolean;
}

const ValidatedInput = ({
    t,
    values,
    initialValues,
    errors,
    path,
    onChange,
    onBlur,
    touched,
    required,
    maxlength,
    type,
    autoFocus,
    disabled,
}: ValidatedInputProps) => {
    const value = getValueAt(path, values);
    const initialValue = getValueAt(path, initialValues);
    const error = getValueAt(path, errors);
    const was_touched = getValueAt(path, touched);
    const validation =
        (was_touched || initialValue) && error
            ? {
                  state: ValidationState.Invalid,
                  message: error,
              }
            : undefined;
    const label = t(
        `settings.payment_connections.collector_callback_modal.fields.${path}`,
    );
    return (
        <InputWrapper>
            <Input
                label={label}
                placeholder={t(
                    `settings.payment_connections.collector_callback_modal.placeholders.${path}`,
                )}
                type={type || "text"}
                name={path}
                value={value || ""}
                autoFocus={autoFocus}
                onChange={(e) => {
                    onChange(e);
                }}
                onBlur={(e) => {
                    onChange(e);
                    onBlur(e);
                }}
                validation={validation}
                maxlength={maxlength}
                disabled={disabled}
                required={required}
            />
        </InputWrapper>
    );
};

const InputWrapper = styled.div`
  &:last-child {
    margin: 0;
  }
`;

export const TranslatedValidatedInput = withTranslation()(ValidatedInput);

export const countryMapping = {
    NOK: "NO",
    SEK: "SE",
    EUR: "FI",
};

const CollectorCallbackModal = () => {
    const { t } = useTranslation();
    const { prodAccountId } = useAccountIds();
    const { closeModal, createCredentials } = useActions(
        "connections.collector_callback",
    );
    const { modalState, type } = useSelector((state) => {
        return {
            modalState: state[namespace].modalState,
            type: state[namespace].modalType,
        };
    });
    const [collectorType, setCollectorType] = React.useState<
        "SUB_MERCHANT" | "DIRECT_AGREEMENT"
    >("SUB_MERCHANT");

    if (!prodAccountId) {
        return null;
    }

    if (modalState === ModalState.Closed) {
        return null;
    }

    const initialFormValues = {
        username: "",
        password: "",
        store_id: "",
        store_id_b2b: "",
        enable_b2b: "",
        currency: "NOK",
    };

    if (type === "swap_agreement") {
        return (
            <Modal fixedWidth>
                {[
                    ModalState.PreApplicationInfo,
                    ModalState.InitiateCallbackLoading,
                ].includes(modalState) && (
                    <>
                        <H1>
                            {t(
                                "settings.payment_connections.collector_callback_modal.title_swap_agreement",
                            )}
                        </H1>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                url: "",
                                username: "",
                                password: "",
                                store_id: "",
                                enable_b2b: false,
                                store_id_b2b: undefined as string | undefined,
                            }}
                            validate={(values) => {
                                let errors: { [key: string]: string } = {};
                                if (!values) {
                                    // this should not happen but...
                                    return {
                                        error: "account not loaded yet...",
                                    };
                                }

                                const validators = [
                                    {
                                        path: "url",
                                        validator: validateRequired(
                                            t(
                                                "settings.payment_connections.collector_callback_modal.invalid.url.required",
                                            ),
                                        ),
                                    },
                                    {
                                        path: "url",
                                        validator: validateUrl(
                                            t(
                                                "settings.payment_connections.collector_callback_modal.invalid.url.invalid",
                                            ),
                                        ),
                                    },
                                    {
                                        path: "username",
                                        validator: validateRequired(
                                            t(
                                                "settings.payment_connections.collector_callback_modal.invalid.username.required",
                                            ),
                                        ),
                                    },
                                    {
                                        path: "password",
                                        validator: validateRequired(
                                            t(
                                                "settings.payment_connections.collector_callback_modal.invalid.password.required",
                                            ),
                                        ),
                                    },
                                    {
                                        path: "store_id",
                                        validator: validateRequired(
                                            t(
                                                "settings.payment_connections.collector_callback_modal.invalid.store_id.required",
                                            ),
                                        ),
                                    },
                                    {
                                        path: "store_id",
                                        validator: validatePositiveNumber(
                                            t(
                                                "settings.payment_connections.collector_callback_modal.invalid.store_id.invalid",
                                            ),
                                        ),
                                    },
                                ];

                                if (values.enable_b2b) {
                                    validators.push({
                                        path: "store_id_b2b",
                                        validator: validatePositiveNumber(
                                            t(
                                                "settings.payment_connections.collector_callback_modal.invalid.store_id_b2b.invalid",
                                            ),
                                        ),
                                    });
                                    validators.push({
                                        path: "store_id_b2b",
                                        validator: validateRequired(
                                            t(
                                                "settings.payment_connections.collector_callback_modal.invalid.store_id_b2b.required",
                                            ),
                                        ),
                                    });
                                }

                                errors = validators.reduce((acc, elem) => {
                                    return validate(
                                        elem.path,
                                        elem.validator,
                                        values,
                                        acc,
                                    );
                                }, errors);

                                return errors;
                            }}
                            onSubmit={(values) => {
                                const country = countryMapping.NOK;
                                const storeIdB2C = parseInt(
                                    values.store_id.trim(),
                                );
                                const stores = [
                                    {
                                        country,
                                        store_id: storeIdB2C,
                                        segment: "B2C" as "B2C" | "B2B",
                                    },
                                ];
                                if (values.enable_b2b && values.store_id_b2b) {
                                    const storeIdB2B = parseInt(
                                        values.store_id_b2b.trim(),
                                    );
                                    stores.push({
                                        country,
                                        store_id: storeIdB2B,
                                        segment: "B2B",
                                    });
                                }
                                const payload: CollectorCredentialsData = {
                                    name: `${storeIdB2C}`,
                                    data: {
                                        url: values.url.trim(),
                                        username: values.username.trim(),
                                        password: values.password.trim(),
                                        store_id: storeIdB2C,
                                        country,
                                        options: {
                                            enable_on_hold: true,
                                        },
                                    },
                                    promote: [
                                        {
                                            type: "default",
                                            value: "",
                                            stores,
                                        },
                                    ],
                                };
                                createCredentials(prodAccountId, payload);
                            }}
                        >
                            {({
                                values,
                                errors,
                                handleChange,
                                handleBlur,
                                touched,
                                handleSubmit,
                                setFieldValue,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <SpacedWrapper>
                                        <P>
                                            <Trans i18nKey="settings.payment_connections.collector_callback_modal.intro_swap_agreement" />
                                        </P>
                                        <SpacedWrapper>
                                            <TranslatedValidatedInput
                                                path="url"
                                                initialValues={
                                                    initialFormValues
                                                }
                                                values={values}
                                                errors={errors}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                touched={touched}
                                                required
                                            />
                                            <TranslatedValidatedInput
                                                path="username"
                                                initialValues={
                                                    initialFormValues
                                                }
                                                values={values}
                                                errors={errors}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                touched={touched}
                                                required
                                            />
                                            <TranslatedValidatedInput
                                                path="password"
                                                initialValues={
                                                    initialFormValues
                                                }
                                                values={values}
                                                errors={errors}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                touched={touched}
                                                required
                                                type="password"
                                            />
                                            <TranslatedValidatedInput
                                                path="store_id"
                                                initialValues={
                                                    initialFormValues
                                                }
                                                values={values}
                                                errors={errors}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                touched={touched}
                                                required
                                            />
                                        </SpacedWrapper>
                                    </SpacedWrapper>
                                    <SpacedWrapper>
                                        <Checkbox
                                            name="enable_b2b"
                                            label={t(
                                                "settings.payment_connections.collector_callback_modal.fields.enable_b2b",
                                            )}
                                            checked={values.enable_b2b}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        {values.enable_b2b && (
                                            <SpacedWrapper>
                                                <TranslatedValidatedInput
                                                    path="store_id_b2b"
                                                    initialValues={
                                                        initialFormValues
                                                    }
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    required
                                                    autoFocus={true}
                                                />
                                            </SpacedWrapper>
                                        )}
                                    </SpacedWrapper>
                                    <ButtonGroup>
                                        <Button
                                            className="alt"
                                            onClick={closeModal}
                                        >
                                            {t(
                                                "settings.payment_connections.collector_callback_modal.cancel",
                                            )}
                                        </Button>
                                        <Button
                                            type="submit"
                                            disabled={
                                                Object.keys(errors || {})
                                                    .length > 0 ||
                                                Object.keys(touched || {})
                                                    .length === 0
                                            }
                                        >
                                            {t(
                                                "settings.payment_connections.collector_callback_modal.send_callback_call_to_action",
                                            )}
                                        </Button>
                                    </ButtonGroup>
                                </form>
                            )}
                        </Formik>
                        {modalState === ModalState.InitiateCallbackLoading && (
                            <LoadingOverlay />
                        )}
                    </>
                )}
                {modalState === ModalState.CallbackError && (
                    <>
                        <H1>
                            {t(
                                "settings.payment_connections.collector_callback_modal.title_api_error",
                            )}
                        </H1>
                        <SpacedWrapper>
                            <P>
                                <Trans i18nKey="settings.payment_connections.collector_callback_modal.api_error" />
                            </P>
                        </SpacedWrapper>
                        <ButtonGroup>
                            <Button onClick={closeModal}>
                                {t(
                                    "settings.payment_connections.collector_callback_modal.close",
                                )}
                            </Button>
                        </ButtonGroup>
                    </>
                )}
                {modalState === ModalState.CallbackSuccess && (
                    <>
                        <H1>
                            {t(
                                "settings.payment_connections.collector_callback_modal.title_swap_agreement_success",
                            )}
                        </H1>

                        <SpacedWrapper>
                            <P>
                                <Trans i18nKey="settings.payment_connections.collector_callback_modal.success_swap_agreement" />
                            </P>
                        </SpacedWrapper>

                        <ButtonGroup>
                            <Button onClick={closeModal}>
                                {t(
                                    "settings.payment_connections.collector_callback_modal.close",
                                )}
                            </Button>
                        </ButtonGroup>
                    </>
                )}
            </Modal>
        );
    }

    const typeOptions = [
        { value: "SUB_MERCHANT", label: "Sub-Merchant" },
        { value: "DIRECT_AGREEMENT", label: "Direct Agreement" },
    ];

    return (
        <Modal fixedWidth>
            {[
                ModalState.PreApplicationInfo,
                ModalState.InitiateCallbackLoading,
            ].includes(modalState) && (
                <>
                    <H1>
                        {t(
                            "settings.payment_connections.collector_callback_modal.title_intro",
                        )}
                    </H1>
                    <SpacedWrapper>
                        <P>
                            <Trans i18nKey="settings.payment_connections.collector_callback_modal.intro" />
                        </P>
                        <SpacedWrapper>
                            <Dropdown
                                value={
                                    typeOptions.find(
                                        (option) =>
                                            option.value === collectorType,
                                    )!
                                }
                                name="type"
                                options={typeOptions}
                                label={t(
                                    "settings.payment_connections.collector_callback_modal.fields.type",
                                )}
                                onChange={(option) => {
                                    setCollectorType(
                                        option.value as
                                            | "SUB_MERCHANT"
                                            | "DIRECT_AGREEMENT",
                                    );
                                }}
                                required
                            />
                        </SpacedWrapper>
                    </SpacedWrapper>
                    {collectorType === "SUB_MERCHANT" && <SubMerchantForm />}
                    {collectorType === "DIRECT_AGREEMENT" && (
                        <DirectAgreementForm />
                    )}
                    {modalState === ModalState.InitiateCallbackLoading && (
                        <LoadingOverlay />
                    )}
                </>
            )}
            {modalState === ModalState.CallbackError && (
                <>
                    <H1>
                        {t(
                            "settings.payment_connections.collector_callback_modal.title_api_error",
                        )}
                    </H1>
                    <SpacedWrapper>
                        <P>
                            <Trans i18nKey="settings.payment_connections.collector_callback_modal.api_error" />
                        </P>
                    </SpacedWrapper>
                    <ButtonGroup>
                        <Button onClick={closeModal}>
                            {t(
                                "settings.payment_connections.collector_callback_modal.close",
                            )}
                        </Button>
                    </ButtonGroup>
                </>
            )}
            {modalState === ModalState.CallbackSuccess && (
                <>
                    <H1>
                        {t(
                            "settings.payment_connections.collector_callback_modal.title_success",
                        )}
                    </H1>

                    <SpacedWrapper>
                        <P>
                            <Trans i18nKey="settings.payment_connections.collector_callback_modal.success" />
                        </P>
                    </SpacedWrapper>

                    <ButtonGroup>
                        <Button onClick={closeModal}>
                            {t(
                                "settings.payment_connections.collector_callback_modal.close",
                            )}
                        </Button>
                    </ButtonGroup>
                </>
            )}
        </Modal>
    );
};

export default CollectorCallbackModal;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${distances.normal};
`;

export const SpacedWrapper = styled.div`
  margin-top: ${distances.small};
  margin-bottom: ${distances.normal};
`;
