import { useState } from "react";
import { Trans, WithTranslation } from "react-i18next";
import { useActions } from "../../../../Actions";
import { Button } from "../../../../components/Buttons";
import { Dd, Dl, Dt } from "../../../../components/DescriptionList/Horizontal";
import { DateTime } from "../../../../components/Formatters";
import { H2, P } from "../../../../components/Typography";
import View from "../../../../components/View";
import { distances } from "../../../../styles/constants";
import { Account, SwishConnection } from "../../../../types/management-auth";
import {
    ConnectionAside,
    ConnectionText,
    ConnectionsStatus,
    Details,
    Flex,
    PaymentConnection,
    ToggleDetails,
} from "./styledListItemComponents";

export interface SwishConnectionListItemProps extends WithTranslation {
    swish: SwishConnection | undefined;
    disabled: boolean;
    account: Account;
    accountId: string;
}

const SwishConnectionListItem = ({
    t,
    swish,
    disabled,
    account,
    accountId,
}: SwishConnectionListItemProps) => {
    const { openSwishModal } = useActions("connections.swish");
    const [swishDetails, setSwishDetails] = useState(false);

    return (
        <PaymentConnection disabled={disabled}>
            <View alignItems="flex-start" gap={distances.small}>
                <H2>{t(`settings.payment_connections.swish.name`)}</H2>
                {swish && swish.status && (
                    <ConnectionsStatus status={swish.status}>
                        {t(
                            `settings.payment_connections.status.${swish.status}`,
                        )}
                    </ConnectionsStatus>
                )}
            </View>
            <Flex>
                <ConnectionText>
                    <P>{t(`settings.payment_connections.swish.description`)}</P>
                </ConnectionText>
                <ConnectionAside>
                    {!swish && (
                        <Button
                            disabled={disabled}
                            onClick={() => openSwishModal(account)}
                            className="stretch"
                        >
                            {t(
                                `settings.payment_connections.swish.call_to_action`,
                            )}
                        </Button>
                    )}
                    {swish && !(swish.signup && swish.signup.callback_at) && (
                        <Button
                            onClick={() => openSwishModal(account)}
                            className="alt"
                        >
                            {t(
                                `settings.payment_connections.swish.complete_callback`,
                            )}
                        </Button>
                    )}
                </ConnectionAside>
            </Flex>
            {swish && (
                <>
                    <ToggleDetails
                        onClick={() => setSwishDetails(!swishDetails)}
                    >
                        {swishDetails
                            ? t("settings.payment_connections.collapse_details")
                            : t("settings.payment_connections.expand_details")}
                    </ToggleDetails>
                    <Details className={swishDetails ? "open" : "closed"}>
                        {swish.status === "pending" && (
                            <Dl>
                                <Dt doubleColumn>
                                    {t(
                                        "settings.payment_connections.fields.instructions",
                                    )}
                                </Dt>
                                <Dd>
                                    <Trans i18nKey="settings.payment_connections.swish_modal.intro">
                                        <strong />
                                        <code />
                                    </Trans>
                                </Dd>
                            </Dl>
                        )}
                        <Dl>
                            <Dt doubleColumn>
                                {t(
                                    "settings.payment_connections.fields.created_at",
                                )}
                            </Dt>
                            <Dd>
                                {swish.signup && swish.signup.created_at ? (
                                    <DateTime
                                        isoString={swish.signup.created_at}
                                    />
                                ) : (
                                    <em>
                                        {t(
                                            "settings.payment_connections.fields.no_callback_yet",
                                        )}
                                    </em>
                                )}
                            </Dd>
                        </Dl>
                        <Dl>
                            <Dt doubleColumn>
                                {t(
                                    "settings.payment_connections.fields.callback_at",
                                )}
                            </Dt>
                            <Dd>
                                {swish.signup && swish.signup.callback_at ? (
                                    <DateTime
                                        isoString={swish.signup.callback_at}
                                    />
                                ) : (
                                    <em>
                                        {t(
                                            "settings.payment_connections.fields.no_callback_yet",
                                        )}
                                    </em>
                                )}
                            </Dd>
                        </Dl>
                    </Details>
                </>
            )}
        </PaymentConnection>
    );
};

export default SwishConnectionListItem;
