import { Component } from "react";
import {
    Trans,
    WithTranslation,
    useTranslation,
    withTranslation,
} from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components/macro";

import Card from "../../../../components/Card";
import { LoadingOverlay } from "../../../../components/Loading";
import Page, { PageButtonLink } from "../../../../components/Page";
import {
    LinkTr,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from "../../../../components/Table/Fake";
import { H1, P } from "../../../../components/Typography";

import {
    AccountIdsProps,
    withAccountIds,
} from "../../../../auth/accessToken/components/withAccountIds";
import { ButtonLink } from "../../../../components/Buttons";
import EmptyState from "../../../../components/EmptyState";
import Icon from "../../../../components/Icons";
import { CHECKOUT_API_HOSTNAME } from "../../../../env";
import { getProfilePaymentTypeLabel } from "../../../../helpers/getProfilePaymentTypeLabel";
import { colors, distances } from "../../../../styles/constants";
import { ProfileConfiguration } from "../../../../types/checkout";
import { getPaymentProfilePaymentMethods } from "../../../helpers";
import { State as ProfilesState } from "../reducer";

const brandingImageUrl = (
    profile: ProfileConfiguration,
    align: "left" | "right",
) => {
    return `${CHECKOUT_API_HOSTNAME}/v1/branding/profiles/${profile.id}/type/colors/align/${align}/width/600/logos.svg?updated_at=${profile.updated_at}`;
};

const ProfileRow = ({
    profile,
    accountId,
}: {
    profile: ProfileConfiguration;
    accountId: string;
}) => {
    const { t } = useTranslation();
    const paymentTypes = getPaymentProfilePaymentMethods(profile);
    const paymentTypesTitle = paymentTypes
        .map((payment_type) => getProfilePaymentTypeLabel(payment_type.type, t))
        .join(", ");
    return (
        <ProfileTr
            key={profile.id}
            to={`/${accountId}/settings/payment-profiles/${profile.account_id}/${profile.id}`}
        >
            <IdTd className="first-child">
                <code>{profile.profile_id}</code>
            </IdTd>
            <Td>{profile.description}</Td>
            <Td className="last-child">
                <LogosWrap>
                    <img
                        src={brandingImageUrl(profile, "left")}
                        title={paymentTypesTitle}
                        alt={paymentTypesTitle}
                    />
                </LogosWrap>
            </Td>
        </ProfileTr>
    );
};

const ProfilesTable = ({
    profiles,
    accountId,
}: {
    profiles: ProfileConfiguration[];
    accountId: string;
}) => {
    const { t } = useTranslation();
    return (
        <Content>
            <Table className="no-wrap">
                <Thead>
                    <Tr>
                        <IdTh className="first-child">
                            {t("settings.payment_profiles.fields.id")}
                        </IdTh>
                        <Th>
                            {t("settings.payment_profiles.fields.description")}
                        </Th>
                        <Th className="last-child">
                            {t(
                                "settings.payment_profiles.fields.payment_methods",
                            )}
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {profiles.map((profile) => (
                        <ProfileRow
                            key={`${profile.account_id}-${profile.id}`}
                            profile={profile}
                            accountId={accountId}
                        />
                    ))}
                </Tbody>
            </Table>
        </Content>
    );
};

interface MatchParams {
    accountId: string;
}

export interface ProfilesListProps
    extends WithTranslation,
        RouteComponentProps<MatchParams>,
        AccountIdsProps {
    getProfiles: (accountId: string) => void;
    profiles: ProfilesState;
}

class ProfilesList extends Component<ProfilesListProps> {
    componentDidMount() {
        this.props.getProfiles(this.props.match.params.accountId);
    }

    render() {
        const { t } = this.props;
        const { profiles, isLoading } = this.props.profiles;
        const accountId = this.props.match.params.accountId;
        const totalCount = profiles ? profiles.length : 0;

        const hasProfiles = Boolean(
            accountId && profiles && profiles.length > 0,
        );

        return (
            <Page
                title={
                    <>
                        <Title>{t("settings.payment_profiles.title")}</Title>
                        <PageButtonLink
                            to={`/${accountId}/settings/payment-profiles/new`}
                        >
                            <Icon icon="plus" fill="currentColor" />
                            {t("settings.payment_profiles.create_new_hook")}
                        </PageButtonLink>
                    </>
                }
            >
                {hasProfiles && (
                    <ProfilesTable
                        accountId={accountId}
                        profiles={profiles || []}
                    />
                )}
                {!hasProfiles && (
                    <Card noPadding>
                        <Wrapper>
                            {isLoading && <LoadingOverlay />}
                            {!isLoading && totalCount === 0 && (
                                <EmptyState>
                                    <P>
                                        {t(
                                            "settings.payment_profiles.empty.message",
                                        )}
                                        <br />
                                        <br />
                                    </P>
                                    <P>
                                        <Trans i18nKey="settings.payment_profiles.empty.docs">
                                            <a
                                                href="https://docs.dintero.com/checkout-api.html#tag/profiles"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {" "}
                                            </a>
                                        </Trans>
                                    </P>
                                    <ButtonLink
                                        to={`/${accountId}/settings/payment-profiles/new`}
                                    >
                                        {t(
                                            "settings.payment_profiles.empty.call_to_action",
                                        )}
                                    </ButtonLink>
                                </EmptyState>
                            )}
                        </Wrapper>
                    </Card>
                )}
            </Page>
        );
    }
}

export default withAccountIds(withTranslation()(ProfilesList));

const LogosWrap = styled.div`
    > img {
        max-width: 100%;
        max-height: 40px;
    }
`;

const Wrapper = styled.div`
    position: relative;
`;

const Content = styled.div`
`;

const Title = styled(H1)`
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

const ProfileTr = styled(LinkTr)`
    &.deleted {
        background-color: ${colors.backgroundAlt};
    }
`;

const IdTh = styled(Th)``;

const IdTd = styled(Td)`
    font-size: 11px;
`;
