import React, {} from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { ButtonExternalLink } from "../../../../components/Buttons";
import { H2, P } from "../../../../components/Typography";
import { colors, distances, grid } from "../../../../styles/constants";

type Props = WithTranslation;

const NoLocationsData = ({ t }: Props) => {
    const link = "https://docs.dintero.com/api.html#tag/locations";
    return (
        <Container>
            <H2>{t("locations.no_data.title")}</H2>
            <br />
            <br />
            <P>{t("locations.no_data.body")}</P>
            <br />
            <br />
            <ButtonExternalLink
                target="_blank"
                rel="noopener noreferrer"
                href={link}
            >
                {t("locations.no_data.call_to_action")}
            </ButtonExternalLink>
        </Container>
    );
};

const Container = styled.div`
    max-width: ${grid.spans.span7};
    margin: 100px auto;
    padding: ${distances.normal};
    position: relative;
    min-height: calc(${distances.ginormous} + ${distances.large});
    border: 2px solid ${colors.borderLight};
`;

export default withTranslation()(NoLocationsData);
