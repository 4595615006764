import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { Client, Grant } from "../../../../types/management-auth";
import DetailsInfo from "./DetailsInfo";
import Hero from "./Hero";

export interface ClientDetailsProps extends WithTranslation {
    client: Client;
    grants: Grant[];
    accountId: string;
    openDeleteGrantModal: (grant: Grant) => void;
}

const ClientDetails = ({
    client,
    grants,
    accountId,
    openDeleteGrantModal,
}: ClientDetailsProps) => {
    return (
        <Wrapper>
            <Hero client={client} />
            <DetailsInfo
                client={client}
                grants={grants}
                accountId={accountId}
                openDeleteGrantModal={openDeleteGrantModal}
            />
        </Wrapper>
    );
};

export default withTranslation()(ClientDetails);

const Wrapper = styled.div`
    position: relative;
`;
