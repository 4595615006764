import React from "react";
import { Route } from "react-router-dom";

import ReportConfigurationsList from "./components/ReportConfigurationsList";

const Routes = [
    <Route
        key="settings-report-configurations"
        path="/:accountId/settings/reports"
        exact
        component={ReportConfigurationsList}
    />,
];

export default Routes;
