import { connect } from "react-redux";

import { State } from "../../../../reducer";
import {
    dismissRemoveProfileModal,
    getProfile,
    openRemoveProfileModal,
    removeProfile,
} from "../actions";
import { getProfileSelector } from "../selectors";

import ProfilePage from "../components/ProfilePage";

const mapStateToProps = (state: State) => ({
    profile: getProfileSelector(state),
});

const mapDispatchToProps = {
    getProfile,
    openRemoveProfileModal,
    dismissRemoveProfileModal,
    removeProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
