import {
    namespace as approvalsPaymentsNamespace,
    reducer as approvalsPaymentsReducer,
    initialState as approvalsPaymentsReducerInitialState,
    State as approvalsPaymentsReducerState,
} from "./approvalsPayments/actions";
import { reducer as bambora, namespace as bamboraNamespace } from "./bambora";
import {
    reducer as collector,
    namespace as collectorNamespace,
} from "./collector";
import {
    reducer as collectorCallback,
    namespace as collectorCallbackNamespace,
} from "./collectorCallback";
import {
    reducer as connections,
    namespace as connectionsNamespace,
} from "./connections";
import {
    reducer as instabank,
    namespace as instabankNamespace,
} from "./instabank";
import { reducer as klarna, namespace as klarnaNamespace } from "./klarna";
import { reducer as payex, namespace as payexNamespace } from "./payex";
import {
    reducer as payexCallback,
    namespace as payexCallbackNamespace,
} from "./payexCallback";
import { reducer as swish, namespace as swishNamespace } from "./swish";
import { reducer as vipps, namespace as vippsNamespace } from "./vipps";

export interface State {
    [connectionsNamespace]: connections.State;
    [vippsNamespace]: vipps.State;
    [instabankNamespace]: instabank.State;
    [payexNamespace]: payex.State;
    [payexNamespace]: payex.State;
    [collectorNamespace]: collector.State;
    [klarnaNamespace]: klarna.State;
    [swishNamespace]: swish.State;
    [payexCallbackNamespace]: payexCallback.State;
    [collectorCallbackNamespace]: collectorCallback.State;
    [bamboraNamespace]: bambora.State;
    [approvalsPaymentsNamespace]: approvalsPaymentsReducerState;
}

export const initialState: State = {
    [connectionsNamespace]: connections.initialState,
    [vippsNamespace]: vipps.initialState,
    [instabankNamespace]: instabank.initialState,
    [payexNamespace]: payex.initialState,
    [collectorNamespace]: collector.initialState,
    [klarnaNamespace]: klarna.initialState,
    [swishNamespace]: swish.initialState,
    [payexCallbackNamespace]: payexCallback.initialState,
    [collectorCallbackNamespace]: collectorCallback.initialState,
    [bamboraNamespace]: bambora.initialState,
    [approvalsPaymentsNamespace]: approvalsPaymentsReducerInitialState,
};

export const reducers = {
    [connectionsNamespace]: connections.reducer,
    [vippsNamespace]: vipps.reducer,
    [instabankNamespace]: instabank.reducer,
    [payexNamespace]: payex.reducer,
    [collectorNamespace]: collector.reducer,
    [klarnaNamespace]: klarna.reducer,
    [swishNamespace]: swish.reducer,
    [payexCallbackNamespace]: payexCallback.reducer,
    [collectorCallbackNamespace]: collectorCallback.reducer,
    [bamboraNamespace]: bambora.reducer,
    [approvalsPaymentsNamespace]: approvalsPaymentsReducer,
};
