import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { InputValidation } from "../../components/Forms";
import Dropdown, { DropdownOption } from "../../components/Forms/Dropdown";
import { Label, Muted } from "../../components/Typography";
import { colors, distances } from "../../styles/constants";
import { PayoutDestinationOption } from "../../util/usePayoutDestinations";

export interface PayoutDestinationDropdownProps {
    currentValue: string | undefined;
    name: string;
    placeholder: string;
    onChange: (value: string) => void;
    validation?: InputValidation;
    disabled?: boolean;
    destinations: PayoutDestinationOption[];
    accountId: string;
}

const PayoutDestinationDropdown = ({
    name,
    currentValue,
    onChange,
    validation,
    disabled,
    destinations,
    accountId,
}: PayoutDestinationDropdownProps) => {
    const { t } = useTranslation();

    const options: DropdownOption[] = destinations
        .filter((x) => x.accountId === accountId)
        .map((value) => ({
            label: (
                <>
                    <span>{value.label}</span>
                    {value.label !== value.value && (
                        <OptionSublabel>{value.value}</OptionSublabel>
                    )}
                </>
            ),
            value: value.value,
        }));
    options.unshift({
        label: (
            <>
                <span>{"*"}</span>{" "}
                <Muted>{t("settings.reports.create.fields.any")}</Muted>
            </>
        ),
        value: "*",
    });
    const currentOptionValue = currentValue || "*";
    const selectedOption = options.find(
        (option) => option.value === currentOptionValue,
    );
    return (
        <Wrapper isDisabled={!!disabled}>
            <Label htmlFor={name}>
                {t("settings.reports.create.fields.paymentDestinationFilter")}
            </Label>
            {selectedOption && (
                <Dropdown
                    name={name}
                    value={selectedOption}
                    options={options}
                    onChange={(val) => {
                        onChange(val.value);
                    }}
                    disabled={disabled}
                />
            )}
            {validation && validation.message && (
                <Message htmlFor={name} className={validation.state}>
                    {validation.message}
                </Message>
            )}
        </Wrapper>
    );
};

const Message = styled.label`
    position: absolute;
    top: 66px;
    left: 0;
    font-size: 11px;
    line-height: ${distances.small};

    &.invalid {
        color: ${colors.invalid};
    }

    &.valid {
        color: ${colors.valid};
    }
`;

const Wrapper = styled.div<{ isDisabled: boolean }>`
    position: relative;
    opacity: ${(props) => (props.isDisabled ? "0.6" : "1")};
`;

const OptionSublabel = styled.div`
    font-size: 0.8em;
    color: ${colors.textSecondary};
`;

export default PayoutDestinationDropdown;
