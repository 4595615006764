import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useActions } from "../../../Actions";
import { useAccountIds } from "../../../auth/accessToken/components/withAccountIds";
import useIsDinteroRoot from "../../../auth/accessToken/hooks/useIsDinteroRoot";
import useHasAnyOfScopes from "../../../common/hooks/useHasAnyOfScopes";
import type { Account, UpdateAccount } from "../../../types/management-auth";
import type { PayoutAccountService } from "../../../types/management-auth/generated";
import useEffectCallback from "../../../util/useEffectCallback";
import useAccount from "../../account/hooks/useAccount";
import { useCheckoutConfiguration } from "../../checkoutConfigurationHooks";
import { getSelector } from "../../components/PayoutBankAccount/BankAccounts";

export default function useDailyPayout(params?: {
    account: Account;
    partnerId: string;
    onAdminUpdateAccountSuccess?: (account: Account) => void;
}) {
    const isDinteroRoot = useIsDinteroRoot();
    const isDinteroAdmin = useHasAnyOfScopes(["admin:dintero"]);
    const { optimisticProdAccountId } = useAccountIds();
    const {
        isLoading: loadingAccount,
        account: reqAccount,
        updateAccount,
        adminUpdateAccount,
        isAdminUpdatingAccount,
    } = useAccount({
        onAdminUpdateAccountSuccess: params?.onAdminUpdateAccountSuccess,
    });
    const account = params?.account || reqAccount;
    const { isLoading: loadingCheckoutConfig, checkoutConfiguration } =
        useCheckoutConfiguration();
    const { getBankAccounts, getAccountConfig } = useActions("payout.config");
    const { bankAccounts, loading: loadingBankAccounts } = useSelector(
        useMemo(
            () =>
                getSelector(optimisticProdAccountId, {
                    accountId: optimisticProdAccountId,
                    organizationNumber: account?.company?.organization_number!,
                }),
            [optimisticProdAccountId, account?.company?.organization_number],
        ),
    );

    useEffectCallback(getBankAccounts, [optimisticProdAccountId]);
    useEffectCallback(getAccountConfig, [optimisticProdAccountId]);

    const features = account?.services?.payout
        ?.features as PayoutAccountService["features"];
    const isLoading =
        loadingAccount || loadingCheckoutConfig || loadingBankAccounts;
    const active = features?.daily_payouts?.status === "enabled";
    const canEnable =
        isDinteroRoot ||
        (bankAccounts.length > 0 &&
            (
                checkoutConfiguration?.configuration?.payout
                    ?.payment_products || []
            ).length > 0);

    const enableDailyPayouts = async () => {
        if (!account) return;
        const newSettings = {
            ...account,
            services: {
                payout: {
                    features: {
                        daily_payouts: {
                            requested: "enable",
                        },
                    },
                },
            },
        } as any as UpdateAccount;
        await updateAccount({
            accountId: optimisticProdAccountId,
            account: newSettings,
        });
    };

    const disableDailyPayouts = async () => {
        if (!account) return;
        const newSettings = {
            ...account,
            services: {
                payout: {
                    features: {
                        daily_payouts: {
                            requested: "disable",
                        },
                    },
                },
            },
        } as any as UpdateAccount;
        await updateAccount({
            accountId: optimisticProdAccountId,
            account: newSettings,
        });
    };

    const adminUpdateStatus = async (
        status: "enabled" | "disabled" | "pending" | "declined",
    ) => {
        if (!account || !isDinteroAdmin || !params) return;
        const newSettings = {
            ...account,
            services: {
                payout: {
                    features: {
                        daily_payouts: {
                            ...features?.daily_payouts,
                            status,
                        },
                    },
                },
            },
        } as any as UpdateAccount;
        await adminUpdateAccount({
            accountId: `${account.livemode ? "P" : "T"}${account.account_id}`,
            partnerId: params.partnerId,
            account: newSettings,
        });
    };

    return {
        isLoading,
        active,
        canEnable,
        enableDailyPayouts,
        disableDailyPayouts,
        adminUpdateStatus,
        status: features?.daily_payouts?.status,
        requested: features?.daily_payouts?.requested,
        isAdminUpdatingAccount,
    };
}
