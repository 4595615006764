import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { namespace } from "..";
import { useActions } from "../../../../Actions";
import { useAccountIds } from "../../../../auth/accessToken/components/withAccountIds";
import useScopes from "../../../../auth/accessToken/hooks/useScopes";
import { getUserId } from "../../../../auth/accessToken/selectors";
import { Button, ButtonGroup } from "../../../../components/Buttons";
import { validate, validateRequired } from "../../../../helpers/validation";
import { ApprovalsPaymentResponse } from "../../../../types/management-auth/generated";
import { countryMapping } from "../../collectorCallback/components/CollectorCallbackModal";
import { isPasswordRequired } from "../../collectorCallback/utils";
import type { CollectorPayload } from "../types";
import StoreConfig from "./StoreConfig";
import TranslatedValidatedInput from "./ValidateInput";
import { determineStoreIdValidators } from "./validators";

type CollectorDirectFormProps = {
    activeApprovals: ApprovalsPaymentResponse[];
};

export default function CollectorDirectForm({
    activeApprovals,
}: CollectorDirectFormProps) {
    const { closeModal, finishRegistration } = useActions(namespace);
    const { prodAccountId } = useAccountIds();
    const { t, i18n } = useTranslation();
    const userId = useSelector((state) => getUserId(state));
    const scopes = useScopes();
    const { account } = useSelector((state) => ({
        account: state[namespace].account,
    }));
    const initialValues = {
        url: "",
        username: "",
        password: "",
        b2b: {
            enabled: false,
            currencies: [
                {
                    currency: "NOK",
                    enabled: false,
                    store_id: "",
                },
                {
                    currency: "SEK",
                    enabled: false,
                    store_id: "",
                },
                {
                    currency: "EUR",
                    enabled: false,
                    store_id: "",
                },
            ],
        },
        b2c: {
            enabled: false,
            currencies: [
                {
                    currency: "NOK",
                    enabled: false,
                    store_id: "",
                },
                {
                    currency: "SEK",
                    enabled: false,
                    store_id: "",
                },
                {
                    currency: "EUR",
                    enabled: false,
                    store_id: "",
                },
            ],
        },
    };
    const onSubmit = async (values: typeof initialValues) => {
        if (
            !account ||
            !account.company ||
            !account.company.organization_number ||
            !account.company.website ||
            !prodAccountId
        ) {
            return;
        }
        const b2cCurrencies =
            (values.b2c.enabled &&
                values.b2c.currencies
                    .filter((currency) => currency.enabled)
                    .map((currency) => currency.currency)) ||
            [];
        const b2bCurrencies =
            (values.b2b.enabled &&
                values.b2b.currencies
                    .filter((currency) => currency.enabled)
                    .map((currency) => currency.currency)) ||
            [];
        const stores: CollectorPayload["body"]["stores"] = [];
        if (values.b2c) {
            values.b2c.currencies
                .filter((currency) => currency.enabled)
                .forEach((currency) => {
                    const country =
                        countryMapping[
                            currency.currency as keyof typeof countryMapping
                        ];
                    const storeIdB2C = Number.parseInt(currency.store_id);
                    stores.push({
                        store_id: storeIdB2C,
                        currency: currency.currency,
                        country,
                        segment: "B2C",
                    });
                });
        }
        if (values.b2b) {
            values.b2b.currencies
                .filter((currency) => currency.enabled)
                .forEach((currency) => {
                    const country =
                        countryMapping[
                            currency.currency as keyof typeof countryMapping
                        ];
                    const storeIdB2B = Number.parseInt(currency.store_id);
                    stores.push({
                        store_id: storeIdB2B,
                        currency: currency.currency,
                        country,
                        segment: "B2B",
                    });
                });
        }
        const payload: CollectorPayload["body"] = {
            signup: {
                legal_name: account?.company?.business_name || "",
                business_name: account?.company?.business_name || "",
                organization_number: account?.company.organization_number || "",
                bank_name: account?.company?.bank_name || "",
                account_number: account?.company?.account_number || "",
                website: account?.company.website || "",
                payment_options: [
                    {
                        currencies: [
                            ...new Set([...b2cCurrencies, ...b2bCurrencies]),
                        ],
                        type: "collector.invoice",
                    },
                    {
                        currencies: [
                            ...new Set([...b2cCurrencies, ...b2bCurrencies]),
                        ],
                        type: "collector.installment",
                    },
                ],
            },
            username: values.username.trim(),
            password: values.password.trim(),
            stores: stores,
            payment_options: [
                ...((values.b2c.enabled && [
                    {
                        currencies: b2cCurrencies,
                        type: "collector.invoice" as const,
                    },
                ]) ||
                    []),
                ...((values.b2b.enabled && [
                    {
                        currencies: b2bCurrencies,
                        type: "collector.invoice_b2b" as const,
                    },
                ]) ||
                    []),
                {
                    currencies: [
                        ...new Set([...b2cCurrencies, ...b2bCurrencies]),
                    ],
                    type: "collector.installment" as const,
                },
            ].filter((option) => option.currencies.length > 0),
        };
        await finishRegistration({
            accountId: prodAccountId,
            account: account,
            body: payload,
            userId,
        });
    };
    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
            validate={(values) => {
                let errors: { [key: string]: string } = {};
                if (!values) {
                    // this should not happen but...
                    return { error: "account not loaded yet..." };
                }

                const determinePasswordValidation = () => {
                    if (isPasswordRequired(values.username.trim(), scopes)) {
                        return [
                            {
                                path: "password",
                                validator: validateRequired(
                                    t(
                                        "settings.payment_connections.collector_callback_modal.invalid.password.required",
                                    ),
                                ),
                            },
                        ];
                    }
                    return [];
                };

                const validators = [
                    {
                        path: "username",
                        validator: validateRequired(
                            t(
                                "settings.payment_connections.collector_callback_modal.invalid.username.required",
                            ),
                        ),
                    },
                    ...determinePasswordValidation(),
                    ...determineStoreIdValidators(values, t),
                ];

                errors = validators.reduce((acc, elem) => {
                    return validate(elem.path, elem.validator, values, acc);
                }, errors);

                return errors;
            }}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
            }) => (
                <form onSubmit={handleSubmit}>
                    <TranslatedValidatedInput
                        path="url"
                        initialValues={initialValues}
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                    />
                    <TranslatedValidatedInput
                        path="username"
                        initialValues={initialValues}
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        required
                    />
                    <TranslatedValidatedInput
                        path="password"
                        initialValues={initialValues}
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        required
                    />
                    <StoreConfig
                        values={values}
                        initialValues={initialValues}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                    />
                    <ButtonGroup>
                        <Button
                            className="alt"
                            type="button"
                            onClick={closeModal}
                        >
                            {t(
                                "settings.payment_connections.collector_callback_modal.cancel",
                            )}
                        </Button>
                        <Button
                            disabled={
                                Object.keys(errors).length > 0 ||
                                Object.keys(touched).length === 0
                            }
                            type="submit"
                        >
                            {t(
                                "settings.payment_connections.collector_modal.setup_collector",
                            )}
                        </Button>
                    </ButtonGroup>
                </form>
            )}
        </Formik>
    );
}
