import { ENVIRONMENT, type Environment } from "../../../env";

export const PASSWORDLESS_USERNAMES: Record<
    Exclude<Environment, "dev" | "">,
    string[]
> = {
    test: ["dintero_api"],
    prod: ["dintero_SM", "dint_api"],
};

export const isPasswordRequired = (username: string, scopes: string[]) => {
    const env = getPasswordlessUsernamesKey();
    return (
        !PASSWORDLESS_USERNAMES[env].includes(username) &&
        !scopes.includes("dintero:admin")
    );
};

export const getPasswordlessUsernamesKey = () => {
    if (ENVIRONMENT === "dev" || ENVIRONMENT === "") {
        return "test";
    }
    return ENVIRONMENT;
};
