import { ProfileConfiguration } from "../../../types/checkout";
import { ActionTypes, Actions } from "./actions";

export interface State {
    isLoading: boolean;
    profiles: ProfileConfiguration[];
}

export const initialState: State = {
    isLoading: false,
    profiles: [],
};

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {
        case ActionTypes.GetProfiles:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FetchProfilesResponse:
            return {
                ...state,
                isLoading: false,
                profiles: action.payload.profiles,
            };

        default:
            return state;
    }
}
