import React from "react";
import styled from "styled-components/macro";

import { WithTranslation, withTranslation } from "react-i18next";
import { InputValidation } from "../../../components/Forms";
import Dropdown, { DropdownOption } from "../../../components/Forms/Dropdown";
import { colors, distances } from "../../../styles/constants";

import { Label } from "../../../components/Typography";

export interface ClientTypeDropdownProps extends WithTranslation {
    value: string;
    name: string;
    label: string;
    placeholder: string;
    onChange: (value: string) => void;
    validation?: InputValidation;
}

const ClientTypeDropdown = ({
    label,
    name,
    value,
    onChange,
    validation,
    placeholder,
    t,
}: ClientTypeDropdownProps) => {
    const optionValues = [
        "any",
        "authorization_code",
        "client_credentials",
        "password",
    ];

    const options: DropdownOption[] = optionValues.map((value) => ({
        label: value,
        value,
    }));

    const selectedOption = options.find((option) => option.value === value);

    return (
        <Wrapper>
            <Label htmlFor={name}>{label}</Label>
            {selectedOption && (
                <Dropdown
                    name={name}
                    value={selectedOption}
                    options={options}
                    onChange={(val) => {
                        onChange(val.value);
                    }}
                />
            )}
            {validation && validation.message && (
                <Message htmlFor={name} className={validation.state}>
                    {validation.message}
                </Message>
            )}
        </Wrapper>
    );
};

const Message = styled.label`
    position: absolute;
    top: 66px;
    left: 0;
    font-size: 11px;
    line-height: ${distances.small};

    &.invalid {
        color: ${colors.invalid};
    }

    &.valid {
        color: ${colors.valid};
    }
`;

const Wrapper = styled.div`
    position: relative;
`;

export default withTranslation()(ClientTypeDropdown);
