export const download = async (filename: string, blob: Blob) => {
    const data = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = data;
    link.download = `${filename}`;
    link.click();
    setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
    }, 100);
};
