import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { Formik } from "formik";
import { TFunction } from "i18next";
import { useAccountId } from "../../../auth/useAccountId";
import Modal from "../../../components/Modal";
import { H1, P } from "../../../components/Typography";
import {
    validate,
    validateEmail,
    validateRequired,
} from "../../../helpers/validation";
import {
    SettlementReportConfig,
    SettlementReportConfigDraft,
} from "../../../reports/settlementList/types";
import { colors, distances } from "../../../styles/constants";
import { PayoutDestinationOption } from "../../../util/usePayoutDestinations";
import {
    ConfigurationForm,
    SettlementReportConfigurationFormikValues,
} from "./ConfigurationForm";

export interface EditConfigurationModalProps {
    accountIds: string[];
    dismissModal: () => void;
    updateConfiguration: (
        accountId: string,
        configuration_id: string,
        configuration: SettlementReportConfigDraft,
    ) => Promise<boolean>;
    configuration: SettlementReportConfig;
    destinations: PayoutDestinationOption[];
    createError: undefined | "bad_request" | "conflict";
    accountBillingEmailAddress: string | undefined;
}

const toConfiguration = (
    values: SettlementReportConfigurationFormikValues,
    originalConfiguration: SettlementReportConfig,
): SettlementReportConfigDraft => {
    const { accountId, emails, accountEmail, ...config } = values;
    const destinations: SettlementReportConfigDraft["destinations"] = emails
        .filter((email) => !!email)
        .map((email) => {
            return {
                destination_type: "email",
                destination_value: email,
            };
        });
    if (accountEmail) {
        destinations.push({
            destination_type: "account_email",
        });
    }
    const updatedFilters = config.filters?.filter((item) => item.value !== "*");
    return {
        destinations,
        send_every: originalConfiguration.send_every,
        filetypes: config.filetypes,
        providers: config.providers,
        filters: updatedFilters,
    };
};

const validateForm = (
    values: SettlementReportConfigurationFormikValues,
    t: TFunction,
) => {
    const validators = [
        {
            path: "filetypes",
            validator: validateRequired(
                t("settings.settlement_report_config.form.invalid.filetypes"),
            ),
        },
    ];
    if (values.emails.length === 0) {
        validators.push({
            path: "accountEmail",
            validator: validateRequired(
                t(
                    "settings.settlement_report_config.form.invalid.account_email.required",
                ),
            ),
        });
    }
    (values.emails || []).forEach((value, i) => {
        validators.push({
            path: `emails.${i}`,
            validator: validateEmail(
                t(
                    "settings.settlement_report_config.form.invalid.email.invalid",
                ),
            ),
        });
    });

    return validators.reduce((acc, elem) => {
        return validate(elem.path, elem.validator, values, acc);
    }, {});
};

const EditConfigurationModal = ({
    accountIds,
    dismissModal,
    configuration,
    updateConfiguration,
    destinations,
    createError,
    accountBillingEmailAddress,
}: EditConfigurationModalProps) => {
    const { t } = useTranslation();
    const accountId = useAccountId();

    const initialEmailList = configuration.destinations
        .filter((destination) => destination.destination_type === "email")
        .map((destination) => destination.destination_value)
        .filter((email): email is string => !!email);

    const initialValues: SettlementReportConfigurationFormikValues = {
        accountId: accountId,
        emails: initialEmailList,
        filters: configuration.filters,
        filetypes: configuration.filetypes,
        providers: configuration.providers,
        accountEmail: configuration.destinations.some(
            (destination) => destination.destination_type === "account_email",
        ),
    };
    return (
        <Modal fixedWidth width="600px">
            <H1>{t("settings.settlement_report_config.edit.title")}</H1>
            <TextWrapper>
                <P>{t("settings.settlement_report_config.edit.description")}</P>
            </TextWrapper>
            {createError && (
                <ErrorMessage>
                    {t("settings.settlement_report_config.edit.error", {
                        context: createError,
                    })}
                </ErrorMessage>
            )}
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validate={(values) => {
                    return validateForm(values, t);
                }}
                onSubmit={async (values, formikBag) => {
                    const updatedConfiguration = toConfiguration(
                        values,
                        configuration,
                    );
                    const result = await updateConfiguration(
                        accountId,
                        configuration.id,
                        updatedConfiguration,
                    );
                    formikBag.setSubmitting(result);
                }}
            >
                {(formik) => {
                    return (
                        <ConfigurationForm
                            operation="edit"
                            formik={formik}
                            destinations={destinations}
                            accountIds={accountIds}
                            dismissModal={dismissModal}
                            accountBillingEmailAddress={
                                accountBillingEmailAddress
                            }
                        />
                    );
                }}
            </Formik>
        </Modal>
    );
};

export default EditConfigurationModal;

const ErrorMessage = styled.div`
    padding: ${distances.small};
    background-color: ${colors.interactiveBackgroundInvalid};
    color: ${colors.invalid};
    border: 1px solid ${colors.invalid};
    border-radius: 4px;
`;

const TextWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.normal};
`;
