import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { Dd, Dl, Dt } from "../../../../components/DescriptionList/Horizontal";
import { EnvironmentBadge } from "../../../../components/EnvironmentBadge";
import { H1 } from "../../../../components/Typography";
import { distances, grid, palette } from "../../../../styles/constants";
import { AccountAdminUser } from "../../../../types/management-auth";

export interface HeroProps extends WithTranslation {
    user: AccountAdminUser;
}

const Hero = ({ t, user }: HeroProps) => {
    return (
        <HeroWrapper>
            <Inner>
                <H1>{user.email}</H1>
                {user.name && (
                    <Dl>
                        <Dt>{t("settings.user.fields.name")}</Dt>
                        <Dd>{user.name}</Dd>
                    </Dl>
                )}
                <Dl>
                    <Dt>{t("settings.user.fields.id")}</Dt>
                    <Dd>
                        <code>{user.id}</code>
                    </Dd>
                </Dl>
                {user.authentication_type === "external" && (
                    <Dl>
                        <Dt>
                            {t("settings.users.fields.authentication_type")}
                        </Dt>
                        <Dd>
                            <EnvironmentBadge
                                environment="unknown"
                                label={t(
                                    "settings.users.authentication_type_external",
                                )}
                            />
                        </Dd>
                    </Dl>
                )}
            </Inner>
        </HeroWrapper>
    );
};

export default withTranslation()(Hero);

const HeroWrapper = styled.div`
    background: ${palette.primary[50]};
    width: 100%;
    color: ${palette.primary[700]};
`;
const Inner = styled.div`
    max-width: ${grid.spans.span8};
    margin: 0 auto;
    padding: ${distances.large} ${distances.small};
`;
