import React, {} from "react";
import { withTranslation } from "react-i18next";

import InputFilter from "../../../../../components/Filters/InputFilter";
import { FilterProps } from "./FilterProps";

export type OrganizationNumberFilterProps = FilterProps;

const OrganizationNumberFilter = ({
    queryParams,
    t,
    onChange,
}: OrganizationNumberFilterProps) => {
    const value = queryParams.get("organization_number") || "";

    return (
        <InputFilter
            label={value ? value : ""}
            name="organization_number"
            title={t("locations.filter.organization_number.title")}
            value={value}
            placeholder={""}
            validate={(value: string) => {
                if (!value) {
                    return "error";
                }
                return;
            }}
            autoFocus={true}
            onApply={(value) => {
                onChange({ organization_number: value ? [value] : [] });
            }}
            onDelete={(_) => {
                onChange({
                    organization_number: [],
                });
            }}
        />
    );
};

export default withTranslation()(OrganizationNumberFilter);
