import {
    Account,
    InstabankConnectionSignup,
} from "../../../types/management-auth";

export enum ActionTypes {
    OpenInstabankModal = "[settings/paymentConnections/instabank] OPEN_MODAL",
    InitiateInstabankConnection = "[settings/paymentConnections/instabank] INITIATE_CONNECTION",
    FetchInitiateInstabankConnectionResponse = "[settings/paymentConnections/instabank] INITIATE_CONNECTION_RESPONSE",
    CloseInstabankModal = "[settings/paymentConnections/instabank] CLOSE_MODAL",
}

export interface OpenInstabankModal {
    type: ActionTypes.OpenInstabankModal;
    payload: {
        accountId: string;
        account: Account;
    };
}

export function openInstabankModal(
    accountId: string,
    account: Account,
): OpenInstabankModal {
    return {
        type: ActionTypes.OpenInstabankModal,
        payload: {
            accountId,
            account,
        },
    };
}

export interface CloseInstabankModal {
    type: ActionTypes.CloseInstabankModal;
    payload: {};
}

export function closeInstabankModal(): CloseInstabankModal {
    return {
        type: ActionTypes.CloseInstabankModal,
        payload: {},
    };
}

export interface InitiateInstabankConnection {
    type: ActionTypes.InitiateInstabankConnection;
    payload: {
        accountId: string;
        data: InstabankConnectionSignup;
    };
}

export function initiateInstabankConnection(
    accountId: string,
    data: InstabankConnectionSignup,
): InitiateInstabankConnection {
    return {
        type: ActionTypes.InitiateInstabankConnection,
        payload: {
            accountId,
            data,
        },
    };
}

export interface FetchInitiateInstabankConnectionResponse {
    type: ActionTypes.FetchInitiateInstabankConnectionResponse;
    payload: {};
    meta: {
        cause: InitiateInstabankConnection;
    };
}

export type Actions =
    | OpenInstabankModal
    | CloseInstabankModal
    | InitiateInstabankConnection
    | FetchInitiateInstabankConnectionResponse;
