import getJson from "./getJson";
type PostalCodes = { [code: string]: string };

let mapping: PostalCodes | undefined = undefined;
let fetching: Promise<PostalCodes | undefined> | undefined = undefined;

export const getPostalPlace = async (country: string, code: string) => {
    if (country !== "NO") {
        return "";
    }
    if (!mapping && !fetching) {
        fetching = getJson("/data/postal-codes/no.json", undefined);
        mapping = await fetching;
    }
    if (!mapping) {
        await fetching;
    }
    if (mapping) {
        return mapping[code] || "";
    }
    return "";
};
