import { ChangeEvent, FocusEvent, useCallback, useMemo, useState } from "react";
import { TFunction, Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { useActions } from "../../../Actions";
import {
    getAccountNumberValidator,
    getOrgNumberValidator,
    validateEmail,
    validateISO_3166_1,
    validateRequired,
} from "../../../helpers/validation";
import useDraft from "../../../util/useDraft";
import {
    ValidationSchema,
    useValidatedSchema,
} from "../../../util/useValidatedSchema";
import { accountCurrencies } from "../../account/helpers";
import { validateValues as LocationFormValidator } from "../validation/SellerLocationFormValidation";

import { ABreakAll } from "../../../components/A";
import {
    Button,
    ButtonExternalLink,
    ButtonGroup,
} from "../../../components/Buttons";
import { H2, P } from "../../../components/Typography";

import styled from "styled-components/macro";
import { useAccountIds } from "../../../auth/accessToken/components/withAccountIds";
import { Dropdown, Input, ValidationState } from "../../../components/Forms";
import Icon from "../../../components/Icons";
import Plus from "../../../components/Icons/plus";
import { LoadingOverlay } from "../../../components/Loading";
import Modal from "../../../components/Modal";
import { Toggle } from "../../../components/Toggle";
import { setAlert } from "../../../stickyAlert/observables/stickyAlertStore";
import { distances } from "../../../styles/constants";
import { ApprovalsPayoutDestinationResponse } from "../../../types/management-auth/generated";
import useAccount from "../../account/hooks/useAccount";
import { approvalsStateSelector } from "../../approvalsSelectors";
import { fallbackIfEmpty } from "../../helpers";
import {
    getCountryOptions,
    getFastTrackOptions,
    getTypeOptions,
} from "../../locations/shared/options";
import {
    getGeoLocation,
    getPhonePrefixFromCountry,
    mapValues,
} from "../../locations/shared/utils";
import { LocationFormValues } from "../../locations/types";
import SellerLocationForm from "./SellerLocationForm";

interface AddLocationFormValues extends LocationFormValues {}

type NewPayoutDestinationWithNewBankAccountValues = {
    accountId: string;
    payout_destination_id: string;
    payout_reference: string;
    name: string;
    description: string;
    // used for first bank account
    country_code: string;
    organization_number: string;
    bank_account_number: string;
    currency: string;
    locations: AddLocationFormValues[];
    form_submitter_email: string;
};

const createValidationSchema = (
    t: TFunction<("app" | "payout")[], undefined>,
): ValidationSchema<NewPayoutDestinationWithNewBankAccountValues> => ({
    payout_destination_id: [
        validateRequired(
            t("payout:general.validations.field.required", {
                name: t("payout:payout_destination.payout_destination_id"),
            }),
        ),
        (value) =>
            /^([\w\d\-_\S]{1,40})$/.test(value)
                ? undefined
                : t("payout:general.validations.field.invalid", {
                      name: t(
                          "payout:payout_destination.payout_destination_id",
                      ),
                  }),
    ],
    country_code: [
        validateRequired(
            t("payout:general.validations.field.required", {
                name: t("payout:bank_account.country_code"),
            }),
        ),
        validateISO_3166_1(
            t("payout:general.validations.field.invalid", {
                name: t("payout:bank_account.country_code"),
            }),
        ),
    ],
    currency: [
        (value, adjacent) =>
            adjacent.bank_account_number.length > 0
                ? validateRequired(
                      t("payout:general.validations.field.required", {
                          name: t("payout:general.currency"),
                      }),
                  )(value)
                : undefined,
        (value, adjacent) =>
            adjacent.bank_account_number.length === 0 ||
            /^[A-Z]{3}$/.test(value)
                ? undefined
                : t("payout:general.validations.field.invalid", {
                      name: t("payout:general.currency"),
                  }),
    ],
    bank_account_number: [
        (value, adjacent) =>
            adjacent.bank_account_number.length > 0
                ? validateRequired(
                      t("payout:general.validations.field.required", {
                          name: t("payout:bank_account.number"),
                      }),
                  )(value)
                : undefined,
        (value, adjacent) =>
            adjacent.bank_account_number.length > 0 && adjacent.country_code
                ? getAccountNumberValidator(
                      adjacent.country_code.toUpperCase(),
                      t("payout:general.validations.field.invalid", {
                          name: t("payout:bank_account.number"),
                      }),
                  )(value)
                : undefined,
    ],
    organization_number: [
        validateRequired(
            t("payout:general.validations.field.required", {
                name: t("payout:bank_account.owner_orgno"),
            }),
        ),
        (value, adjacent) =>
            adjacent.country_code
                ? getOrgNumberValidator(
                      adjacent.country_code.toUpperCase(),
                      t("payout:general.validations.field.invalid", {
                          name: t("payout:bank_account.owner_orgno"),
                      }),
                  )(value)
                : undefined,
    ],
    locations: [
        (value) => {
            const locations = value as unknown as AddLocationFormValues[];
            if (locations && locations.length > 0) {
                return locations.reduce((a, location) => {
                    const errors = LocationFormValidator(location, t);
                    if ("locationId" in errors) {
                        return errors.locationId;
                    }
                    if ("country" in errors) {
                        return errors.country;
                    }
                    return a;
                }, "");
            } else {
                return undefined;
            }
        },
    ],
    form_submitter_email: [
        validateEmail(
            t("payout:payout_destination.form.form_submitter_email_invalid"),
        ),
    ],
});

export type CreatePayoutDestinationDialogProps = {
    onClose: () => void;
    environment: "test" | "prod";
    allowCreateProdPayoutDestinations: boolean;
};

const selector = (prodAccountId: string, testAccountId: string) =>
    createSelector(
        approvalsStateSelector(prodAccountId),
        approvalsStateSelector(testAccountId),
        (prodState, testState) => ({
            isPostingProdSeller: prodState.loading,
            isPostingTestSeller: testState.loading,
        }),
    );

const quickerVersionQueryString = () => {
    const queryString = new URLSearchParams(window.location.search);
    const quickrVersion = queryString.get("quickr_version");
    if (quickrVersion) {
        return `quickr_version=${quickrVersion}`;
    }
    return `quickr_version=2`;
};

const getDefaultCountryCurrency = (country: string) => {
    const mapping: Record<string, string> = {
        NO: "NOK",
        SE: "SEK",
        DK: "DKK",
    };
    return mapping[country] || "";
};

const CreatePayoutDestinationDialog = ({
    onClose,
    allowCreateProdPayoutDestinations,
    environment,
    ...props
}: CreatePayoutDestinationDialogProps) => {
    const { t, i18n } = useTranslation(["app", "payout"]);
    const accountIds = useAccountIds();
    const { postSellerApproval } = useActions("management.approvals");
    const createLocation = useActions("settings.locations").createLocation;
    const { account } = useAccount();
    const country = account?.company?.address?.country;
    const currencies = fallbackIfEmpty(
        account ? accountCurrencies(account) : [],
        [getDefaultCountryCurrency(country || "")],
    );
    const [isFastTrack, setIsFastTrack] = useState(false);

    const countryOptions = getCountryOptions(t);
    const typeOptions = getTypeOptions(t);
    const fastTrackOptions = getFastTrackOptions(t);

    const [postedApprovalSeller, setPostedSeller] =
        useState<ApprovalsPayoutDestinationResponse>();

    const { isPostingProdSeller, isPostingTestSeller } = useSelector(
        selector(
            accountIds.prodAccountId || "",
            accountIds.testAccountId || "",
        ),
    );

    const initialAccountId =
        environment === "prod"
            ? accountIds.prodAccountId
            : accountIds.testAccountId;
    const { setValue, combined, setTouched, isNotPristine, setSubmit, clear } =
        useDraft<NewPayoutDestinationWithNewBankAccountValues>({
            accountId: initialAccountId || "",
            payout_destination_id: "",
            payout_reference:
                account?.company.display_name ||
                account?.company.business_name ||
                "",
            name: "",
            description: "",
            country_code: country || "",
            organization_number: "",
            bank_account_number: "",
            currency: currencies[0] || getDefaultCountryCurrency(country || ""),
            form_submitter_email: "",
            locations: [],
        });
    const {
        hasErrors,
        result: errors,
        validateValues,
    } = useValidatedSchema({
        schema: useMemo(() => createValidationSchema(t), [t]),
        value: combined,
    });

    const onDialogClose = () => {
        onClose();
        // Reset form after it has been closed
        window.setTimeout(() => {
            clear();
            setPostedSeller(undefined);
        }, 300);
    };

    const isPostingSeller = isPostingProdSeller || isPostingTestSeller;

    const onSave = async () => {
        setSubmit();
        if (isPostingSeller || hasErrors || !!postedApprovalSeller) return;
        if (combined.locations.length > 0) {
            if (
                combined.locations.some(
                    (l) =>
                        !/^([\w\d\-_\S]{1,40})$/.test(l.locationId) &&
                        l.country.value === "",
                )
            )
                return;
            for (const l of combined.locations) {
                const location = mapValues(l);
                const geoLocation = await getGeoLocation(
                    location.address!,
                    i18n.language,
                );

                await createLocation(
                    {
                        ...location,
                        address: {
                            ...location.address!,
                            ...(geoLocation || {}),
                        },
                    },
                    l.accountId,
                );
            }
        }

        const result = await postSellerApproval(
            combined.accountId,
            {
                payout_destination_id: combined.payout_destination_id,
                payout_reference: combined.payout_reference,
                payout_destination_name: combined.name,
                payout_destination_description:
                    combined.accountId.startsWith("T") && combined.description
                        ? combined.description
                        : undefined,
                country_code: combined.country_code.toUpperCase(),
                organization_number: combined.organization_number.replace(
                    /\D/g,
                    "",
                ),
                bank_accounts: [
                    {
                        bank_name: "",
                        bank_account_number: "",
                        bank_account_currency: combined.currency,
                        payout_currency: combined.currency,
                    },
                ],
                form_submitter: combined.form_submitter_email
                    ? {
                          email: combined.form_submitter_email,
                      }
                    : undefined,
            },
            quickerVersionQueryString(),
        );

        setPostedSeller(result || undefined);
    };
    const onChange = useCallback(
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setValue(e.currentTarget.name, e.currentTarget.value);
        },
        [setValue],
    );
    const onBlur = useCallback(
        (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setValue(e.currentTarget.name, e.currentTarget.value);
            setTouched(e.currentTarget.name);
        },
        [setValue, setTouched],
    );

    const creatableAccountIds = accountIds.accountIds.filter((aid) => {
        return allowCreateProdPayoutDestinations || !aid.startsWith("P");
    });

    const errorFor = (key: keyof typeof errors) => {
        const hasError = isNotPristine(key) ? errors[key] : undefined;
        return hasError
            ? { message: hasError, state: ValidationState.Invalid }
            : undefined;
    };

    const addNewLocation = () => {
        setValue("locations", [
            ...combined.locations,
            {
                accountId: combined.accountId,
                country: {
                    label:
                        countryOptions.find(
                            (c) => c.value === combined.country_code,
                        )?.label || "",
                    value: country,
                },
                addressLine: "",
                addressLine2: "",
                businessName: "",
                countryPrefix: getPhonePrefixFromCountry(country),
                phoneNumber: "",
                email: "",
                franchise: false,
                franchiseName: "",
                latitude: 0,
                longitude: 0,
                name: "",
                postalCode: "",
                postalPlace: "",
                websiteUrl: "",
                type: typeOptions[0],
                city: "",
                organizationNumber: combined.organization_number.replace(
                    /\s/g,
                    "",
                ),
            },
        ]);
    };

    return (
        <Modal width="850px">
            <H2>{t("payout:payout_destination.form.title")}</H2>
            {!postedApprovalSeller && (
                <P>{t("payout:payout_destination.form.description")}</P>
            )}
            {postedApprovalSeller && (
                <>
                    <Spacer />
                    <PostedSellerApproval
                        postedApprovalSeller={postedApprovalSeller}
                        isFastTrack
                    />
                    <Spacer />
                </>
            )}
            {!postedApprovalSeller && (
                <>
                    <HalfNHalf>
                        <Dropdown
                            label={t(
                                "app:locations.location_form.fields.country",
                            )}
                            name="country_code"
                            value={
                                countryOptions.find(
                                    (c) => c.value === combined.country_code,
                                ) || countryOptions[0]
                            }
                            options={countryOptions}
                            onChange={(selected) =>
                                setValue("country_code", selected.value)
                            }
                            required
                        />
                        <Input
                            label={t("payout:bank_account.owner_orgno")}
                            value={combined.organization_number}
                            name="organization_number"
                            onChange={(e) => onChange(e as any)}
                            onBlur={(e) => onBlur(e as any)}
                            placeholder=""
                            validation={errorFor("organization_number")}
                            required
                        />
                    </HalfNHalf>
                    <HalfNHalf>
                        <Dropdown
                            label={t("payout:general.currency")}
                            name="currency"
                            value={{
                                value: combined.currency,
                                label: combined.currency,
                            }}
                            options={currencies.map((currency) => ({
                                label: currency,
                                value: currency,
                            }))}
                            onChange={(selected) =>
                                setValue("currency", selected.value)
                            }
                            required
                        />
                        <Input
                            label={t(
                                "payout:payout_destination.form.payout_destination_id",
                            )}
                            value={combined.payout_destination_id}
                            name="payout_destination_id"
                            onChange={(e) => onChange(e as any)}
                            onBlur={(e) => onBlur(e as any)}
                            placeholder=""
                            validation={errorFor("payout_destination_id")}
                            required
                        />
                    </HalfNHalf>
                    <SlideInOut
                        className={
                            combined.accountId.startsWith("T") ? "open" : ""
                        }
                    >
                        <Spacer />
                        <H2>
                            {t(
                                "payout:payout_destination.form.fast_track_title",
                            )}
                        </H2>
                        <div>
                            <p>
                                <Trans
                                    i18nKey={
                                        "payout:payout_destination.form.fast_track_description"
                                    }
                                >
                                    <b> </b>
                                </Trans>
                            </p>
                            <Toggle
                                value={isFastTrack}
                                onClick={() => {
                                    isFastTrack
                                        ? setValue("description", "")
                                        : setValue(
                                              "description",
                                              fastTrackOptions[0].value,
                                          );
                                    setIsFastTrack(!isFastTrack);
                                }}
                            />
                            <SlideInOut className={isFastTrack ? "open" : ""}>
                                <Dropdown
                                    disabled={!isFastTrack}
                                    label={t(
                                        "payout:payout_destination.form.fast_track_option_label",
                                    )}
                                    name="description"
                                    value={
                                        fastTrackOptions.find(
                                            (c) =>
                                                c.value ===
                                                combined.description,
                                        ) || fastTrackOptions[0]
                                    }
                                    options={fastTrackOptions}
                                    onChange={(selected) =>
                                        setValue("description", selected.value)
                                    }
                                />
                            </SlideInOut>
                        </div>
                        <div />
                    </SlideInOut>
                    <Spacer />
                    <H2>
                        {t(
                            "payout:payout_destination.form.communication_title",
                        )}
                    </H2>
                    <P>
                        {t(
                            "payout:payout_destination.form.communication_description",
                        )}
                    </P>
                    <Input
                        label={t(
                            "payout:payout_destination.form.form_submitter_email",
                        )}
                        value={combined.form_submitter_email}
                        name="form_submitter_email"
                        onChange={(e) => onChange(e as any)}
                        onBlur={(e) => onBlur(e as any)}
                        placeholder="name@seller.com"
                        validation={errorFor("form_submitter_email")}
                    />
                    <Spacer />
                    <H2>
                        {t(
                            "payout:payout_destination.form.add_locations_title",
                        )}
                    </H2>
                    <Trans
                        i18nKey={
                            "payout:payout_destination.form.add_locations_desc"
                        }
                    >
                        <b> </b>
                    </Trans>
                    <br />
                    <br />
                    {combined.locations &&
                        combined.locations.length > 0 &&
                        combined.locations.map((location, index) => (
                            <SellerLocationForm
                                key={index}
                                onDelete={() => {
                                    setValue(
                                        "locations",
                                        combined.locations?.filter(
                                            (l, i) => i !== index,
                                        ),
                                    );
                                }}
                                locationFormValues={location}
                                onSubmit={(values) =>
                                    setValue(`locations[${index}]`, values)
                                }
                                onBlur={(values) => {
                                    setValue(`locations[${index}]`, values);
                                    validateValues();
                                }}
                                accountIds={creatableAccountIds}
                            />
                        ))}
                    <Button
                        style={{ marginTop: 32 }}
                        onClick={addNewLocation}
                        className="alt"
                    >
                        <Plus />
                        {t(
                            "payout:payout_destination.form.add_location_button",
                        )}
                    </Button>
                    <Spacer />
                </>
            )}
            <ButtonGroup>
                {!postedApprovalSeller ? (
                    <>
                        <Button
                            className="alt"
                            onClick={onDialogClose}
                            disabled={isPostingSeller}
                        >
                            {t("payout:payout_destination.form.cancel")}
                        </Button>
                        <Button onClick={onSave}>
                            {t("payout:payout_destination.form.submit")}
                        </Button>
                    </>
                ) : (
                    <>
                        <Button onClick={onDialogClose}>
                            {t("payout:payout_destination.form.close")}
                        </Button>
                    </>
                )}
            </ButtonGroup>
            {isPostingSeller && <LoadingOverlay />}
        </Modal>
    );
};

const PostedSellerApproval = ({
    postedApprovalSeller,
    isFastTrack,
}: {
    postedApprovalSeller: ApprovalsPayoutDestinationResponse;
    isFastTrack: boolean;
}) => {
    const { t } = useTranslation(["payout", "app"]);
    const contractUrl = (postedApprovalSeller.links || []).find(
        (l) => l.rel === "contract_url",
    )?.href;
    const isPostedFastTrack =
        isFastTrack &&
        ["AUTO_APPROVE", "AUTO_DECLINE", "AUTO_WAIT_FOR_SIGNATURE"].includes(
            postedApprovalSeller.payout_destination_description || "",
        );
    const showContactUrl = contractUrl && !isPostedFastTrack;
    return (
        <>
            {isPostedFastTrack ? (
                <SuccessBox>
                    <Icon icon="check_circle" />
                    <div>
                        <P>
                            {t(
                                "payout:payout_destination.form.fast_track_submitted",
                            )}
                        </P>
                    </div>
                </SuccessBox>
            ) : (
                <SuccessBox>
                    <Icon icon="check_circle" />
                    <div>
                        <P>
                            {t(
                                "payout:payout_destination.form.new_bank_account.alert_link",
                            )}
                        </P>
                        {contractUrl ? (
                            <>
                                <ABreakAll href={contractUrl} target="_blank">
                                    {contractUrl}
                                </ABreakAll>
                            </>
                        ) : (
                            <P>
                                {t(
                                    "app:settings.account.payout.application_link_missing",
                                )}
                            </P>
                        )}
                    </div>
                </SuccessBox>
            )}
            {showContactUrl && (
                <>
                    <Spacer />
                    <ButtonGroup>
                        <ButtonExternalLink
                            onClick={async () => {
                                try {
                                    await window.navigator.clipboard.writeText(
                                        contractUrl || "",
                                    );
                                    setAlert({
                                        severity: "info",
                                        message: t(
                                            "payout:payout_destination.form.link_copied",
                                        ),
                                    });
                                } catch {}
                            }}
                            className="alt"
                        >
                            {t("payout:payout_destination.form.copy_link")}
                        </ButtonExternalLink>
                        <ButtonExternalLink
                            href={contractUrl}
                            target="_blank"
                            rel="noreferrer"
                            className="alt"
                        >
                            {t(
                                "payout:payout_destination.form.open_declaration_form",
                            )}
                        </ButtonExternalLink>
                    </ButtonGroup>
                </>
            )}
        </>
    );
};

const HalfNHalf = styled.div`
    display: flex;
    justify-content: space-between;
    word-break: normal;
    white-space: nowrap;
    > * {
        width: 100%;

        &:nth-child(odd) {
            margin-right: ${distances.tiny};
        }
        &:nth-child(even) {
            margin-left: ${distances.tiny};
        }
    }
`;

const SlideInOut = styled.div`
    transition: all 0.3s;
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
    &.open {
        visibility: visible;
        overflow: visible;
        max-height: 200px;
    }
`;

const SuccessBox = styled.div`
    background-color:rgb(235, 252, 245);
    padding: 16px;
    display: flex;
    align-items: flex-start;
    max-width: 800px;

    & *:first-child {
        margin-right: 8px;
        margin-top: 4px;
    }
`;

const Spacer = styled.div`
    margin-top: 32px;
`;

export default CreatePayoutDestinationDialog;
