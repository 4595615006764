import { connect } from "react-redux";

import { State } from "../../../../reducer";
import { openInstabankModal } from "../../instabank/actions";
import { openPayExCallbackModal } from "../../payexCallback/actions";
import { getAccount } from "../actions";

import ConnectionsPage from "../components/ConnectionsPage";
import namespace from "../namespace";

const mapStateToProps = (state: State) => ({
    account: state[namespace].account,
    isLoading: state[namespace].isLoading,
});

const mapDispatchToProps = {
    getAccount,
    openInstabankModal,
    openPayExCallbackModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionsPage);
