import { combineReducers } from "redux";

import {
    ConfigState as PayoutMerchantConfigState,
    reducer as payoutConfig,
    namespace as payoutConfigNamespace,
} from "./payout/merchant/config/actions";
import { reducer as settings } from "./settings";

export interface State extends settings.State {
    [payoutConfigNamespace]: PayoutMerchantConfigState;
}

export const initialState: State = {
    ...settings.initialState,
    [payoutConfigNamespace]: payoutConfig(undefined, { type: "@@INIT" }),
};

export const reducer = combineReducers<State>({
    ...settings.reducers,
    [payoutConfigNamespace]: payoutConfig,
});

declare module "react-redux" {
    interface DefaultRootState extends State {}
}
