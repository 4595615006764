import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components/macro";

import ApiUser from "../../../../common/components/ApiUser";
import { Button, ButtonLink } from "../../../../components/Buttons";
import { Dd, Dl, Dt } from "../../../../components/DescriptionList/Horizontal";
import { EnvironmentBadge } from "../../../../components/EnvironmentBadge";
import { DateTime } from "../../../../components/Formatters";
import Hr from "../../../../components/Hr";
import { H2, H3, P } from "../../../../components/Typography";
import { border, colors, distances, grid } from "../../../../styles/constants";
import { Client, Grant } from "../../../../types/management-auth";
export interface DetailsInfoProps extends WithTranslation {
    client: Client;
    grants: Grant[];
    accountId: string;
    openDeleteGrantModal: (grant: Grant) => void;
}

const getEnvironmentFromAudience = (audience: string) => {
    // assumes audience starts with `https://host/accounts/{aid}`
    const grantAudienceAccountId: string = audience.split("/")[5] || "";
    const firstChar: string = grantAudienceAccountId[0];
    if (firstChar === "T") {
        return "test";
    }
    if (firstChar === "P") {
        return "production";
    }
    return "unknown";
};

const DetailsInfo = ({
    t,
    client,
    grants,
    accountId,
    openDeleteGrantModal,
}: DetailsInfoProps) => {
    const audienceType = (audience: string) => {
        const environment = getEnvironmentFromAudience(audience);
        return <EnvironmentBadge environment={environment} />;
    };

    return (
        <Wrapper>
            <Span8>
                <H2>{t("settings.client.client_info")}</H2>
                <Hr />
                <Dl>
                    <Dt doubleColumn>
                        {t("settings.client.fields.updated_at")}
                    </Dt>
                    <Dd>
                        <DateTime
                            isoString={client.updated_at || ""}
                            boldTime
                        />
                    </Dd>
                </Dl>
                <Dl>
                    <Dt doubleColumn>
                        {t("settings.client.fields.created_at")}
                    </Dt>
                    <Dd>
                        <DateTime
                            isoString={client.created_at || ""}
                            boldTime
                        />
                    </Dd>
                </Dl>
                <Dl>
                    <Dt doubleColumn>
                        {t("settings.client.fields.created_by")}
                    </Dt>
                    <Dd>
                        <ApiUser id={client.created_by} />
                    </Dd>
                </Dl>
                {(client.deleted_at || client.deleted_by) && <Spacer />}
                {client.deleted_at && (
                    <Dl>
                        <Dt doubleColumn>
                            {t("settings.client.fields.deleted_at")}
                        </Dt>
                        <Dd>
                            <DateTime
                                isoString={client.deleted_at || ""}
                                boldTime
                            />
                        </Dd>
                    </Dl>
                )}
                {client.deleted_by && (
                    <Dl>
                        <Dt doubleColumn>
                            {t("settings.client.fields.deleted_at")}
                        </Dt>
                        <Dd>
                            <ApiUser id={client.deleted_by} />
                        </Dd>
                    </Dl>
                )}

                {client.callbacks && client.callbacks.length > 0 && (
                    <React.Fragment>
                        <Spacer />
                        <Dl>
                            <Dt doubleColumn>
                                {t("settings.client.fields.callbacks")}
                            </Dt>
                            <Dd>
                                {client.callbacks
                                    .map((callback) => <code>{callback}</code>)
                                    .join(", ")}
                            </Dd>
                        </Dl>
                    </React.Fragment>
                )}
                {client.description && (
                    <Dl>
                        <Dt doubleColumn>
                            {t("settings.client.fields.description")}
                        </Dt>
                        <Dd>{client.description}</Dd>
                    </Dl>
                )}
            </Span8>
            <Span8>
                <H2>{t("settings.client.grants")}</H2>
                <Hr />

                <AddNewGrantButton
                    to={`/${accountId}/settings/clients/${client.id}/grants/new`}
                    className={client.deleted_at ? "alt disabled" : "alt"}
                >
                    {t("settings.client.button_new_grant")}
                </AddNewGrantButton>

                {grants.length === 0 && (
                    <P>{t("settings.client.client_has_no_grants")}</P>
                )}

                {grants.map((grant) => (
                    <GrantWrapper
                        key={grant.id}
                        className={grant.deleted_at ? "deleted" : ""}
                    >
                        <Dl>
                            <Dt doubleColumn>
                                {t("settings.client.fields.state")}
                            </Dt>
                            <Dd>
                                {grant.deleted_at ? (
                                    <Deleted>
                                        {t("settings.client.state.deleted")}
                                    </Deleted>
                                ) : (
                                    <Active>
                                        {t("settings.client.state.active")}
                                    </Active>
                                )}
                            </Dd>
                        </Dl>
                        {grant.deleted_at && (
                            <Dl>
                                <Dt doubleColumn>
                                    {t("settings.client.fields.deleted_at")}
                                </Dt>
                                <Dd>
                                    <DateTime
                                        isoString={grant.deleted_at || ""}
                                        boldTime
                                    />
                                </Dd>
                            </Dl>
                        )}
                        {grant.deleted_by && (
                            <Dl>
                                <Dt doubleColumn>
                                    {t("settings.client.fields.deleted_by")}
                                </Dt>
                                <Dd>{grant.deleted_by}</Dd>
                            </Dl>
                        )}
                        {(grant.deleted_at || grant.deleted_by) && <Spacer />}
                        <Dl>
                            <Dt doubleColumn>
                                {t(
                                    "settings.client.fields.audience_environment",
                                )}
                            </Dt>
                            <Dd>{audienceType(grant.audience)}</Dd>
                        </Dl>
                        <Dl>
                            <Dt doubleColumn>
                                {t("settings.client.fields.audience_url")}
                            </Dt>
                            <Dd>
                                <code>{grant.audience}</code>
                            </Dd>
                        </Dl>
                        <Dl>
                            <Dt doubleColumn>
                                {t("settings.client.fields.grant_type")}
                            </Dt>
                            <Dd>
                                <code>{grant.type}</code>
                            </Dd>
                        </Dl>

                        <H3>{t("settings.client.fields.scopes")}</H3>
                        {!grant.deleted_at && !client.deleted_at && (
                            <DeleteButton
                                className="alt"
                                onClick={() => openDeleteGrantModal(grant)}
                            >
                                {t("settings.client.button_delete_grant")}
                            </DeleteButton>
                        )}
                        <Ul>
                            {grant.scope &&
                                grant.scope.sort().map((scope) => (
                                    <li key={scope}>
                                        <code>{scope}</code>
                                    </li>
                                ))}
                        </Ul>
                        {(!grant.scope || grant.scope.length === 0) && (
                            <Muted>
                                {t("settings.client.grant_scopes_empty")}
                            </Muted>
                        )}

                        {grant.oidc && (
                            <>
                                <Hr />
                                <H3>{t("settings.client.fields.oidc")}</H3>
                                <Dl>
                                    <Dt doubleColumn>
                                        {t(
                                            "settings.client.fields.oidc_client_id",
                                        )}
                                    </Dt>
                                    <Dd>
                                        <code>{grant.oidc.client_id}</code>
                                    </Dd>
                                </Dl>
                                <Dl>
                                    <Dt doubleColumn>
                                        {t(
                                            "settings.client.fields.oidc_client_secret",
                                        )}
                                    </Dt>
                                    <Dd>
                                        <code>{grant.oidc.client_secret}</code>
                                    </Dd>
                                </Dl>
                                <Dl>
                                    <Dt doubleColumn>
                                        {t(
                                            "settings.client.fields.oidc_authority",
                                        )}
                                    </Dt>
                                    <Dd>
                                        <code>{grant.oidc.authority}</code>
                                    </Dd>
                                </Dl>
                            </>
                        )}
                    </GrantWrapper>
                ))}
            </Span8>
        </Wrapper>
    );
};

export default withTranslation()(DetailsInfo);

const Wrapper = styled.div`
    max-width: ${grid.spans.span8};
    width: 100%;
    margin: 0 auto;
    padding: ${distances.normal} ${distances.small};
`;

const Span8 = styled.div`
    padding-top: ${distances.normal};
    max-width: ${grid.spans.span8};
    width: 100%;
`;

const GrantWrapper = styled.div`
    padding: ${distances.normal};
    margin-bottom: ${distances.tiny};
    border: ${border.normal} solid ${colors.borderLight};

    &.deleted {
        background: ${colors.backgroundAlt};
    }
`;

const Ul = styled.ul`
    margin: 0;
    padding-left: 17px;
    list-style-type: circle;
`;

const AddNewGrantButton = styled(ButtonLink)`
    margin-bottom: ${distances.small};
`;

const DeleteButton = styled(Button)`
    float: right;
`;

const Spacer = styled.div`
    height: ${distances.normal};
`;

const Active = styled.span`
    color: ${colors.primary};
`;

const Deleted = styled.span`
    color: ${colors.invalid};
`;

const Muted = styled.em`
    color: ${colors.textSecondary};
`;
