import { useState } from "react";
import { useTranslation } from "react-i18next";
import useHasAnyOfScopes from "../../../common/hooks/useHasAnyOfScopes";
import { Button } from "../../../components/Buttons";
import { Money } from "../../../components/Formatters";
import Hr from "../../../components/Hr";
import Icon from "../../../components/Icons";
import Modal from "../../../components/Modal";
import { Divider } from "../../../components/PayoutBuffer/Divider";
import { PayoutBuffer } from "../../../components/PayoutBuffer/PayoutBuffer";
import { H2, P } from "../../../components/Typography";
import View from "../../../components/View";
import PayoutBufferEditor from "../../../payout/merchant/config/components/PayoutBufferEditor";
import type { PayoutDestinationConfig } from "../../../payout/types";
import { distances } from "../../../styles/constants";

type SellerPayoutBufferProps = {
    payoutDestination: PayoutDestinationConfig;
    onPayoutBufferChange: (
        payoutBuffer: PayoutDestinationConfig["payout_buffer"],
    ) => Promise<void>;
    disabled?: boolean;
};

export const SellerPayoutBuffer = ({
    payoutDestination,
    onPayoutBufferChange,
    disabled,
}: SellerPayoutBufferProps) => {
    const { t } = useTranslation();
    const canModify = useHasAnyOfScopes(["write:payout", "admin:payout"]);
    const [payoutBufferEditorActive, setPayoutBufferEditorActive] =
        useState(false);
    const setPayoutBuffer = async (
        payoutBuffer: PayoutDestinationConfig["payout_buffer"],
    ) => {
        await onPayoutBufferChange(payoutBuffer);
        setPayoutBufferEditorActive(false);
    };
    return (
        <>
            <View justify="space-between">
                <H2>{t("payout:payout_buffer.merchant.title")}</H2>
                {canModify && (
                    <Button
                        className="small left-icon"
                        disabled={disabled}
                        onClick={() =>
                            setPayoutBufferEditorActive(
                                !payoutBufferEditorActive,
                            )
                        }
                    >
                        <Icon
                            icon={
                                (payoutDestination.payout_buffer?.buffers || [])
                                    ?.length
                                    ? "edit_pen"
                                    : "plus"
                            }
                        />
                        {(payoutDestination.payout_buffer?.buffers || [])
                            ?.length
                            ? t("payout:payout_buffer.actions.edit")
                            : t("payout:payout_buffer.form.actions.add_buffer")}
                    </Button>
                )}
            </View>
            <Hr />
            <View gap={distances.small} width="100%" justify="space-between">
                <View gap={distances.tiny}>
                    {payoutDestination.payout_buffer?.buffers?.map((buffer) => (
                        <PayoutBuffer key={buffer.currency}>
                            <P>
                                <Money
                                    amount={Number.parseInt(
                                        buffer.fixed_amount || "0",
                                    )}
                                    currency={buffer.currency}
                                    hideCurrency
                                />
                            </P>
                            <Divider />
                            <P>{buffer.currency}</P>
                        </PayoutBuffer>
                    ))}
                    {!payoutDestination.payout_buffer?.buffers?.length && (
                        <P>
                            {t("payout:payout_buffer.payout_destination.empty")}
                        </P>
                    )}
                </View>
            </View>
            {payoutBufferEditorActive && (
                <Modal
                    onClose={() => setPayoutBufferEditorActive(false)}
                    wrapperStyle={{
                        minHeight: "520px",
                    }}
                >
                    <PayoutBufferEditor
                        payoutBuffer={payoutDestination.payout_buffer}
                        updatePayoutBuffer={setPayoutBuffer}
                        inModal
                        onClose={() => setPayoutBufferEditorActive(false)}
                        disabled={disabled}
                    />
                </Modal>
            )}
        </>
    );
};
