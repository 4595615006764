import { Dispatch } from "redux";
import { createAccountIdChangedCaseReducer } from "../../../auth/useAccountId";
import { CORE_API_HOSTNAME } from "../../../env";
import fulfill from "../../../fulfill";
import { Account } from "../../../types/management-auth";
import { createSwitchlessReducer } from "../../../util/switchlessReducer";
import { getAccount } from "../connections/actions";
import { CollectorPayload } from "./types";

export const namespace = "connections.collector";

export enum ModalState {
    Closed = "Closed",
    InitiateApplicationLoading = "InitiateApplicationLoading",
    ApplicationFormOpened = "ApplicationFormOpened",
    CollectorApiError = "CollectorApiError",
    ApplicationReceived = "ApplicationReceived",
}

export interface State {
    modalState: ModalState;
    account: Account | undefined;
}

export const initialState: State = {
    modalState: ModalState.Closed,
    account: undefined,
};

export const { reducer, actions } = createSwitchlessReducer({
    namespace: namespace,
    initialState,
    reducers: {
        openModal: (state: State, account: Account) => {
            return {
                ...state,
                modalState: ModalState.ApplicationFormOpened,
                account: account,
            };
        },
        closeModal: (state: State) => {
            return {
                ...state,
                modalState: ModalState.Closed,
            };
        },
        postConnection: (state) => {
            return {
                ...state,
                modalState: ModalState.InitiateApplicationLoading,
            };
        },
        postConnectionSuccess: (state) => {
            return {
                ...state,
                modalState: ModalState.ApplicationReceived,
            };
        },
        postConnectionFailure: (state) => {
            return {
                ...state,
                modalState: ModalState.CollectorApiError,
            };
        },
        postConnectionComplete: (state) => {
            return {
                ...state,
                modalState: ModalState.Closed,
            };
        },
    },
    globalReducer: createAccountIdChangedCaseReducer(() => initialState),
});

export const createConnectedActions = (dispatch: Dispatch) => {
    const openModal = (account: Account) => {
        dispatch(actions.openModal(account));
    };

    const closeModal = () => {
        dispatch(actions.closeModal());
    };

    const finishRegistration = async (payload: CollectorPayload) => {
        const { accountId, body, userId, account } = payload;
        if (!accountId || !userId) return;
        dispatch(actions.postConnection());
        if (!account.connections?.collector?.signup) {
            await fulfill.post({
                accountId,
                url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/management/settings/connections/collector`,
                json: {
                    signup: body.signup,
                },
                handlers: {
                    200: () => {
                        dispatch(actions.postConnectionSuccess());
                    },
                    500: () => {
                        dispatch(actions.postConnectionFailure());
                    },
                    501: () => {
                        dispatch(actions.postConnectionFailure());
                    },
                    502: () => {
                        dispatch(actions.postConnectionFailure());
                    },
                    503: () => {
                        dispatch(actions.postConnectionFailure());
                    },
                    504: () => {
                        dispatch(actions.postConnectionFailure());
                    },
                },
            });
        }
        await fulfill.post({
            accountId,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/management/settings/connections/collector/${userId}`,
            json: {
                username: body.username,
                password: body.password,
                payment_options: body.payment_options,
                stores: body.stores,
            },
            handlers: {
                202: () => {
                    dispatch(getAccount(accountId));
                    dispatch(actions.postConnectionComplete());
                },
                500: () => {
                    dispatch(actions.postConnectionFailure());
                },
                501: () => {
                    dispatch(actions.postConnectionFailure());
                },
                502: () => {
                    dispatch(actions.postConnectionFailure());
                },
                503: () => {
                    dispatch(actions.postConnectionFailure());
                },
                504: () => {
                    dispatch(actions.postConnectionFailure());
                },
            },
        });
    };

    return {
        openModal,
        closeModal,
        finishRegistration,
    };
};
