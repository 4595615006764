import { connect } from "react-redux";

import { getAccountUser } from "../../../../auth/accessToken/selectors";
import { State } from "../../../../reducer";
import {
    createPaymentProfile,
    getCheckoutConfiguration,
    resetForm,
} from "../actions";
import { getPaymentProfileSelector } from "../selectors";

import NewPaymentProfileForm from "../components/NewPaymentProfileForm";

const mapStateToProps = (state: State) => ({
    paymentProfile: getPaymentProfileSelector(state),
    accountUser: getAccountUser(state),
});

const mapDispatchToProps = {
    createPaymentProfile,
    resetForm,
    getCheckoutConfiguration,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NewPaymentProfileForm);
