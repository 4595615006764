import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { Dd, Dl, Dt } from "../../../../components/DescriptionList/Horizontal";
import { H1 } from "../../../../components/Typography";
import { colors, distances, grid, palette } from "../../../../styles/constants";
import { Client } from "../../../../types/management-auth";

export interface HeroProps extends WithTranslation {
    client: Client;
}

const Hero = ({ t, client }: HeroProps) => {
    return (
        <HeroWrapper>
            <Inner>
                <H1>{client.name}</H1>
                <Dl>
                    <Dt>{t("settings.client.fields.state")}</Dt>
                    <Dd>
                        {client.deleted_at ? (
                            <Deleted>
                                {t("settings.client.state.deleted")}
                            </Deleted>
                        ) : (
                            <Active>{t("settings.client.state.active")}</Active>
                        )}
                    </Dd>
                </Dl>
                <Dl>
                    <Dt>{t("settings.client.fields.client_id")}</Dt>
                    <Dd>
                        <code>{client.client_id}</code>
                    </Dd>
                </Dl>
            </Inner>
        </HeroWrapper>
    );
};

export default withTranslation()(Hero);

const HeroWrapper = styled.div`
    background: ${palette.primary[50]};
    width: 100%;
    color: ${palette.primary[700]};
`;
const Inner = styled.div`
    max-width: ${grid.spans.span8};
    margin: 0 auto;
    padding: ${distances.large} ${distances.small};
`;

const Active = styled.span`
    color: ${colors.primary};
`;

const Deleted = styled.span`
    color: ${colors.invalid};
`;
