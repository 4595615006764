import styled from "styled-components/macro";
import { palette } from "../../styles/constants";
import { Button } from "../Buttons";

const MenuItem = styled(Button).attrs({
    className: "menu-item-button",
})`
    color: ${palette.primary[500]};
    background: transparent;
    border: 1px solid transparent;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: 4px;
    width: 100%;
    &:hover {
        background: ${palette.primary[50]};
        border: 1px solid ${palette.primary[50]};
    }
`;

export default MenuItem;
