import { Trans, useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import { useAccountIds } from "../../../auth/accessToken/components/withAccountIds";
import { LoadingOverlay } from "../../../components/Loading";
import Page from "../../../components/Page";
import { P } from "../../../components/Typography";
import { palette } from "../../../styles/constants";
import { Account } from "../../../types/management-auth";
import useAccount from "../../account/hooks/useAccount";
import { useCheckoutConfiguration } from "../../checkoutConfigurationHooks";
import FinishAccountInfobox from "../../components/FinishAccountInfobox";
import ApprovalsStatus from "./ApprovalStatus";
import ApprovalsPaymentsForm from "./ApprovalsPaymentsForm";
import PaymentMethodConfiguration from "./PaymentMethodConfiguration";
import { usePaymentApprovals } from "./hooks";
import { PaymentMethod } from "./types";
import {
    checkShowApprovalsStatus,
    hasAnyApprovals,
    hasAnyConnection,
    hasAnyPaymentMethod,
} from "./utils";

const ApprovalsPage = () => {
    const { t } = useTranslation();
    const { prodAccountId, urlAccountId } = useAccountIds();
    const {
        isLoading: isLoadingAccount,
        account,
        updateAccount,
    } = useAccount();
    const { isLoading: isLoadingApprovals, approvals } = usePaymentApprovals({
        forceCheck: true,
    });
    const { isLoading: isLoadingCheckoutConfiguration, checkoutConfiguration } =
        useCheckoutConfiguration("P");

    const showApprovalsStatus = checkShowApprovalsStatus(
        approvals || [],
        checkoutConfiguration,
        account,
    );
    const showPaymentMethodConfiguration =
        hasAnyApprovals(approvals || []) ||
        hasAnyPaymentMethod(checkoutConfiguration) ||
        hasAnyConnection(account);

    const onActivatePaymentMethod = async (paymentMethod: PaymentMethod) => {
        if (
            approvals.length === 0 ||
            !showPaymentMethodConfiguration ||
            !urlAccountId ||
            !account
        ) {
            return;
        }

        const newSettings: Account = {
            ...account,
            services: {
                checkout: {
                    ...account.services?.checkout,
                    features: {
                        ...account.services?.checkout?.features,
                        [paymentMethod]: {
                            requested: "enable",
                        },
                    },
                },
            },
        };

        await updateAccount({ accountId: urlAccountId, account: newSettings });
    };

    const isLegacyConfiguration =
        approvals.length === 0 &&
        account &&
        hasAnyPaymentMethod(checkoutConfiguration);

    if (approvals.length === 0 && !prodAccountId) {
        return (
            <Page title={t("settings.approvalsPayments.title")}>
                <FinishAccountInfobox context="payment_connections" />
            </Page>
        );
    }

    return (
        <Page title={t("settings.approvalsPayments.title")}>
            {approvals.length === 0 && account && (
                <ApprovalsPaymentsForm
                    account={account}
                    legacy={isLegacyConfiguration}
                />
            )}
            {approvals.length > 0 && showApprovalsStatus && account && (
                <>
                    <ApprovalsStatus approvals={approvals} account={account} />
                    <br />
                </>
            )}
            {approvals.length > 0 && !showApprovalsStatus && account && (
                <Trans
                    parent={P}
                    i18nKey="settings.approvalsPayments.description"
                >
                    <strong
                        style={{
                            fontWeight: 600,
                        }}
                    />
                    <Link
                        to={`/${urlAccountId}/settings/payment-profiles`}
                        style={{
                            fontWeight: 600,
                            textDecoration: "none",
                            color: palette.primary[500],
                        }}
                    />
                </Trans>
            )}
            {showPaymentMethodConfiguration &&
                !showApprovalsStatus &&
                approvals.length > 0 &&
                account && (
                    <PaymentMethodConfiguration
                        approvals={approvals}
                        checkoutConfiguration={checkoutConfiguration}
                        account={account}
                        onActivation={onActivatePaymentMethod}
                        isLegacyConfiguration={isLegacyConfiguration}
                    />
                )}
            {(isLoadingAccount ||
                isLoadingApprovals ||
                isLoadingCheckoutConfiguration) && <LoadingOverlay />}
        </Page>
    );
};

export default ApprovalsPage;
