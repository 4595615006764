import { useCallback, useEffect, useRef, useState } from "react";

const useDocumentVisibleAgainEffect = (fn: () => any) => {
    const prevVisibleStateRef = useRef(false);
    const [counter, setCounter] = useState(0);
    // biome-ignore lint/correctness/useExhaustiveDependencies: Memoize callback once
    const handleVisibilityChange = useCallback(() => {
        if (
            !prevVisibleStateRef.current &&
            document.visibilityState === "visible"
        ) {
            setCounter(counter + 1);
            fn();
        }
        prevVisibleStateRef.current = document.visibilityState === "visible";
    }, []);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Register once
    useEffect(() => {
        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange,
            );
        };
    }, []);

    return counter;
};

export default useDocumentVisibleAgainEffect;
