import createExternalStore from "../../../../common/utils/createExternalStore";
import { CORE_API_HOSTNAME } from "../../../../env";
import fulfill from "../../../../fulfill";
import type {
    AccountAdminUser,
    AccountUser,
} from "../../../../types/management-auth";

export interface State {
    isLoading: boolean;
    user: AccountAdminUser | undefined;
}

export const initialState: State = {
    isLoading: false,
    user: undefined,
};

export const newUserStore = createExternalStore(
    initialState,
    {
        resetForm: () => initialState,
        createUser: (state) => ({
            ...state,
            isLoading: true,
        }),
        fetchCreateUserResponse: (state, user: AccountAdminUser) => ({
            ...state,
            isLoading: false,
            user,
        }),
    },
    "newUser",
);

export const actions = {
    resetForm: () => newUserStore.dispatch("resetForm"),
    createUser: async (accountId: string, newUser: AccountUser) => {
        newUserStore.dispatch("createUser");
        return await fulfill.post<AccountUser>({
            accountId: accountId,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/management/users`,
            json: newUser,
            handlers: {
                200: (user: AccountAdminUser) =>
                    newUserStore.dispatch("fetchCreateUserResponse", user),
            },
        });
    },
};
