import { CORE_API_HOSTNAME } from "../../../env";
import fulfill from "../../../fulfill";
import { SideEffectFunction } from "../../../sideEffects";
import { Account } from "../../../types/management-auth";
import {
    ActionTypes,
    FetchAccount,
    FetchAccountResponse,
    SearchCompanyName,
    SearchCompanyNameResponse,
    searchCompanyNameResponse,
} from "./actions";

const fetchAccount: SideEffectFunction<
    FetchAccount,
    FetchAccountResponse
> = async (action) => {
    const result: FetchAccountResponse = await fulfill.get({
        accountId: action.payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/management/settings`,
        handlers: {
            200: (account: Account) => ({
                type: ActionTypes.FetchAccountResponse,
                payload: {
                    account,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const asProtocolUrl = (url: string | undefined) => {
    if (!url) {
        return "";
    }
    if (url.startsWith("http")) {
        return url;
    }
    return `https://${url}`;
};

export const searchCompanyName: SideEffectFunction<
    SearchCompanyName,
    SearchCompanyNameResponse
> = async (action) => {
    const partialName = action.payload.partialName;
    let searchProp = "name";
    let searchValue = partialName;
    const possibleOrgnumber = partialName.replace(/\D/g, "");
    if (possibleOrgnumber.length === 9) {
        searchValue = possibleOrgnumber;
        searchProp = "organization_number";
    }
    searchValue = encodeURIComponent(searchValue);
    const response: SearchCompanyNameResponse = await fulfill.get({
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/search/external/organizations/no?${searchProp}=${searchValue}`,
        accountId: "none",
        handlers: {
            200: (response) => {
                const accounts = response.map((entry: any) => {
                    return {
                        organization_number: entry.organization_number,
                        business_name: entry.business_name,
                        website: asProtocolUrl(entry.url),
                        address: {
                            address_line: entry.business_address?.address_line,
                            address_line_2:
                                entry.business_address?.address_line_2,
                            postal_code: entry.business_address?.postal_code,
                            postal_place: entry.business_address?.postal_place,
                            country: entry.business_address?.country,
                        },
                    };
                });
                return searchCompanyNameResponse(accounts);
            },
        },
    });
    return response;
};

const effects = {
    [ActionTypes.FetchAccount]: fetchAccount,
    [ActionTypes.SearchCompanyName]: searchCompanyName,
};

export default effects;
