import { useAccountIds } from "../../auth/accessToken/components/withAccountIds";
import { useClients } from "../../settings/clients/clients/hooks/useClients";

type UseApiClientsProps = {
    environment: "test" | "production" | "url";
};

const getEnvironmentAccountId = (
    environment: UseApiClientsProps["environment"],
    testAccountId: string | undefined,
    prodAccountId: string | undefined,
    urlAccountId: string,
) => {
    switch (environment) {
        case "test":
            return testAccountId || urlAccountId;
        case "production":
            return prodAccountId || urlAccountId;
        case "url":
            return urlAccountId;
        default:
            return urlAccountId;
    }
};

const useApiClients = (props?: UseApiClientsProps) => {
    const { environment } = props || { environment: "url" };
    const { testAccountId, prodAccountId, urlAccountId } = useAccountIds();
    const { clients, isLoading, grants, refresh } = useClients(
        getEnvironmentAccountId(
            environment,
            testAccountId,
            prodAccountId,
            urlAccountId,
        ),
    );
    return {
        isLoading,
        clients,
        grants,
        refresh,
    };
};

export default useApiClients;
