import styled from "styled-components/macro";

import { distances } from "../styles/constants";

const EmptyState = styled.section`
    padding: ${distances.large};
    text-align: center;
    line-height: ${distances.ginormous};
`;

export const EmptyStateImg = styled.img`
    max-width: 60%;
    margin-bottom: ${distances.small};
`;

export const EmptyStateIconWrapper = styled.div`
    max-width: 60%;
    padding-bottom: ${distances.normal};
    margin: 0 auto;
    height: 200px;
`;

export default EmptyState;
