import L from "leaflet";
import iconRetinaUrl from "leaflet/dist/images/marker-icon-2x.png";
import iconUrl from "leaflet/dist/images/marker-icon.png";
import shadowUrl from "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/leaflet.css";
import { Map, Marker, TileLayer } from "react-leaflet";
import styled from "styled-components/macro";
import { SalesLocation } from "../../../../types/customers";

delete (L.Icon.Default.prototype as any)._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl,
    iconUrl,
    shadowUrl,
});

interface LocationMapProps {
    location: SalesLocation;
}

const LocationMap = ({ location }: LocationMapProps) => {
    const osloCenterCoordinates = {
        latitude: 59.911491,
        longitude: 10.757933,
    };

    const latitude =
        location.address && location.address.latitude
            ? location.address.latitude
            : osloCenterCoordinates.latitude;
    const longitude =
        location.address && location.address.longitude
            ? location.address.longitude
            : osloCenterCoordinates.longitude;

    return (
        <>
            <link
                rel="stylesheet"
                href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
                integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
                crossOrigin=""
            />
            <StyledMap center={[latitude, longitude]} zoom={12}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="&copy; <a href=`http://osm.org/copyright`>OpenStreetMap</a> contributors"
                />
                <Marker position={[latitude, longitude]} />
            </StyledMap>
        </>
    );
};

const StyledMap = styled(Map as any)`
    height: 300px;
`;

export default LocationMap;
