import React from "react";
import styled from "styled-components/macro";

function replaceURLWithHTMLLinks(text: string) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
        return `<a href="${url}">${url}</a>`;
    });
}

function removeAllTagsExceptAnchors(html: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const body = doc.body;

    function traverse(node: Node) {
        if (!node) return "";
        if (node.nodeName === "A" && node instanceof HTMLAnchorElement) {
            // node is an anchor, remove any event handlers
            const safeAnchor = doc.createElement("a");
            safeAnchor.href = node.href;
            safeAnchor.target = "_blank";
            safeAnchor.textContent = (node.textContent || "") + "↗";
            return safeAnchor.outerHTML;
        } else if (node.nodeName === "#text") {
            // node is a text node
            return node.textContent || "";
        } else {
            // node is of other type, process its children
            let result = "";
            node.childNodes.forEach((child) => {
                result += traverse(child);
            });
            return result;
        }
    }

    return traverse(body);
}

const Json = ({
    data,
    anchors,
}: {
    data: any;
    anchors?: boolean;
}) => {
    // A horrible component that tries to remove cruft from json
    // to make it more humanly readable.

    if (!data) {
        return null;
    }

    const humanKeys = keyToHumanCase(data);
    const humanReadableJson = JSON.stringify(humanKeys, null, 2)
        .replace(/,\n\s\s"/g, "\n\n  ") // all top-level keys ends with double \n
        .replace(/\[\n\s+{\n/g, "\n") // remove double \n at beginning of lists
        .replace(/: {/g, ":") // removes general json cruft
        .replace(/: \[/g, ":") // removes general json cruft
        .replace(/"|{|}|\[|\]|,/g, "") // removes general json cruft
        .replace(/\n(\s*\n)+/g, "\n\n"); // flatten all consecutive empty lines into max double \n

    if (anchors) {
        // Replace urls with anchors
        const humanReadableJsonWithAnchors =
            replaceURLWithHTMLLinks(humanReadableJson);

        // Remove all tags but anchors from the json input
        const safeHumanReadableJsonWithAnchors = removeAllTagsExceptAnchors(
            humanReadableJsonWithAnchors,
        );
        return (
            <Pre
                dangerouslySetInnerHTML={{
                    __html: "  " + safeHumanReadableJsonWithAnchors,
                }}
            />
        );
    }
    return <Pre>{humanReadableJson}</Pre>;
};

const capitalize = (key: string) => {
    return key.charAt(0).toUpperCase() + key.slice(1);
};

const keyToHumanCase = (key: any): any => {
    if (typeof key === "string") {
        const spaced = key.replace(/_/g, " ");
        return capitalize(spaced);
    }
    return key;
};

const Pre = styled.pre`
    margin: 0;
    padding: 0;
    overflow: auto;
    min-width: 0;
    white-space: pre-wrap;
`;

export default Json;
