import type { ExternalStore } from "./createExternalStore";

/**
 * Factory function to create a selector for an external store. Backward compatibility for the old way of selecting from an external store.
 * @param store External store to select from
 * @param selector Selector function to get the desired value from the store
 * @returns The selected value from the store
 */
export default function createExternalStoreSelector<T, U>(
    store: ExternalStore<T>,
    selector: (state: T) => U,
) {
    return (_: unknown) => store.select(selector);
}
