import { useRef } from "react";
import styled from "styled-components/macro";
import { palette } from "../../styles/constants";
import { useAccordionContext, useAccordionItemContext } from "./Context";

type AccordionPanelProps = {
    children: React.ReactNode;
    unstyled?: boolean;
    indent?: string | number;
};

export default function AccordionPanel({
    children,
    unstyled,
    indent,
}: AccordionPanelProps) {
    const { value } = useAccordionItemContext();
    const ctx = useAccordionContext();
    const isActive = ctx.isItemActive(value);
    const ref = useRef<HTMLDivElement>(null);

    const getChildHeight = () => {
        if (!ref.current) {
            return 0;
        }
        return ref.current.scrollHeight;
    };

    return (
        <Panel
            id={ctx.getRegionId(value)}
            data-active={isActive}
            data-unstyled={unstyled}
            role="region"
            ref={ref}
            openHeight={getChildHeight()}
            style={{
                ...(indent && {
                    paddingLeft: indent,
                }),
            }}
        >
            {children}
        </Panel>
    );
}

type PanelProps = {
    openHeight: number;
};

const Panel = styled.div<PanelProps>`
    width: 100%;
    transition: all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
    &[data-active='false'] {
        pointer-events: none;
        user-select: none;
        max-height: 0px;
        opacity: 0;
    }
    &[data-active='true'] {
        pointer-events: auto;
        user-select: auto;
        max-height: ${(props) => props.openHeight}px;
        opacity: 1;
        background-color: ${palette.neutral[50]};
    }
    &[data-unstyled='true'] {
        background-color: unset;
    }
`;
