import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { useActions } from "../Actions";
import { useAccountIds } from "../auth/accessToken/components/withAccountIds";
import { namespace } from "./checkoutConfigurationActions";
import {
    checkoutConfiguration as checkoutConfigurationSelector,
    isLoading as isLoadingSelector,
} from "./checkoutConfigurationSelectors";

export const useCheckoutConfiguration = (environment?: "T" | "P") => {
    const { getCheckoutConfig, updateCheckoutConfig } = useActions(namespace);
    const getCheckoutConfigRef = useRef(getCheckoutConfig);
    const { urlAccountId, testAccountId, prodAccountId } = useAccountIds();
    const accountIdMap = {
        T: testAccountId,
        P: prodAccountId,
    };
    const accountId =
        (environment ? accountIdMap[environment] : urlAccountId) || "";
    const isLoading = useSelector((state) =>
        isLoadingSelector(state, accountId),
    );
    const checkoutConfiguration = useSelector((state) =>
        checkoutConfigurationSelector(state, accountId),
    );
    // biome-ignore lint/correctness/useExhaustiveDependencies: Re-run only when accountId changes
    useEffect(() => {
        if (accountId) {
            getCheckoutConfigRef.current(accountId);
        }
    }, [accountId, getCheckoutConfigRef]);
    return {
        isLoading,
        checkoutConfiguration,
        updateCheckoutConfiguration: updateCheckoutConfig,
    };
};
