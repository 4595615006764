import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import useScopes from "../auth/accessToken/hooks/useScopes";
import { useAccountId } from "../auth/useAccountId";
import usePaymentsEnabled from "../common/hooks/usePaymentsEnabled";
import { usePaymentApprovals } from "../settings/paymentConnections/approvalsPayments/hooks";
import { globalMobileBreakpoint, palette } from "../styles/constants";
import chevronRightSvg from "./TopBar/icons/chevron-right.svg";

type ActivatePaymentsLinkProps = {
    shrink?: boolean;
    sideEffect?: () => void;
};

export default function ActivatePaymentsLink({
    shrink,
    sideEffect,
}: ActivatePaymentsLinkProps) {
    const accountId = useAccountId();
    const paymentsEnabled = usePaymentsEnabled();
    const userScopes = useScopes();

    if (paymentsEnabled || !userScopes.includes("admin:accounts")) {
        return null;
    }

    return (
        <Button accountId={accountId} sideEffect={sideEffect} shrink={shrink} />
    );
}

const Button = ({
    accountId,
    sideEffect,
    shrink,
}: {
    accountId: string;
    sideEffect?: () => void;
    shrink?: boolean;
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { approvals, isLoading } = usePaymentApprovals({
        forceCheck: true,
    });
    if (isLoading) {
        return null;
    }
    const mostRecentApprovalStatus =
        approvals.length > 0
            ? approvals[approvals.length - 1].case_status
            : undefined;
    const inReview = ["UNDER_MANUAL_REVIEW", "AUTOMATIC_REVIEW"].includes(
        mostRecentApprovalStatus || "",
    );
    return (
        <Wrapper
            to={`/${accountId}/settings/payment-connections`}
            data-shrink={shrink}
            onClick={(e) => {
                if (sideEffect) {
                    e.preventDefault();
                    sideEffect();
                    history.push(`/${accountId}/settings/payment-connections`);
                }
            }}
            bg={inReview ? palette.primary[500] : palette.success[500]}
            bg-hover={inReview ? palette.primary[600] : palette.success[600]}
            bg-active={inReview ? palette.primary[700] : palette.success[700]}
        >
            {t("top_bar.activate_payments", {
                context: mostRecentApprovalStatus,
            })}
            <Icon />
        </Wrapper>
    );
};

const Wrapper = styled(Link).attrs((props) => props)<{
    bg: string;
    "bg-hover": string;
    "bg-active": string;
}>`
    transition: all 0.2s ease;
    background: ${({ bg }) => bg};
    color: white;
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 24px;
    text-decoration: none;
    text-align: center;

    /* Paragraph/Small/Medium */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */

    &:hover {
        background: ${(props) => props["bg-hover"]};
    }

    &:active {
        background: ${(props) => props["bg-active"]};
    }

    @media (max-width: ${globalMobileBreakpoint}px) {
        &[data-shrink='false'] {
            align-self: center;
            flex: 1;
            border-radius: 0;
            margin-block: -8px;
            display: inline-flex;
            min-height: 40px;
        }
        &[data-shrink='true'] {
            display: flex;
            padding: 2px 10px;
            justify-content: center;
            align-items: center;
            gap: 6px;

            /* Paragraph/XSmall/Medium */
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 166.667% */
        }
    }
`;

const Icon = styled.div.attrs({ className: "icon" })`
    mask-image: url(${chevronRightSvg});
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    background: white;
    width: 16px;
    height: 16px;
`;
