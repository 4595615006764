import { effects as clientsEffects } from "./clients";
import { effects as locationsEffects } from "./locations";
import { effects as paymentConnectionsEffects } from "./paymentConnections";
import { effects as paymentProfilesEffects } from "./paymentProfiles";
import { effects as payoutRulesEffects } from "./payoutRules";

const effects = {
    ...clientsEffects,
    ...paymentProfilesEffects,
    ...paymentConnectionsEffects,
    ...payoutRulesEffects,
    ...locationsEffects,
};

export default effects;
