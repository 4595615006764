import { CORE_API_HOSTNAME } from "../../../env";
import fulfill from "../../../fulfill";
import { SideEffectFunction } from "../../../sideEffects";
import { BillingPayoutRule } from "../../../types/billing";
import { SalesLocation } from "../../../types/customers";
import {
    ActionTypes,
    EditPayoutRule,
    EditPayoutRuleResponse,
    FetchLocations,
    FetchLocationsResponse,
    FetchPayoutRule,
    FetchPayoutRuleResponse,
} from "./actions";

const fetchPayoutRule: SideEffectFunction<
    FetchPayoutRule,
    FetchPayoutRuleResponse
> = async (action) => {
    const { accountId, payoutRuleId } = action.payload;
    const result: FetchPayoutRuleResponse = await fulfill.get({
        accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/billing/payout-rules`,
        handlers: {
            200: (response: {
                payout_rules: BillingPayoutRule[];
            }) => ({
                type: ActionTypes.FetchPayoutRuleResponse,
                payload: {
                    payoutRules: response.payout_rules,
                    payoutRuleId,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const editPayoutRule: SideEffectFunction<
    EditPayoutRule,
    EditPayoutRuleResponse
> = async (action) => {
    const { accountId, payoutRule, payoutRuleId } = action.payload;
    const result: EditPayoutRuleResponse = await fulfill.put<BillingPayoutRule>(
        {
            accountId,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/billing/payout-rules/${payoutRuleId}`,
            json: payoutRule,
            handlers: {
                200: (payoutRule: BillingPayoutRule) => ({
                    type: ActionTypes.EditPayoutRuleResponse,
                    payload: {
                        payoutRule,
                    },
                    meta: {
                        cause: action,
                    },
                }),
            },
        },
    );
    return result;
};

// todo, migrate to useActions('settings.locations').getLocations
export const fetchLocations: SideEffectFunction<
    FetchLocations,
    FetchLocationsResponse
> = async (action) => {
    const { accountId } = action.payload;
    const result: FetchLocationsResponse = await fulfill.get({
        accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/locations/?limit=100`,
        handlers: {
            200: (locations: SalesLocation[]) => ({
                type: ActionTypes.FetchLocationsResponse,
                payload: locations,
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const effects = {
    [ActionTypes.FetchPayoutRule]: fetchPayoutRule,
    [ActionTypes.EditPayoutRule]: editPayoutRule,
    [ActionTypes.FetchLocations]: fetchLocations,
};

export default effects;
