import { TFunction } from "i18next";

import {
    validate,
    validatePhoneNumber,
    validateRequired,
} from "../../../helpers/validation";
import { LocationFormValues } from "../../locations/types";

export const validateValues = (values: LocationFormValues, t: TFunction) => {
    let errors: { [key: string]: string } = {};
    const manipulatedValues = {
        ...values,
        phoneNumber: values.phoneNumber
            ? `${values.countryPrefix} ${values.phoneNumber}`
            : "",
    };

    const validators: {
        path: string;
        validator: (value: any) => string | undefined;
    }[] = [
        {
            path: "locationId",
            validator: validateRequired(
                t("locations.location_form.validation.required", {
                    fieldName: t("locations.location_form.fields.location_id"),
                }),
            ),
        },
    ];

    if (values.phoneNumber.length > 0) {
        validators.push({
            path: "phoneNumber",
            validator: validatePhoneNumber(
                t("locations.location_form.validation.invalid", {
                    fieldName: t("locations.location_form.fields.phone_number"),
                }),
            ),
        });
    }

    if (values.franchise) {
        validators.push({
            path: "franchiseName",
            validator: validateRequired(
                t("locations.location_form.validation.required", {
                    fieldName: t(
                        "locations.location_form.fields.franchise_name",
                    ),
                }),
            ),
        });
    }

    errors = validators.reduce((acc, elem) => {
        return validate(elem.path, elem.validator, manipulatedValues, acc);
    }, errors);

    return errors;
};
