export interface ParamChanges {
    [key: string]: string[] | string | undefined | boolean;
}

export const updateParams = (
    queryParams: URLSearchParams,
    changes: ParamChanges,
) => {
    // keeps order of queryParams when setting existing parameters
    let entries = [...queryParams.entries()];

    Object.entries(changes).forEach(([key, value]) => {
        // get old position in entries
        const firstIndex = entries.findIndex(
            ([originKey, originValue]) => originKey === key,
        );

        // removes old entries
        entries = entries.filter(
            ([originKey, originValue]) => originKey !== key,
        );

        const spliceAt = firstIndex >= 0 ? firstIndex : entries.length;

        if (typeof value === "string" && value) {
            entries.splice(spliceAt, 0, [key, value]);
        }
        if (value instanceof Array && value.length > 0) {
            const members = value.map((member) => [key, member]) as [
                string,
                string,
            ][];
            entries.splice(spliceAt, 0, ...members);
        }
        if (typeof value === "boolean" && value) {
            entries.splice(spliceAt, 0, [key, value as unknown as string]);
        }
    });
    return new URLSearchParams(entries);
};

export const paramsEqual = (
    a: URLSearchParams,
    b: URLSearchParams,
    blacklist: string[],
) => {
    const aa = new URLSearchParams(a);
    const bb = new URLSearchParams(b);
    // remove blacklist words
    blacklist.forEach((w) => {
        aa.delete(w);
        bb.delete(w);
    });
    // ignore order of items
    const aaa = [...aa.entries()].map((entry) => entry.join("=")).sort();
    const bbb = [...bb.entries()].map((entry) => entry.join("=")).sort();
    // check equality
    return aaa.join("&") === bbb.join("&");
};

export const createURLSearchParams = (dict: { [key: string]: string }) => {
    return new URLSearchParams(Object.entries(dict));
};
