import styled from "styled-components/macro";
interface DlProps extends React.HTMLProps<HTMLDListElement> {
    break?: number;
}

const Dl = styled.dl<DlProps>`
    padding: 0;
    margin: 0;
    display: flex;
    border-bottom: 1px solid transparent;
    position: relative;

    width: 100%;

    &:hover {
        border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
    }

    ${(props) =>
        props.break
            ? `
            @media (max-width: ${props.break}px) {
                flex-direction: column;
                margin-bottom: 8px;
                width: 100%;
            }`
            : `
            @media (max-width: 768px) {
                flex-direction: column;
                margin-bottom: 8px;
                width: 100%;
            }`};
`;

export default Dl;
