import { CORE_API_HOSTNAME } from "../../../env";
import fulfill from "../../../fulfill";
import { SideEffectFunction } from "../../../sideEffects";
import { ActionTypes, PutOverride, PutOverrideResponse } from "./actions";

export const putOverride: SideEffectFunction<
    PutOverride,
    PutOverrideResponse
> = async (action) => {
    const { accountId, gateway, storeId, credentials } = action.payload;
    const result: PutOverrideResponse = await fulfill.put({
        accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/management/settings/connections/${gateway}/overrides`,
        json: {
            overrides: [
                {
                    store_id: storeId,
                    credentials,
                },
            ],
        },
        handlers: {
            200: () => ({
                type: ActionTypes.PutOverrideResponse,
                payload: {
                    gateway,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const effects = {
    [ActionTypes.PutOverride]: putOverride,
};

export default effects;
