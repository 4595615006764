import { useTranslation } from "react-i18next";
import { H1, H6, P } from "../../../components/Typography";
import View from "../../../components/View";
import { formatMoney } from "../../../helpers/formatters";
import { getCurrentLanguage } from "../../../i18n";
import {
    SPLIT_PAYMENT_MONTHLY_FEE,
    SPLIT_PAYMENT_MONTHLY_FEE_PER_PAYOUT_TO_SELLER,
    SPLIT_PAYMENT_MONTHLY_FEE_PER_SELLER,
} from "../constants";
import { lngToCurrency } from "../helpers";
import useSplitPayment from "../hooks/useSplitPayment";
import SplitPaymentEnabledStatus from "./SplitPaymentEnabledStatus";
import SplitPaymentToggleButton from "./SplitPaymentToggleButton";
import { Box, BoxInner } from "./elements";

const SplitPaymentBox = () => {
    const { t, i18n } = useTranslation();
    const lng = getCurrentLanguage(i18n);
    const currency = lngToCurrency(lng);
    const { status, isLoading, activateSplitPayment } = useSplitPayment();
    const showPricing = status !== "enabled";
    return (
        <Box>
            <BoxInner>
                <View gap={8} mb={showPricing ? 15 : 0}>
                    <H6>
                        {t("settings.additional_services.split_payment.title")}
                    </H6>
                    <SplitPaymentEnabledStatus
                        status={status}
                        isLoading={isLoading}
                    />
                </View>
                {showPricing && (
                    <>
                        <View direction="column" alignItems="flex-start">
                            <P>
                                {t(
                                    "settings.additional_services.split_payment.monthly_fee",
                                )}
                            </P>
                            <H1>
                                {formatMoney(
                                    lng,
                                    SPLIT_PAYMENT_MONTHLY_FEE,
                                    currency,
                                )}
                            </H1>
                        </View>
                        <View direction="column" alignItems="flex-start">
                            <P>
                                {t(
                                    "settings.additional_services.split_payment.monthly_fee_per_seller",
                                )}
                            </P>
                            <H1>
                                {formatMoney(
                                    lng,
                                    SPLIT_PAYMENT_MONTHLY_FEE_PER_SELLER,
                                    currency,
                                )}
                            </H1>
                        </View>
                        <View direction="column" alignItems="flex-start">
                            <P>
                                {t(
                                    "settings.additional_services.split_payment.monthly_fee_per_payout_to_seller",
                                )}
                            </P>
                            <H1>{`${formatMoney(lng, SPLIT_PAYMENT_MONTHLY_FEE_PER_PAYOUT_TO_SELLER, currency)} + 0.25%`}</H1>
                        </View>
                    </>
                )}
            </BoxInner>
            <BoxInner>
                <P
                    style={{
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                        margin: 0,
                    }}
                >
                    {t(
                        "settings.additional_services.split_payment.description",
                    )}
                </P>
            </BoxInner>
            <SplitPaymentToggleButton
                status={status}
                onClick={activateSplitPayment}
                disabled={isLoading}
            />
        </Box>
    );
};

export default SplitPaymentBox;
