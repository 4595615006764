import { effects as editPaymentProfileEffects } from "./editProfile";
import { effects as newPaymentProfileEffects } from "./newProfile";
import { effects as paymentProfileEffects } from "./profileDetails";
import { effects as profilesEffects } from "./profiles";

const effects = {
    ...profilesEffects,
    ...newPaymentProfileEffects,
    ...paymentProfileEffects,
    ...editPaymentProfileEffects,
};

export default effects;
