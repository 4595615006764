import { Route } from "react-router-dom";

import ApprovalsPage from "./approvalsPayments/ApprovalsPage";
import EmbeddedQuickr from "./approvalsPayments/EmbeddedQuickr";
import { Connections } from "./connections";

const Routes = [
    <Route
        key="/payment-connections/gateways"
        path="/:accountId/settings/payment-connections/gateways"
        exact
        component={Connections}
    />,
    <Route
        key="/payment-connections"
        path="/:accountId/settings/payment-connections"
        exact
        component={ApprovalsPage}
    />,
    <Route
        key="/payment-connections/declaration"
        path="/:accountId/settings/payment-connections/declaration"
        exact
        component={EmbeddedQuickr}
    />,
];

export default Routes;
