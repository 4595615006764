import styled from "styled-components/macro";

import { colors, distances } from "../../styles/constants";

const Td = styled.td`
    margin: 0;
    padding: ${distances.small} 0;
    padding-bottom: calc(${distances.small} -1px);
    border-bottom: 1px solid transparent;
    color: ${colors.text};
    font-size: 14px;
    font-weight: 400;
    min-width: 30px;
    font-feature-settings: 'tnum';

    &.first-child {
        padding-left: ${distances.normal};
    }

    &.last-child {
        padding-right: ${distances.normal};
    }
    &.align-right {
        text-align: right;
    }

    &.align-center {
        text-align: center;
    }

    &.collapse-768 {
        @media (max-width: 768px) {
            display: none;
        }
    }

    &.collapse-650 {
        @media (max-width: 650px) {
            display: none;
        }
    }

    &.limit-100 {
        max-width: 100px;
    }
`;

export default Td;
