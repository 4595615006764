import React from "react";
import styled from "styled-components/macro";

import { useTranslation } from "react-i18next";
import { InputValidation } from "../../../components/Forms";
import Dropdown, { DropdownOption } from "../../../components/Forms/Dropdown";
import { colors, distances } from "../../../styles/constants";

import { Label } from "../../../components/Typography";

export interface SourceDropdownProps {
    value: string;
    name: string;
    placeholder: string;
    onChange: (value: string) => void;
    validation?: InputValidation;
    disabled?: boolean;
}

const SourceDropdown = ({
    name,
    value,
    onChange,
    validation,
    disabled,
}: SourceDropdownProps) => {
    const { t } = useTranslation();
    const optionValues = [
        "checkout/transactions",
        "checkout/transactions/open",
        "wallets/transactions",
        "orders/orderoperations",
    ];

    const options: DropdownOption[] = optionValues.map((value) => ({
        label: (
            <span>
                {t(`settings.reports.sources.source`, { context: value })}
            </span>
        ),
        value,
    }));
    const selectedOption = options.find((option) => option.value === value);
    return (
        <Wrapper isDisabled={!!disabled}>
            <Label htmlFor={name}>{t("settings.reports.source")}</Label>
            {selectedOption && (
                <Dropdown
                    name={name}
                    value={selectedOption}
                    options={options}
                    onChange={(val) => {
                        onChange(val.value);
                    }}
                    disabled={disabled}
                />
            )}
            {validation && validation.message && (
                <Message htmlFor={name} className={validation.state}>
                    {validation.message}
                </Message>
            )}
        </Wrapper>
    );
};

const Message = styled.label`
    position: absolute;
    top: 66px;
    left: 0;
    font-size: 11px;
    line-height: ${distances.small};

    &.invalid {
        color: ${colors.invalid};
    }

    &.valid {
        color: ${colors.valid};
    }
`;

const Wrapper = styled.div<{ isDisabled: boolean }>`
    position: relative;
    opacity: ${(props) => (props.isDisabled ? "0.6" : "1")};
`;

export default SourceDropdown;
