import { Dispatch } from "redux";
import apiRequest from "../../../apiRequest";
import { createAccountIdChangedCaseReducer } from "../../../auth/useAccountId";
import { CHECKOUT_API_HOSTNAME, CORE_API_HOSTNAME } from "../../../env";
import { CollectorConnectionCallback } from "../../../types/management-auth/generated";
import { createSwitchlessReducer } from "../../../util/switchlessReducer";
import { getAccount } from "../connections/actions";

export const namespace = "connections.collector_callback";

export type PatchedCollectorConnectionCallback = Omit<
    CollectorConnectionCallback,
    "password"
> & {
    password?: string;
};

export interface CollectorCredentialsData {
    name: string;
    data: {
        url: string;
        username: string;
        password: string;
        store_id: number;
        country: string;
        options?: {
            customer_client?: {
                customers_client_id: string;
                customers_client_secret: string;
                customers_client_audience: string;
            };
            enable_on_hold?: boolean;
            settlement_reference_fields?: string;
        };
    };
    description?: string;
    promote?: {
        type: "default" | "override";
        value: string;
        stores?: {
            store_id: number;
            country: string;
            segment: "B2C" | "B2B";
        }[];
    }[];
}

export enum ModalState {
    Closed = "Closed",
    PreApplicationInfo = "PreApplicationInfo",
    InitiateCallbackLoading = "InitiateCallbackLoading",
    CallbackSuccess = "CallbackSuccess",
    CallbackError = "CallbackError",
}

export interface State {
    modalState: ModalState;
    modalType: "swap_agreement" | "initial_connection_configuration";
}

export const initialState: State = {
    modalState: ModalState.Closed,
    modalType: "initial_connection_configuration",
};

export const { reducer, actions } = createSwitchlessReducer({
    namespace: namespace,
    initialState,
    reducers: {
        openModal: (state: State, { modalType }) => {
            return {
                ...state,
                modalState: ModalState.PreApplicationInfo,
                modalType,
            };
        },
        closeModal: (state: State) => {
            return {
                ...state,
                modalState: ModalState.Closed,
            };
        },
        postConnection: (state) => {
            return {
                ...state,
                modalState: ModalState.InitiateCallbackLoading,
            };
        },
        postConnectionSuccess: (state) => {
            return {
                ...state,
                modalState: ModalState.CallbackSuccess,
            };
        },
        postConnectionFailure: (state) => {
            return {
                ...state,
                modalState: ModalState.CallbackError,
            };
        },
    },
    globalReducer: createAccountIdChangedCaseReducer(() => initialState),
});

export const createConnectedActions = (dispatch: Dispatch) => {
    const openModal = (
        modalType: State["modalType"] = "initial_connection_configuration",
    ) => {
        dispatch(actions.openModal({ modalType }));
    };

    const closeModal = () => {
        dispatch(actions.closeModal());
    };

    const confirmConnection = async (
        account_id: string,
        userId: string,
        data: PatchedCollectorConnectionCallback,
    ) => {
        const url = `${CORE_API_HOSTNAME}/v1/accounts/${account_id}/management/settings/connections/collector/${userId}`;
        dispatch(actions.postConnection());

        try {
            await apiRequest("POST", account_id, url, {}, data);
            dispatch(actions.postConnectionSuccess());
            dispatch(getAccount(account_id));
        } catch (err) {
            dispatch(actions.postConnectionFailure());
        }
    };

    const createCredentials = async (
        account_id: string,
        data: CollectorCredentialsData,
    ) => {
        const url = `${CHECKOUT_API_HOSTNAME}/v1/admin/gateways/collector/credentials`;
        dispatch(actions.postConnection());

        try {
            await apiRequest("PUT", account_id, url, {}, data);
            dispatch(actions.postConnectionSuccess());
            dispatch(getAccount(account_id));
        } catch (err) {
            dispatch(actions.postConnectionFailure());
        }
    };

    return {
        openModal,
        closeModal,
        confirmConnection,
        createCredentials,
    };
};
