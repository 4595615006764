import { CHECKOUT_API_HOSTNAME } from "../../../env";
import fulfill from "../../../fulfill";
import { SideEffectFunction } from "../../../sideEffects";
import { ProfileConfiguration } from "../../../types/checkout";
import { ActionTypes, FetchProfilesResponse, GetProfiles } from "./actions";

const fetchProfiles: SideEffectFunction<
    GetProfiles,
    FetchProfilesResponse
> = async (action) => {
    return fulfill.get({
        accountId: action.payload.accountId,
        url: `${CHECKOUT_API_HOSTNAME}/v1/admin/session/profiles?limit=100`,
        handlers: {
            200: (profiles: ProfileConfiguration[]) => ({
                type: ActionTypes.FetchProfilesResponse,
                payload: {
                    profiles: profiles,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
};

const effects = {
    [ActionTypes.GetProfiles]: fetchProfiles,
};

export default effects;
