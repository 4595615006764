import { parseISOStrictOr } from "./date";

export interface HasId {
    id?: string;
}

export interface Gap {
    id: string;
    gap: true;
}

export type HasIdOrGap = HasId | Gap;

export const isGap = (obj: HasIdOrGap): obj is Gap => {
    return (obj as Gap).gap === true;
};

const defaultPagingIdFn = <T extends HasId>(obj: T) => {
    return obj.id;
};

export const mergeLists = <T extends HasId>(
    incoming: T[],
    old: (T | Gap)[],
    insertAfterId: string | null,
    pagingIdFn: (obj: T | Gap) => string | undefined = defaultPagingIdFn,
): (T | Gap)[] => {
    // Merges incoming data from an API response to an existing list of objects. If the insertAfterId is missing
    // we insert the incoming objects at the top of the old data, otherwise we append the incoming data after
    // the insertAfterId. If there are any objects with the same id in both incoming and the old lists, we use the object
    // from the incoming list (as it has newer data). If there is no overlap between the lists and we are not inserting
    // at the bottom of the list we insert a Gap object to signify that there might be some data that is missing after
    // the last of the incoming objects.

    const result = [...old];

    // For incoming objects either replace existing object in result array or add to newObjects.
    const newObjects: (T | Gap)[] = [];
    incoming.forEach((incomingObject) => {
        const idx = result.findIndex(
            (resultObject) =>
                pagingIdFn(resultObject) === pagingIdFn(incomingObject),
        );
        if (idx > -1) {
            // Replace existing object with the incoming object
            if (!isGap(result[idx])) {
                result.splice(idx, 1, incomingObject);
            }
        } else {
            // Add new object to newObjects
            newObjects.push(incomingObject);
        }
    });

    const incomingIsSuperset =
        incoming.length > old.length &&
        incoming.length - old.length === newObjects.length;
    if (incomingIsSuperset) {
        return incoming;
    }
    // If cursor is not found we insert new objects at the top of the list
    const insertAtIdx =
        result.findIndex((r) => pagingIdFn(r) === insertAfterId) + 1; // 0 if the insertAfterId is not found
    const isAppendingAtEnd = insertAtIdx === result.length; // check if we are appending to bottom of the list
    result.splice(insertAtIdx, 0, ...newObjects);

    // Remove the gap if we have a insertAfterId and we did not append at the end of the result array
    if (!isAppendingAtEnd && insertAfterId) {
        const oldGapIdx = result.findIndex(
            (obj) => isGap(obj) && pagingIdFn(obj) === insertAfterId,
        );
        if (oldGapIdx > -1) {
            result.splice(oldGapIdx, 1);
        }
    }

    // Add gap after the newObjects if we are not inserting at the end and there is no overlap between the old and
    // incoming objects.
    if (
        !isAppendingAtEnd &&
        newObjects.length &&
        newObjects.length === incoming.length
    ) {
        const lastNewObject = newObjects[newObjects.length - 1];
        const gap = {
            gap: true,
            id: (lastNewObject && pagingIdFn(lastNewObject)) || "",
        } as Gap;
        const gapIndex =
            result.findIndex(
                (obj) => pagingIdFn(obj) === pagingIdFn(lastNewObject),
            ) + 1;
        result.splice(gapIndex, 0, gap);
    }
    return result;
};

export const getListKey = <T extends HasId>(
    obj: T | Gap,
    pagingIdFn: (obj: T | Gap) => string | undefined = defaultPagingIdFn,
) => {
    const prefix = isGap(obj) ? "gap" : "object";
    return `${prefix}-${pagingIdFn(obj)}`;
};

export interface HasCreatedAt {
    created_at?: string;
}

const compareCreatedAtDateDesc = <T extends HasCreatedAt>(a: T, b: T) => {
    const aDate = parseISOStrictOr(a.created_at, new Date(0));
    const bDate = parseISOStrictOr(b.created_at, new Date(0));
    return bDate.valueOf() - aDate.valueOf();
};

export const orderedUniqueList = <T extends HasCreatedAt & HasId>(
    incoming: T[],
    old: T[],
) => {
    // removes duplicates, prefer incoming
    const byId = [...old, ...incoming].reduce<{ [key: string]: T }>(
        (acc, elem) => {
            const id = defaultPagingIdFn(elem);
            if (id) {
                return {
                    ...acc,
                    [id]: elem,
                };
            }
            return acc;
        },
        {},
    );
    return Object.values(byId).sort(compareCreatedAtDateDesc);
};
