import deepmerge from "deepmerge";
import { FormikErrors, FormikHandlers, FormikTouched } from "formik";
import debounce from "lodash/debounce";
import React, {} from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components/macro";
import { Button, ButtonGroup } from "../../../../components/Buttons";
import { ValidationState } from "../../../../components/Forms";
import Select from "../../../../components/Forms/Select";
import Hr from "../../../../components/Hr";
import { H2 } from "../../../../components/Typography";
import { getValueAt } from "../../../../helpers/getValueAt";
import {
    distances,
    globalColumnMaxWidth,
    grid,
} from "../../../../styles/constants";
import { Account } from "../../../../types/management-auth";
import CountryDropdown from "../../../components/CountryDropdown";
import { FormValues } from "./PrefillAccount";
import { TranslatedValidatedInput } from "./ValidatedInput";
interface PrefillFormProps {
    values: FormValues;
    handleChange: FormikHandlers["handleChange"];
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean,
    ) => void;
    setTouched: (
        touched: FormikTouched<FormValues>,
        shouldValidate?: boolean | undefined,
    ) => void;
    touched: FormikTouched<FormValues>;
    handleBlur: FormikHandlers["handleBlur"];
    hasChosenNorway: boolean;
    hasChosenCountry: boolean;
    businessNameValidation:
        | {
              state: ValidationState;
              message: any;
          }
        | undefined;
    errors: FormikErrors<FormValues>;
    isValid: boolean;
    searchCompanyName: (country: string, partialName: string) => void;
    possibleCompanies: Account[];
}

const PrefillForm = ({
    values,
    handleChange,
    setFieldValue,
    setTouched,
    touched,
    handleBlur,
    hasChosenNorway,
    hasChosenCountry,
    businessNameValidation,
    errors,
    isValid,
    searchCompanyName,
    possibleCompanies,
}: PrefillFormProps) => {
    const { t } = useTranslation();
    const debouncedSearch = debounce(searchCompanyName, 500);

    return (
        <FormGroup>
            <H2>{t("settings.edit_account.company_info")}</H2>
            <Hr />
            <FlexHalf>
                <CountryDropdown
                    name="company.address.country"
                    value={getValueAt("company.address.country", values)}
                    label={
                        t(
                            "settings.edit_account.fields.company.address.country",
                        ) + " *"
                    }
                    onChange={(value) => {
                        setFieldValue("company.address.country", value);
                        setTouched(
                            deepmerge(touched || {}, {
                                company: {
                                    address: {
                                        country: true,
                                    },
                                },
                            }),
                        );
                    }}
                    placeholder={t(
                        "settings.edit_account.placeholders.company.address.country",
                    )}
                />
            </FlexHalf>
            {hasChosenNorway && (
                <FlexHalf>
                    <Select
                        name="company.business_name"
                        value={
                            getValueAt("company.business_name", values) || ""
                        }
                        label={t(
                            "settings.edit_account.prefill.fields.business_name_or_orgnr",
                        )}
                        options={possibleCompanies}
                        getOptionLabel={(option) =>
                            option.company.business_name
                        }
                        getOptionValue={(option) =>
                            option.company.organization_number
                        }
                        getOptionSubLabel={(option) =>
                            option.company.organization_number
                        }
                        onBlur={handleBlur}
                        maxOptions={10}
                        maxOptionsReachedMessage={t(
                            "settings.edit_account.prefill.max_options",
                        )}
                        noOptionsMessage={t(
                            "settings.edit_account.prefill.full_words",
                        )}
                        autoFocus={true}
                        onInputChange={(e) => {
                            const partialName = e.target.value;
                            setFieldValue("company.business_name", partialName);
                            if (partialName.length > 1) {
                                debouncedSearch(
                                    values.company.address.country,
                                    partialName,
                                );
                            }
                        }}
                        onChange={(selection) => {
                            if (!selection) {
                                setFieldValue("company.business_name", "");
                                setFieldValue("prefilled_account", undefined);
                            } else {
                                setFieldValue(
                                    "company.business_name",
                                    selection.company.business_name,
                                    true,
                                );
                                setFieldValue(
                                    "prefilled_account",
                                    selection,
                                    true,
                                );
                            }
                        }}
                        placeholder={t(
                            "settings.edit_account.prefill.placeholders.business_name_or_orgnr",
                        )}
                        disabled={!hasChosenNorway}
                        validation={businessNameValidation}
                    />
                </FlexHalf>
            )}
            {hasChosenCountry && !hasChosenNorway && (
                <Flex>
                    <TranslatedValidatedInput
                        path="company.business_name"
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        required
                    />
                </Flex>
            )}
            <ButtonGroup>
                <Button disabled={!isValid} type="submit">
                    {t("settings.edit_account.prefill.move_on")}
                </Button>
            </ButtonGroup>
        </FormGroup>
    );
};

const FormGroup = styled.div`
    margin-bottom: ${distances.normal};
`;

const Flex = styled.div`
    display: flex;

    @media (max-width: ${globalColumnMaxWidth}px) {
        flex-direction: column;
    }
`;

const FlexHalf = styled.div`
    display: flex;
    max-width: ${grid.spans.span4};
`;

export default PrefillForm;
