import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { distances, grid } from "../../styles/constants";

import { Checkbox } from "../Forms";
import { H3, P } from "../Typography";
import BaseFilter from "./BaseFilter";

export type OnChangeFunction = (event: Event) => void;

export interface Option {
    label: any;
    value: string;
}

interface CheckboxListFilterProps extends WithTranslation {
    name: string;
    title: string;
    label: string;
    disabled?: boolean;
    options: Option[];
    values: string[];
    onApply: (name: string, values: string[]) => void;
    onDelete: (name: string) => void;
    noOptionsMessage?: string;
    optionsClassName?: string;
    onCloseMenu?: () => void;
}

interface FilterSelectState {
    pendingSelections: string[];
}

class CheckboxListFilter extends Component<
    CheckboxListFilterProps,
    FilterSelectState
> {
    constructor(props: CheckboxListFilterProps, context: any) {
        super(props, context);
        this.state = {
            pendingSelections: [],
        };
        this.handleToggle = this.handleToggle.bind(this);
        this.resetPending = this.resetPending.bind(this);
    }

    resetPending() {
        this.setState({ pendingSelections: [] });
    }

    handleToggle(value: string) {
        this.setState({
            pendingSelections: this.state.pendingSelections.includes(value)
                ? this.state.pendingSelections.filter((v) => v !== value)
                : [value, ...this.state.pendingSelections],
        });
    }

    render() {
        const {
            label,
            title,
            name,
            options,
            onApply,
            onDelete,
            disabled,
            values,
            optionsClassName,
            onCloseMenu,
        } = this.props;
        const { pendingSelections } = this.state;

        const selectedValues = options
            .filter((option) => {
                const inValues = values.includes(option.value);
                const inPending = pendingSelections.includes(option.value);
                return inValues && inPending ? false : inValues || inPending;
            })
            .map((v) => v.value);

        return (
            <BaseFilter
                initialOpen={values.length === 0}
                label={label}
                disabled={disabled}
                applyIsDisabled={options.length === 0}
                name={name}
                onApply={() => {
                    this.resetPending();
                    onApply(name, selectedValues);
                }}
                onDelete={() => {
                    this.resetPending();
                    onDelete(name);
                }}
                onCloseMenu={onCloseMenu}
            >
                <H3>{title}</H3>
                <Options className={optionsClassName}>
                    {options.map((option) => (
                        <OptionItem key={option.value}>
                            <Checkbox
                                key={option.value}
                                name="selected_values"
                                label={option.label}
                                value={option.value}
                                checked={selectedValues.includes(option.value)}
                                onChange={(_) =>
                                    this.handleToggle(option.value)
                                }
                            />
                        </OptionItem>
                    ))}
                </Options>
                {options.length === 0 && this.props.noOptionsMessage && (
                    <NoOptionsMessage>
                        {this.props.noOptionsMessage}
                    </NoOptionsMessage>
                )}
            </BaseFilter>
        );
    }
}

const NoOptionsMessage = styled(P)`
    padding-top: ${distances.tiny};
    padding-bottom: ${distances.small};
`;

const OptionItem = styled.div`
    padding-bottom: ${distances.small};
`;

const Options = styled.div`
    margin-top: ${distances.tiny};

    &.span3 {
      width: ${grid.spans.span3};
    }
`;

export default withTranslation()<React.ComponentType<CheckboxListFilterProps>>(
    CheckboxListFilter,
);
