import * as React from "react";
import styled, { keyframes } from "styled-components/macro";

import { colors, distances, palette } from "../../styles/constants";

type LoadingProps = {
    className?: string;
};

const Loading = ({ className }: LoadingProps) => {
    return (
        <LoadingWrapper>
            {/* <svg width="25px" height="26px" viewBox="0 0 25 26" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <path
                        d="M8.136,17.473 C9.304,18.657 10.72,19.248 12.384,19.248 C14.048,19.248 15.464,18.657 16.632,17.473 C17.8,16.289 18.384,14.641 18.384,12.528 C18.384,10.416 17.8,8.768 16.632,7.584 C15.464,6.401 14.048,5.809 12.384,5.809 C10.72,5.809 9.304,6.401 8.136,7.584 C6.968,8.768 6.384,10.416 6.384,12.528 C6.384,14.641 6.968,16.289 8.136,17.473 M3.529,3.551 C5.88,1.184 8.832,0 12.384,0 C15.936,0 18.888,1.184 21.24,3.551 C23.592,5.92 24.768,8.912 24.768,12.528 C24.768,16.145 23.592,19.137 21.24,21.504 C18.888,23.873 15.936,25.057 12.384,25.057 C8.832,25.057 5.88,23.873 3.529,21.504 C1.176,19.137 0,16.145 0,12.528 C0,8.912 1.176,5.92 3.529,3.551"
                        fill="#02435D"
                        fillRule="nonzero"
                    />
                </g>
            </svg> */}
            <LoadingBar className={className} />
        </LoadingWrapper>
    );
};

const LoadingWrapper = styled.div``;

const progress = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;
const LoadingBar = styled.div`
    /** Spinner */
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 3px solid ${palette.primary[50]};
    border-top-color: ${colors.primary};
    animation: ${progress} 1s linear infinite;

    &.small {
        width: 24px;
        height: 24px;
        border-width: 2px;
    }

    &.xsmall {
        width: 16px;
        height: 16px;
        border-width: 2px;
    }
`;

export default Loading;
