import { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

type LogoProps = {
    alt?: string;
    logo?: string;
};

const LogoInternal = styled.img`
    width: 50%;
    height: 100%;
    object-fit: contain;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    &.loaded {
        opacity: 1;
    }
`;

const Title = styled.h2`
    margin: auto;
    line-height: 20px;
`;

const Wrapper = styled.div`
    display: flex;
    height: 100px;
    width: 100%;
    align-items: center;
`;

export const Logo = (props: LogoProps) => {
    const { alt, logo } = props;
    const [error, setError] = useState(false);

    if (error || !logo) {
        return (
            <Wrapper>
                <Title>{alt}</Title>
            </Wrapper>
        );
    }
    return (
        <Wrapper>
            <LogoInternal
                loading="lazy"
                alt={alt}
                onError={(e) => {
                    setError(true);
                }}
                src={logo}
                onLoad={(e) => {
                    e.currentTarget.classList.add("loaded");
                }}
            />
        </Wrapper>
    );
};

export default Logo;
