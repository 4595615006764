import { effects as newPayoutRule } from "./newPayoutRule";
import { effects as payoutRuleDetails } from "./payoutRuleDetails";
import { effects as payoutRuleEdit } from "./payoutRuleEdit";
import { effects as payoutRules } from "./payoutRules";

const effects = {
    ...payoutRules,
    ...newPayoutRule,
    ...payoutRuleEdit,
    ...payoutRuleDetails,
};

export default effects;
