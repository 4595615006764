import React, {} from "react";
import { withTranslation } from "react-i18next";

import InputFilter from "../../../../../components/Filters/InputFilter";
import { FilterProps } from "./FilterProps";

export type EmailFilterProps = FilterProps;

const EmailFilter = ({ queryParams, t, onChange }: EmailFilterProps) => {
    const value = queryParams.get("email") || "";

    return (
        <InputFilter
            label={value ? value : ""}
            name="email"
            title={t("locations.filter.email.title")}
            value={value}
            placeholder={""}
            validate={(value: string) => {
                if (!value) {
                    return "error";
                }
                return;
            }}
            autoFocus={true}
            onApply={(value) => {
                onChange({ email: value ? [value] : [] });
            }}
            onDelete={(_) => {
                onChange({
                    email: [],
                });
            }}
        />
    );
};

export default withTranslation()(EmailFilter);
