import { CORE_API_HOSTNAME } from "../../../env";
import fulfill from "../../../fulfill";
import { SideEffectFunction } from "../../../sideEffects";
import {
    ActionTypes,
    FetchInitiateInstabankConnectionResponse,
    InitiateInstabankConnection,
} from "./actions";

const fetchInitiateInstabankConnection: SideEffectFunction<
    InitiateInstabankConnection,
    FetchInitiateInstabankConnectionResponse
> = async (action) => {
    const result: FetchInitiateInstabankConnectionResponse = await fulfill.post(
        {
            accountId: action.payload.accountId,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/management/settings/connections/instabank`,
            json: { signup: action.payload.data },
            handlers: {
                200: () => ({
                    type: ActionTypes.FetchInitiateInstabankConnectionResponse,
                    payload: {},
                    meta: {
                        cause: action,
                    },
                }),
                403: () => ({
                    type: ActionTypes.FetchInitiateInstabankConnectionResponse,
                    payload: {},
                    meta: {
                        cause: action,
                    },
                }),
            },
        },
    );

    return result;
};

const effects = {
    [ActionTypes.InitiateInstabankConnection]: fetchInitiateInstabankConnection,
};

export default effects;
