import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { useAccountId } from "../../../../auth/useAccountId";
import Card from "../../../../components/Card";
import { ValidationState } from "../../../../components/Forms";
import { LoadingOverlay } from "../../../../components/Loading";
import Page, { Dismiss } from "../../../../components/Page";
import { H1 } from "../../../../components/Typography";
import { getValueAt } from "../../../../helpers/getValueAt";
import { validate, validateRequired } from "../../../../helpers/validation";
import { colors, grid } from "../../../../styles/constants";
import { Account, AccountCompany } from "../../../../types/management-auth";
import { useOrganizationSearch } from "../../../useOrganizationSearch";
import { checkAccountReadyForProduction } from "../../helpers";
import PrefillForm from "./PrefillForm";

export interface InitialCompanyInfoProps {
    account: Account;
    setPrefill: (account: Account) => void;
    isEmbedded?: boolean;
}

function initialCountryFromPhoneNumber(account: Account) {
    if (account && account.applicant.phone_number) {
        const phoneNumber = account.applicant.phone_number;
        if (phoneNumber.startsWith("+47")) {
            return "NO";
        } else if (phoneNumber.startsWith("+46")) {
            return "SE";
        }
    }
    return "NO";
}

export interface FormValues {
    company: AccountCompany;
    prefilled_account?: Account;
    [key: string]: any;
}

const PrefillAccount = ({
    account,
    setPrefill,
    isEmbedded,
}: InitialCompanyInfoProps) => {
    const { t } = useTranslation();
    const accountId = useAccountId();
    const { search, searchResults } = useOrganizationSearch(accountId);
    const isNotReadyForProduction =
        account && !checkAccountReadyForProduction(account);
    if (!account) {
        return <LoadingOverlay />;
    }

    const initialFormValues: FormValues = {
        company: {
            // @ts-ignore
            address: {
                country: initialCountryFromPhoneNumber(account),
            },
            ...account.company,
        },
    };

    return (
        <Formik
            validateOnMount={true}
            enableReinitialize
            initialValues={initialFormValues}
            onSubmit={(values) => {
                if (!account || !values) {
                    return;
                }

                const accountInitial = {
                    ...account,
                    company: values.company,
                };

                const prefilledAccount: Account = {
                    ...accountInitial,
                    ...values.prefilled_account,
                    billing:
                        values.prefilled_account &&
                        values.prefilled_account.company
                            ? values.prefilled_account.company
                            : values.company,
                };
                setPrefill(prefilledAccount);
            }}
            validate={(values) => {
                let errors: { [key: string]: string } = {};
                if (!values) {
                    // this should not happen but...
                    return { error: "account not loaded yet..." };
                }

                const validationValues = { ...values };
                const requiredBusinessName = validateRequired(
                    t("settings.edit_account.invalid.company.business_name"),
                );

                const validators = [
                    {
                        path: "company.business_name",
                        validator: requiredBusinessName,
                    },
                ];

                if (getValueAt("company.address.country", values) === "NO") {
                    validators.push({
                        path: "prefilled_account",
                        validator: requiredBusinessName,
                    });
                }

                errors = validators.reduce((acc, elem) => {
                    return validate(
                        elem.path,
                        elem.validator,
                        validationValues,
                        acc,
                    );
                }, errors);

                return errors;
            }}
        >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                touched,
                setTouched,
                isValid,
            }) => {
                const hasChosenCountry =
                    getValueAt("company.address.country", values) !== undefined;
                const hasChosenNorway =
                    getValueAt("company.address.country", values) === "NO";

                const businessNameValidation =
                    getValueAt("company.business_name", touched) &&
                    getValueAt("company.business_name", errors)
                        ? {
                              state: ValidationState.Invalid,
                              message: getValueAt(
                                  "company.business_name",
                                  errors,
                              ),
                          }
                        : undefined;

                return (
                    <Form onSubmit={handleSubmit} autoComplete="nope">
                        {!isEmbedded && (
                            <Page
                                title={
                                    <React.Fragment>
                                        <Dismiss />
                                        <Title>
                                            {t(
                                                isNotReadyForProduction
                                                    ? "settings.edit_account.title_not_finished"
                                                    : "settings.edit_account.title",
                                            )}
                                        </Title>
                                    </React.Fragment>
                                }
                            >
                                <Card title={undefined}>
                                    <Wrapper>
                                        <PrefillForm
                                            values={values}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            setTouched={setTouched}
                                            touched={touched}
                                            handleBlur={handleBlur}
                                            hasChosenNorway={hasChosenNorway}
                                            hasChosenCountry={hasChosenCountry}
                                            businessNameValidation={
                                                businessNameValidation
                                            }
                                            errors={errors}
                                            isValid={isValid}
                                            searchCompanyName={search}
                                            possibleCompanies={searchResults}
                                        />
                                    </Wrapper>
                                </Card>
                            </Page>
                        )}
                        {!!isEmbedded && (
                            <PrefillForm
                                values={values}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                setTouched={setTouched}
                                touched={touched}
                                handleBlur={handleBlur}
                                hasChosenNorway={hasChosenNorway}
                                hasChosenCountry={hasChosenCountry}
                                businessNameValidation={businessNameValidation}
                                errors={errors}
                                isValid={isValid}
                                searchCompanyName={search}
                                possibleCompanies={searchResults}
                            />
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};

const Form = styled.form`
    width: 100%;
`;

const Wrapper = styled.div`
    position: relative;
    max-width: ${grid.spans.span8};
    margin: 0 auto;
    width: 100%;
`;

const Title = styled(H1)`
    color: ${colors.text};
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

export default PrefillAccount;
