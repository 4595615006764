import isValid from "date-fns/isValid";
import parseISO from "date-fns/parseISO";

export const toISODatePartStr = (date: Date): string => {
    return date.toISOString().substr(0, 10);
};

export const parseISOStrict = (value: string): Date | undefined => {
    const result = parseISO(value);
    if (!isValid(result)) {
        return undefined;
    }
    return result;
};

export const parseISOStrictOr = (value: any, defaultDate: Date): Date => {
    if (typeof value !== "string") return defaultDate;
    return parseISOStrict(value) ?? defaultDate;
};
