import React from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components/macro";

import {
    Button,
    ButtonExternalLink,
    ButtonGroup,
    ButtonLink,
} from "../../../../components/Buttons";
import { Dd, Dl, Dt } from "../../../../components/DescriptionList/Horizontal";
import { Checkbox } from "../../../../components/Forms";
import Modal from "../../../../components/Modal";
import { H1, P } from "../../../../components/Typography";
import { copy } from "../../../../helpers/clipboard";
import { clientCsv } from "../../../../helpers/csvExport";
import { clientPaste } from "../../../../helpers/formatPaste";
import { distances } from "../../../../styles/constants";
import {
    Grant,
    HasSecretClientResponse,
} from "../../../../types/management-auth";

export interface ClientSecretModalProps extends WithTranslation {
    accountId: string;
    toggleSecretIsSafe: (value: boolean) => void;
    isSecretSafe: boolean;
    client: HasSecretClientResponse;
    grants: Grant[];
}

const ClientSecretModal = ({
    t,
    client,
    accountId,
    isSecretSafe,
    toggleSecretIsSafe,
    grants,
}: ClientSecretModalProps) => {
    const nonPrefixedAccountId = accountId.substr(1);
    const blob = client
        ? new Blob([clientCsv(nonPrefixedAccountId, client, grants)], {
              type: "text/csv; charset=utf-8",
          })
        : null;
    const blobHref = blob ? URL.createObjectURL(blob) : "";
    return (
        <Modal>
            <H1>
                {t("settings.client_secret_modal.title")} {client.name}
            </H1>
            <SpacedWrapper>
                <P>
                    <Trans i18nKey="settings.client_secret_modal.description">
                        Before moving on you need to save the{" "}
                        <em>client secret</em> in a secure location, like a
                        password vault.
                    </Trans>
                </P>
            </SpacedWrapper>
            <SpacedWrapper>
                <P>
                    <Trans i18nKey="settings.client_secret_modal.never_revealed_again">
                        When this modal is closed the client secret will{" "}
                        <strong>never be revealed again</strong>.
                    </Trans>
                </P>
            </SpacedWrapper>
            <SpacedWrapper>
                <Dl>
                    <Dt doubleColumn>
                        {t("settings.client_secret_modal.fields.account_id")}
                    </Dt>
                    <Dd>
                        <code>{nonPrefixedAccountId}</code>
                    </Dd>
                </Dl>
                <Dl>
                    <Dt doubleColumn>
                        {t("settings.client_secret_modal.fields.client_id")}
                    </Dt>
                    <Dd>
                        <code>{client.client_id}</code>
                    </Dd>
                </Dl>
                <Dl>
                    <Dt doubleColumn>
                        {t("settings.client_secret_modal.fields.client_secret")}
                    </Dt>
                    <Dd>
                        <code>{client.client_secret}</code>
                    </Dd>
                </Dl>
                <ButtonGroup>
                    <Button
                        className="alt"
                        onClick={() => {
                            const secretText = clientPaste(
                                nonPrefixedAccountId,
                                client,
                                grants,
                            );
                            copy(secretText);
                        }}
                    >
                        {t("settings.client_secret_modal.button_copy")}
                    </Button>
                    <ButtonExternalLink
                        className="alt"
                        href={blobHref}
                        download={`dintero_credentials_${accountId}_client_${client.client_id}.csv`}
                    >
                        {t(
                            "settings.client_secret_modal.button_download_csv_file",
                        )}
                    </ButtonExternalLink>
                </ButtonGroup>
            </SpacedWrapper>
            <SpacedWrapper>
                <Checkbox
                    name="client_secret_is_safe"
                    label={
                        <Trans i18nKey="settings.client_secret_modal.key_is_safe">
                            Yes, the <em>client secret</em> is saved in a secure
                            storage.
                        </Trans>
                    }
                    checked={isSecretSafe}
                    onChange={(e) => {
                        toggleSecretIsSafe(e.target.checked);
                    }}
                />
            </SpacedWrapper>
            <ButtonLink
                to={`/${accountId}/settings/clients/${client.id}`}
                className={isSecretSafe ? "" : "disabled"}
            >
                {t("settings.client_secret_modal.button_ok")}
            </ButtonLink>
        </Modal>
    );
};

export default withTranslation()(ClientSecretModal);

const SpacedWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.normal};
`;
