import { connect } from "react-redux";

import { getUser } from "../../../../auth/accessToken/selectors";
import { State } from "../../../../reducer";
import {
    clearData,
    getCheckoutConfiguration,
    getPayoutRules,
} from "../actions";
import { getPayoutRulesSelector } from "../selectors";

import useAccount from "../../../account/hooks/useAccount";
import PayoutRules, {
    type PayoutRulesListProps,
} from "../components/PayoutRules";

const mapStateToProps = (state: State) => ({
    payoutRules: getPayoutRulesSelector(state),
    user: getUser(state),
});

const mapDispatchToProps = {
    getPayoutRules,
    getCheckoutConfiguration,
    clearData,
};

const PayoutRulesWrapper = (props: PayoutRulesListProps) => {
    const account = useAccount();
    return <PayoutRules {...props} account={account} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(PayoutRulesWrapper);
