import { createSelector } from "reselect";
import { namespace } from "./checkoutConfigurationActions";
import { State } from "./reducer";

export const updatedSuccessfully = (state: State) =>
    state[namespace].updatedSuccessfully;
export const isLoading = createSelector(
    (state: State) => state[namespace].isLoading,
    (_: State, accountId: string) => accountId,
    (isLoading, accountId) => isLoading[accountId],
);

export const checkoutConfiguration = createSelector(
    (state: State) => state[namespace].checkoutConfiguration,
    (_: State, accountId: string) => accountId,
    (checkoutConfiguration, accountId) => checkoutConfiguration[accountId],
);
