import { Account } from "../types/management-auth";

export const currencyCodes = ["NOK", "SEK", "DKK", "EUR", "GBP"] as const;
export type CurrencyCode = (typeof currencyCodes)[number];

const countryCurrencyMap: {
    [countryCode: string]: (typeof currencyCodes)[number];
} = {
    NO: "NOK",
    SE: "SEK",
    DK: "DKK",
    FI: "EUR",
    DE: "EUR",
    PL: "EUR",
    FR: "EUR",
    UK: "GBP",
};

export const getDefaultCountryCurrency = (
    account: Account | undefined,
    allowedCurrencies: CurrencyCode[],
    fallback: CurrencyCode,
) => {
    const country = account?.company?.address?.country || "";
    const currency = countryCurrencyMap[country];
    return allowedCurrencies.includes(currency) ? currency : fallback;
};
