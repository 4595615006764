import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { Button } from "../../../../components/Buttons";
import { distances } from "../../../../styles/constants";
import { CheckoutConfig } from "../../../../types/checkout";

import { History, Location } from "history";
import { FilterSelect } from "../../../../components/Filters";
import {
    FilterComponentWrapper,
    FilterRowWrapper,
    FiltersWrapper,
} from "../../../../components/Filters/elements";
import { ParamChanges, updateParams } from "../../../../helpers/urlParams";
import RuleTypeFilter from "./RuleTypeFilter";

export interface StateFilterProps extends WithTranslation {
    queryParams: URLSearchParams;
    onChange: (changes: { [key: string]: string[] }) => void;
}

const extraFilterProps = (
    _: FiltersProps,
): { [name: string]: { [key: string]: any } } => {
    return {};
};

const updateFilterParams = (
    queryParams: URLSearchParams,
    changes: ParamChanges,
) => {
    // remove paging when filter changes
    const nonPagingChanges = { ...changes, starting_after: undefined };
    return updateParams(queryParams, nonPagingChanges);
};

const filterConfigs = {
    rule_type: {
        component: RuleTypeFilter,
        keys: ["rule_type"],
    },
};
type FilterKey = keyof typeof filterConfigs;

const getConfigNamesFromQueryParams = (queryParamsKeys: string[]) => {
    const entries = Object.entries(filterConfigs);
    const matchingEntries = entries.filter(([_, config]) => {
        const matchingKeys = config.keys.filter((configKey) =>
            queryParamsKeys.includes(configKey),
        );
        return matchingKeys.length > 0;
    });
    return matchingEntries.map(([name, _]) => name);
};

interface FiltersProps extends WithTranslation {
    location: Location;
    history: History;
    checkoutConfig?: CheckoutConfig;
}

interface FiltersState {
    pending: FilterKey | undefined;
}

class Filters extends Component<FiltersProps, FiltersState> {
    constructor(props: FiltersProps, context: any) {
        super(props, context);
        this.state = {
            pending: undefined,
        };
        this.setPending = this.setPending.bind(this);
    }

    setPending(pending: FilterKey | undefined) {
        this.setState({ pending });
    }

    render() {
        const { t, location, history } = this.props;
        const { pending } = this.state;
        const queryParams = new URLSearchParams(location.search.substring(1));
        const queryParamKeys = [...queryParams.keys()];
        const visibleFilterNames = [
            ...getConfigNamesFromQueryParams(queryParamKeys),
            pending,
        ];
        const addableFilters = Object.keys(filterConfigs).filter(
            (key) => !visibleFilterNames.includes(key),
        );
        return (
            <FilterRowWrapper uniformPadding>
                <FiltersWrapper>
                    {visibleFilterNames
                        .filter(
                            (key): key is FilterKey =>
                                !!key && key in filterConfigs,
                        )
                        .map((key) => {
                            const FilterComponent =
                                filterConfigs[key].component;
                            const componentExtraFilterProps = extraFilterProps(
                                this.props,
                            )[key];
                            return (
                                <FilterComponentWrapper
                                    key={`order-filter-${key}`}
                                >
                                    <FilterComponent
                                        {...componentExtraFilterProps}
                                        queryParams={queryParams}
                                        onChange={(value) => {
                                            const nextParams =
                                                updateFilterParams(
                                                    queryParams,
                                                    value,
                                                );
                                            history.push(
                                                `${location.pathname}?${nextParams.toString()}`,
                                            );
                                            if (key === pending) {
                                                this.setPending(undefined);
                                            }
                                        }}
                                    />
                                </FilterComponentWrapper>
                            );
                        })}
                    {!pending && addableFilters.length > 0 && (
                        <FilterSelect
                            name="filterSelect"
                            options={addableFilters.map((key) => (
                                <Button
                                    className="alt tiny"
                                    onClick={() =>
                                        this.setPending(key as FilterKey)
                                    }
                                    key={`add-filter-${key}`}
                                >
                                    {t(
                                        `payout_rules.payout_rules.filter.${key}.add_filter_button` as any,
                                    )}
                                </Button>
                            ))}
                        />
                    )}
                </FiltersWrapper>
            </FilterRowWrapper>
        );
    }
}
export default withTranslation("app")<React.ComponentType<FiltersProps>>(
    Filters,
);
