import { BillingPayoutRule } from "../../../types/billing";

import { ActionTypes, Actions } from "./actions";

export interface State {
    isLoading: boolean;
    payoutRule: BillingPayoutRule | undefined;
}

export const initialState: State = {
    isLoading: false,
    payoutRule: undefined,
};

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {
        case ActionTypes.FetchPayoutRule:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FetchPayoutRuleResponse:
            return {
                ...state,
                isLoading: false,
                payoutRule: action.payload.payoutRules.find(
                    (rule) => rule.id === action.payload.payoutRuleId,
                ),
            };

        case ActionTypes.DeletePayoutRule:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.DeletePayoutRuleResponse:
            return {
                ...state,
                isLoading: false,
                payoutRule: undefined,
            };

        case ActionTypes.ClearFetchedData:
            return {
                isLoading: false,
                error: false,
                payoutRule: undefined,
            };

        default:
            return state;
    }
}
