import { TFunction } from "i18next";
import React, {} from "react";
import { useTranslation } from "react-i18next";

import CheckboxListFilter, {
    Option,
} from "../../../../../components/Filters/CheckboxListFilter";
import { FilterProps } from "./FilterProps";

export type TypeFilterProps = FilterProps;

const getStatusName = (t: TFunction, value: string) => {
    const key = `locations.location.type.${value || "undefined"}`;
    const translated = t(key);
    if (translated === key) {
        return value;
    }
    return translated;
};

const TypeFilter = ({ queryParams, onChange }: TypeFilterProps) => {
    const { t } = useTranslation("app");
    const optionValues = ["online", "physical"];

    const options: Option[] = optionValues.map((value) => ({
        label: t(`locations.location.type.${value}` as any),
        value,
    }));

    const values = queryParams.getAll("type");

    return (
        <CheckboxListFilter
            label={t("locations.filter.type.label", {
                count: values.length,
                value: values
                    .map((value) => getStatusName(t, value))
                    .join(", "),
            })}
            title={t("locations.filter.type.title")}
            name="type"
            values={values}
            options={options}
            onApply={(name, values) => {
                onChange({ [name]: values });
            }}
            onDelete={(name) => {
                onChange({ [name]: [] });
            }}
        />
    );
};

export default TypeFilter;
