import styled from "styled-components/macro";
import { distances } from "../../styles/constants";
import { Button } from "../Buttons";

interface PagingProps {
    label: string;
    isLoading: boolean;
    hasMore: boolean;
    onLoadMore: () => void;
}

const Paging = ({ onLoadMore, hasMore, isLoading, label }: PagingProps) =>
    !hasMore && !isLoading ? null : (
        <Wrapper isLoading={isLoading}>
            {hasMore && (
                <Button className="alt" onClick={onLoadMore}>
                    {label}
                </Button>
            )}
        </Wrapper>
    );

export default Paging;

interface WrapperProps {
    isLoading: boolean;
}
const Wrapper = styled.div<WrapperProps>`
    display: flex;
    justify-content: center;
    padding: ${distances.normal};
    opacity: ${(props) => (props.isLoading ? "0" : "1")};
`;
