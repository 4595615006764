import { Component, memo } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components/macro";

import { AuthenticatedUser } from "../../../../auth/types";
import { LoadingOverlay } from "../../../../components/Loading";
import Page, { PageButtonLink } from "../../../../components/Page";
import { paramsEqual, updateParams } from "../../../../helpers/urlParams";

import {
    AccountIdsProps,
    withAccountIds,
} from "../../../../auth/accessToken/components/withAccountIds";
import { hasAnyOfScopes } from "../../../../auth/accessToken/helpers";
import Icon from "../../../../components/Icons";
import { H1 } from "../../../../components/Typography";
import { BillingPayoutRule } from "../../../../types/billing";
import { Account } from "../../../../types/management-auth";
import SplitPaymentBox from "../../../additionalServices/components/SplitPaymentBox";
import { Wrapper } from "../../../additionalServices/components/elements";
import { GetPayoutRulesReason } from "../actions";
import { State as PayoutRulesState } from "../reducer";
import Filters from "./Filters";
import PayoutRulesTable from "./PayoutRulesTable";

interface MatchParams {
    accountId: string;
}

export interface PayoutRulesListProps
    extends WithTranslation,
        RouteComponentProps<MatchParams>,
        AccountIdsProps {
    getPayoutRules: (
        accountId: string,
        params: URLSearchParams,
        origin: GetPayoutRulesReason,
    ) => void;
    payoutRules: PayoutRulesState;
    user: AuthenticatedUser;
    getCheckoutConfiguration: (accountId: string) => void;
    clearData: () => void;
    account: Account;
}

interface PayoutRulesListState {
    isSearching: boolean;
    timeoutReference: number | undefined;
}

type PayoutRulesAccountSectionProps = {
    payoutRules: BillingPayoutRule[];
    accountId: string;
};

const sortRules = (rules: BillingPayoutRule[]) => {
    const active = rules.filter((x) => !x.deleted_at);
    const deleted = rules.filter((x) => x.deleted_at);
    return [...active, ...deleted];
};

const PayoutRulesAccountSection = memo(
    ({ payoutRules, accountId }: PayoutRulesAccountSectionProps) => {
        return (
            <MarginTopWrapper>
                <PayoutRulesTable
                    payoutRules={sortRules(payoutRules)}
                    accountId={accountId}
                />
            </MarginTopWrapper>
        );
    },
);

class PayoutRulesList extends Component<
    PayoutRulesListProps,
    PayoutRulesListState
> {
    componentDidMount() {
        const { limit, payoutRules, accountId, params } =
            this.props.payoutRules;
        const user = this.props.user;
        const locationQueryParams = new URLSearchParams(
            this.props.location.search.substring(1),
        );
        const requestQueryParams = updateParams(locationQueryParams, {
            limit: limit.toString(),
            include_deleted: true,
        });
        const urlAccountId = this.props.match.params.accountId;
        const hasPayoutRules = payoutRules.length > 0;
        const isSameAccount = accountId === urlAccountId;
        const hasSameParams = paramsEqual(requestQueryParams, params, [
            "limit",
            "starting_after",
            "include_deleted",
        ]);
        const reason =
            hasPayoutRules && isSameAccount && hasSameParams
                ? GetPayoutRulesReason.refresh
                : GetPayoutRulesReason.mount;
        this.props.getPayoutRules(
            urlAccountId,
            requestQueryParams,
            urlAccountId.startsWith("T")
                ? GetPayoutRulesReason.testAccount
                : reason,
        );
        if (
            hasAnyOfScopes(urlAccountId, user, [
                ["admin"],
                ["admin:admin"],
                ["admin:checkout"],
            ])
        ) {
            this.props.getCheckoutConfiguration(urlAccountId);
        }
    }

    componentDidUpdate(prevProps: PayoutRulesListProps, prevState: any) {
        if (this.props.location.search !== prevProps.location.search) {
            const { limit, accountId } = this.props.payoutRules;
            const locationQueryParams = new URLSearchParams(
                this.props.location.search.substring(1),
            );
            const requestQueryParams = updateParams(locationQueryParams, {
                limit: limit.toString(),
                include_deleted: true,
            });
            this.props.getPayoutRules(
                accountId,
                requestQueryParams,
                accountId.startsWith("T")
                    ? GetPayoutRulesReason.testAccount
                    : GetPayoutRulesReason.update,
            );
        }
    }

    componentWillUnmount() {
        this.props.clearData();
    }

    render() {
        const { t, account } = this.props;
        const { payoutRules, isLoading, checkoutConfiguration } =
            this.props.payoutRules;
        const accountId = this.props.match.params.accountId;

        const hasRules = Boolean(payoutRules && (payoutRules || []).length > 0);

        const splitPaymentEnabled =
            (account.services?.payout?.features?.status === "enabled" &&
                account?.services?.payout?.features?.features?.split_payment
                    ?.status === "enabled") ||
            hasRules;

        return (
            <Page
                title={
                    <>
                        <Title>{t("payout_rules.payout_rules.title")}</Title>
                        {splitPaymentEnabled && (
                            <PageButtonLink
                                to={`/${accountId}/settings/payout-rules/new`}
                            >
                                <Icon icon="plus" fill="currentColor" />
                                {t("payout_rules.payout_rules.create_new")}
                            </PageButtonLink>
                        )}
                    </>
                }
                hasEnvFrame={false}
            >
                {splitPaymentEnabled && (
                    <Filters
                        history={this.props.history}
                        location={this.props.location}
                        checkoutConfig={checkoutConfiguration}
                    />
                )}
                {splitPaymentEnabled && (
                    <PayoutRulesAccountSection
                        accountId={accountId}
                        payoutRules={payoutRules || []}
                    />
                )}
                {!splitPaymentEnabled && !isLoading && (
                    <MarginTopWrapper>
                        <RelativeGroup>
                            {((account.services?.payout?.features?.status !==
                                "enabled" &&
                                account?.services?.payout?.features?.features
                                    ?.split_payment?.status !== "enabled") ||
                                !hasRules) && (
                                <Wrapper>
                                    <SplitPaymentBox />
                                </Wrapper>
                            )}
                        </RelativeGroup>
                    </MarginTopWrapper>
                )}
                {isLoading && <LoadingOverlay />}
            </Page>
        );
    }
}

export default withAccountIds(withTranslation()(PayoutRulesList));

const RelativeGroup = styled.div`
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
`;

const Title = styled(H1)`
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;
const MarginTopWrapper = styled.div`
`;
