import { connect } from "react-redux";

import { State } from "../../../../reducer";
import { closeInstabankModal, initiateInstabankConnection } from "../actions";
import { getSelector } from "../selectors";

import InstabankModal from "../components/InstabankModal";

const mapStateToProps = (state: State) => ({
    instabank: getSelector(state),
});

const mapDispatchToProps = {
    initiateInstabankConnection,
    closeInstabankModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(InstabankModal);
