import styled from "styled-components/macro";

import { colors, distances } from "../../styles/constants";

const Table = styled.table`
    margin: 0;
    padding: 0;
    width: 100%;
    display: table;
    border-spacing: ${distances.small};

    &.no-wrap > thead > tr > th,
    &.no-wrap > tbody > tr > td {
        overflow: hidden;
        word-break: normal;
        text-overflow: ellipsis;
        padding-right: ${distances.tiny};

        &:last-child {
            padding-right: 0;
        }
    }

    &.highlight > tbody > tr:hover > td {
        border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
    }
    &.striped > tbody > tr > td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    &.inline > thead > tr > th {
        border-bottom: none;
        color: ${colors.text};
        font-weight: 700;
    }
`;

export default Table;
