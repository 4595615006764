import { BillingPayoutRule } from "../../../types/billing";

import { SalesLocation } from "../../../types/customers";
import { ActionTypes, Actions } from "./actions";

export interface State {
    isLoading: boolean;
    payoutRule: BillingPayoutRule | undefined;
    result: BillingPayoutRule | undefined;
    locations: SalesLocation[] | undefined;
}

export const initialState: State = {
    isLoading: false,
    payoutRule: undefined,
    result: undefined,
    locations: undefined,
};

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {
        case ActionTypes.FetchPayoutRule:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FetchPayoutRuleResponse:
            return {
                ...state,
                isLoading: false,
                payoutRule: action.payload.payoutRules.find(
                    (rule) => rule.id === action.payload.payoutRuleId,
                ),
            };

        case ActionTypes.EditPayoutRule:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.EditPayoutRuleResponse:
            return {
                ...state,
                isLoading: false,
                result: action.payload.payoutRule,
            };

        case ActionTypes.ResetForm:
            return {
                ...initialState,
            };

        case ActionTypes.FetchLocationsResponse:
            return {
                ...state,
                locations: action.payload,
            };

        default:
            return state;
    }
}
