import type { Dispatch } from "redux";
import { CORE_API_HOSTNAME } from "../../env";
import fulfill from "../../fulfill";
import { PersistedInsightReportConfig } from "../../types/insight";
import { createSwitchlessReducer } from "../../util/switchlessReducer";

export const namespace = "settings.reports" as const;

export interface State {
    configurations: PersistedInsightReportConfig[];
    isLoading: boolean;
    account_id: string;
    configurationToDelete: PersistedInsightReportConfig | undefined;
    showNewConfigurationModal: boolean;
    createError: undefined | "bad_request" | "conflict";
}

export const initialState: State = {
    configurations: [],
    isLoading: true,
    account_id: "",
    configurationToDelete: undefined,
    showNewConfigurationModal: false,
    createError: undefined,
};

type Requested = {};
type ReturnedConfigs = Requested & {
    account_id: string;
    configurations: PersistedInsightReportConfig[];
};
type Removed = { account_id: string; configuration_id: string };
type ToggleDeleteModal = {
    account_id: string;
    configuration?: PersistedInsightReportConfig;
};
type ToggleCreateModal = { showNewConfigurationModal: boolean };
type NewConfiguration = {
    account_id: string;
    configuration: PersistedInsightReportConfig;
};
type NewConfigurationError = { error: State["createError"] };

export const { reducer, actions } = createSwitchlessReducer({
    namespace: namespace,
    initialState,
    reducers: {
        startLoading: (state: State) => {
            return {
                ...initialState,
            };
        },
        addConfigurations: (
            state: State,
            { account_id, configurations }: ReturnedConfigs,
        ): State => {
            return {
                ...state,
                isLoading: false,
                configurations: configurations || [],
            };
        },
        resetLoading: (state: State): State => {
            return {
                ...state,
                isLoading: false,
            };
        },
        removeConfiguration: (
            state: State,
            { account_id, configuration_id }: Removed,
        ): State => {
            return {
                ...state,
                isLoading: false,
                configurations: state.configurations.filter(
                    (x) => x.id !== configuration_id,
                ),
                configurationToDelete: undefined,
            };
        },
        toggleDeleteModal: (
            state: State,
            { configuration, account_id }: ToggleDeleteModal,
        ): State => {
            return {
                ...state,
                account_id,
                configurationToDelete: configuration,
            };
        },
        toggleNewModal: (
            state: State,
            { showNewConfigurationModal }: ToggleCreateModal,
        ): State => {
            return {
                ...state,
                showNewConfigurationModal: showNewConfigurationModal,
                createError: undefined,
            };
        },
        addNewConfiguration: (
            state: State,
            { account_id, configuration }: NewConfiguration,
        ): State => {
            return {
                ...state,
                showNewConfigurationModal: false,
                createError: undefined,
                configurations: [configuration, ...state.configurations],
            };
        },
        setCreateError: (
            state: State,
            { error }: NewConfigurationError,
        ): State => {
            return {
                ...state,
                createError: error,
            };
        },
    },
});

export const createConnectedActions = (dispatch: Dispatch) => {
    const fetchConfigurations = async (account_id: string) => {
        dispatch(actions.startLoading());
        await fulfill.get({
            accountId: account_id,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${account_id}/insight/reports/configuration`,
            handlers: {
                200: (configurations: PersistedInsightReportConfig[]) => {
                    dispatch(
                        actions.addConfigurations({
                            account_id,
                            configurations,
                        }),
                    );
                },
            },
        });
        dispatch(actions.resetLoading());
    };

    const removeConfiguration = async (
        account_id: string,
        configuration_id: string,
    ) => {
        fulfill.delete({
            accountId: account_id,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${account_id}/insight/reports/configuration/${configuration_id}`,
            handlers: {
                200: () => {
                    dispatch(
                        actions.removeConfiguration({
                            account_id,
                            configuration_id,
                        }),
                    );
                },
            },
        });
    };
    const openDeleteModal = async (
        account_id: string,
        configuration: PersistedInsightReportConfig,
    ) => {
        dispatch(actions.toggleDeleteModal({ account_id, configuration }));
    };
    const closeDeleteModal = async () => {
        dispatch(actions.toggleDeleteModal({ account_id: "" }));
    };
    const openNewModal = async () => {
        dispatch(actions.toggleNewModal({ showNewConfigurationModal: true }));
    };
    const closeNewModal = async () => {
        dispatch(actions.toggleNewModal({ showNewConfigurationModal: false }));
    };
    const postNewConfiguration = async (
        account_id: string,
        configuration: PersistedInsightReportConfig,
    ) => {
        return await fulfill.post({
            accountId: account_id,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${account_id}/insight/reports/configuration/`,
            json: configuration,
            handlers: {
                200: (response) => {
                    dispatch(
                        actions.addNewConfiguration({
                            account_id,
                            configuration: response,
                        }),
                    );
                    return true;
                },
                409: () => {
                    dispatch(actions.setCreateError({ error: "conflict" }));
                    return false;
                },
                400: () => {
                    dispatch(actions.setCreateError({ error: "bad_request" }));
                    return false;
                },
            },
        });
    };
    return {
        fetchConfigurations,
        removeConfiguration,
        openDeleteModal,
        closeDeleteModal,
        openNewModal,
        closeNewModal,
        postNewConfiguration,
    };
};
