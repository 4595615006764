import { Account } from "../../../types/management-auth";
import { ActionTypes, Actions } from "./actions";

export enum InstabankModalState {
    Closed = "Closed",
    ApplicationForm = "ApplicationForm",
    InitiateApplicationLoading = "InitiateApplicationLoading",
    ApplicationReceived = "ApplicationReceived",
}

export interface State {
    modalState: InstabankModalState;
    accountId: string;
    account: Account | undefined;
}

export const initialState: State = {
    modalState: InstabankModalState.Closed,
    accountId: "",
    account: undefined,
};

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {
        case ActionTypes.OpenInstabankModal:
            return {
                ...state,
                modalState: InstabankModalState.ApplicationForm,
                accountId: action.payload.accountId,
                account: action.payload.account,
            };

        case ActionTypes.InitiateInstabankConnection:
            return {
                ...state,
                modalState: InstabankModalState.InitiateApplicationLoading,
            };

        case ActionTypes.FetchInitiateInstabankConnectionResponse:
            return {
                ...state,
                modalState: InstabankModalState.ApplicationReceived,
            };

        case ActionTypes.CloseInstabankModal:
            return {
                ...initialState,
            };

        default:
            return state;
    }
}
