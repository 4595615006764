// Based on Mantine 7.9.0 @mantine/core/Portal

import { forwardRef, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

function createPortalNode(props: React.ComponentPropsWithoutRef<"div">) {
    const node = document.createElement("div");
    node.setAttribute("data-portal", "true");
    typeof props.className === "string" &&
        node.classList.add(...props.className.split(" "));
    typeof props.style === "object" && Object.assign(node.style, props.style);
    typeof props.id === "string" && node.setAttribute("id", props.id);
    return node;
}

function assignRef<T>(ref: React.Ref<T> | undefined, value: T) {
    if (typeof ref === "function") {
        ref(value);
    } else if (typeof ref === "object" && ref !== null && "current" in ref) {
        (ref as React.MutableRefObject<T>).current = value;
    }
}

type PortalProps = {
    children: React.ReactNode;
    target?: HTMLElement | string;
};

export default forwardRef<HTMLDivElement, PortalProps>(function Portal(
    { children, target, ...rest },
    ref,
) {
    const [mounted, setMounted] = useState(false);
    const nodeRef = useRef<HTMLElement | null>(null);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Re-run only when target changes
    useEffect(() => {
        setMounted(true);
        nodeRef.current = !target
            ? createPortalNode(rest)
            : typeof target === "string"
              ? document.querySelector(target)
              : target;
        assignRef(ref, nodeRef.current);

        if (!target && nodeRef.current) {
            document.body.appendChild(nodeRef.current);
        }

        return () => {
            if (!target && nodeRef.current) {
                document.body.removeChild(nodeRef.current);
            }
        };
    }, [target]);

    if (!mounted || !nodeRef.current) {
        return null;
    }

    return createPortal(<>{children}</>, nodeRef.current);
});
