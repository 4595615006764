import { connect } from "react-redux";

import { getUserId } from "../../../../auth/accessToken/selectors";
import { State } from "../../../../reducer";
import {
    closePayExCallbackModal,
    completePayExConnection,
    payExCreateCredentials,
} from "../actions";
import { getPayExSelector } from "../selectors";

import PayExCallbackModal from "../components/PayExCallbackModal";

const mapStateToProps = (state: State) => ({
    payex: getPayExSelector(state),
    userId: getUserId(state),
});

const mapDispatchToProps = {
    completePayExConnection: completePayExConnection,
    payExCreateCredentials: payExCreateCredentials,
    closePayExCallbackModal: closePayExCallbackModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayExCallbackModal);
