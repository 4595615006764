import { PaymentType } from "../types/checkout";

type Trans = (key: string) => string;

export const showGatewayTypes: PaymentType[] = [
    "bambora.creditcard",
    "bambora.vipps",
    "bambora.mobilepay",
    "netaxept.creditcard",
    "payex.creditcard",
    "payex.swish",
    "payex.vipps",
    "payex.mobilepay",
    "payex.applepay",
    "payex.googlepay",
    "payex.clicktopay",
    "dintero_psp.creditcard",
];

export const getProfilePaymentTypeLabel = (
    paymentType: PaymentType,
    t: Trans,
): any => {
    let gatewaySuffix = "";
    if (showGatewayTypes.includes(paymentType)) {
        const gw = paymentType.split(".")[0];
        gatewaySuffix = "(" + t(`payments.payment_product.${gw}`) + ")";
    }
    return t(`payments.product_types.${paymentType}`) + " " + gatewaySuffix;
};
