import {
    Account,
    PayExConnection,
    PayExConnectionCallback,
} from "../../../types/management-auth";

export enum ActionTypes {
    OpenPayExCallbackModal = "[settings/paymentConnections/payex/callback] OPEN_PAYEX_CALLBACK_MODAL",
    CompletePayExConnection = "[settings/paymentConnections/payex/callback] COMPLETE_PAYEX_CONNECTION",
    PayExCreateCredentials = "[settings/paymentConnections/payex/callback] PAYEX_CREATE_CREDENTIALS",
    FetchPayExCreateCredentialsResponse = "[settings/paymentConnections/payex/callback] PAYEX_CREATE_CREDENTIALS_RESPONSE",
    FetchCompletePayExConnectionResponse = "[settings/paymentConnections/payex/callback] COMPLETE_PAYEX_CONNECTION_RESPONSE",
    ClosePayExCallbackModal = "[settings/paymentConnections/payex/callback] CLOSE_PAYEX_CALLBACK_MODAL",
    PayExApiError = "[settings/paymentConnections/payex/callback] PAYEX_API_ERROR",
}

export interface OpenPayExCallbackModal {
    type: ActionTypes.OpenPayExCallbackModal;
    payload: {
        accountId: string;
        account: Account;
        type?: "swap_agreement" | "initial_connection_configuration";
    };
}

export function openPayExCallbackModal(
    accountId: string,
    account: Account,
    type?: "swap_agreement" | "initial_connection_configuration",
): OpenPayExCallbackModal {
    return {
        type: ActionTypes.OpenPayExCallbackModal,
        payload: {
            accountId,
            account,
            type,
        },
    };
}

export interface ClosePayExCallbackModal {
    type: ActionTypes.ClosePayExCallbackModal;
    payload: {};
}

export function closePayExCallbackModal(): ClosePayExCallbackModal {
    return {
        type: ActionTypes.ClosePayExCallbackModal,
        payload: {},
    };
}

export interface CompletePayExConnection {
    type: ActionTypes.CompletePayExConnection;
    payload: {
        accountId: string;
        userId: string;
        data: PayExConnectionCallback;
    };
}

export function completePayExConnection(
    accountId: string,
    userId: string,
    data: PayExConnectionCallback,
): CompletePayExConnection {
    return {
        type: ActionTypes.CompletePayExConnection,
        payload: {
            accountId,
            userId,
            data,
        },
    };
}

export interface PayExCredentialsData {
    name: string;
    data: {
        payee_id: string;
        token: string;
        subsite: string;
        url: string;
    };
    promote: {
        type: "default" | "override";
        value: string;
    }[];
}

export interface PayExCreateCredentials {
    type: ActionTypes.PayExCreateCredentials;
    payload: {
        accountId: string;
        userId: string;
        data: PayExCredentialsData;
    };
}

export function payExCreateCredentials(
    accountId: string,
    userId: string,
    data: PayExCredentialsData,
): PayExCreateCredentials {
    return {
        type: ActionTypes.PayExCreateCredentials,
        payload: {
            accountId,
            userId,
            data,
        },
    };
}

export interface FetchPayExCreateCredentialsResponse {
    type: ActionTypes.FetchPayExCreateCredentialsResponse;
    payload: {
        response: PayExConnection;
    };
    meta: {
        cause: PayExCreateCredentials;
    };
}

export interface FetchCompletePayExConnectionResponse {
    type: ActionTypes.FetchCompletePayExConnectionResponse;
    payload: {
        response: PayExConnection;
    };
    meta: {
        cause: CompletePayExConnection;
    };
}

export interface PayExApiError {
    type: ActionTypes.PayExApiError;
    payload: {
        error?: any;
    };
}

export type Actions =
    | OpenPayExCallbackModal
    | ClosePayExCallbackModal
    | CompletePayExConnection
    | PayExCreateCredentials
    | FetchCompletePayExConnectionResponse
    | FetchPayExCreateCredentialsResponse
    | PayExApiError;
