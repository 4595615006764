import { PaymentType } from "../types/checkout";

export const allPaymentTypes: PaymentType[] = [
    "instabank.finance",
    "instabank.invoice",
    "instabank.installment",
    "instabank.postponement",
    "vipps",
    "payex.applepay",
    "payex.googlepay",
    "payex.clicktopay",
    "payex.creditcard",
    "payex.swish",
    "payex.vipps",
    "payex.mobilepay",
    "collector.invoice",
    "collector.invoice_b2b",
    "collector.invoice_b2b_preapproved",
    "collector.finance",
    "collector.installment",
    "santander.debit_account",
    "swish.swish",
    "bambora.creditcard",
    "bambora.vipps",
    "bambora.mobilepay",
    "klarna.klarna",
    "klarna.billie",
    "dintero_psp.creditcard",
];
