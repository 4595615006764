import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components/macro";

import {
    colors,
    defaultRadius,
    distances,
    palette,
    shadows,
    zIndex,
} from "../../styles/constants";
import { Button, ButtonGroup } from "../Buttons";

export type OnChangeFunction = (event: Event) => void;

interface BaseFilterProps extends WithTranslation {
    initialOpen: boolean;
    label: any;
    name: string;
    disabled: boolean | undefined;
    applyIsDisabled?: boolean;
    onApply: () => void;
    onDelete: () => void;
    useAsToggleButton?: boolean;
    onCloseMenu?: () => void;
}

interface FilterSelectState {
    open: boolean;
}

class BaseFilter extends Component<BaseFilterProps, FilterSelectState> {
    wrapperRef: any = null;

    constructor(props: BaseFilterProps, context: any) {
        super(props, context);
        this.state = {
            open: props.initialOpen,
        };
        this.toggleOpen = this.toggleOpen.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.onApply = this.onApply.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.handleFilterButtonClick = this.handleFilterButtonClick.bind(this);
        this.wrapperRef = React.createRef();
    }
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    setWrapperRef(node: any) {
        this.wrapperRef = node;
    }

    handleClick(event: any) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ open: false }, () => {
                this.props.onCloseMenu && this.props.onCloseMenu();
                if (!this.props.label) {
                    this.props.onDelete && this.props.onDelete();
                }
            });
        }
    }

    toggleOpen() {
        this.setState({ open: !this.state.open });
    }

    onApply() {
        this.toggleOpen();
        this.props.onApply();
    }

    onDelete() {
        this.toggleOpen();
        this.props.onDelete();
    }

    handleFilterButtonClick() {
        if (this.props.useAsToggleButton) {
            this.props.onDelete();
        } else {
            this.toggleOpen();
        }
    }

    render() {
        const { t, label, name, disabled, children, applyIsDisabled } =
            this.props;
        const { open } = this.state;
        return (
            <Wrapper ref={this.setWrapperRef}>
                <FilterButton
                    id={name}
                    name={name}
                    onClick={this.handleFilterButtonClick}
                    className="selected"
                    disabled={disabled}
                >
                    {label}
                </FilterButton>
                <Collapsable open={open}>
                    <Content>{children}</Content>
                    <Buttons>
                        <Button className="alt" onClick={this.onDelete}>
                            {t("components.filters.remove")}
                        </Button>
                        <Button
                            className=""
                            onClick={this.onApply}
                            disabled={
                                applyIsDisabled !== undefined && applyIsDisabled
                            }
                        >
                            {t("components.filters.apply_filter")}
                        </Button>
                    </Buttons>
                </Collapsable>
            </Wrapper>
        );
    }
}

const Buttons = styled(ButtonGroup)`
    position: sticky;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    padding-top: ${distances.tiny};
    padding-bottom: ${distances.tiny};
    z-index: ${zIndex.top};
    padding-left: ${distances.tiny};
    padding-right: ${distances.tiny};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Content = styled.div`
    padding-left: ${distances.tiny};
    padding-right: ${distances.tiny};
    padding-top: ${distances.tiny};
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

const FilterButton = styled(Button)`
    &.alt.selected {
    }
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 24px;
    background: ${palette.neutral[100]};
    color: ${palette.neutral[700]};
    border-color: ${palette.neutral[100]};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    min-height: 30px;
    min-width: 30px;
    &:hover {
        background: ${palette.neutral[200]};
        border-color: ${palette.neutral[300]};
        color: ${palette.neutral[800]};
    }
    &:focus {
        background: ${palette.neutral[200]};
        border-color: ${palette.neutral[300]};
        color: ${palette.neutral[800]};
    }
    &:active {
        background: ${palette.neutral[300]};
        border-color: ${palette.neutral[400]};
        color: ${palette.neutral[900]};
    }
`;

const Wrapper = styled.div`
    position: relative;
`;

interface CollapsableProps {
    open: boolean;
}
const Collapsable = styled.div<CollapsableProps>`
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 40px;
    z-index: ${zIndex.top};
    background-color: ${colors.background};
    box-shadow: ${shadows.large};
    transition: all 0.15s ease;
    transform-origin: top center;
    border: 1px solid ${colors.borderDark};
    border-radius: 8px;
    overflow-x: hidden;
    ${(props) =>
        props.open
            ? `
        transform: translateY(0);
        max-height: 400px;
        overflow-y: auto;
        opacity: 1;
    `
            : `
        transform: translateY(-1%);
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0;
        border-width: 0;
        overflow-y: hidden;
        opacity: 0;
    `};
    white-space: nowrap;

    scrollbar-width: thin;
    scrollbar-color: ${palette.neutral[200]} transparent;

    @media (max-width: 768px) {
      position: fixed;
      right: 0;
      left: 0;
      top: 100px;
      width: 90%;
      margin: 0 auto;

      z-index: ${zIndex.top + 10}; /* keep on top of other elements on the page */
      outline: 9999px solid rgba(0,0,0,0.6);
    }

    &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${palette.neutral[200]};
        border-radius: 3px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
`;

export default withTranslation()<React.ComponentType<BaseFilterProps>>(
    BaseFilter,
);
