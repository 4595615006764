import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { colors, palette } from "../../styles/constants";

interface ToggleProps extends WithTranslation {
    value: boolean;
    onFill?: string;
    onText?: string;
    offText?: string;
    onClick: (nextValue: boolean) => void;
    id?: string;
    disabled?: boolean;
}

const Toggle = ({
    value,
    onClick,
    t,
    onFill,
    onText,
    offText,
    id,
    disabled,
}: ToggleProps) => {
    return (
        <ToggleArea
            id={id}
            onClick={disabled ? undefined : () => onClick(!value)}
            value={value}
            fill={onFill}
            tabIndex={0}
            role="button"
            href="#"
            disabled={disabled}
        >
            <Switch value={value} />
        </ToggleArea>
    );
};

interface ToggleAreaProps {
    value: boolean;
    fill?: string;
    disabled?: boolean;
}

const ToggleArea = styled.a.attrs(() => ({
    className: "toggle-area",
}))<ToggleAreaProps>`
    display: inline-block;
    background: ${(props) => (props.value ? props.fill || colors.text : palette.neutral[200])};
    width: 26px;
    height: 16px;
    border-radius: 50px;
    margin-left: 8px;
    overflow: hidden;
    cursor: pointer;
    ${(props) =>
        props.disabled &&
        `
        pointer-events: none;
        opacity: 0.5;
        cursor: default;
    `};
`;

interface SwitchProps {
    value: boolean;
}

export const Switch = styled.span<SwitchProps>`
    transition: all ease-in 200ms;
    position: relative;
    display: block;
    width: 14px;
    height: 14px;
    background: #fff;
    border-radius: 50px;
    margin-left: ${(props) => (props.value ? "11px" : "1px")};
    margin-top: 1px;
    transition: all ease-in 200ms;

    &::before {
        width: 100px;
        position: absolute;
        top: 2px;
        right: 20px;
        font-size: 11px;
        text-align: right;
        color: ${(props) => (props.value ? "#fff" : "transparent")};
        transition: color ease-in 200ms;
    }

    &::after {
        width: 100px;
        position: absolute;
        top: 2px;
        left: 20px;
        color: ${(props) => (!props.value ? "#fff" : "#transparent")};
        font-size: 11px;
        transition: color ease-in 200ms;
    }
`;

export default withTranslation()(Toggle);
