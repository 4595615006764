import { Route } from "react-router-dom";

import LocationDetails from "./locationDetails";
import LocationEdit from "./locationEdit";
import LocationsList from "./locationsList";
import NewLocation from "./newLocation";

const Routes = [
    <Route
        key="locations"
        path="/:accountId/settings/locations"
        exact
        component={LocationsList}
    />,
    <Route
        key="newLocation"
        path="/:accountId/settings/locations/new"
        exact
        component={NewLocation}
    />,
    <Route
        key="locationDetails"
        path="/:accountId/settings/locations/:locationId"
        exact
        component={LocationDetails}
    />,
    <Route
        key="locationEdit"
        path="/:accountId/settings/locations/:locationId/edit"
        exact
        component={LocationEdit}
    />,
];

export default Routes;
