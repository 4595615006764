import { BillingPayoutRule } from "../../../types/billing";
import { SalesLocation } from "../../../types/customers";

export enum ActionTypes {
    CreatePayoutRule = "[payout_rules/new_payout_rule] CREATE_PAYOUT_RULE",
    CreatePayoutRuleResponse = "[payout_rules/new_payout_rule] FETCH_POST_PAYOUT_RULE_RESPONSE",
    ResetForm = "[payout_rules/new_payout_rule] RESET_CREATE_PAYOUT_RULE_FORM",
    FetchLocations = "[payout_rules/new_payout_rule] FETCH_LOCATIONS",
    FetchLocationsResponse = "[payout_rules/new_payout_rule] FETCH_LOCATIONS_RESPONSE",
}

export interface CreatePayoutRule {
    type: ActionTypes.CreatePayoutRule;
    payload: {
        accountId: string;
        newPayoutRule: BillingPayoutRule;
    };
}

export interface FetchCreatePayoutRuleResponse {
    type: ActionTypes.CreatePayoutRuleResponse;
    payload: {
        payoutRule: BillingPayoutRule;
    };
    meta: {
        cause: CreatePayoutRule;
    };
}

export function createPayoutRule(
    accountId: string,
    newPayoutRule: BillingPayoutRule,
): CreatePayoutRule {
    return {
        type: ActionTypes.CreatePayoutRule,
        payload: {
            accountId,
            newPayoutRule,
        },
    };
}

export interface ResetForm {
    type: ActionTypes.ResetForm;
    payload: {};
}

export function resetForm() {
    return {
        type: ActionTypes.ResetForm,
        payload: {},
    };
}

export interface FetchLocations {
    type: ActionTypes.FetchLocations;
    payload: {
        accountId: string;
    };
}

export interface FetchLocationsResponse {
    type: ActionTypes.FetchLocationsResponse;
    payload: SalesLocation[];
    meta: {
        cause: FetchLocations;
    };
}

export const fetchLocations = (accountId: string): FetchLocations => {
    return {
        type: ActionTypes.FetchLocations,
        payload: {
            accountId,
        },
    };
};

export type Actions =
    | CreatePayoutRule
    | FetchCreatePayoutRuleResponse
    | ResetForm
    | FetchLocations
    | FetchLocationsResponse;
