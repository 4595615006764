import { LocationFormValues } from "../../locations/types";

import { Formik } from "formik";
import { ReactNode, memo } from "react";
import { TFunction, useTranslation } from "react-i18next";

import _upperFirst from "lodash/upperFirst";
import styled from "styled-components/macro";
import { useAccountIds } from "../../../auth/accessToken/components/withAccountIds";
import { Button, ButtonGroup } from "../../../components/Buttons";
import {
    Checkbox,
    Dropdown,
    Input,
    PhoneNumber,
} from "../../../components/Forms";
import Grid, { GridItem } from "../../../components/Grid";
import Icon from "../../../components/Icons";
import Modal from "../../../components/Modal";
import { H2, H6 } from "../../../components/Typography";
import View from "../../../components/View";
import { getPostalPlace } from "../../../helpers/dataload/postalCodes";
import { defaultRadius, palette } from "../../../styles/constants";
import useAccount from "../../account/hooks/useAccount";
import EnvironmentDropdown, {
    EnvironmentDropdownProps,
} from "../../components/EnvironmentDropdown";
import {
    getCountryOptions,
    getTypeOptions,
} from "../../locations/shared/options";
import {
    getPhonePrefixFromCountry,
    validateInput,
} from "../../locations/shared/utils";
import { validateValues } from "../validation/SellerLocationFormValidation";

/**
 * Dialog props for the seller location form
 */
type SellerLocationFormDialogProps = {
    /** Should the dialog be visible */
    open: boolean;
    /** What should happen when the dialog is closed */
    onClose: () => void;
    /** Title of the dialog */
    title: string;
    /** Submit button text */
    saveText: string;
    /** Cancel button text */
    cancelText: string;
};

/**
 * Props for the seller location form itself
 */
export interface SellerLocationFormProps {
    /** Initial location form values */
    locationFormValues?: LocationFormValues;
    /** What should happen on submit */
    onSubmit: (values: LocationFormValues) => void;
    /** Account ids (for enviroment dropdown) */
    accountIds?: string[];
    /** Should the seller location form submit on blur, useful in a parent form */
    autoSave?: boolean;
    /** Should the form show up as a dialog */
    asDialog?: SellerLocationFormDialogProps;
    /** A Sellers organization number (bank account is necessary to be added) */
    organizationNumber?: string;
    /** Show the environment dropdown menu */
    showEnvMenu?: boolean;
    /** What should happen when a location form is deleted (in a list view) */
    onDelete?: (values: LocationFormValues) => void;
    /** Validate the form inside with an external validator */
    externalValidator?: (
        values: LocationFormValues,
        t: TFunction,
    ) => { [key: string]: string };
    /** What should happen on error */
    onError?: (hasErrors: boolean) => void;
    /** What should happen on blur */
    onBlur?: (values: LocationFormValues) => void;
}

/**
 * Agnostic seller location form with a necessary submit handler and optional props
 */
const SellerLocationForm = ({
    asDialog,
    locationFormValues,
    onSubmit,
    onDelete,
    externalValidator,
    onError,
    onBlur,
    accountIds,
    organizationNumber,
    showEnvMenu,
    autoSave = true,
}: SellerLocationFormProps) => {
    const { t } = useTranslation("app");
    const { account } = useAccount();
    const allAccountIds = useAccountIds();
    const usableAccountIds = accountIds ?? [
        allAccountIds.prodAccountId,
        allAccountIds.testAccountId,
    ];
    const initialAccountId =
        allAccountIds.urlAccountId || allAccountIds.testAccountId!;
    const country = account?.company?.address?.country;
    const typeOptions = getTypeOptions(t);
    const countryOptions = getCountryOptions(t);

    const Form = ({
        children,
    }: {
        children: (hasErrors: boolean, values: LocationFormValues) => ReactNode;
    }) => (
        <Formik
            initialValues={
                locationFormValues ??
                ({
                    accountId: initialAccountId,
                    locationId: "",
                    country: {
                        label:
                            country === "NO"
                                ? t("locations.location_form.fields.norway")
                                : t("locations.location_form.fields.sweden"),
                        value: country,
                    },
                    addressLine: "",
                    addressLine2: "",
                    businessName: "",
                    countryPrefix: getPhonePrefixFromCountry(country),
                    phoneNumber: "",
                    email: "",
                    franchise: false,
                    franchiseName: "",
                    latitude: 0,
                    longitude: 0,
                    name: "",
                    postalCode: "",
                    postalPlace: "",
                    websiteUrl: "",
                    type: typeOptions[0],
                    city: "",
                    organizationNumber,
                } as LocationFormValues)
            }
            validateOnChange
            validateOnBlur
            validate={(values) =>
                externalValidator
                    ? externalValidator(values, t)
                    : validateValues(values, t)
            }
            onSubmit={(values) => {
                onSubmit(values);
                if (asDialog) {
                    asDialog.onClose();
                }
            }}
            enableReinitialize
        >
            {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                errors,
                touched,
            }) => {
                const blur = (e: any) => {
                    handleBlur(e);
                    if (autoSave) {
                        handleSubmit(e);
                        if (onBlur) {
                            onBlur(values);
                        }
                    }
                };
                return (
                    <form onSubmit={handleSubmit}>
                        {!asDialog && (
                            <View width="100%" justify="space-between">
                                <H6>{t("marketing.campaign.location")}</H6>
                                {onDelete && (
                                    <Button
                                        className="alt tiny"
                                        onClick={() => onDelete(values)}
                                    >
                                        <Icon icon="close" />
                                    </Button>
                                )}
                            </View>
                        )}
                        <Grid gap={16} columns={2} alignItems="center">
                            {showEnvMenu && (
                                <GridItem>
                                    <EnvironmentDropdown
                                        {...({
                                            label: t(
                                                "locations.location_form.fields.environment",
                                            ),
                                            placeholder: t(
                                                "locations.location_form.fields.environment",
                                            ),
                                            value: values.accountId,
                                            name: "accountId",
                                            onChange: (value: string) => {
                                                setFieldValue(
                                                    "accountId",
                                                    value,
                                                );
                                            },
                                            accountIds: usableAccountIds,
                                        } as EnvironmentDropdownProps)}
                                    />
                                </GridItem>
                            )}
                            <GridItem>
                                <Input
                                    name={"locationId"}
                                    label={t(
                                        "locations.location_form.fields.location_id",
                                    )}
                                    placeholder={t(
                                        "locations.location_form.placeholders.location_id",
                                    )}
                                    value={values.locationId}
                                    onChange={handleChange}
                                    onBlur={blur}
                                    validation={validateInput(
                                        "locationId",
                                        errors,
                                        touched,
                                    )}
                                    required
                                    controlled
                                />
                            </GridItem>
                            <GridItem>
                                <Input
                                    name={"name"}
                                    label={t(
                                        "locations.location_form.fields.name",
                                    )}
                                    placeholder={t(
                                        "locations.location_form.placeholders.name",
                                    )}
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={blur}
                                />
                            </GridItem>
                            <GridItem>
                                <Dropdown
                                    required
                                    name={"country"}
                                    label={t(
                                        "locations.location_form.fields.country",
                                    )}
                                    value={values.country}
                                    options={[countryOptions[0]]}
                                    onChange={(option) => {
                                        setFieldValue("country", option);
                                        setFieldValue(
                                            "countryPrefix",
                                            getPhonePrefixFromCountry(
                                                option.value,
                                            ),
                                        );
                                    }}
                                />
                            </GridItem>
                            <GridItem>
                                <Input
                                    name={"addressLine"}
                                    label={t(
                                        "locations.location_form.fields.address",
                                    )}
                                    placeholder={t(
                                        "locations.location_form.placeholders.address_line",
                                    )}
                                    value={values.addressLine}
                                    onChange={handleChange}
                                    onBlur={blur}
                                    validation={validateInput(
                                        "addressLine",
                                        errors,
                                        touched,
                                    )}
                                />
                            </GridItem>
                            <GridItem>
                                <Input
                                    name={"postalPlace"}
                                    label={t(
                                        "locations.location_form.fields.city",
                                    )}
                                    placeholder={t(
                                        "locations.location_form.placeholders.postal_place",
                                    )}
                                    value={values.postalPlace}
                                    onChange={handleChange}
                                    onBlur={blur}
                                    validation={validateInput(
                                        "postalPlace",
                                        errors,
                                        touched,
                                    )}
                                />
                            </GridItem>
                            <GridItem>
                                <Input
                                    name={"postalCode"}
                                    label={t(
                                        "locations.location_form.fields.postcode",
                                    )}
                                    placeholder={t(
                                        "locations.location_form.placeholders.postal_code",
                                    )}
                                    value={values.postalCode}
                                    onChange={(event) => {
                                        getPostalPlace(
                                            values.country.value,
                                            event.currentTarget.value,
                                        ).then((place: string) => {
                                            if (place) {
                                                setFieldValue(
                                                    "postalPlace",
                                                    _upperFirst(
                                                        place.toLowerCase(),
                                                    ),
                                                );
                                            }
                                        });
                                        handleChange(event);
                                    }}
                                    onBlur={blur}
                                    validation={validateInput(
                                        "postalCode",
                                        errors,
                                        touched,
                                    )}
                                />
                            </GridItem>
                            <GridItem>
                                <Dropdown
                                    name={"type"}
                                    label={t(
                                        "locations.location_form.fields.type",
                                    )}
                                    value={values.type}
                                    options={typeOptions}
                                    onChange={(value) =>
                                        setFieldValue("type", value)
                                    }
                                />
                            </GridItem>
                            <GridItem>
                                <Input
                                    name={"websiteUrl"}
                                    label={t(
                                        "locations.location_form.fields.website",
                                    )}
                                    placeholder={t(
                                        "locations.location_form.placeholders.website",
                                    )}
                                    value={values.websiteUrl}
                                    onChange={handleChange}
                                    onBlur={blur}
                                />
                            </GridItem>
                            <GridItem>
                                <PhoneNumber
                                    name={"phoneNumber"}
                                    label={t(
                                        "locations.location_form.fields.phone_number",
                                    )}
                                    phoneNumber={values.phoneNumber}
                                    countryPrefix={values.countryPrefix}
                                    countryPrefixName="countryPrefix"
                                    setFieldValue={setFieldValue}
                                    onBlur={blur}
                                    disabled={false}
                                    autoComplete="off"
                                    validation={validateInput(
                                        "phoneNumber",
                                        errors,
                                        touched,
                                    )}
                                />
                            </GridItem>
                            <GridItem>
                                <Input
                                    name={"email"}
                                    label={t(
                                        "locations.location_form.fields.email",
                                    )}
                                    placeholder={t(
                                        "locations.location_form.placeholders.email",
                                    )}
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={blur}
                                />
                            </GridItem>
                            <GridItem>
                                <Checkbox
                                    name="franchise"
                                    label={t(
                                        "locations.location_form.fields.franchise",
                                    )}
                                    checked={values.franchise}
                                    onChange={() =>
                                        setFieldValue(
                                            "franchise",
                                            !values.franchise,
                                        )
                                    }
                                />
                            </GridItem>
                            <GridItem>
                                <Input
                                    name={"franchiseName"}
                                    label={t(
                                        "locations.location_form.fields.franchise_name",
                                    )}
                                    placeholder={t(
                                        "locations.location_form.placeholders.franchise_name",
                                    )}
                                    value={values.franchiseName}
                                    onChange={handleChange}
                                    onBlur={blur}
                                    validation={validateInput(
                                        "franchiseName",
                                        errors,
                                        touched,
                                    )}
                                    disabled={!values.franchise}
                                />
                            </GridItem>
                        </Grid>
                        {children(
                            Boolean(Object.keys(errors).length > 0),
                            values,
                        )}
                    </form>
                );
            }}
        </Formik>
    );

    if (asDialog) {
        return asDialog.open ? (
            <Modal onClose={asDialog.onClose}>
                <H2>{asDialog.title}</H2>
                <Form>
                    {(hasErrors, values) => (
                        <ButtonGroup>
                            <Button
                                type="reset"
                                onClick={asDialog.onClose}
                                className="alt"
                            >
                                {asDialog.cancelText}
                            </Button>
                            <Button
                                type="submit"
                                disabled={
                                    hasErrors || !values.locationId.length
                                }
                            >
                                {asDialog.saveText}
                            </Button>
                        </ButtonGroup>
                    )}
                </Form>
            </Modal>
        ) : null;
    }

    return (
        <Wrapper>
            <Form>
                {(hasErrors, values) => {
                    if (onError && hasErrors) {
                        onError(hasErrors);
                    }
                    return (
                        !autoSave && (
                            <ButtonGroup>
                                <Button
                                    type="submit"
                                    disabled={
                                        hasErrors || !values.locationId.length
                                    }
                                >
                                    {t("locations.location_edit.action_button")}
                                </Button>
                            </ButtonGroup>
                        )
                    );
                }}
            </Form>
        </Wrapper>
    );
};

const Wrapper = styled(View).attrs({
    direction: "column",
    alignItems: "unset",
    p: "24px",
})`
    border: 1px solid ${palette.neutral[100]};
    border-radius: ${defaultRadius};
`;

export default memo(SellerLocationForm);
