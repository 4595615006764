import { CORE_API_HOSTNAME } from "../../../env";
import fulfill from "../../../fulfill";
import { SideEffectFunction } from "../../../sideEffects";
import { BillingPayoutRule } from "../../../types/billing";
import { SalesLocation } from "../../../types/customers";
import {
    ActionTypes,
    CreatePayoutRule,
    FetchCreatePayoutRuleResponse,
    FetchLocations,
    FetchLocationsResponse,
} from "./actions";

const fetchCreatePayoutRule: SideEffectFunction<
    CreatePayoutRule,
    FetchCreatePayoutRuleResponse
> = async (action) => {
    const account = action.payload.accountId;
    const result: FetchCreatePayoutRuleResponse =
        await fulfill.post<BillingPayoutRule>({
            accountId: account || "",
            url: `${CORE_API_HOSTNAME}/v1/accounts/${account}/billing/payout-rules`,
            json: action.payload.newPayoutRule,
            handlers: {
                200: (payoutRule: BillingPayoutRule) => ({
                    type: ActionTypes.CreatePayoutRuleResponse,
                    payload: {
                        payoutRule,
                    },
                    meta: {
                        cause: action,
                    },
                }),
            },
        });
    return result;
};

// todo, migrate to useActions('settings.locations').getLocations
export const fetchLocations: SideEffectFunction<
    FetchLocations,
    FetchLocationsResponse
> = async (action) => {
    const { accountId } = action.payload;
    const result: FetchLocationsResponse = await fulfill.get({
        accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/locations/?limit=100`,
        handlers: {
            200: (locations: SalesLocation[]) => ({
                type: ActionTypes.FetchLocationsResponse,
                payload: locations,
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const effects = {
    [ActionTypes.CreatePayoutRule]: fetchCreatePayoutRule,
    [ActionTypes.FetchLocations]: fetchLocations,
};

export default effects;
