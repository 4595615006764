import React from "react";
import styled from "styled-components/macro";

import { border, colors, distances } from "../styles/constants";

export type OnTabClickFunction = (id: string) => void;

export interface TabGroup {
    id: string;
    option: string;
    content: any;
}

interface TabsProps {
    groups: TabGroup[];
    active: string;
    onTabClick: OnTabClickFunction;
}

export const Tabs = ({ groups, active, onTabClick }: TabsProps) => {
    const activeGroup = groups.find((g) => g.id === active) || { content: "" };
    return (
        <React.Fragment>
            <TabBar>
                {groups.map((group) => (
                    <Tab
                        key={group.id}
                        onClick={() => onTabClick(group.id)}
                        className={group.id === active ? "active" : ""}
                    >
                        {group.option}
                    </Tab>
                ))}
            </TabBar>
            {activeGroup.content}
        </React.Fragment>
    );
};

const TabBar = styled.div``;
const Tab = styled.div`
    display: inline-block;
    min-width: 114px;
    font-size: 14px;
    padding-left: 2px;
    padding-right: 2px;
    font-weight: 700;
    line-height: ${distances.normal};
    border-bottom: ${border.normal} solid ${colors.borderLight};
    cursor: pointer;

    &.active {
        border-bottom-color: ${colors.primary};
    }
`;
