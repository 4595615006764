import React from "react";
import { Bambora } from "./bambora";
import { Collector } from "./collector";
import { CollectorCallback } from "./collectorCallback";
import { Instabank } from "./instabank";
import { Klarna } from "./klarna";
import { Payex } from "./payex";
import { Payex as PayexCallback } from "./payexCallback";
import { Swish } from "./swish";
import { Vipps } from "./vipps";

const ConnectionModals = () => (
    <>
        <Vipps />
        <Instabank />
        <Payex />
        <PayexCallback />
        <Collector />
        <CollectorCallback />
        <Swish />
        <Klarna />
        <Bambora />
    </>
);

export default ConnectionModals;
