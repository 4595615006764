import { paramsEqual } from "../../../helpers/urlParams";
import { BillingPayoutRule } from "../../../types/billing";
import { CheckoutConfig } from "../../../types/checkout";
import {
    ActionTypes,
    Actions,
    GetPayoutRules,
    GetPayoutRulesReason,
} from "./actions";

export interface State {
    isLoading: boolean;
    limit: number;
    payoutRules: BillingPayoutRule[];
    hasNext: boolean;
    accountId: string;
    params: URLSearchParams;
    requestId: number;
    checkoutConfiguration: CheckoutConfig | undefined;
}

export const initialState: State = {
    isLoading: true,
    limit: 100,
    payoutRules: [],
    hasNext: true,
    accountId: "",
    params: new URLSearchParams(),
    requestId: 0,
    checkoutConfiguration: undefined,
};

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {
        case ActionTypes.GetPayoutRules:
            const clearList = shouldClearList(action, state);
            return {
                ...state,
                isLoading: true,
                isInvalid: false,
                accountId: action.payload.accountId,
                payoutRules: clearList ? [] : state.payoutRules,
                params: action.payload.params,
                requestId: action.payload.requestId,
            };

        case ActionTypes.FetchPayoutRulesResponse:
            return {
                ...state,
                isLoading: false,
                payoutRules: action.payload.payoutRules,
            };

        case ActionTypes.FetchCheckoutConfigurationResponse:
            return {
                ...state,
                isLoading: false,
                checkoutConfigurations: action.payload.configuration,
            };

        case ActionTypes.ClearData:
            return initialState;

        default:
            return state;
    }
}

const shouldClearList = (action: GetPayoutRules, state: State) => {
    if (action.payload.accountId.substr(1) !== state.accountId.substr(1)) {
        return true;
    }
    if (action.payload.reason === GetPayoutRulesReason.update) {
        return true;
    }
    const blacklist = ["starting_after", "limit"];
    return !paramsEqual(action.payload.params, state.params, blacklist);
};
