import useSWR from "swr";
import { useAccountId } from "../../../../auth/useAccountId";
import useHasAnyOfScopes from "../../../../common/hooks/useHasAnyOfScopes";
import { CORE_API_HOSTNAME } from "../../../../env";
import fulfill from "../../../../fulfill";
import type { AccountAdminUser } from "../../../../types/management-auth";

const fetchUsers = async (payload: {
    accountId: string;
}): Promise<AccountAdminUser[]> => {
    return await fulfill.get({
        url: `${CORE_API_HOSTNAME}/v1/accounts/${payload.accountId}/management/users`,
        accountId: payload.accountId,
        handlers: {
            200: (users: AccountAdminUser[]) => {
                return users;
            },
            401: () => [],
            403: () => [],
            500: () => [],
        },
    });
};

export default function useUsers() {
    const accountId = useAccountId();
    const hasScopes = useHasAnyOfScopes(["read:accounts", "admin:accounts"]);
    const {
        data: users,
        isLoading,
        mutate,
    } = useSWR(
        () =>
            accountId && hasScopes
                ? { accountId, key: `users-${accountId}` }
                : null,
        fetchUsers,
    );

    return {
        isLoading,
        users: users || [],
        refresh: mutate,
    };
}
