import { ProfileConfiguration } from "../types/checkout";

const getProfilePaymentTypes = (profile: ProfileConfiguration) => {
    if (!profile || !profile.configuration) {
        return [];
    }
    return [
        {
            type: "bambora.creditcard",
            enabled:
                profile.configuration.bambora &&
                profile.configuration.bambora.creditcard &&
                profile.configuration.bambora.creditcard.enabled,
        },
        {
            type: "bambora.vipps",
            enabled:
                profile.configuration.bambora &&
                profile.configuration.bambora.vipps &&
                profile.configuration.bambora.vipps.enabled,
        },
        {
            type: "bambora.mobilepay",
            enabled:
                profile.configuration.bambora &&
                profile.configuration.bambora.mobilepay &&
                profile.configuration.bambora.mobilepay.enabled,
        },
        {
            type: "payex.applepay",
            enabled:
                profile.configuration.payex &&
                profile.configuration.payex.applepay &&
                profile.configuration.payex.applepay.enabled,
        },
        {
            type: "payex.googlepay",
            enabled:
                profile.configuration.payex &&
                profile.configuration.payex.googlepay &&
                profile.configuration.payex.googlepay.enabled,
        },
        {
            type: "payex.clicktopay",
            enabled:
                profile.configuration.payex &&
                profile.configuration.payex.clicktopay &&
                profile.configuration.payex.clicktopay.enabled,
        },
        {
            type: "payex.creditcard",
            enabled:
                profile.configuration.payex &&
                profile.configuration.payex.creditcard &&
                profile.configuration.payex.creditcard.enabled,
        },
        {
            type: "payex.mobilepay",
            enabled:
                profile.configuration.payex &&
                profile.configuration.payex.mobilepay &&
                profile.configuration.payex.mobilepay.enabled,
        },
        {
            type: "payex.swish",
            enabled:
                profile.configuration.payex &&
                profile.configuration.payex.swish &&
                profile.configuration.payex.swish.enabled,
        },
        {
            type: "payex.vipps",
            enabled:
                profile.configuration.payex &&
                profile.configuration.payex.vipps &&
                profile.configuration.payex.vipps.enabled,
        },
        {
            type: "instabank.finance",
            enabled:
                profile.configuration.instabank &&
                profile.configuration.instabank.finance &&
                profile.configuration.instabank.finance.enabled,
        },
        {
            type: "instabank.installment",
            enabled:
                profile.configuration.instabank &&
                profile.configuration.instabank.installment &&
                profile.configuration.instabank.installment.enabled,
        },
        {
            type: "instabank.invoice",
            enabled:
                profile.configuration.instabank &&
                profile.configuration.instabank.invoice &&
                profile.configuration.instabank.invoice.enabled,
        },
        {
            type: "instabank.postponement",
            enabled:
                profile.configuration.instabank &&
                profile.configuration.instabank.postponement &&
                profile.configuration.instabank.postponement.enabled,
        },
        {
            type: "collector.finance",
            enabled:
                profile.configuration.collector &&
                profile.configuration.collector.finance &&
                profile.configuration.collector.finance.enabled,
        },
        {
            type: "collector.invoice",
            enabled:
                profile.configuration.collector &&
                profile.configuration.collector.invoice &&
                profile.configuration.collector.invoice.enabled,
        },
        {
            type: "collector.invoice_b2b",
            enabled:
                profile.configuration.collector &&
                profile.configuration.collector.invoice_b2b &&
                profile.configuration.collector.invoice_b2b.enabled,
        },
        {
            type: "collector.installment",
            enabled:
                profile.configuration.collector &&
                profile.configuration.collector.installment &&
                profile.configuration.collector.installment.enabled,
        },
        {
            type: "santander.debit_account",
            enabled:
                profile.configuration.santander &&
                profile.configuration.santander.debit_account &&
                profile.configuration.santander.debit_account.enabled,
        },
        {
            type: "swish.swish",
            enabled:
                profile.configuration.swish &&
                profile.configuration.swish.swish &&
                profile.configuration.swish.swish.enabled,
        },
        {
            type: "netaxept.creditcard",
            enabled:
                profile.configuration.netaxept &&
                profile.configuration.netaxept.creditcard &&
                profile.configuration.netaxept.creditcard.enabled,
        },
        {
            type: "vipps",
            enabled:
                profile.configuration.vipps &&
                profile.configuration.vipps.enabled,
        },
        {
            type: "klarna.klarna",
            enabled:
                profile.configuration.klarna &&
                profile.configuration.klarna.klarna &&
                profile.configuration.klarna.klarna.enabled,
        },
        {
            type: "klarna.billie",
            enabled:
                profile.configuration.klarna &&
                profile.configuration.klarna.billie &&
                profile.configuration.klarna.billie.enabled,
        },
        {
            type: "dintero_psp.creditcard",
            enabled:
                profile.configuration.dintero_psp &&
                profile.configuration.dintero_psp.creditcard &&
                profile.configuration.dintero_psp.creditcard.enabled,
        },
    ]
        .filter((x) => x.enabled)
        .map((x) => x.type);
};

export default getProfilePaymentTypes;
